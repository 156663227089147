import { ChartOptions } from 'chart.js'

export function chartOptions(xAxisdisplayUnit?: any) {
  const result: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        type: 'time',
        time: {
          unit: xAxisdisplayUnit,
          tooltipFormat: 'll',
          displayFormats: {
            quarter: 'MMM YY',
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${Number(value).toLocaleString()}`,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        displayColors: true,
        multiKeyBackground: 'transparent',
        padding: 10,
        titleMarginBottom: 10,
        bodySpacing: 10,
        callbacks: {
          label: (tooltipItem) => {
            let value = ''
            if (tooltipItem) {
              let label = tooltipItem.dataset.label
              let { y }: any = tooltipItem.dataset.data[tooltipItem.dataIndex]
              value = `${label}: ${Number(y).toLocaleString()}`
            }
            return value
          },

          labelColor: (tooltipItem) => {
            let bgColor: any = ''
            if (tooltipItem) {
              bgColor = tooltipItem.dataset.backgroundColor
            }
            return {
              backgroundColor: bgColor,
              borderColor: 'transparent',
            }
          },
        },
      },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
  }

  return result
}
