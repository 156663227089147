import { OrderReviseExpenseRateInfo_field, OrderRevise_fields } from 'src/types/globalTypes'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'

interface Props {
  revise?: OrderRevise_fields
  shipping_expense_locale_currency: number
  shipping_cost: number
  total_net_weight: number
  currency: string | null
  unit_code?: string
}
export function pi_expense2(props: Props) {
  const { revise, shipping_cost, shipping_expense_locale_currency, total_net_weight, currency, unit_code } = props
  let items = makeData(revise?.expense_rate_info || [], total_net_weight)

  return {
    style: 'table',
    fontSize: 9,
    table: {
      widths: [110, 50, 40],
      headerRows: 1,
      body: [
        [
          {
            style: 'tColumn',
            text: 'DETAIL OF EXPENSES',
          },
          {
            style: 'tColumn',
            text: `TOTAL(${currency}).`,
          },
          {
            style: 'tColumn',
            text: `${currency}/${unit_code?.toUpperCase() || ''}.`,
          },
        ],
        ...items,

        [
          { text: `Shipping Expense (${currency}).` },
          { style: 'trCell', text: `${toDecimalStr(shipping_expense_locale_currency)}` },
          {},
        ],
        [{ text: ' ' }, {}, {}],
        [{ text: ' ' }, {}, {}],
        [{ text: `Shipping Cost.= ${toDecimal(shipping_cost)}` }, {}, {}],
        [{ text: `NET WET.= ${toDecimalStr(total_net_weight)} KG.` }, {}, {}],
        [
          { text: `- Shipping Expense (USD).` },
          {
            style: 'trCell',
            text: `${toDecimalStr(shipping_expense_locale_currency / (revise?.exchange_rate_info.shipping_usd || 0))}`,
          },
          {},
        ],
      ],
    },
  }
}

function makeData(revise: OrderReviseExpenseRateInfo_field[], total_net_weight: number) {
  let formatted_data = revise
    .filter((item) => item.value > 0)
    .map((item) => {
      return [
        {
          style: 'tlCell',
          text: `${item.label}`,
        },
        {
          style: 'trCell',
          text: `${toDecimalStr(item.value)}`,
        },
        {
          style: 'trCell',
          text: `${toDecimal(item.value / total_net_weight)}`,
        },
      ]
    })
  return formatted_data
}
