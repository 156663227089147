export const permissionPages = [
  {
    keyId: 0,
    name: 'customer',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 1,
  },
  {
    keyId: 1,
    name: 'product',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 1,
  },
  {
    keyId: 2,
    name: 'order',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 1,
  },
  {
    keyId: 3,
    name: 'invoice_header',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 1,
  },
  {
    keyId: 4,
    name: 'invoice-1',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 1,
  },
  {
    keyId: 5,
    name: 'invoice-2',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 1,
  },
  {
    keyId: 6,
    name: 'invoice-3',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 1,
  },
  {
    keyId: 7,
    name: 'payment',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 1,
  },
]

export const permissionsCofigurations = [
  {
    keyId: 8,
    name: 'category',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 9,
    name: 'product_type',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 10,
    name: 'size',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 11,
    name: 'pack',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 12,
    name: 'vendor',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 13,
    name: 'currency',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 14,
    name: 'destination_port',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 15,
    name: 'bank_choice',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 16,
    name: 'profile_item',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 17,
    name: 'expenses',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 18,
    name: 'unit_code',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 19,
    name: 'invoice_prefix',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 20,
    name: 'calendar_event',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 21,
    name: 'shipment_type',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
  {
    keyId: 22,
    name: 'country',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 2,
  },
]

export const permissionAdmin = [
  {
    keyId: 23,
    name: 'insight',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 3,
  },
  {
    keyId: 24,
    name: 'user',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 3,
  },
  {
    keyId: 25,
    name: 'permission_group',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 3,
  },
]
export const permissionOrderLineItemsTab = [
  {
    keyId: 26,
    name: 'exchange_rate_expenses',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 4,
  },
  {
    keyId: 27,
    name: 'items_with_costs',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 4,
  },
  {
    keyId: 28,
    name: 'pricing',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 4,
  },
  {
    keyId: 29,
    name: 'overview',
    create: false,
    read: false,
    update: false,
    delete: false,
    tab: 4,
  },
]
