import { toDecimalStr } from 'src/utils/toDecimal'
import _ from 'lodash'
import { InvoiceLineItem_expanded } from 'src/pages/invoices/types'
import { InvoiceGroupItem_fields } from 'src/types/globalTypes'
import getInvoiceLineAmount from '../../getInvoiceLineAmount'

interface Props {
  mark_nos: string | null
  data: InvoiceLineItem_expanded[]
  groupData: InvoiceGroupItem_fields[]
  unit_code: string
  showSample: boolean
}

export function invoice_lineItemsGroupItems3(props: Props) {
  let formatted_lineItmes = makeData(props)
  return formatted_lineItmes
}

function makeData(props: Props) {
  const { data, groupData, unit_code, showSample, mark_nos } = props
  let noneGroup = data
    .filter((item) => !item.group_fk)
    .map((item) => {
      let total_price = getInvoiceLineAmount({
        unit_code,
        weight_mc: item.weight_mc,
        box: item.box,
        price: item.price,
        custom_kg: item.custom_kg,
        showSample,
      })
      return {
        id: '',
        product: `${item.product?.name}`,
        net_weight_kgs: item.net_weight_kgs,
        box: item.box,
        weight_mc: item.weight_mc,
        gross_weight: item.gross_weight,
        gross_weight_kgs: item.gross_weight_kgs,
        unit_price: item.price,
        amount: total_price,
        invoice_line_items: [],
        slug: '',
        invoice_id: '',
        size: `${item.size_value?.label || ''} ${item.size ? `- ${item.size.label}` : ''}`,
      }
    })

  let combineItems = _.orderBy([...noneGroup, ...groupData], ['product', 'size'], ['asc', 'asc'])

  let formatted_data = combineItems.map((item, index) => {
    let result =
      index === 0
        ? [
            {
              style: 'tcCell',
              text: `${mark_nos || ''}`,
            },
            {
              style: 'tlCell',
              text: `${item.product || ''}`,
            },
            {
              style: 'tcCell',
              text: `${item.box}`,
            },
            {
              style: 'textRight',
              text: `${toDecimalStr(item.net_weight_kgs)}`,
            },

            {
              style: 'textRight',
              text: `${toDecimalStr(item.net_weight_kgs + item.box)}`,
            },

            {
              style: 'textRight',
              text: `${toDecimalStr(item.unit_price)}`,
            },
            {
              style: 'textRight',
              text: `${toDecimalStr(item.amount)}`,
            },
          ]
        : [
            {
              style: 'tcCell',
              text: ``,
            },
            {
              style: 'tlCell',
              text: `${item.product}`,
            },
            {
              style: 'tcCell',
              text: `${item.box}`,
            },
            {
              style: 'textRight',
              text: `${toDecimalStr(item.net_weight_kgs)}`,
            },

            {
              style: 'textRight',
              text: `${toDecimalStr(item.net_weight_kgs + item.box)}`,
            },

            {
              style: 'textRight',
              text: `${toDecimalStr(item.unit_price)}`,
            },
            {
              style: 'textRight',
              text: `${toDecimalStr(item.amount)}`,
            },
          ]

    return result
  })

  return formatted_data
}
