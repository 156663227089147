import CurrencyList from './CurrencyList'
import CurrencyCreate from './CurrencyCreate'
import CurrencyEdit from './CurrencyEdit'

const currencies = {
  list: CurrencyList,
  create: CurrencyCreate,
  edit: CurrencyEdit,
}

export default currencies
