import React, { FC } from 'react'
import { Create, CreateProps, required, SimpleForm, TextInput } from 'react-admin'
import SectionTitle from '../../components/SectionTitle'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import { sanitize } from 'src/utils/sanitize'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

const styles: Styles<Theme, any> = {
  field: { maxWidth: 544 },
}

const useStyles = makeStyles(styles)

const SizeCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props)

  const transform = (data: any) => ({
    ...data,
    code: sanitize(data.label),
  })

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New size`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props} transform={transform}>
        <SimpleForm>
          <SectionTitle label="resources.global.fieldGroups.information" />
          <TextInput source="label" validate={required()} fullWidth className={classes.field} />
          <TextInput source="description" fullWidth className={classes.field} />
        </SimpleForm>
      </Create>
    </>
  )
}

export default SizeCreate
