import { TranslationMessages } from 'react-admin'
import englishMessage from 'ra-language-english'

const customEnglishMessage: TranslationMessages = {
  ...englishMessage,
  pos: {
    access_denied: `You doesn't have permission to access this resource.`,
    configuration: 'Configuration',
    dashboard: {
      recent_orders: 'Recent Orders',
      recent_invoices: 'Recent Invoices',
      exchange_rates: 'Exchange Rates',
      monthly_revenue: 'Monthly Revenue',
      month_history: '30 Day Revenue History',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      all_reviews: 'See all reviews',
      new_customers: 'New Customers',
      all_customers: 'See all customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
          'created at %{created_at} by %{customer_name}, 1 item |||| created at %{created_at} by %{username} - %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to the CKERP',
        subtitle: 'Hello there! ',
        ra_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },

    insight: {
      sales_summary: 'Sales summary',
      results: 'Results',
    },
  },
  resources: {
    global: {
      fieldGroups: {
        overview: 'Overview',
        information: 'Information',
        options: 'Options',
        settings: 'Settings',
        primaryAddress: 'Primary Address',
      },
      notifications: {
        success: {
          create: 'You have successfully created your data',
          update: 'You have successfully update your data',
          delete: 'You have successfully deleted your data.',
        },
        error: {
          create: 'Update failed! An error occurred while creating the data',
          update: 'Update failed! An error occurred while updating the data',
          delete: 'Delete failed! An error occurred while deleting the data',
          login: `We didn't know who you are, Please log in the web application.`,
        },
      },
    },
    bank_choice: {
      fieldGroups: {
        detail: 'Bank Details',
      },
    },

    category: {},
    customer: {
      title: {
        primary_address: 'Primary Address',
        address: 'Address',
      },
      fieldGroups: {
        identify: 'Identify',
        credit_limit: 'Credit Limit',
        recent_orders: 'Recent Orders',
        address: 'Address',
        addresses: 'Addresses',
        document: 'Document & Certificate',
        ports: 'Destination Ports',
      },
    },
    inventory: {
      fields: {
        'created_at@_gte': 'Start date (mm/dd/yyyy)',
        'created_at@_lte': 'End date (mm/dd/yyyy)',
        line_items: 'Line items',
      },
    },
    invoice: {
      fields: {
        'date@_gte': 'Start date (mm/dd/yyyy)',
        'date@_lte': 'End date (mm/dd/yyyy)',
      },
      fieldGroups: {
        shipping_details: 'Shipping details',
        dhl_details: 'DHL details',
      },
    },

    invoice_header: {
      fields: {
        'date@_gte': 'Start date (mm/dd/yyyy)',
        'date@_lte': 'End date (mm/dd/yyyy)',
      },
      fieldGroups: {
        terms: 'Terms',
        product_samples: 'Product samples',
        export_details: 'Export details',
        notes: 'Notes',
      },
    },

    order: {
      tabTitle: {
        line_items: 'Line Items',
        config: 'Config',
      },
      fields: {
        'pi_date@_gte': 'Start date (mm/dd/yyyy)',
        'pi_date@_lte': 'End date (mm/dd/yyyy)',
        etc_cost: 'ETC COST',
        shipping: 'Shipping',
      },
      fieldGroups: {
        unit: 'Unit of Weight',
        total: 'Total',
        expenses: 'Expenses',
        product_samples: 'Product samples',
        currency: 'Currency',
        exchange_rates: 'Exchange Rates',
        general_information: 'General Information',
        shipping_details: 'Shipping Details',
        address_terms: 'Addresses & Terms',
        document_details: 'Document Details',
      },
    },

    pack: {
      fieldGroups: {
        dimensions: 'Dimensional Weight',
      },
    },
    payment: {
      fieldGroups: {
        currency: 'Currency',
        amount: 'Amount',
      },
      fields: {
        'date@_gte': 'Start date (mm/dd/yyyy)',
        'date@_lte': 'End date (mm/dd/yyyy)',
      },
      action: {
        save: 'Save',
        save_and_add: 'Save and add',
      },
    },
    permission_group: {
      fieldGroups: {
        configuration: 'Configuration',
        permissions: 'Permissions',
        vendors: 'Vendors',
      },
    },
    profile_item_value: {
      fieldGroups: {
        items: 'Items',
      },
    },
    product: {
      fieldGroups: {
        organize_product: 'Organize Product',
        variants: 'Variants',
      },
    },
    product_variant: {
      fieldGroups: {
        inventory: 'Inventory',
      },
    },

    size: {
      fieldGroups: {
        attribute_values: 'Attribute Values',
      },
    },
    user: {
      fieldGroups: {
        permissions: 'Permissions',
      },
    },
    vendor: {
      fieldGroups: {
        ports: 'From port',
      },
    },
  },
}

export default customEnglishMessage
