import React, { FC, useCallback, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useDataProvider } from 'react-admin'
import { Customer_fields } from 'src/types/globalTypes'
import { ProfileItemValue_expanded } from '../profileItemValues/types'
import { ReportItemState } from './index'

interface Props {
  vendor: string
  customers: Customer_fields[]
  profiles: ReportItemState
  setProfile: (value: ReportItemState) => void
}

interface State {
  code: string
  nbItems: number
}

const CountingProfileItems: FC<Props> = ({ vendor, customers, profiles, setProfile }) => {
  const dataProvider = useDataProvider()
  const [state, setState] = useState<State[]>([])

  const fetchItems = useCallback(async () => {
    const items: State[] = []
    const { data } = await dataProvider.getList<ProfileItemValue_expanded>('profile_item_value', {
      pagination: { page: 1, perPage: 4000 },
      sort: { field: 'vendor_code', order: 'ASC' },
      filter: {
        vendor_code: vendor,
      },
    })

    let aggregations = Object.values(customers).map(({ code }) => {
      let stats: State = { code: '', nbItems: 0 }
      data.reduce((acc, curr) => {
        if (curr.customer_fk_ids.includes(code)) {
          stats.code = code
          stats.nbItems += 1
        }
        return acc
      }, {} as any)

      return stats
    })

    Object.values(aggregations).map((item) => item.code && items.push(item))

    setState(items)
  }, [dataProvider, vendor, customers])

  useEffect(() => {
    fetchItems()
  }, [fetchItems])

  useEffect(() => {
    const itemIndex = profiles.profileItems?.findIndex((item) => item.vendor_code === vendor) || 0
    const updateData = [...profiles.profileItems]
    updateData[itemIndex].customers = state

    setProfile({ profileItems: updateData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <div style={{ width: '100%', padding: 8 }}>
      <Grid container spacing={3}>
        {state.length
          ? state.map((item, index) => (
              <Grid key={index} item xs={12} md={4}>
                <Typography color="textPrimary" variant="caption">
                  {item.code}: {item.nbItems}
                </Typography>
              </Grid>
            ))
          : null}
      </Grid>
    </div>
  )
}

export default CountingProfileItems
