import { toDecimal } from 'src/utils/toDecimal'
import { OrderLineItems_expanded } from '../../types'

export default function formatTotalNetWeight(data: OrderLineItems_expanded[]) {
  let result = 0
  if (data.length) {
    result = data.map((item) => toDecimal(item.weight_mc * item.box)).reduce((a, b) => a + b)
  }

  return result
}
