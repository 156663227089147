import React from 'react'
import { useState } from 'react'
import { DateField, useResourceContext, useDelete, useNotify, useRefresh } from 'react-admin'
import { Typography, Tooltip, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TrashIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  metadata: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  textarea: {
    paddingTop: 16,
    paddingLeft: 14,
    paddingRight: 60,
    paddingBottom: 14,
    lineHeight: 1.3,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  cancel: {
    marginRight: theme.spacing(1),
  },
  content: {
    backgroundColor: '#edf3f0',
    padding: '0 1em',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: theme.spacing(1),
  },
  text: {
    flex: 1,
  },
  paragraph: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: 1.3,
    marginBottom: theme.spacing(2.4),
  },
  toolbar: {
    marginLeft: theme.spacing(2),
    visibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
}))

export const Comment = ({ note }: { note: any; reference: string }) => {
  const [isHover, setHover] = useState(false)
  const resource = useResourceContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const classes = useStyles()

  const [handleDelete] = useDelete(resource, note.id, note, {
    onSuccess: () => {
      notify('Note deleted', { type: 'info' })
      refresh()
    },
  })

  return (
    <div className={classes.root} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div className={classes.metadata}>
        <Typography style={{ fontWeight: 'bold' }} component="span">
          {note.user?.name.split('@')[0]}
        </Typography>{' '}
        <Typography component="span" variant="body1">
          added a note on{' '}
        </Typography>
        <DateField
          source="createdAt"
          record={note}
          variant="body1"
          showTime
          locales="en"
          options={{
            dateStyle: 'full',
            timeStyle: 'short',
          }}
        />{' '}
      </div>

      <div className={classes.content}>
        <div className={classes.text}>
          {note.text.split('\n').map((paragraph: string, index: number) => (
            <p className={classes.paragraph} key={index}>
              {paragraph}
            </p>
          ))}
        </div>
        <div className={classes.toolbar} style={{ visibility: isHover ? 'visible' : 'hidden' }}>
          <Tooltip title="Delete note">
            <IconButton size="small" onClick={handleDelete}>
              <TrashIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
