import { useCallback, useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { Order_fields } from 'src/types/globalTypes'

interface State {
  vendorOrders?: number
  customerOrders?: number
  ref_pi?: string
}

interface Props {
  vendor_fk_code?: string
  customer_fk_code?: string
  pi_date?: string
}

export default function useGetNewRefPI(props: Props) {
  const [state, setState] = useState<State>({})

  const { vendor_fk_code, customer_fk_code, pi_date } = props
  const dataProvider = useDataProvider()

  const year = new Date(pi_date || new Date()).getFullYear()

  const fetchVendorOrders = useCallback(async () => {
    const { total: vendorCount } = await dataProvider.getList<Order_fields>('order', {
      filter: {
        vendor_fk_code: vendor_fk_code,
        'pi_date@_gte': `${year}-01-01`,
        'pi_date@_lte': `${year}-12-31`,
      },
      sort: { field: 'date', order: 'asc' },
      pagination: { page: 1, perPage: 1 },
    })

    setState((state) => ({
      ...state,
      vendorOrders: vendor_fk_code ? vendorCount + 1 : 0,
    }))
  }, [dataProvider, vendor_fk_code, year])

  const fetchCustomerOrders = useCallback(async () => {
    const { total: customerCount } = await dataProvider.getList<Order_fields>('order', {
      filter: {
        vendor_fk_code: vendor_fk_code,
        customer_fk_code: customer_fk_code,
        'pi_date@_gte': `${year}-01-01`,
        'pi_date@_lte': `${year}-12-31`,
      },

      sort: { field: 'date', order: 'asc' },
      pagination: { page: 1, perPage: 1 },
    })
    setState((state) => ({
      ...state,
      customerOrders: customer_fk_code ? customerCount + 1 : 0,
    }))
  }, [dataProvider, vendor_fk_code, customer_fk_code, year])

  useEffect(() => {
    fetchVendorOrders()
    fetchCustomerOrders()
  }, [fetchCustomerOrders, fetchVendorOrders])

  // console.log(state)

  return state
}
