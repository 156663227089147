import React, { useState } from 'react'
import classnames from 'classnames'
import { DashboardMenuItem, ReduxState } from 'react-admin'
import { MenuProps, MenuItemLink } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import ProductIcon from '@material-ui/icons/ShoppingBasket'
import CustomerIcon from '@material-ui/icons/Person'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import InvoiceIcon from '@material-ui/icons/Description'
import Assessment from '@material-ui/icons/Assessment'
import PaymentIcon from '@material-ui/icons/MonetizationOnOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import Divider from '@material-ui/core/Divider'
import StatIcon from '@material-ui/icons/Equalizer'
import WidgetIcon from '@material-ui/icons/Widgets'
import SubMenu from './SubMenu'
import Dot from '../icons/Dot'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import useUser from 'src/hooks/useUser'
import usePermissionByUser from 'src/hooks/usePermissionByUser'

type MenuName = 'menuOrder' | 'menuInvoice' | 'menuPayment'

const Menu = ({ dense = false }: MenuProps) => {
  const classes = useStyles()
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen)
  const { data: user } = useUser()
  const { permissions, enableConfig, enableReport } = usePermissionByUser()

  const [state, setState] = useState({
    menuOrder: true,
    menuInvoice: true,
    menuPayment: false,
  })

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      <DashboardMenuItem />
      {permissions['insight'].read && <MenuItemLink to="/insights" primaryText="Insights" leftIcon={<Assessment />} />}
      {permissions['customer'].read && (
        <MenuItemLink to="/customer" primaryText="Customers" leftIcon={<CustomerIcon />} />
      )}
      {permissions['product'].read && <MenuItemLink to="/product" primaryText="Products" leftIcon={<ProductIcon />} />}
      {permissions['order'].read && (
        <SubMenu
          handleToggle={() => handleToggle('menuOrder')}
          isOpen={state.menuOrder}
          name="PI"
          icon={<CartIcon />}
          dense={dense}
        >
          <MenuItemLink to={'/order'} primaryText={'Orders'} dense={dense} leftIcon={<Dot size="small" />} />
        </SubMenu>
      )}
      {(permissions['invoice_header'].read ||
        permissions['invoice-1'].read ||
        permissions['invoice-2'].read ||
        permissions['invoice-3'].read) && (
        <SubMenu
          handleToggle={() => handleToggle('menuInvoice')}
          isOpen={state.menuInvoice}
          name="Invoices"
          icon={<InvoiceIcon />}
          dense={dense}
        >
          {permissions['invoice_header'].read && (
            <MenuItemLink to={'/invoice_header'} primaryText={'Header'} dense={dense} leftIcon={<Dot size="small" />} />
          )}
          {permissions['invoice-1'].read && (
            <MenuItemLink
              to={'/invoice/type-1'}
              primaryText={`Type 1 Exporter`}
              dense={dense}
              leftIcon={<Dot size="small" />}
            />
          )}
          {permissions['invoice-2'].read && (
            <MenuItemLink
              to={'/invoice/type-2'}
              primaryText={`Type 2 Client`}
              dense={dense}
              leftIcon={<Dot size="small" />}
            />
          )}
          {permissions['invoice-3'].read && (
            <MenuItemLink
              to={'/invoice/type-3'}
              primaryText={'Type 3 Actual'}
              dense={dense}
              leftIcon={<Dot size="small" />}
            />
          )}
        </SubMenu>
      )}

      {permissions['payment'].read && (
        <SubMenu
          handleToggle={() => handleToggle('menuPayment')}
          isOpen={state.menuPayment}
          name="Financials"
          icon={<PaymentIcon />}
          dense={dense}
        >
          <MenuItemLink to={'/payment'} primaryText={'Payment'} dense={dense} leftIcon={<Dot size="small" />} />
          <MenuItemLink
            to={'/statement'}
            primaryText={'Bank statement'}
            dense={dense}
            leftIcon={<Dot size="small" />}
          />
          <MenuItemLink
            to={'/customer-statement'}
            primaryText={'Customer statement'}
            dense={dense}
            leftIcon={<Dot size="small" />}
          />
          <MenuItemLink
            to={'/buyer-balance'}
            primaryText={'Buyer balance'}
            dense={dense}
            leftIcon={<Dot size="small" />}
          />
        </SubMenu>
      )}
      {(user.isSuperuser || enableConfig) && (
        <MenuItemLink to={'/configuration'} primaryText={'Configuration'} dense={dense} leftIcon={<SettingsIcon />} />
      )}
      <Divider className={classes.divider} />
      {(user.isSuperuser || enableReport) && (
        <MenuItemLink to={'/report_items'} primaryText={'Report items'} dense={dense} leftIcon={<StatIcon />} />
      )}
      {user.isSuperuser && (
        <MenuItemLink to={'/user_log'} primaryText={'Logs'} dense={dense} leftIcon={<SupervisedUserCircleIcon />} />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 240,
  },
  closed: {
    width: 55,
  },

  divider: {
    width: 240,
    marginTop: 30,
    marginBottom: 10,
  },
}))

export default Menu
