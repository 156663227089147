import React, { FC, ReactNode } from 'react'
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import RefetchIcon from '@material-ui/icons/Refresh'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardTitle: {
      marginBottom: theme.spacing(1),
      borderBottom: `1px solid #eaeaea`,
    },
    between: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    cardContent: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    cardActions: {
      marginTop: theme.spacing(1),
      borderTop: `1px solid #eaeaea`,
      justifyContent: 'flex-end',
    },
  })
)

interface Props {
  title: string
  errorMessage?: string
  open: boolean
  size?: DialogProps['maxWidth']
  multiple?: boolean
  disabled?: boolean
  onMultiple?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClose?: () => void
  onSave?: () => void
  onCancel?: () => void
  onRefetch?: () => void
  action?: ReactNode
}

const Dialog: FC<Props> = ({
  children,
  title,
  open,
  multiple,
  action,
  size,
  errorMessage,
  disabled,
  onMultiple,
  onClose,
  onSave,
  onCancel,
  onRefetch,
}) => {
  const classes = useStyles()
  return (
    <MuiDialog open={open} maxWidth={size ? size : 'sm'} onClose={onClose} fullWidth>
      <DialogTitle className={classes.cardTitle}>
        <div className={classes.between}>
          <span>{title}</span>
          <span>
            <Typography color="secondary">{errorMessage}</Typography>
          </span>
          {onRefetch ? (
            <Tooltip title="Refetch data" onClick={onRefetch}>
              <IconButton size="small">
                <RefetchIcon />
              </IconButton>
            </Tooltip>
          ) : null}

          {action}
        </div>
      </DialogTitle>
      <DialogContent className={classes.cardContent}>{children}</DialogContent>
      <DialogActions className={classes.cardActions}>
        {onMultiple ? (
          <Tooltip title="Add multiple">
            <Switch checked={multiple} onChange={onMultiple} inputProps={{ 'aria-label': 'secondary checkbox' }} />
          </Tooltip>
        ) : null}
        <Button variant="text" color="default" onClick={onCancel}>
          Cancel
        </Button>
        {onSave && (
          <Button variant="contained" color="primary" onClick={onSave} disabled={disabled}>
            Save
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
