import { useMemo, useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import { Currency_fields, Vendor_fields } from 'src/types/globalTypes'

interface Data extends Vendor_fields {
  currency?: Currency_fields
}

const initialValues: Data = {
  id: '',
  company_name: '',
  code: '',
  email: '',
  first_name: '',
  last_name: '',
  address_1: '',
  address_2: '',
  country: '',
  tel: '',
  fax: '',
  phone: '',
  currency_default: 'USD',
  currency: {
    id: '',
    label: '',
    code: '',
  },
}

interface Props {
  vendor_fk_code?: string
}

export default function useVendorOne(props: Props) {
  const [result, setResult] = useState<Data>(initialValues)

  const { data, loading, error } = useQueryWithStore({
    type: 'getList',
    resource: 'vendor',
    payload: {
      pagination: { page: 1, perPage: 1 },
      filter: { code: props.vendor_fk_code },
    },
  })

  let vendor = useMemo(() => {
    if (data) {
      setResult(data[0])
    }
    return result
  }, [data, result])

  return {
    data: vendor,
    loading,
    error,
  }
}
