import React, { SetStateAction, useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useDataProvider, useLoading } from 'react-admin'
import { InsightFilterStates, InsightOperationStates } from './types'
import { Order_expanded } from '../orders/types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import InsightDateRange from './components/Tools/InsightDateRange'
import { colors } from 'src/utils/colors'
import OperationTabPanel from './components/Operations/OperationTabPanel'
import { DateRange } from 'materialui-daterange-picker'
import InsightFilters from './components/Tools/InsightFilters'
import { formatArrayNames } from './utils/formatArrayNames'
import { formatDateToAPI } from 'src/utils/formatDate'

interface OrderStats {
  title: string
  shipped_out: Order_expanded[]
  order_completed: Order_expanded[]
  order_received: Order_expanded[]
  processing_order: Order_expanded[]
  cancelled: Order_expanded[]
  annulled: Order_expanded[]
}

interface Props {
  state: InsightFilterStates
  setState: (values: SetStateAction<InsightFilterStates>) => void
}

export default function InsightOperations(props: Props) {
  let loading = useLoading()
  const dataProvider = useDataProvider()
  const { state, setState } = props
  const [tab, setTab] = useState(0)
  const [data, setData] = useState<InsightOperationStates>({})

  const fetchTopProducts = useCallback(async () => {
    let vendors: string[] = []
    let customers: string[] = []

    Object.values(state.filterTags || []).forEach((item) => {
      item.vendors.map((vendor) => vendors.push(vendor))
      item.customers.map((customer) => customers.push(customer))
    })

    let vendor = vendors.length ? { vendor_fk_code: _.uniq(vendors) } : undefined
    let customer = customers.length ? { customer_fk_code: _.uniq(customers) } : undefined
    let start_date = state.dateRange ? { 'pi_date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'pi_date@_lte': formatDateToAPI(state.dateRange.endDate) } : null

    const { data: orders } = await dataProvider.getList<Order_expanded>('order', {
      filter: { ...vendor, ...customer, ...start_date, ...end_date },
      sort: { field: 'ref_pi', order: 'DESC' },
      pagination: { page: 1, perPage: vendors.length || customers.length ? 3000 : 0 },
    })

    const aggregations = state.filterTags?.map((item) => {
      let dataset: OrderStats = {
        title: `${item.vendors.join(',')}:${item.customers.join(',')}`,
        annulled: [],
        order_received: [],
        cancelled: [],
        order_completed: [],
        processing_order: [],
        shipped_out: [],
      }

      orders.reduce((stats, order) => {
        if (item.vendors.includes(order.vendor_fk_code) && item.customers.includes(order.customer_fk_code)) {
          if (order.status === 'order_received') {
            dataset.order_received.push(order)
          }
          if (order.status === 'processing_order') {
            dataset.processing_order.push(order)
          }
          if (order.status === 'order_completed') {
            dataset.order_completed.push(order)
          }
          if (order.status === 'shipped_out') {
            dataset.shipped_out.push(order)
          }
          if (order.status === 'annulled') {
            dataset.annulled.push(order)
          }
          if (order.status === 'cancelled') {
            dataset.cancelled.push(order)
          }
        }

        return stats
      }, {})

      return dataset
    })

    setData((prev) => ({ ...prev, fetchOrders: aggregations }))
  }, [state.filterTags, state.dateRange, dataProvider])

  useEffect(() => {
    fetchTopProducts()
    // setTab(Number(state.length - 1))
    return () => {
      setData({})
    }
  }, [fetchTopProducts])

  const handleCustomerChange = (name: string, value: any) => {
    let newValue = { ...state, [name]: value }
    setState(newValue)
  }

  const handleDateRange = (value: DateRange) => {
    setState((prev) => ({ ...prev, dateRange: value }))
  }

  const handleAddFilter = () => {
    let newTags = [...(state.filterTags || []), { vendors: state.vendors || [], customers: state.customers || [] }]
    setState((prev) => ({
      ...prev,
      vendors: [],
      customers: [],
      filterTags: newTags,
    }))
  }

  const handleRemoveFilter = (index: number) => {
    if (state.filterTags) {
      let newValue = [...state.filterTags]
      newValue.splice(index, 1)
      setState({ ...state, filterTags: newValue })
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <InsightFilters
            loading={loading}
            useVendors
            useCustomers
            {...state}
            onChange={handleCustomerChange}
            onAddFilter={handleAddFilter}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="right">
            <InsightDateRange dateRange={state.dateRange} setDateRange={handleDateRange} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          {state.filterTags?.length
            ? state.filterTags.map((item, i) => {
                const dataColor = colors[i].join(',')
                return (
                  <Tooltip title={item.customers.join(', ')} key={i}>
                    <Chip
                      variant="outlined"
                      style={{ border: `2px solid rgb(${dataColor})`, marginRight: 8 }}
                      label={`${formatArrayNames(item.vendors || [])}: ${formatArrayNames(item.customers || [])}`}
                      onDelete={() => handleRemoveFilter(i)}
                    />
                  </Tooltip>
                )
              })
            : null}
        </Grid>
      </Grid>

      <Box my={2} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {state.filterTags?.length ? (
            <AppBar style={{ boxShadow: 'none' }} position="static" color="inherit">
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {state.filterTags.map((item, index) => (
                  <Tab
                    key={index}
                    label={`${formatArrayNames(item.vendors || [])}: ${formatArrayNames(item.customers || [])}`}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>

              {tab >= 0 && (
                <OperationTabPanel
                  value={tab}
                  index={tab}
                  vendors={state.filterTags[tab].vendors}
                  customers={state.filterTags[tab].customers}
                  data={data.fetchOrders && data.fetchOrders[tab]}
                />
              )}
            </AppBar>
          ) : (
            <Paper>
              <Box display="flex" alignItems="center" justifyContent="center" height="30em" width="100%">
                No data found
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}
