import React, { FC } from 'react'
import MaterialTable, { MaterialTableProps } from 'material-table'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'

const MTTable: FC<MaterialTableProps<{}>> = ({ isLoading, options, data, columns, style, ...rest }) => {
  const theme = createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          padding: `0.3em`,
          fontSize: '12px !important',
        },
      },
      MuiTypography: {
        h6: {
          fontWeight: 'bold',
        },
      },
      MuiToolbar: {
        root: {
          paddingLeft: 0,
        },
        gutters: {
          paddingLeft: `5px !important`,
        },
      },
    },
  })
  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        isLoading={isLoading}
        title=""
        columns={columns}
        data={data}
        options={{
          pageSize: 10,
          draggable: false,
          actionsColumnIndex: -1,
          actionsCellStyle: {
            color: '#3f51b5',
          },
          headerStyle: {
            fontSize: '12px',
            fontWeight: 500,
            color: theme.palette.grey[700],
            textTransform: 'uppercase',
          },
          ...options,
        }}
        style={{
          padding: 3,
          boxShadow: 'none',
          ...style,
        }}
        {...rest}
      />
    </MuiThemeProvider>
  )
}

export default MTTable
