import React, { useEffect, useState } from 'react'
import { Chart, registerables } from 'chart.js'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { InsightProduct_topProducts } from '../../types'
import { Order_expanded } from 'src/pages/orders/types'
import { Identifier, useGetMany } from 'react-admin'
import DestinationPie from './DestinationPie'
import { colors } from 'src/utils/colors'

Chart.register(...registerables)

interface Stats {
  name: string
  counter: number
}

interface Props {
  orders: Order_expanded[]
  topProducts: InsightProduct_topProducts
}

export default function ExpandProductRow(props: Props) {
  const { topProducts, orders } = props
  const [stats, setStats] = useState<Stats[]>([])
  const [destinationIds, setDestinationIds] = useState<Identifier[]>([])
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

  const { data: ports, loaded } = useGetMany('customer_port', destinationIds)

  useEffect(() => {
    let result: string[] = []
    if (topProducts.orderIds.length) {
      Object.values(
        orders.filter((order) => topProducts.orderIds.some((product) => product.includes(order.id)))
      ).forEach((order) => {
        if (order.shipping_details.destination_port) {
          if (order.shipping_details.destination_port.match(regex)) {
            result.push(order.shipping_details.destination_port)
          }
        }
      })
    }

    setDestinationIds(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, topProducts])

  useEffect(() => {
    let counts: any = {}
    let result: Stats[] = []
    if (!loaded) return

    if (ports.length) {
      Object.values(ports).forEach((x) => {
        if (x.destination_port) {
          counts[x.destination_port.name] = (counts[x.destination_port.name] || 0) + 1
        }
      })

      Object.entries(counts).map(([key, value]) => result.push({ name: key, counter: value as number }))
    }

    setStats(result)
  }, [ports, loaded, destinationIds])

  return (
    <Box p={3}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <DestinationPie stats={stats} colors={colors} totalOrders={destinationIds.length} />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Box>
  )
}
