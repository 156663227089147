import React, { useEffect, useState } from 'react'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from 'src/components/Dialog'
import { OrderProductSample_fields, OrderRevise_fields, Order_fields } from 'src/types/globalTypes'
import { useRefresh, useUpdate, useUpdateMany } from 'react-admin'
import { OrderLineItems_expanded } from '../types'
import order_template2 from '../utils/exportPDF/template2'
import { useFormState } from 'react-final-form'
import order_template3 from '../utils/exportPDF/template3'
import useCreateUserLog from 'src/hooks/useCreateUserLog'

const pdf = pdfMake
pdf.vfs = pdfFonts.pdfMake.vfs

interface Props {
  disabled?: boolean
  revise?: OrderRevise_fields
  lineItems: OrderLineItems_expanded[]
  productSamples: OrderProductSample_fields[]
  current_num: number
}

export default function NewReviseButton(props: Props) {
  const { disabled, revise, current_num, lineItems, productSamples } = props
  const { values } = useFormState()
  const { createLog } = useCreateUserLog()
  const [open, setOpen] = useState(false)
  const [text, setText] = useState('')
  const refresh = useRefresh()

  let temp2 = order_template2({ data: values as any, lineItems, revise, productSamples }) as any
  let temp3 = order_template3({ data: values as any, lineItems, revise }) as any

  useEffect(() => {
    setText(`REVISE-${current_num + 1}`)
  }, [current_num])

  let ids = lineItems.length ? lineItems.map((item) => item.id) : []

  const [updateOrder] = useUpdate<Order_fields>('order', revise?.order_id, {
    current_revise_num: current_num + 1,
  })
  const [updateOrderLineItems] = useUpdateMany('order_line_item', ids, { revise_key: current_num + 1 })

  const [handleNewRevise] = useUpdate<OrderRevise_fields>(
    'order_revise',
    revise?.id,
    { date: new Date(), name: text, revise_key: current_num + 1 },
    revise,
    {
      onSuccess: () => {
        updateOrder()
        updateOrderLineItems()
        createLog('order', 'UPDATE', `New revise ${values.ref_pi} - ${current_num + 1}`)
        pdf.createPdf(temp2).download(`${values.ref_pi}-expenses-revise-${current_num}`)
        pdf.createPdf(temp3).download(`${values.ref_pi}-loading-revise-${current_num}`)
        setText('')
        setOpen(false)
        refresh()
      },
    }
  )

  return (
    <>
      <Button color="primary" variant="outlined" onClick={() => setOpen(true)} disabled={disabled}>
        New Revise
      </Button>
      <Dialog
        open={open}
        title="New revise"
        onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        onSave={handleNewRevise}
      >
        <TextField label="Title" fullWidth value={text} onChange={(e) => setText(e.target.value)} variant="filled" />
      </Dialog>
    </>
  )
}
