import React, { FC, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import { FieldProps } from 'react-admin'
import { User_fields } from 'src/types/globalTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'nowrap',
    // alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))

interface Props extends FieldProps<User_fields> {
  size?: string
}

const UserDisableuserField: FC<Props> = ({ record }) => {
  const classes = useStyles()
  return record ? <div className={classes.root}>{record.disable_user && <CheckIcon fontSize="small" />}</div> : null
}

UserDisableuserField.defaultProps = {
  source: 'isSuperuser',
  label: 'Superuser',
}

export default memo<Props>(UserDisableuserField)
