import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import moment from 'moment'

import { InsightFilterStates, InsightOverviewState, InsightProformanceStates } from './types'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import Separate from 'src/components/Separate'
import InsightOverview from './InsightOverview'
import { setSidebarVisibility } from 'react-admin'
import InsightSalesAndProfit from './InsightSalesAndProfit'
import InsightProducts from './InsightProducts'
import InsightProformance from './InsightProformance'
import InsightVendorFinancials from './InsightVendorFinancials'
import InsightOperations from './InsightOperations'
import InsightCustomerFinancials from './InsightCustomerFinancials'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const initialState: InsightFilterStates = {
  vendor: null,
  customer: null,
  vendors: [],
  customers: [],
  users: [],
  filterTags: [],
  split: false,
}

const dateRange = {
  label: 'This year',
  startDate: moment(new Date()).startOf('year').toDate(),
  endDate: moment(new Date()).endOf('year').toDate(),
}

export default function Insights(props: any) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [overview, setOverview] = useState<InsightOverviewState>({ dateRange })
  const [salesAndProfits, setSalesAndProfits] = useState<InsightFilterStates>({ ...initialState, dateRange })
  const [products, setProducts] = useState<InsightFilterStates>({ ...initialState, dateRange })
  const [financials, setFinancials] = useState<InsightFilterStates>({ ...initialState, dateRange })
  const [customerFinancials, setCustomerFinancials] = useState<InsightFilterStates>({ ...initialState, dateRange })
  const [proformance, setProformance] = useState<InsightProformanceStates>({})
  const [operations, setOperations] = useState<InsightFilterStates>({ ...initialState, dateRange })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSidebarVisibility(false))
    // eslint-disable-next-line
  }, [setSidebarVisibility])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Separate value={2} />
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Sales & Profit" {...a11yProps(0)} />
          <Tab label="Products" {...a11yProps(0)} />
          <Tab label="Performance" {...a11yProps(0)} />
          <Tab label="Vendor Financials" {...a11yProps(0)} />
          <Tab label="Customer Financials" {...a11yProps(0)} />
          <Tab label="Operations" {...a11yProps(0)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <InsightOverview state={overview} setState={setOverview} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InsightSalesAndProfit state={salesAndProfits} setState={setSalesAndProfits} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <InsightProducts state={products} setState={setProducts} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <InsightProformance state={proformance} setState={setProformance} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <InsightVendorFinancials state={financials} setState={setFinancials} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <InsightCustomerFinancials state={customerFinancials} setState={setCustomerFinancials} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <InsightOperations state={operations} setState={setOperations} />
        </TabPanel>
      </div>
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{
        flexGrow: 1,
        width: 240,
      }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}
