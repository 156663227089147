import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Title, useDelete, useGetOne, useNotify, useRedirect, useRefresh, useUpdate } from 'react-admin'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import Separate from 'src/components/Separate'
import ProductVariantExistList from './ProductVariantExistList'
import ProductVariantDetailForm from './ProductVariantDetailForm'
import { ProductVariant_expanded, ProductVariant_form } from './types/ProductVariant'
import { SizeValue_autocomplete_type } from '../sizes/types/SizeValue'

const initialValues: ProductVariant_form = {
  full_size: null,
  glazing: null,
  sku: '',
  weight_loss: 0,
}

const ProductVariantEditItem = (props: any) => {
  const { id, variantId }: any = useParams()
  const [data, setData] = useState<ProductVariant_form>(initialValues)
  const { loaded, data: productVariant } = useGetOne<ProductVariant_expanded>('product_variant', variantId)
  const [updateItem] = useUpdate()
  const [deleteItem] = useDelete()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const refresh = useRefresh()

  useEffect(() => {
    if (productVariant) {
      let { size_fk, size_value_fk, size_value, size } = productVariant
      let full_size: SizeValue_autocomplete_type | null = productVariant.size_value
        ? {
            id: size_value_fk || '',
            label: size_value?.label || '',
            code: size_value?.code || '',
            letter: `${size_value?.label || ''} ${size?.label || ''}`,
            size_fk_id: size_fk,
            size: {
              id: size_fk || '',
              code: size?.code || '',
              label: size?.label || '',
            },
          }
        : null
      setData({
        full_size,
        sku: productVariant.sku,
        glazing: productVariant.glazing,
        weight_loss: productVariant.weight_loss,
      })
    }
  }, [productVariant])

  const onSubmit = async (values: ProductVariant_form) => {
    const data = {
      size_fk: values.full_size?.size_fk_id || null,
      size_value_fk: values.full_size?.id || null,
      weight_loss: values.weight_loss,
      sku: values.sku,
      glazing: values.glazing,
    }
    updateItem('product_variant', variantId, data, productVariant, {
      onSuccess: () => {
        notify('resources.global.notifications.success.update', 'info')
        refresh()
        redirectTo(`/product/${id}/product_variant/${variantId}`)
      },
    })
  }

  const handleDelete = () => {
    deleteItem('product_variant', variantId, productVariant, {
      onSuccess: () => {
        redirectTo(`/product/${id}/product_variant/create`)
      },
    })
  }

  if (!loaded || !data) return null

  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Separate value={3} />
      <Title title={productVariant?.product?.name || ''} />
      <Form
        initialValues={data}
        onSubmit={onSubmit}
        render={({ form, handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <ProductVariantExistList productId={id} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <ProductVariantDetailForm
                    submitting={submitting}
                    pristine={pristine}
                    deleteBtn={
                      <Button variant="contained" color="secondary" onClick={handleDelete}>
                        Delete
                      </Button>
                    }
                  />
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </>
  )
}

export default ProductVariantEditItem
