import React, { FC } from 'react'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import getOrderLineUnitCost from '../utils/getOrderLineUnitCost'
import { OrderLineItemsTableProps, OrderLineItems_expanded } from '../types'
import getOrderLineAmount from '../utils/getOrdeLineAmount'
import getOrderLineTotalCostUsd from '../utils/getOrderLineTotalCostUsd'

const useStyles = makeStyles((theme: Theme) => ({
  noPadding: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardHeader: {
    borderBottom: 'none',
  },
  pack: {
    color: theme.palette.grey[600],
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'right',
  },
  small: {
    width: `70px`,
  },
  bold: {
    fontWeight: 'bold',
  },
}))

interface Props extends OrderLineItemsTableProps {
  hasAccess: boolean
  loading?: boolean
  data: OrderLineItems_expanded[]
  exchange_rate: number
  shipping_cost: number
  total_cost_usd: number
  total_net_weight_kg: number
  total_ctn: number
  total_amount: number
  total_gross_profit: number
}

const OrderItemsOverview: FC<Props> = ({
  hasAccess,
  loading,
  unit_code,
  revise,
  data,
  exchange_rate,
  shipping_cost,
  total_cost_usd,
  total_ctn,
  total_net_weight_kg,
  total_amount,
  total_gross_profit,
}) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent
            classes={{
              root: classes.noPadding,
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '370px' }}>Product</TableCell>
                  <TableCell style={{ width: '80px' }}>Total MC</TableCell>
                  <TableCell align="right" style={{ width: '150px' }}>
                    Total Net Weight
                  </TableCell>
                  <TableCell align="right" style={{ width: '80px' }}>
                    Unit Cost (USD/{unit_code?.toUpperCase()})
                  </TableCell>
                  {hasAccess ? (
                    <>
                      <TableCell align="right" style={{ width: '100px' }}>
                        Total Cost (USD)
                      </TableCell>
                      <TableCell align="right" style={{ width: '80px' }}>
                        Unit Price (USD)
                      </TableCell>
                      <TableCell align="right" style={{ width: '100px' }}>
                        Total Amount (USD)
                      </TableCell>
                      <TableCell>Gross Profit (USD)</TableCell>
                      <TableCell>Actual Profit (%)</TableCell>
                    </>
                  ) : (
                    <TableCell align="right" style={{ width: '100px' }}>
                      Total Cost (USD)
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <>
                    <TableRow style={{ height: 32 * 20 }}>
                      <TableCell align="center" colSpan={7}>
                        loading...
                      </TableCell>
                    </TableRow>
                  </>
                ) : data.length > 0 ? (
                  data.map((item: any, index) => {
                    let net_weight_kg = toDecimal(item.box * item.weight_mc)

                    let unit_cost_usd = getOrderLineUnitCost({
                      import_cost: item.import_cost,
                      pack_cost_baht: item.pack_cost_baht,
                      etc_cost: revise?.exchange_rate_info.etc_cost,
                      usd_export: exchange_rate,
                      shipping_cost,
                    })

                    let total_cost_usd = getOrderLineTotalCostUsd({
                      unit_code,
                      weight_mc: item.weight_mc,
                      box: item.box,
                      unit_cost: unit_cost_usd,
                    })

                    let amount = getOrderLineAmount({
                      unit_code,
                      weight_mc: item.weight_mc,
                      box: item.box,
                      unit_price: item.unit_price,
                    })

                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body2">
                            {item.product.name}
                            <Typography component="span" variant="caption">
                              {item.size_value ? ` - ${item.size_value.label} ${item.size?.label}` : ''}
                            </Typography>
                          </Typography>
                          <Typography className={classes.pack} variant="caption">
                            {item.pack?.name || ''}
                          </Typography>
                        </TableCell>
                        <TableCell>{toDecimalStr(item.box, true)}</TableCell>
                        <TableCell align="right">{toDecimalStr(net_weight_kg)}</TableCell>
                        <TableCell align="right">{toDecimalStr(unit_cost_usd)}</TableCell>
                        {hasAccess ? (
                          <>
                            <TableCell align="right">{toDecimalStr(total_cost_usd)}</TableCell>
                            <TableCell align="right">{toDecimalStr(item.unit_price)}</TableCell>
                            <TableCell align="right">{toDecimalStr(amount)}</TableCell>
                            <TableCell>{toDecimalStr(amount - total_cost_usd)}</TableCell>
                            <TableCell>{toDecimalStr(((amount - total_cost_usd) / total_cost_usd) * 100)}%</TableCell>
                          </>
                        ) : (
                          <TableCell align="right">{toDecimal(total_gross_profit / total_cost_usd)}</TableCell>
                        )}
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow style={{ height: 58 * 10 }}>
                    <TableCell align="center" colSpan={7}>
                      No data found!
                    </TableCell>
                  </TableRow>
                )}

                {data.length ? (
                  <TableRow>
                    <TableCell className={classes.bold} align="center">
                      TOTAL
                    </TableCell>
                    <TableCell className={classes.bold}>{total_ctn}</TableCell>
                    <TableCell align="right" className={classes.bold}>
                      {toDecimalStr(total_net_weight_kg || 0)}
                    </TableCell>
                    <TableCell align="right" className={classes.bold}>
                      {/* {toDecimalStr(total_unit_cost || 0)} */}
                    </TableCell>
                    {hasAccess ? (
                      <>
                        <TableCell align="right" className={classes.bold}>
                          {toDecimalStr(total_cost_usd)}
                        </TableCell>
                        <TableCell align="right" className={classes.bold}>
                          {/* {toDecimalStr(total_unit_price)} */}
                        </TableCell>
                        <TableCell align="right" className={classes.bold}>
                          {toDecimalStr(total_amount)}
                        </TableCell>
                        <TableCell className={classes.bold}>{toDecimalStr(total_gross_profit)}</TableCell>
                        <TableCell className={classes.bold} align="center">
                          {toDecimal((total_gross_profit / total_cost_usd) * 100)}%
                        </TableCell>
                      </>
                    ) : (
                      <TableCell align="right" className={classes.bold}>
                        {toDecimalStr(total_cost_usd)}
                      </TableCell>
                    )}
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

OrderItemsOverview.displayName = 'OrderItemsOverview'

export default OrderItemsOverview
