import React, { FC, useEffect, useState } from 'react'
import _ from 'lodash'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Dialog from 'src/components/Dialog'
import { useFormState } from 'react-final-form'
import { useQueryWithStore } from 'react-admin'
import MTTable from 'src/components/MTTable'
import { OrderLineItems_expanded } from '../types'

interface Props {
  open: boolean
  vendor_fk_code?: string
  customer_fk_code?: string
  tableRef?: any
  disabled?: boolean
  onSelectionChange?: (row: any) => void
  onSave?: () => void
  onCancel?: () => void
  onClose?: () => void
}

const OrderAddItemsDialog: FC<Props> = ({ tableRef, onSelectionChange, onClose, ...rest }) => {
  const { values } = useFormState()
  const [data, setData] = useState<OrderLineItems_expanded[]>([])

  const { data: productVariants, loading } = useQueryWithStore({
    type: 'getList',
    resource: 'profile_item_value',
    payload: {
      filter: {
        vendor_code: values.vendor_fk_code,
        'customer_fk_ids@_contains': values.customer_fk_code,
      },
    },
  })

  useEffect(() => {
    if (productVariants) {
      let items = _.orderBy(
        productVariants,
        ['product_variant.product.name', 'product_variant.size_value.label', 'product_variant.size.labe', 'pack.name'],
        ['asc', 'asc', 'asc', 'asc']
      )

      setData(items)
    }
  }, [productVariants]) //ts-ignored

  return (
    <Dialog
      size="lg"
      title="Add item"
      onClose={onClose}
      {...rest}
      action={
        <IconButton size="small" color="secondary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <MTTable
        tableRef={tableRef}
        isLoading={loading}
        data={data}
        columns={[
          {
            title: 'Product',
            field: 'product_variant.product.name',
            editable: 'never',
            render: (rowData: any) => (
              <div style={{ width: '250px' }}>
                <Typography variant="body2">
                  {rowData.product_variant.product.name}
                  <Typography component="span" variant="caption">
                    {rowData.product_variant.size_value
                      ? ` - ${rowData.product_variant.size_value?.label} ${rowData.product_variant.size?.label}`
                      : ''}
                  </Typography>
                </Typography>
              </div>
            ),
          },
          {
            title: 'Pack',
            field: 'pack.name',
            editable: 'never',
            render: (rowData: any) => <div style={{ width: '240px' }}>{rowData.pack?.name || ''}</div>,
          },
          {
            title: 'WET/MC (KG) & GROSS NET/MC (KG)',
            field: 'weight_mc',
            align: 'center',
            editable: 'never',
            render: (rowData: any) => (
              <div>
                {rowData.weight_mc} / {rowData.gross_weight}
              </div>
            ),
          },
        ]}
        options={{
          selection: true,
          pageSize: 100,
          pageSizeOptions: [5, 10, 20, 100],
          searchFieldStyle: {
            width: '500px',
          },
          searchFieldAlignment: 'left',
        }}
        onSelectionChange={onSelectionChange}
        style={{
          border: '1px solid #e3e3e3',
        }}
      />
    </Dialog>
  )
}

export default OrderAddItemsDialog
