import React, { createElement, FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  main: {
    overflow: 'inherit',
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .icon': {
      color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
    },
  },
  title: {
    display: 'block',
    color: 'rgb(97, 97, 97)',
    fontSize: '16px',
  },
  subTitle: {
    display: 'block',
  },
  icon: {
    fontSize: '52px',
  },
}))

interface Props {
  icon?: any
  title?: string
  subTitle?: string
  value: string
  color?: string
  pointer?: boolean
  onClick?: () => void
}

const InsightWidget: FC<Props> = ({ icon, title, subTitle, value, children, color, pointer, onClick }) => {
  const classes = useStyles()
  return (
    <Card className={classes.card} style={{ cursor: pointer ? 'pointer' : 'auto' }} onClick={onClick}>
      <CardContent className={classes.main}>
        <Box display="flex" alignItems="center">
          {icon && <Box width="3em">{createElement(icon, { style: { color }, classes: { root: classes.icon } })}</Box>}
          <Box pl={2}>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            {subTitle && (
              <Typography className={classes.subTitle} variant="caption">
                ({subTitle})
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <Typography color="primary" variant="h5">
            {value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default InsightWidget
