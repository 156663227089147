import React from 'react'
import Grid from '@material-ui/core/Grid'
import { DateInput, SelectArrayInput } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import { DOCUMENT_CHOICE } from '../constants'

const OrderDocumentDetails = ({ disabled }: { disabled?: boolean }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SectionTitle mb={0.1} label="resources.order.fieldGroups.document_details" />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateInput
          disabled={disabled}
          fullWidth
          label="Estimated date of loading"
          source="shipping_details.est_date_of_loading"
          helperText={false}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectArrayInput
          disabled={disabled}
          fullWidth
          label="Document Required"
          choices={DOCUMENT_CHOICE}
          source="shipping_details.documents_required"
        />
      </Grid>
    </Grid>
  )
}

export default OrderDocumentDetails
