import React, { useState } from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Permission_fields } from 'src/types/globalTypes'
import { useResourceContext, useUpdate } from 'react-admin'

interface Props {
  permission: Permission_fields
}

export default function Permission(props: Props) {
  const { permission } = props
  const resource = useResourceContext()
  const [update] = useUpdate()
  const [state, setState] = useState<Permission_fields>(props.permission)

  const handleChange = (name: string, value: any) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
    if (permission.id) {
      update(resource, permission.id, { [name]: value }, {})
    }
  }

  const handleSelectRow = () => {
    let data = { create: true, read: true, update: true, delete: true }
    setState((prev) => ({ ...prev, ...data }))
    if (permission.id) {
      update(resource, permission.id, data, {})
    }
  }

  return (
    <TableRow>
      <TableCell width="260" component="th" scope="row">
        <Box marginLeft={3}>
          <Tooltip title="Select all on this row">
            <div
              style={{ cursor: 'pointer', color: '#7e7a7a', textTransform: 'uppercase', fontSize: 12 }}
              onClick={handleSelectRow}
            >
              {permission.name}
            </div>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell>
        <Checkbox name="create" checked={state.create} onChange={(e) => handleChange('create', e.target.checked)} />
      </TableCell>
      <TableCell>
        <Checkbox name="read" checked={state.read} onChange={(e) => handleChange('read', e.target.checked)} />
      </TableCell>
      <TableCell>
        <Checkbox name="update" checked={state.update} onChange={(e) => handleChange('update', e.target.checked)} />
      </TableCell>
      <TableCell>
        <Checkbox name="delete" checked={state.delete} onChange={(e) => handleChange('delete', e.target.checked)} />
      </TableCell>
    </TableRow>
  )
}
