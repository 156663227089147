export const empty_brackets = (space: number, empty?: boolean) => {
  let result = []
  for (let i = 0; i < space; i++) {
    if (empty) {
      result[i] = {
        border: [false, false, false, false],
        text: '',
      }
    } else {
      result[i] = {}
    }
  }
  return result
}
