export function toDecimal(num: number): number {
  let scale = 2
  if (!('' + num).includes('e')) {
    return +(Math.round(Number(num + 'e+' + scale)) + 'e-' + scale)
  } else {
    var arr = ('' + num).split('e')
    var sig = ''
    if (+arr[1] + scale > 0) {
      sig = '+'
    }
    var i: number = +Number(arr[0] + 'e' + sig + (+arr[1] + scale))
    var j = Math.round(i)
    var k = +(j + 'e-' + scale)
    return k
  }
}

export function toDecimalStr(value: number, noDecimal?: boolean) {
  let result = ''

  if (noDecimal) {
    result = new Intl.NumberFormat(undefined, undefined).format(value as any)
  } else {
    result = value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  return result
}
