import PackList from './PackList'
import PackCreate from './PackCreate'
import PackEdit from './PackEdit'

const packs = {
  list: PackList,
  create: PackCreate,
  edit: PackEdit,
}

export default packs
