import { ProfileItemValue_input } from './types/input'

export const addItemState: ProfileItemValue_input = {
  product_variant: null,
  pack: null,
  product_variant_fk: null,
  pack_fk: '',
  profile_item_fk: '',
  use_default: false,
  vendor_code: '',
  weight_mc: 0,
  gross_weight: 0,
  default_gross_weight: 0.5,
  customer_fk_ids: [],
  weight_glazing: 0,
  rw_price: 0,
  profit: 0,
}
