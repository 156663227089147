import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Separate from 'src/components/Separate'
import { Order_expanded } from 'src/pages/orders/types'
import { Invoice_expanded } from 'src/pages/invoices/types'
import { Payment_fields } from 'src/types/globalTypes'
import Cashflow from './Cashflow'
import RevenueAnalysis from './RevenueAnalysis'
import RevenueAnalysisTable from './RevenueAnalysisTable'
import FinancialCorporateTable from './FinancialCorporateTable'

interface Props {
  value: number
  index: number
  fetchOrders?: {
    shippedout_orders: Order_expanded[]
    pending_orders: Order_expanded[]
    split: boolean
  }
  fetchInvoices?: {
    invoices: Invoice_expanded[]
    split: boolean
  }
  fetchPayments?: {
    payments: Payment_fields[]
    split: boolean
  }

  monthly: string[]
}

export default function FinancialTabPanel(props: Props) {
  const { value, index, fetchOrders, fetchInvoices, fetchPayments, monthly } = props

  return (
    <>
      <TabPanel value={value} index={index}>
        <Separate value={3} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Cashflow</Typography>
            <Separate value={1} />
            <Cashflow
              invoices={fetchInvoices?.invoices || []}
              split={fetchInvoices?.split || false}
              monthly={monthly}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Revenue Analysis</Typography>
            <Separate value={1} />
            <RevenueAnalysis data={fetchOrders} monthly={monthly} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Corporate Financials</Typography>
            <FinancialCorporateTable
              shipeedout_orders={fetchOrders?.shippedout_orders || []}
              payments={fetchPayments?.payments || []}
              invoices={fetchInvoices?.invoices || []}
              split={fetchOrders?.split || false}
              monthly={monthly}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Revenue Analysis</Typography>
            <RevenueAnalysisTable
              shipeedout_orders={fetchOrders?.shippedout_orders || []}
              pending_orders={fetchOrders?.pending_orders || []}
              payments={fetchPayments?.payments || []}
              invoices={fetchInvoices?.invoices || []}
              split={fetchOrders?.split || false}
              monthly={monthly}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
