import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Separate from 'src/components/Separate'
import { Order_expanded } from '../orders/types'

const lastDay = new Date()

interface Props {
  orders: Order_expanded[]
}

const UserOrdersChart = (props: Props) => {
  return (
    <>
      <Typography>Activity</Typography>
      <Separate />
      <Paper>
        <Box p={2}>
          <canvas id="user-orders" />
        </Box>
      </Paper>
    </>
  )
}

export default UserOrdersChart
