import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import SectionTitle from 'src/components/SectionTitle'
import Separate from 'src/components/Separate'
import { useForm, useFormState } from 'react-final-form'
import useGetNewRefPI from '../hooks/useGetNewRefPI'
import useCustomerOne from 'src/hooks/useCustomerOne'
import useVendorOne from 'src/hooks/useVendorOne'
import OrderGeneralInformation from './OrderGeneralInformation'
import OrderShippingDetails from './OrderShippingDetail'
import OrderDocumentDetails from './OrderDocumentDetails'
import OrderOptions from './OrderOptions'

const OrderInformationTabCreate = () => {
  const { values } = useFormState()
  const { change } = useForm()

  let state = useGetNewRefPI({
    vendor_fk_code: values.vendor_fk_code,
    customer_fk_code: values.customer_fk_code,
    pi_date: values.pi_date,
  })

  let { data: customer } = useCustomerOne({ customer_fk_code: values.customer_fk_code })
  let { data: vendor } = useVendorOne({ vendor_fk_code: values.vendor_fk_code })

  useEffect(() => {
    let result = ''
    let currency_fk = ''
    let port_fk = ''
    let year = new Date(values.pi_date).getFullYear().toString().slice(2, 4)
    if (state) {
      result = `${values.vendor_fk_code} ${state.vendorOrders?.toString().padStart(3, '0')}/${
        values.customer_fk_code
      }${state.customerOrders?.toString().padStart(3, '0')}/${year}`
    }

    if (values.vendor_fk_code) {
      currency_fk = vendor.currency?.code || ''
    }

    if (customer.customer_ports.length) {
      port_fk = customer.customer_ports[0].id
    }

    change('ref_pi', result)
    change('currency_fk', currency_fk)
    change('shipping_details.destination_port', port_fk)
  }, [customer, values.vendor_fk_code, values.customer_fk_code, values.pi_date, vendor, state, change])

  useEffect(() => {
    let doc: string[] = []
    if (customer.document.length) {
      doc = customer.document.filter((item) => item.isUsed === true).map((item) => item.label)
    }
    change('shipping_details.documents_required', doc)
  }, [customer.document, change])

  return (
    <>
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center">
              <Box display="flex">
                <SectionTitle mb={0.1} label="resources.order.fieldGroups.general_information" />
              </Box>
              <Typography style={{ marginLeft: '16px' }} variant="caption">
                {values.vendor_fk_code && values.customer_fk_code && `#${values.ref_pi}`}
              </Typography>
            </Box>

            <OrderGeneralInformation />
            <Separate value={5} />
            <OrderShippingDetails customer_fk_code={values.customer_fk_code} vendor_fk_code={values.vendor_fk_code} />
          </Grid>

          <Grid item xs={12} md={6}>
            <OrderOptions />
            <Separate value={5} />
            <OrderDocumentDetails />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default OrderInformationTabCreate
