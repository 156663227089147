import { empty_brackets } from 'src/utils/emptyBrackets'
import { toDecimalStr } from 'src/utils/toDecimal'

export function pi_total2(
  term_of_sales: string,
  destination: string,
  total_ctn: number,
  total_net_weight: number,
  total_cost_usd: number
) {
  const total = [
    [
      {},
      { style: 'tcCell', text: 'TOTAL' },
      ...empty_brackets(2),
      { style: 'tcCell', text: `${toDecimalStr(total_ctn, true)}` },
      { style: 'trCell', text: `${toDecimalStr(total_net_weight)}` },
      ...empty_brackets(3),
      { style: 'tcCell', colSpan: 3, text: `TOTAL ${term_of_sales.toUpperCase()} ${destination?.toUpperCase()}` },
      {},
      {},
      { style: 'trCell', text: `${toDecimalStr(total_cost_usd)}` },
    ],
  ]

  return total
}
