import React from 'react'
import Grid from '@material-ui/core/Grid'
import PaymentDetailForm from './PaymentDetailForm'
import Separate from 'src/components/Separate'
import PaymentAmount from './PaymentAmount'
import CurrencyForm from 'src/components/CurrencyForm'
import SectionTitle from 'src/components/SectionTitle'

const PaymentCreatePage = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={7}>
        <PaymentDetailForm />
        <Separate value={3} />
        <PaymentAmount />
      </Grid>
      <Grid item xs={12} md={4}>
        <SectionTitle label="resources.payment.fieldGroups.currency" />
        <CurrencyForm source="rate" />
      </Grid>
    </Grid>
  )
}

export default PaymentCreatePage
