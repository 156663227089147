import { useAuth0 } from '@auth0/auth0-react'
import { useCreate } from 'react-admin'
import moment from 'moment-timezone'

type ACTION_TYPE = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE' | 'HEADER'

export default function useCreateUserLog() {
  const { user } = useAuth0()
  let timestamp = moment.tz('Asia/Bangkok')

  const [create, { loading, error }] = useCreate()

  const createLog = (table?: string, type?: ACTION_TYPE, subject?: string) => {
    let data = {
      action_table: table,
      action_type: type,
      action_subject: subject,
      username: user?.nickname || '',
      timestamp,
    }

    create('user_log', data, {
      onFailure: (error) => {
        console.log(error)
      },
    })
  }

  return {
    createLog,
    loading,
    error,
  }
}
