import React, { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import UserDetailForm from './UserDetailForm'

const Title = ({ record }: any) => {
  return record.name
}

const transform = (data: any) => ({
  ...data,
  permission_group_ids: data.permission_group_choices.map((item: any) => item.id),
})

const UserEdit: FC<EditProps> = (props) => {
  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Edit {...props} title={<Title />} transform={transform} component="div">
        <UserDetailForm redirect={false} />
      </Edit>
    </>
  )
}

export default UserEdit
