import { gql } from '@apollo/client'

export const GET_LIST_INVOICE_HEADERS = gql`
  {
    id
    date
    order_id
    order {
      id
      ref_pi
      date_shipment
      customer_fk_code
      vendor_fk_code
      status
      total_net_weight
      unit_code_fk_id
      shipping_details
      customer {
        id
      }
      vendor {
        country
      }
    }

    ref_invoice
    information
    date
    terms_of_sale
    description
    author_name
    bank_fk_code
    use_header1
    use_header2
    use_header3
    use_format
    custom_ref_inv
    format_ref_invoice
    invoice_prefix_fk_code
    invoice_prefix {
      id
      format
    }
    port
    terms_of_sale
  }
`
export const GET_INVOICE_HEADER = gql`
  {
    id
    order_id
    order {
      id
      date_shipment
      ref_pi
      customer_fk_code
      vendor_fk_code
      current_revise_num
      total_net_weight
      currency_fk
      unit_code_fk_id
      customer {
        id
      }
    }
    ref_invoice
    information
    date
    terms_of_sale
    description
    author_name
    bank_fk_code
    use_format
    custom_ref_inv
    format_ref_invoice
    invoice_prefix_fk_code
    invoice_prefix {
      id
      format
    }
    port
    terms_of_sale
    use_header1
    use_header2
    use_header3
  }
`
