import React, { FC, useState } from 'react'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import {
  FieldProps,
  Link,
  useDelete,
  useGetList,
  useNotify,
  useQueryWithStore,
  useRedirect,
  useRefresh,
} from 'react-admin'
import { Product_fields } from 'src/types/globalTypes'
import { ProductVariant_expanded } from './types/ProductVariant'
import RemoveDialog from 'src/components/RemoveDialog'
import { OrderLineItems_expanded } from '../orders/types'
import MTTable from 'src/components/MTTable'
import { formatDate } from '@fullcalendar/react'

const ProductVariantList: FC<FieldProps<Product_fields>> = ({ record }) => {
  const [deleteOne] = useDelete()
  const redirectTo = useRedirect()
  const notify = useNotify()
  const refresh = useRefresh()

  const { loading, data: productVariants } = useQueryWithStore({
    type: 'getList',
    resource: 'product_variant',
    payload: {
      filter: {
        product_fk: record && record.id,
      },
      sort: {
        field: 'size_value.label',
        order: 'ASC',
      },
    },
  })

  const handleDelete = (id: string) => {
    deleteOne('product_variant', id, productVariants[id], {
      onSuccess: () => {
        notify('resources.global.notifications.success.create', { type: 'info' })
        refresh()
      },
    })
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Size</TableCell>
          <TableCell>Code</TableCell>
          <TableCell>GL</TableCell>
          <TableCell align="right">Weight loss</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading || !productVariants ? (
          <TableRow>
            <TableCell colSpan={4}>Loading...</TableCell>
          </TableRow>
        ) : productVariants.length ? (
          productVariants.map((item: ProductVariant_expanded, index: number) => (
            <Row
              key={index}
              item={item}
              product_id={record?.id || ''}
              redirectTo={redirectTo}
              handleDelete={handleDelete}
            />
          ))
        ) : null}
      </TableBody>
    </Table>
  )
}

export default ProductVariantList

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

interface RowProps {
  item: ProductVariant_expanded
  product_id: string
  redirectTo: (value: any) => void
  handleDelete: (value: string) => void
}

function Row(props: RowProps) {
  const classes = useRowStyles()
  const { item, product_id, redirectTo, handleDelete } = props
  const [open, setOpen] = useState(false)

  const { data: lineItems, ids: lineItemIds } = useGetList<OrderLineItems_expanded>(
    'order_line_item',
    { page: 1, perPage: 100 },
    { field: 'order.pi_date', order: 'DESC' },
    { product_fk: product_id, size_fk: item.size_fk, size_value_fk: item.size_value_fk }
  )

  return (
    <>
      <TableRow className={classes.root} hover>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell onClick={() => redirectTo(`/product/${product_id}/product_variant/${item.id}`)}>
          {item.size_value?.label} {item.size?.label}
        </TableCell>
        <TableCell onClick={() => redirectTo(`/product/${product_id}/product_variant/${item.id}`)}>
          {item.sku}
        </TableCell>
        <TableCell onClick={() => redirectTo(`/product/${product_id}/product_variant/${item.id}`)}>
          {item.glazing}
        </TableCell>
        <TableCell align="right" onClick={() => redirectTo(`/product/${product_id}/product_variant/${item.id}`)}>
          {item.weight_loss}
        </TableCell>
        <TableCell>
          <RemoveDialog title={item.product.name} onClick={() => handleDelete(item.id)} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>

              <MTTable
                columns={[
                  {
                    title: 'Date',
                    field: 'order.pi_date',
                    render: (rowData: any) => <div>{formatDate(rowData.order?.pi_date)} </div>,
                  },
                  {
                    title: 'Ref PI',
                    field: 'order.ref_pi',
                    render: (rowData: any) => <Link to={`/order/${rowData.order?.id}`}>{rowData.order?.ref_pi}</Link>,
                  },
                ]}
                data={lineItemIds.length ? lineItemIds.map((id) => lineItems[id]) : []}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
