import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Separate from 'src/components/Separate'
import OrderStepper from './OrderStepper'
import OrderTotal from './OrderTotal'
import Divider from '@material-ui/core/Divider'
import OrderItemsDetail from './OrderItemsDetail'
import OrderItemsWithCosts from './OrderItemsWithCosts'
import OrderItemsPricingDetail from './OrderItemsPricingDetail'
import OrderItemsOverview from './OrderItemsOverview'
import OrderExchangesExpenses from './OrderExchangesExpenses'
import { OrderProductSample_fields, OrderRevise_fields, Order_fields } from 'src/types/globalTypes'
import { OrderLineItems_expanded } from '../types'
import getOrderSumValues from '../utils/getOrderSumValues'
import NewReviseButton from './NewReviseButton'
import OrderAddItemDialog from './OrderAddItemDialog'
import { OrderAddLineItemInput } from '../types/input'
import formatProductVariantToLineItem from '../utils/formats/formatProductVariantToLineItem'
import { useCreate, useDelete, useNotify, useRefresh, useUpdate } from 'react-admin'
import formatUpdateOrderLineItem from '../utils/formats/formatUpdateOrderLineItem'
import OrderProductSamples from './OrderProductSample'
import formatUpdateOrderProductSample from '../utils/formats/formatUpdateOrderProductSample'
import OrderAddProductSampleDialog from './OrderAddProductSampleDialog'
import formatArrayLineItemsToSamples from '../utils/formats/formatArrayLineItemsToSamples'

interface Props {
  disabled?: boolean
  loading?: boolean
  unit_code: string
  current_revise_num: number
  currency_code: string
  record: Order_fields
  lineItems: OrderLineItems_expanded[]
  productSamples: OrderProductSample_fields[]
  revise?: OrderRevise_fields
  exchange_rate: number
  setlineItems: (values: OrderLineItems_expanded[]) => void
  setProductSamples: (values: OrderProductSample_fields[]) => void
  setRevise: (values: OrderRevise_fields) => void
}

const OrderLineItemsDetailTab = (props: Props) => {
  const {
    disabled,
    loading,
    currency_code,
    unit_code,
    exchange_rate,
    current_revise_num,
    productSamples,
    lineItems,
    record,
    revise,
    setlineItems,
    setProductSamples,
    setRevise,
  } = props
  const [openItems, setOpenItems] = useState(false)
  const [openSample, setOpenSample] = useState(false)
  const tableRef = React.createRef<any>()
  const [sampleSelected, setSampleSelected] = useState<any[]>([])
  const [tab, setTab] = React.useState(0)
  const notify = useNotify()
  let refresh = useRefresh()

  const [addItem] = useCreate()
  const [updateItem] = useUpdate()
  const [deleteItem] = useDelete()

  const {
    shipping_cost,
    shipping_expense_usd,
    total_cost_usd,
    total_ctn,
    total_amount,
    total_net_weight_kg,
    total_gross_weight_kg,
    total_gross_profit,
    total_product_samples_net_wet_kg,
  } = getOrderSumValues({
    unit_code,
    lineItems,
    revise,
    exchange_rate,
  })

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const handleAddItem = (value: OrderAddLineItemInput) => {
    let formatValue = formatProductVariantToLineItem(value, current_revise_num, 1, record.id)
    addItem('order_line_item', formatValue, {
      onSuccess: () => {
        notify('resources.global.notifications.success.create')
      },
    })
  }

  const handleAddItemClose = () => {
    setOpenItems(false)
    refresh()
  }

  const handleUpdateItemRow = (newData: any, oldData: any) => {
    let index = oldData.tableData.id
    let dataUpdate = [...lineItems]
    dataUpdate[index] = formatUpdateOrderLineItem({
      unit_code,
      data: newData,
      etc_cost: revise?.exchange_rate_info.etc_cost || 0,
      shipping_cost,
    })

    updateItem('order_line_item', oldData.id, newData, oldData, {
      onSuccess: () => {
        setlineItems(dataUpdate)
      },
    })
  }

  const handleDeleteItemRow = (oldData: any) => {
    let index = oldData.tableData.id
    let dataDelete = [...lineItems]
    dataDelete.splice(index, 1)
    deleteItem('order_line_item', oldData.id, oldData, {
      onSuccess: () => {
        setlineItems(dataDelete)
        notify('resources.global.notifications.success.delete', 'info')
      },
    })
  }

  const handleSampleSelectedRow = (row: any) => {
    setSampleSelected(row)
  }

  const handleAddSampleSave = () => {
    let formatValue = formatArrayLineItemsToSamples(sampleSelected, record.id)
    addItem('order_product_sample', formatValue, {
      onSuccess: ({ data }) => {
        if (data) {
          setProductSamples([...productSamples, { ...formatValue, id: data.id } as any])
          notify('resources.global.notifications.success.create', 'info')
        }
      },
    })

    setOpenSample(false)
    setSampleSelected([])
    tableRef.current?.onAllSelected(false)
  }

  const handleUpdateProductSampleRow = (newData: any, oldData: any) => {
    let index = oldData.tableData.id
    let dataUpdate = [...productSamples]
    dataUpdate[index] = formatUpdateOrderProductSample({ data: newData })
    updateItem('order_product_sample', oldData.id, newData, oldData, {
      onSuccess: () => {
        setProductSamples(dataUpdate)
      },
    })
  }

  const handleDeleteProductSampleRow = (oldData: any) => {
    let index = oldData.tableData.id
    let dataDelete = [...productSamples]
    dataDelete.splice(index, 1)
    deleteItem('order_product_sample', oldData.id, oldData, {
      onSuccess: () => {
        setProductSamples(dataDelete)
        notify('resources.global.notifications.success.delete', 'info')
      },
    })
  }

  const handleAddSampleClose = () => {
    setSampleSelected([])
    setOpenSample(false)
  }

  return (
    <>
      <Box p={3}>
        <OrderTotal
          currency_code={currency_code}
          locale_currency_fk={revise?.locale_currency_fk || ''}
          unit_code={unit_code}
          countItems={lineItems?.length || 0}
          shipping_usd={revise?.exchange_rate_info.shipping_usd || 0}
          etc_cost={revise?.exchange_rate_info.etc_cost || 0}
          exchange_rate={exchange_rate}
          shipping_cost={shipping_cost}
          shipping_expense_usd={shipping_expense_usd}
          total_ctn={total_ctn}
          total_amount={total_amount}
          total_cost_usd={total_cost_usd}
          total_gross_weight_kg={total_gross_weight_kg}
          total_net_weight_kg={total_net_weight_kg}
          total_product_samples_net_wet_kg={total_product_samples_net_wet_kg}
        />
        <Separate value={3} />
        <Divider />

        <Box my={3} display="flex" justifyContent="space-between">
          <Box width="30em" display="flex">
            <Typography variant="h6">Revise: {current_revise_num}</Typography>
            <Box ml={3}>
              <NewReviseButton
                disabled={disabled}
                current_num={current_revise_num}
                revise={revise}
                lineItems={lineItems}
                productSamples={productSamples}
              />
            </Box>
          </Box>
          <Box>
            {tab === 3 && (
              <Button color="primary" variant="contained" onClick={() => setOpenSample(true)} disabled={disabled}>
                + Add Product Sample
              </Button>
            )}

            {tab !== 3 && (
              <Button
                disabled={disabled}
                color="primary"
                variant="contained"
                style={{ marginRight: '8px' }}
                onClick={() => setOpenItems(true)}
              >
                + Add Product
              </Button>
            )}
          </Box>
        </Box>

        <OrderStepper loading={loading} value={tab} onChange={handleTabClick} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {tab === 0 && (
              <OrderItemsDetail
                loading={loading}
                data={lineItems}
                onUpdate={handleUpdateItemRow}
                onDelete={handleDeleteItemRow}
              />
            )}
          </Grid>
        </Grid>

        {tab === 1 && (
          <OrderExchangesExpenses
            vendor_fk_code={record.vendor_fk_code}
            customer_fk_code={record.customer_fk_code}
            disabled={true}
            unit_code={unit_code}
            currency_code={currency_code}
            revise={revise}
            total_ctn={total_ctn || 0}
            total_net_weight_kg={total_net_weight_kg || 0}
            setRevise={setRevise}
          />
        )}
        {tab === 2 && (
          <OrderItemsWithCosts
            currency_code={currency_code}
            unit_code={unit_code}
            loading={loading}
            revise={revise}
            exchange_rate={exchange_rate}
            data={lineItems}
            shipping_cost={shipping_cost}
            onUpdate={handleUpdateItemRow}
            onDelete={handleDeleteItemRow}
          />
        )}
        {tab === 3 && (
          <OrderProductSamples
            loading={loading}
            data={productSamples}
            onUpdate={handleUpdateProductSampleRow}
            onDelete={handleDeleteProductSampleRow}
          />
        )}
        {tab === 4 && (
          <OrderItemsPricingDetail
            loading={loading}
            data={lineItems}
            unit_code={unit_code}
            currency_code={currency_code}
            revise={revise}
            exchange_rate={exchange_rate}
            shipping_cost={shipping_cost}
            onUpdate={handleUpdateItemRow}
            onDelete={handleDeleteItemRow}
          />
        )}
        {tab === 5 && (
          <OrderItemsOverview
            data={lineItems}
            unit_code={unit_code}
            currency_code={currency_code}
            loading={loading}
            hasAccess={true}
            revise={revise}
            exchange_rate={exchange_rate}
            shipping_cost={shipping_cost}
            total_cost_usd={total_cost_usd}
            total_ctn={total_ctn}
            total_net_weight_kg={total_net_weight_kg}
            total_amount={total_amount}
            total_gross_profit={total_gross_profit}
          />
        )}
      </Box>

      {openSample && (
        <OrderAddProductSampleDialog
          tableRef={tableRef}
          open={openSample}
          loading={loading}
          lineItems={lineItems}
          disabled={sampleSelected.length ? false : true}
          onSelectionChange={handleSampleSelectedRow}
          onUpdate={handleUpdateItemRow}
          onSave={handleAddSampleSave}
          onCancel={handleAddSampleClose}
          onClose={handleAddSampleClose}
        />
      )}

      {openItems && (
        <OrderAddItemDialog
          open={openItems}
          vendor_fk_code={record.vendor_fk_code}
          customer_fk_code={record.customer_fk_code}
          onAddClick={handleAddItem}
          onClose={handleAddItemClose}
          onCancel={() => setOpenItems(false)}
        />
      )}
    </>
  )
}

export default OrderLineItemsDetailTab
