import React from 'react'
import { DateInput, required, SelectInput, TextInput } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import SectionTitle from 'src/components/SectionTitle'
import CustomerReferenceInput from '../customers/CustomerReferenceInput'
import BankChoiceReferenceInput from '../bankChoices/BankChoiceReferenceInput'

const PaymentDetailForm = () => {
  return (
    <Box>
      <SectionTitle label="resources.global.fieldGroups.information" />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth
            source="type"
            choices={PAYMENT_TYPE_CHOICES}
            optionText="name"
            validate={required()}
            helperText={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomerReferenceInput
            fullWidth
            source="customer_fk_code"
            label="Customer"
            helperText={false}
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput fullWidth source="date" helperText={false} disabled />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput fullWidth source="payment_date" helperText={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            fullWidth
            source="paid_type"
            choices={PAID_TYPE_CHOICES}
            optionText="name"
            allowEmpty
            helperText={false}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BankChoiceReferenceInput source="bank" allowEmpty fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="remark" fullWidth />
        </Grid>
      </Grid>
    </Box>
  )
}

export default PaymentDetailForm

const PAYMENT_TYPE_CHOICES = [
  {
    id: 'deposit',
    name: 'Deposit',
  },
  {
    id: 'withdraw',
    name: 'Withdraw',
  },
  {
    id: 'adjust',
    name: 'Adjust',
  },
]

const PAID_TYPE_CHOICES = [
  {
    id: 'local',
    name: 'Local',
  },
  {
    id: 'over_sea',
    name: 'Over sea',
  },
]
