import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { useInput } from 'react-admin'
import { STATUS_CHOICES } from '../constants/status'

const useStyles = makeStyles(() => ({
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
}))

export default function OrderStatusSelectInput(props: any) {
  const classes = useStyles()

  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  return (
    <TextField
      select
      name={name}
      label={props.label}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      fullWidth
      size="small"
      variant="filled"
      disabled={props.disabled}
      {...rest}
    >
      {STATUS_CHOICES.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          <span className={classes.color} style={{ backgroundColor: option.color }} />
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}
