import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { toDecimalStr } from 'src/utils/toDecimal'
import { useFormState } from 'react-final-form'
import { NumberInput } from 'react-admin'
import { formatDate } from 'src/utils/formatDate'
import CurrencySelect from '../CurrencySelect'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: 'flex',
      width: `350px`,
    },
    heading: {
      display: 'flex',
      flexDirection: 'column',
    },
    usdText: {
      fontSize: `15px`,
      color: '#546e7a',
    },
    currencyText: {
      marginTop: theme.spacing(1),
    },
    date: {
      marginTop: theme.spacing(1),
      color: '#546e7a',
    },
  })
)

export default function CurrencyForm(props: any) {
  const { values } = useFormState()
  const classes = useStyles()

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div className={classes.heading}>
          <Typography className={classes.usdText} variant="caption">
            1 United States Dollar equals
          </Typography>
          <Typography className={classes.currencyText} variant="h4">
            {toDecimalStr(values.exchange_rate || values.rate || 0)} {values?.currency_fk}
          </Typography>
          <Typography className={classes.date} variant="caption">
            {formatDate(values.pi_date)}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.row}>
          <TextField
            fullWidth
            style={{ marginRight: '16px' }}
            size="small"
            type="number"
            variant="outlined"
            value={1}
            disabled={true}
          />
          <TextField fullWidth size="small" variant="outlined" value={'USD'} disabled={true} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.row}>
          <NumberInput
            disabled={props.disabled}
            fullWidth
            source={props.source ? props.source : 'exchange_rate'}
            variant="outlined"
            label={''}
            style={{ marginRight: '16px' }}
          />
          <CurrencySelect source="currency_fk" disabled={props.disabled} />
        </div>
      </Grid>
    </Grid>
  )
}
