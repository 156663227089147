import { gql } from '@apollo/client'

export const GET_LIST_PRODUCTS = gql`
  {
    id
    name
    full_name
    product_type {
      id
      name
    }
    category {
      id
      name
    }
  }
`
