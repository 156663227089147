import { gql } from '@apollo/client'

export const GET_LIST_PRODUCT_VARIANTS = gql`
  {
    id
    product_fk
    size_fk
    size_value_fk
    product {
      id
      name
    }
    size {
      id
      label
    }
    size_value {
      id
      label
    }
    sku
    weight_loss
    glazing
  }
`

export const GET_ONE_PRODUCT_VARIANT = gql`
  {
    id
    product_fk
    size_fk
    size_value_fk
    product {
      id
      name
    }
    size {
      id
      label
      code
    }
    size_value {
      id
      label
      code
    }
    sku
    weight_loss
    glazing
  }
`
