import React, { FC } from 'react'
import { ReferenceField, ReferenceFieldProps } from 'react-admin'
import ProductTypeNameField from './ProductTypeNameField'

const ProductTypeReferenceField: FC<
  Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string
  }
> = (props) => {
  return (
    <ReferenceField source="product_type.id" reference="product_type" {...props}>
      <ProductTypeNameField />
    </ReferenceField>
  )
}

ProductTypeReferenceField.defaultProps = {
  source: 'product_type.id',
  addLabel: true,
}

export default ProductTypeReferenceField
