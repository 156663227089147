import { toDecimal } from 'src/utils/toDecimal'

interface Props {
  unit_code: string
  weight_mc: number
  box: number
  unit_cost: number
}

export default function getOrderLineTotalCostUsd(props: Props) {
  const { unit_code, weight_mc, box, unit_cost } = props
  let result = 0

  let net_weight_kg = toDecimal(weight_mc * box)

  if (unit_code) {
    result = unit_code === 'mc' ? toDecimal(box * unit_cost) : toDecimal(net_weight_kg * unit_cost)
  }

  return result
}
