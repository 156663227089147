import React, { FC } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Dialog from 'src/components/Dialog'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { CustomerDocument_field } from 'src/types/globalTypes'

interface Props {
  open: boolean
  data: CustomerDocument_field[]
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: () => void
  onClose?: () => void
  onCancel?: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 14,
    fontWeight: 400,
  },
}))

const EditCustomerDocumentDialog: FC<Props> = ({ open, data, onChange, onSubmit, onClose, onCancel }) => {
  const classes = useStyles()

  return (
    <Dialog title="Edit document" open={open} onClose={onClose} onCancel={onCancel} onSave={onSubmit}>
      <Grid container>
        {data.length
          ? data.map((item, index) => (
              <Grid key={index} item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={item.isUsed} onChange={onChange} name={`${item.label}`} />}
                  label={item.label}
                  className={classes.label}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </Dialog>
  )
}

export default EditCustomerDocumentDialog
