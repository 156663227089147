import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import SectionTitle from 'src/components/SectionTitle'
import { toDecimalStr } from 'src/utils/toDecimal'

const useStyles = makeStyles(() => ({
  leftSide: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginRight: 32,
  },
  rightSide: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  value: {
    fontSize: 13,
    fontWeight: 300,
  },
}))

interface Props {
  unit_code: string
  countItems: number
  total_ctn: number
  total_amount: number
  total_net_weight_kg: number
  total_gross_weight_kg: number
  total_product_samples_net_wet_kg: number
}

export default function InvoiceTotal(props: Props) {
  const classes = useStyles()
  const {
    countItems,
    total_amount,
    total_ctn,
    total_net_weight_kg,
    total_gross_weight_kg,
    total_product_samples_net_wet_kg,
  } = props

  return (
    <>
      <Box width="50em" display="flex" flexDirection="column">
        <SectionTitle mb={1} label="resources.order.fieldGroups.total" />
        <Box display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}>SUBTOTAL</Typography>
            <Typography className={classes.value}>{countItems} ITEMS</Typography>
          </Box>

          <Box className={classes.rightSide}>
            <Typography className={classes.title}>TOTAL/BOX</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_ctn)}</Typography>
          </Box>
        </Box>
        <Box mt={1} display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}>NET WEIGHT (KG)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_net_weight_kg || 0)} KG</Typography>
          </Box>
          <Box className={classes.rightSide}>
            <Typography className={classes.title}>GROSS WEIGHT (KG)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_gross_weight_kg)}</Typography>
          </Box>
        </Box>
        <Box mt={1} mb={3} display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}></Typography>
            <Typography className={classes.value}></Typography>
          </Box>
          <Box className={classes.rightSide}>
            <Typography className={classes.title}>TOTAL (USD)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_amount)}</Typography>
          </Box>
        </Box>
        <SectionTitle mb={1} label="resources.order.fieldGroups.product_samples" />
        <Box mt={1} mb={3} display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}>NET WEIGHT (KG)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_product_samples_net_wet_kg)} KG</Typography>
          </Box>
          <Box className={classes.rightSide}>
            {/* <Typography className={classes.title}>TOTAL (USD)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_amount)}</Typography> */}
          </Box>
        </Box>
      </Box>
    </>
  )
}
