import React from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useQueryWithStore } from 'react-admin'
import { formatDateToAPI } from 'src/utils/formatDate'
import { AppState, CalendarEvent_fields } from 'src/types/globalTypes'
import moment from 'moment'

const CalendarEvents = () => {
  const today = formatDateToAPI(new Date())

  const { data } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'calendar_event',
    payload: {
      filter: { 'start@_gte': today },
    },
  })

  return (
    <Card>
      <CardHeader title="List events" />
      <CardContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ? (
              data.map((item: CalendarEvent_fields, index: number) => (
                <TableRow hover key={index}>
                  <TableCell>{moment(item.start || today).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{item.title} </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  No found events
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default CalendarEvents
