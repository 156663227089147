import React from 'react'
import buildHasuraProvider from 'ra-data-hasura'
import { Admin, Resource } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import customEnglishMessage from './i18n/en'
import customBuildFields from './custom-build-filed'
import Layout from './layout/Layout'
import { customRoutes } from './routes'
import { useAuth0 } from '@auth0/auth0-react'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import products from './pages/products'
import customers from './pages/customers'
import orders from './pages/orders'
import payments from './pages/payments'
import invoiceHeaders from './pages/invoiceHeaders'
import invoices from './pages/invoices'
import categories from './pages/categories'
import productTypes from './pages/productTypes'
import sizes from './pages/sizes'
import packs from './pages/packs'
import currencies from './pages/currencies'
import unitCodes from './pages/unitCodes'
import countries from './pages/countries'
import shipmentTypes from './pages/shipmentTypes'
import invoicePrefix from './pages/invoicePrefix'
import vendors from './pages/vendors'
import destinationPorts from './pages/destinationPorts'
import LoginPage from './pages/auth/login'
import Dashboard from './pages/dashboard'
import useAuth0Provider from './auth/useAuth0Provider'
import bankChoices from './pages/bankChoices'
import profileItems from './pages/profileItems'
import users from './pages/users'
import permissionGroups from './pages/permissionGroups'
import userlogs from './pages/userlogs'


const i18nProvider = polyglotI18nProvider(() => {
  // Always fallback on english
  return customEnglishMessage
}, 'en')

const authLink = setContext((_, { headers }) => {
  let token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      'Authorization': `Bearer ${token}`,
    },
  }
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_GRAPHQL_URL,
  credentials: 'include',
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

function App() {
  const [dataProvider, setDataProvider] = React.useState<any>(null)
  let authProvider = useAuth0Provider()
  const { isLoading, error, isAuthenticated, getIdTokenClaims, loginWithRedirect, getAccessTokenSilently } = useAuth0()

  React.useEffect(() => {
    void (async () => {
      let token = (await getIdTokenClaims())?.__raw
      if (token === undefined) {
        loginWithRedirect()
      }
    })()
  }, [isAuthenticated, loginWithRedirect, getIdTokenClaims])

  React.useEffect(() => {
    if (isAuthenticated && !isLoading && !error) {
      void (async () => {
        const accessToken = await getAccessTokenSilently({ authorizationParams: { 
          audience: process.env.REACT_APP_AUDIENCE,
        }})

        localStorage.setItem('token', accessToken)
        const dataProvider = await buildHasuraProvider({ client }, { buildFields: customBuildFields })
        setDataProvider(() => dataProvider)
      })()
    }

    // eslint-disable-next-line
  }, [isAuthenticated]) //ts-ignored

  if (!dataProvider) return <p>Loading...</p>

  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      customRoutes={customRoutes}
      i18nProvider={i18nProvider}
      layout={Layout}
      loginPage={LoginPage}
      dashboard={Dashboard}
      disableTelemetry
    >
      {() => [
        // {/* Products */}
        <Resource name="category" {...categories} />,
        <Resource name="product_type" {...productTypes} />,
        <Resource name="size" {...sizes} />,
        <Resource name="size_value" />,
        <Resource name="pack" {...packs} />,

        // {/* Information */}
        <Resource name="vendor" {...vendors} />,
        <Resource name="vendor_port" />,
        <Resource name="currency" {...currencies} />,
        <Resource name="destination_port" {...destinationPorts} />,
        <Resource name="bank_choice" {...bankChoices} />,

        // {/* Orders & Invoices */}
        <Resource name="profile_item" {...profileItems} />,
        <Resource name="profile_item_value" />,
        <Resource name="expenses" />,
        <Resource name="unit_code" {...unitCodes} />,
        <Resource name="invoice_prefix" {...invoicePrefix} />,

        // {/* Users & Permissions*/}
        <Resource name="user" {...users} />,
        <Resource name="user_log" {...userlogs} />,
        <Resource name="permission_group" {...permissionGroups} />,
        <Resource name="permission" />,

        // {/* Others */}
        <Resource name="calendar_event" />,
        <Resource name="shipment_type" {...shipmentTypes} />,
        <Resource name="country" {...countries} />,
        <Resource name="address_book" />,
        <Resource name="bank_choice" />,
        <Resource name="user_log" />,
        <Resource name="widgets" />,

        // {/* Resources */}
        <Resource name="address_book" />,
        <Resource name="customer" {...customers} />,
        <Resource name="customer_port" />,
        <Resource name="product" {...products} />,
        <Resource name="product_variant" />,
        <Resource name="order" {...orders} options={{ label: 'PI' }} />,
        <Resource name="order_line_item" />,
        <Resource name="order_revise" />,
        <Resource name="timeline_comment" />,
        <Resource name="payment" {...payments} />,
        <Resource name="payment_term_choice" />,
        <Resource name="invoice_header" {...invoiceHeaders} />,
        <Resource name="invoice" {...invoices} />,
      ]}
    </Admin>
  )
}

export default App
