import React, { FC } from 'react'
import { Edit, EditProps, required, SimpleForm, TextInput, SelectInput, NumberInput } from 'react-admin'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import SectionTitle from '../../components/SectionTitle'
import Separate from '../../components/Separate'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

const styles: Styles<Theme, any> = {
  lInput: { display: 'inline-block' },
  rInput: { display: 'inline-block', marginLeft: 32 },
  field: { maxWidth: 544 },
}
const useStyles = makeStyles(styles)

const Title = ({ record }: any) => {
  return record.name
}

const ShipmentTypeEdit: FC<EditProps> = (props) => {
  const classes = useStyles(props)

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} -  Shipment type #${props.id}`} />
      <RouteBreadcrumbs {...props} />
      <Edit {...props} title={<Title />}>
        <SimpleForm redirect={false}>
          <SectionTitle label="resources.category.fieldGroups.information" />
          <TextInput source="name" validate={required()} fullWidth className={classes.field} />

          <TextInput source="code" validate={required()} formClassName={classes.lInput} />
          <NumberInput source="performance_count" formClassName={classes.rInput} />
          <Separate />

          <SelectInput
            source="container_size"
            choices={[
              { id: '20', name: '20' },
              { id: '40', name: '40' },
            ]}
            formClassName={classes.lInput}
            defaultValue={'20'}
          />
          <SelectInput
            source="container_type"
            choices={[
              { id: 'reefer', name: 'Reefer' },
              { id: 'dry', name: 'Dry' },
            ]}
            formClassName={classes.rInput}
            defaultValue={'reefer'}
          />
        </SimpleForm>
      </Edit>
    </>
  )
}

export default ShipmentTypeEdit
