export const insightGraphData = {
  labels: [''],
  datasets: [
    {
      label: ``,
      pointRadius: 4,
      pointHoverRadius: 4,
      pointBorderWidth: 1,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBorderColor: '#ffffff',
      fill: false,
      data: [{ x: 0, y: 0 }],
    },
  ],
}
