import React, { FC, memo } from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
  success: {
    color: theme.palette.success.main,
  },
}))

interface Props extends FieldProps {
  comparator: string | number | boolean
}

const CheckField: FC<Props> = ({ comparator, record, source }) => {
  const classes = useStyles()

  return record && source ? (
    <div className={classes.root}>
      {record[source] === comparator ? (
        <CheckCircleIcon className={classes.success} />
      ) : (
        <RemoveCircleIcon color="disabled" />
      )}
    </div>
  ) : null
}

CheckField.defaultProps = {
  source: '',
  label: 'Check',
}

export default memo<Props>(CheckField)
