import { useMemo } from 'react'
import _ from 'lodash'
import { useQueryWithStore } from 'react-admin'
import { OrderProductSample_fields } from 'src/types/globalTypes'

interface Props {
  order_id?: string
}

export default function useFetchOrderSamplesByOrder(props: Props) {
  const { order_id } = props

  const { loading, data, error } = useQueryWithStore({
    type: 'getList',
    resource: 'order_product_sample',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: { order_id: order_id },
    },
  })

  let lineItems = useMemo(() => formatItems(data), [data])

  return { data: lineItems, loading, error }
}

function formatItems(data: OrderProductSample_fields[]) {
  let result: OrderProductSample_fields[] = []
  if (data) {
    result = _.orderBy(data, ['product'], ['asc'])
  }
  return result
}
