import { gql } from '@apollo/client'

export const GET_LIST_VENDOR_PORTS = gql`
  {
    id
    vendor_fk_code
    port_fk_id
    destination_port {
      id
      name
    }
    vendor {
      id
    }
    isDefault
  }
`
