import { useMemo, useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import { AppState, Customer_fields } from 'src/types/globalTypes'

export default function useCustomers() {
  const [result, setResult] = useState<Customer_fields[]>([])

  const { data, total, loading, error } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'customer',
    payload: {
      sort: { field: 'code', order: 'ASC' },
    },
  })

  let customers = useMemo(() => {
    if (data) {
      setResult(data)
    }

    return result
  }, [data, result])

  return {
    data: customers,
    total: total || 0,
    loading,
    error,
  }
}
