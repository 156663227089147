interface Data {
  id: string
  name: string
}

export const DOCUMENT_CHOICE: Data[] = [
  {
    id: 'ACTUAL INVOICE',
    name: 'ACTUAL INVOICE',
  },
  {
    id: 'PACKING LIST',
    name: 'PACKING LIST',
  },
  {
    id: 'INVOICE(CUSTOMS)',
    name: 'INVOICE(CUSTOMS)',
  },
  {
    id: 'CERTIFICATE OF ORIGIN (C.O)',
    name: 'CERTIFICATE OF ORIGIN (C.O)',
  },
  {
    id: 'JTEPA (C.O FOR JAPAN ONLY)',
    name: 'JTEPA (C.O FOR JAPAN ONLY)',
  },
  {
    id: 'FORM D (C.O FOR VIETNAM ONLY)',
    name: 'FORM D (C.O FOR VIETNAM ONLY)',
  },
  {
    id: 'PINK FORM',
    name: 'PINK FORM',
  },
  {
    id: 'HEALTH CERTIFICATE(H.C)',
    name: 'HEALTH CERTIFICATE(H.C)',
  },
  {
    id: 'PHYTHOSANITARY CERTIFICATE',
    name: 'PHYTHOSANITARY CERTIFICATE',
  },
  {
    id: 'CERTIFICATE OF ANALYSIS (C.O.A)',
    name: 'CERTIFICATE OF ANALYSIS (C.O.A)',
  },
  {
    id: 'HALAL CERTIFICATE',
    name: 'HALAL CERTIFICATE',
  },
  {
    id: 'ANALYSIS REPORT',
    name: 'ANALYSIS REPORT',
  },
  {
    id: 'PACKING DECLARATION',
    name: 'PACKING DECLARATION',
  },
  {
    id: 'NOA (USA ONLY)',
    name: 'NOA (USA ONLY)',
  },
  {
    id: 'SHRIMP DECLARATION CERT.',
    name: 'SHRIMP DECLARATION CERT.',
  },
  {
    id: `QUALITY ASSURANCE CERTIFICATE  FOR FISH & FISHERY PRODUCT'S`,
    name: `QUALITY ASSURANCE CERTIFICATE  FOR FISH & FISHERY PRODUCT'S`,
  },
  {
    id: 'QC-NOC (CERTIFICATE)',
    name: 'QC-NOC (CERTIFICATE)',
  },
  {
    id: 'LOT CODE CERTIFICATE',
    name: 'LOT CODE CERTIFICATE',
  },
  {
    id: 'FUMIGATION CERTIFICATE',
    name: 'FUMIGATION CERTIFICATE',
  },
  {
    id: 'BL COPY',
    name: 'BL COPY',
  },
  {
    id: 'ORIGINAL BL',
    name: 'ORIGINAL BL',
  },
]
