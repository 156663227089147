interface Data {
  label: string
  isUsed: boolean
}

export const doc_data: Data[] = [
  {
    label: 'ACTUAL INVOICE',
    isUsed: false,
  },
  {
    label: 'PACKING LIST',
    isUsed: false,
  },
  {
    label: 'INVOICE(CUSTOMS)',
    isUsed: false,
  },
  {
    label: 'CERTIFICATE OF ORIGIN (C.O)',
    isUsed: false,
  },
  {
    label: 'JTEPA (C.O FOR JAPAN ONLY)',
    isUsed: false,
  },
  {
    label: 'FORM D (C.O FOR VIETNAM ONLY)',
    isUsed: false,
  },
  {
    label: 'PINK FORM',
    isUsed: false,
  },
  {
    label: 'HEALTH CERTIFICATE(H.C)',
    isUsed: false,
  },
  {
    label: 'PHYTHOSANITARY CERTIFICATE',
    isUsed: false,
  },
  {
    label: 'CERTIFICATE OF ANALYSIS (C.O.A)',
    isUsed: false,
  },
  {
    label: 'HALAL CERTIFICATE',
    isUsed: false,
  },
  {
    label: 'ANALYSIS REPORT',
    isUsed: false,
  },
  {
    label: 'PACKING DECLARATION',
    isUsed: false,
  },
  {
    label: 'NOA (USA ONLY)',
    isUsed: false,
  },
  {
    label: 'SHRIMP DECLARATION CERT.',
    isUsed: false,
  },
  {
    label: `QUALITY ASSURANCE CERTIFICATE  FOR FISH & FISHERY PRODUCT'S`,
    isUsed: false,
  },
  {
    label: 'QC-NOC (CERTIFICATE)',
    isUsed: false,
  },
  {
    label: 'LOT CODE CERTIFICATE',
    isUsed: false,
  },
  {
    label: 'FUMIGATION CERTIFICATE',
    isUsed: false,
  },
  {
    label: 'BL COPY',
    isUsed: false,
  },
  {
    label: 'ORIGINAL BL',
    isUsed: false,
  },
  {
    label: 'CATCHING CERTIFICATE',
    isUsed: false,
  },
  {
    label: 'REX',
    isUsed: false,
  },
  {
    label: 'IMPORTER SECURITY FILING (10+2)/ ISF(10+2)',
    isUsed: false,
  },
  {
    label: 'BL (MYANMAR)',
    isUsed: false,
  },
  {
    label: 'ORIGINAL BL CTG',
    isUsed: false,
  },
]
