import { useQueryWithStore } from 'react-admin'

const useInvoiceHeaderPrefixCount = (code: string) => {
  const { total } = useQueryWithStore({
    type: 'getList',
    resource: 'invoice_header',
    payload: { filter: { invoice_prefix_fk_code: code } },
  })

  return total
}

export default useInvoiceHeaderPrefixCount
