import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import AddIcon from '@material-ui/icons/Add'
import { useGetOne, useNotify, useRefresh, useUpdate } from 'react-admin'
import { InsightSalesAndProfitState, InsightWidgetStates } from '../../types'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import InsightCard from '../Tools/InsightCard'
import { ordersByPeriod } from '../../utils/ordersByPeriod'
import { Order_expanded } from 'src/pages/orders/types'
import moment from 'moment'
import getOrderSumValues from 'src/pages/orders/utils/getOrderSumValues'
import RevenueTargetDialog from './RevenueTargetDialog'
import { formatArrayNames } from '../../utils/formatArrayNames'

const BorderLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      height: 30,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  })
)(LinearProgress)

const useStyles = makeStyles(() => ({
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
}))

interface Props {
  stats: InsightSalesAndProfitState
  colors: any
}

const RevenueTarget = (props: Props) => {
  const classes = useStyles()
  const { stats, colors } = props
  const [open, setOpen] = useState(false)
  const id = 'e0952e5e-e24a-4002-94bc-d31749fd430f'
  const [target, setTarget] = useState(0)
  const [updateOne] = useUpdate()
  const notify = useNotify()
  const refresh = useRefresh()

  const { data: insight } = useGetOne<InsightWidgetStates>('widgets', id)

  useEffect(() => {
    setTarget(insight?.values.revenue_target || 0)
  }, [insight])

  function handleSave() {
    let data = {
      values: { ...insight?.values, revenue_target: target },
    }
    updateOne('widgets', id, data, insight, {
      onSuccess: () => {
        notify('resources.global.notifications.success.create', { type: 'info' })
        refresh()
        setOpen(false)
      },
    })
  }

  return (
    <>
      <InsightCard
        title="Revenue target vs. current"
        action={
          <Tooltip title="Revenue target input">
            <IconButton style={{ marginLeft: 8 }} size="small" onClick={() => setOpen(true)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        }
      >
        <Grid container spacing={3}>
          {stats.fetchOrders?.length ? (
            stats.fetchOrders.map((graphStat, i) => {
              const dataColor = colors[i].join(',')
              const groupedData = groupAmountByWeek({
                orders: graphStat.shippedout_orders || [],
                weekly: stats.weekly || [],
                period: 'week',
                split: graphStat.split,
              })

              return groupedData.length
                ? groupedData.map((item: any, index: number) => (
                    <Grid key={index} item xs={12} md={6}>
                      <Box display="flex">
                        <span className={classes.color} style={{ backgroundColor: `rgba(${dataColor})` }} />
                        <Tooltip title={`${graphStat.vendor}: ${formatArrayNames(graphStat.customers)}`}>
                          <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                            {`${graphStat.vendor}: ${formatArrayNames(graphStat.customers)}`}
                          </Typography>
                        </Tooltip>
                      </Box>
                      <Box position="relative" width="100%">
                        <BorderLinearProgress variant="determinate" value={target} title="target" />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography>{toDecimalStr((item.revenue / target) * 100)}%</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                : null
            })
          ) : (
            <Grid item xs={12}>
              <Box position="relative" width="100%">
                <BorderLinearProgress variant="determinate" value={target} title="target" />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography>Target value: {toDecimalStr(target)}</Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </InsightCard>

      <RevenueTargetDialog
        open={open}
        value={target}
        onChange={setTarget}
        onClose={() => setOpen(false)}
        onSave={handleSave}
      />
    </>
  )
}

export default RevenueTarget

interface GroupAmount {
  orders: Order_expanded[]
  weekly: string[]
  period: moment.unitOfTime.StartOf
  split?: boolean
}

const groupAmountByWeek = (props: GroupAmount) => {
  const { orders, weekly, period, split } = props
  let groupOrdersByWeek = ordersByPeriod(orders, weekly, period, split)
  const result: any = []

  Object.values(groupOrdersByWeek).forEach((item) => {
    const amountByGroup: any = { title: '', orders: {} }
    Object.keys(item.orders).forEach((date) => {
      let amountOrders = item.orders[date].map((order) => {
        let { total_amount } = getOrderSumValues({
          unit_code: order.unit_code?.code || 'kg',
          lineItems: order.order_line_items,
          revise: order.order_revises[0],
          exchange_rate: order.exchange_rate,
        })
        return toDecimal(total_amount)
      })

      let revenue = amountOrders.length ? toDecimal(amountOrders.reduce((a, b) => a + b)) : 0
      amountByGroup.orders[date] = amountByGroup.orders[date] ? amountByGroup.orders[date] + revenue || 0 : revenue || 0
    })

    let revenues: number[] = Object.values(amountByGroup.orders).map((value) => toDecimal((value as number) || 0))
    let total_revenue = revenues.length ? revenues.reduce((a, b) => a + b) : 0

    result.push({ title: item.title, revenue: total_revenue })
  })

  return result
}
