import React, { FC } from 'react'
import clsx from 'clsx'
import {
  Create,
  CreateProps,
  required,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  useNotify,
  useRedirect,
} from 'react-admin'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import SectionTitle from 'src/components/SectionTitle'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import Separate from 'src/components/Separate'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'
import { doc_data } from './constants/doc_data'

const styles: Styles<Theme, any> = {
  fieldGroups: { display: 'inline-block', width: 350 },
  mr2: { marginRight: `16px` },
}

const useStyles = makeStyles(styles)

const CustomToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveWithLogButton action_type="CREATE" source="code" />
  </Toolbar>
)

const CustomerCreate: FC<CreateProps> = (props) => {
  const { permission, loaded } = usePermissionByUser('customer')
  const redirect = useRedirect()
  const notify = useNotify()

  const classes = useStyles(props)

  if (loaded && !permission.create) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  const transform = (data: any) => ({
    ...data,
    document: doc_data,
  })

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New customer`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props} transform={transform}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <SectionTitle label="resources.customer.fieldGroups.identify" />
          <TextInput fullWidth source="first_name" formClassName={clsx(classes.fieldGroups, classes.mr2)} />
          <TextInput fullWidth source="last_name" formClassName={classes.fieldGroups} />
          <Separate />
          <TextInput fullWidth source="email" formClassName={clsx(classes.fieldGroups, classes.mr2)} />
          <Separate value={2} />
          <SectionTitle label="resources.customer.fieldGroups.address" />
          <TextInput
            fullWidth
            source="company_name"
            validate={required()}
            formClassName={clsx(classes.fieldGroups, classes.mr2)}
          />
          <TextInput fullWidth source="code" validate={required()} formClassName={classes.fieldGroups} />
          <Separate />

          <TextInput fullWidth source="tel" formClassName={clsx(classes.fieldGroups, classes.mr2)} />
          <TextInput fullWidth source="fax" formClassName={classes.fieldGroups} />
          <Separate />

          <TextInput fullWidth source="phone" label="Mobile" formClassName={classes.fieldGroups} />
        </SimpleForm>
      </Create>
    </>
  )
}

export default CustomerCreate
