import { InvoiceProductSample_fields } from 'src/types/globalTypes'

const initialValues: InvoiceProductSample_fields = {
  id: '',
  invoice_id: '',
  product: '',
  size: '',
  pack: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kg: 0,
  gross_weight_kg: 0,
  box: 0,
  amount: 0,
  unit_price: 0,
}

interface Props {
  data: InvoiceProductSample_fields
}

export default function formatInvoiceUpdateProductSample(props: Props) {
  const { data } = props
  let result: InvoiceProductSample_fields = initialValues

  if (data) {
    result = {
      ...initialValues,
      id: data.id,
      invoice_id: data.invoice_id,
      product: data.product,
      net_weight_kg: data.net_weight_kg,
      gross_weight_kg: data.gross_weight_kg,
      box: data.box,
    }
  }

  return result
}
