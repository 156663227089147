import { gql } from '@apollo/client'

export const GET_PERMISSION_GROUP_ONE = gql`
  {
    id
    name
    vendors
    enable_configuration
    enable_report_items
    permissions {
      id
      name
      create
      read
      update
      delete
      permission_group_id
      tab
      keyId
    }
  }
`
