import { toDecimal } from 'src/utils/toDecimal'
import { InvoiceLineItem_expanded } from '../../types'

const initialValues: InvoiceLineItem_expanded = {
  id: '',
  invoice_id: '',
  product_fk: '',
  size_fk: null,
  size_value_fk: null,
  size: null,
  size_value: null,
  box: 0,
  group_fk: null,
  remark: '',
  pack_fk: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kgs: 0,
  gross_weight_kgs: 0,
  price: 0,
  total_price: 0,
  weight_glazing: 0,
  glazing: '',
  raw_price: 0,
  finished_goods: 0,
  sku_fk: null,
  custom_kg: {
    net_weight_kg: 0,
    gross_weight_kg: 0,
  },
}

interface Props {
  data: InvoiceLineItem_expanded
  unit_code?: string
}

export default function formatUpdateInvoiceLineItem(props: Props) {
  const { data, unit_code } = props
  let result: InvoiceLineItem_expanded = initialValues
  let net_weight_kg = toDecimal(data.weight_mc * (data.box || 0))
  let total_price = unit_code === 'mc' ? toDecimal(data.price * data.box) : toDecimal(data.price * net_weight_kg)

  if (data) {
    result = {
      ...initialValues,
      id: data.id,
      invoice_id: data.invoice_id,
      product_fk: data.product_fk,
      size_fk: data.size_fk || null,
      size_value_fk: data.size_value_fk || null,
      pack_fk: data.pack_fk,
      product: data.product,
      size: data.size,
      size_value: data.size_value,
      pack: data.pack,
      weight_mc: data.weight_mc,
      net_weight_kgs: net_weight_kg,
      gross_weight: data.gross_weight,
      custom_kg: data.custom_kg,
      price: data.price,
      total_price,
      box: data.box || 0,
      remark: data.remark,
    }
  }

  return result
}
