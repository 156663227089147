import React, { FC } from 'react'
import ArrowDown from '@material-ui/icons/ArrowDownward'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FieldProps, ReferenceField, useShowController } from 'react-admin'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import useFetchOrderLineItemsByOrder from '../hooks/useFetchOrderLineItemsByOrder'
import { Customer_fields, Order_fields } from 'src/types/globalTypes'
import { OrderLineItems_expanded } from '../types'
import { formatDate } from 'src/utils/formatDate'
import CustomerPortReferenceField from 'src/pages/customerPorts/CustomerPortReferenceField'

const CustomerField: FC<FieldProps<Customer_fields>> = ({ record }) =>
  record ? (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption">{record.company_name}</Typography>
      <Typography variant="caption">
        {record.first_name} {record.last_name}
      </Typography>
      <Typography variant="caption">{record.email}</Typography>
    </Box>
  ) : null

interface Props extends FieldProps<Order_fields> {
  onOpen: (id: string) => void
}

const OrderPanel: FC<Props> = ({ onOpen, ...rest }) => {
  const { record } = useShowController<Order_fields>({ ...rest })
  const classes = useStyles()

  let { data: lineItems, loading } = useFetchOrderLineItemsByOrder({
    order_id: record?.id,
    revise_key: record?.current_revise_num,
  })

  if (!record) return null

  return (
    <>
      <ArrowDown className={classes.arrowIcon} />
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography className={classes.headline} variant="h6">
                CKERP-NEXT
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" alignItems="flex-end" position="relative">
                <Typography className={classes.headline} variant="h6" align="right">
                  {record.ref_pi}
                </Typography>
                <span className={classes.quickEditButton} onClick={() => onOpen(record.id)}>
                  Quick edit
                </span>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="caption">Date: {formatDate(record.pi_date)}</Typography>
              <br />
              <ReferenceField reference="customer" source="customer.id" record={record} link={false}>
                <CustomerField />
              </ReferenceField>
              <Box display="flex">
                <Typography variant="caption" style={{ marginRight: 4 }}>
                  Destination port:{' '}
                </Typography>
                <Typography variant="caption">
                  <CustomerPortReferenceField source="shipping_details.destination_port" link={false} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Pack</TableCell>
                    <TableCell align="center">MC</TableCell>
                    <TableCell align="right">Total NET/WET</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading || !lineItems ? (
                    lineItems?.map((item: OrderLineItems_expanded, index: number) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body2">
                            {item.product?.name}
                            <Typography className={classes.size} component="span" variant="caption">
                              {item.size_value ? ` - ${item.size_value.label} ${item.size?.label}` : ''}
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{item.pack?.name}</Typography>
                        </TableCell>
                        <TableCell align="center">{item.box}</TableCell>
                        <TableCell align="right">{toDecimalStr(item.box * item.weight_mc)}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>Loading...</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      <Typography variant="body2" className={classes.textBold}>
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" className={classes.textBold}>
                        {toDecimalStr(
                          lineItems.length
                            ? lineItems
                                .map((item: OrderLineItems_expanded) => item.box)
                                .reduce((a: number, b: number) => a + b)
                            : 0
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" className={classes.textBold}>
                        {toDecimalStr(
                          lineItems.length
                            ? lineItems
                                .map((item: OrderLineItems_expanded) => toDecimal(item.weight_mc * item.box))
                                .reduce((a: number, b: number) => a + b)
                            : 0
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default OrderPanel

const useStyles = makeStyles((theme: Theme) => ({
  root: { width: 998, margin: 'auto' },
  arrowIcon: { width: `100%`, color: '#b3b3b3', textAlign: 'center', margin: `0 auto` },
  size: { color: theme.palette.grey[600] },
  headline: { fontWeight: 600 },
  textBold: { fontWeight: 'bold' },
  quickEditButton: {
    color: theme.palette.primary.light,
    cursor: 'pointer',
  },
}))
