import React, { useEffect } from 'react'
import Dialog from 'src/components/Dialog'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { useGetOne } from 'react-admin'
import { Widgets_fields, WidgetValues_field } from 'src/types/globalTypes'

interface Props {
  id: string
  open: boolean
  state: WidgetValues_field
  setState: (values: WidgetValues_field) => void
  onClose: () => void
  onSave: () => void
}

export default function EditExchangeRateDialog(props: Props) {
  const { id, state, setState, ...rest } = props
  const { data } = useGetOne<Widgets_fields>('widgets', id)

  useEffect(() => {
    if (data) {
      setState({ ...data.values })
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleChange(name: string, value: number) {
    setState({
      ...state,
      [name]: value,
    })
  }

  return (
    <Dialog title="Edit exchange rate" {...rest}>
      <Box display="flex">
        <Box flex={1} mr={3}>
          <TextField
            variant="filled"
            size="small"
            label="Buy rate"
            fullWidth
            type="number"
            name="buy_rate"
            value={state.buy_rate}
            onChange={(e) => handleChange('buy_rate', Number(e.target.value))}
          />
        </Box>
        <Box flex={1}>
          <TextField
            variant="filled"
            size="small"
            label="Sale rate"
            fullWidth
            type="number"
            name="sale_rate"
            value={state.sale_rate}
            onChange={(e) => handleChange('sale_rate', Number(e.target.value))}
          />
        </Box>
      </Box>
    </Dialog>
  )
}
