import React, { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { ReduxState } from 'react-admin'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
    icon: {
      minWidth: theme.spacing(5),
    },
    sidebarIsOpen: {
      '& a': {
        paddingLeft: theme.spacing(4),
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      },
    },
    sidebarIsClosed: {
      '& a': {
        paddingLeft: theme.spacing(2),
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      },
    },
  })
)

interface Props {
  dense: boolean
  icon: ReactElement
  isOpen: boolean
  name: string
  handleToggle: () => void
}

const SubMenu: FC<Props> = ({ dense, icon, isOpen, name, children, handleToggle }) => {
  const classes = useStyles()
  const sidebarIsOpen = useSelector<ReduxState, boolean>((state) => state.admin.ui.sidebarOpen)

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle} component="div">
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <Typography className={classes.title} variant="inherit" color="textSecondary">
        {name}
      </Typography>
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </MenuItem>
  )

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
        >
          {children}
        </List>
      </Collapse>
    </>
  )
}

export default SubMenu
