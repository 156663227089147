import { toDecimal } from 'src/utils/toDecimal'
import { OrderLineItemInput } from '../types/input'

export default function getTotalNetWeight(data: OrderLineItemInput[], revise_key: number) {
  let total = 0
  if (data?.length) {
    total = data
      .filter((item) => item.revise_key === revise_key)
      .map((item) => toDecimal((item.box || 0) * item.weight_mc))
      .reduce((a, b) => a + b)
  }

  return Number(total)
}
