import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MTTable from 'src/components/MTTable'
import { toDecimalStr } from 'src/utils/toDecimal'
import { InsightProduct_topProducts } from '../../types'
import { Order_expanded } from 'src/pages/orders/types'
import ExpandProductRow from './ExpandProductRow'

interface Props {
  loading?: boolean
  value: number
  index: number
  data?: {
    shippedout_orders: Order_expanded[]
    topProducts: InsightProduct_topProducts[]
  }
}

export default function ProductTabPanel(props: Props) {
  const { value, index, loading, data } = props

  return (
    <TabPanel value={value} index={index}>
      <MTTable
        isLoading={loading}
        title={`Top Ranking Products`}
        columns={[
          {
            title: 'Product',
            field: 'product',
            editable: 'never',
            render: (rowData: any) => (
              <div>
                <Typography variant="body2">
                  {rowData.product}
                  <Typography component="span" variant="caption">
                    {rowData.size_value ? ` - ${rowData.size_value || ''} ${rowData.size || ''}` : ''}
                  </Typography>
                </Typography>
              </div>
            ),
          },
          { title: 'Pack', field: 'pack' },
          {
            title: 'Total NET/WET (KG)',
            field: 'net_weight_kg',
            type: 'numeric',
            render: (rowData: any) => <div>{toDecimalStr(rowData.net_weight_kg)}</div>,
          },
          {
            title: 'Profit margin (%)',
            field: 'profit',
            type: 'numeric',
            render: (rowData: any) => <div>{toDecimalStr(rowData.profit)}%</div>,
          },
          {
            title: 'Amount (USD)',
            field: 'amount',
            type: 'numeric',
            render: (rowData: any) => <div>{toDecimalStr(rowData.amount)}</div>,
          },
        ]}
        options={{
          pageSize: 50,
          pageSizeOptions: [10, 20, 50],
        }}
        style={{ padding: 18 }}
        data={data?.topProducts || []}
        detailPanel={(rowData: any) => {
          return <ExpandProductRow topProducts={rowData} orders={data?.shippedout_orders || []} />
        }}
      />
    </TabPanel>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
