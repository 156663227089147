import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { FormWithRedirect, Pagination, ReferenceManyField, TextInput, Toolbar } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import ProfileItemValueIterator from '../profileItemValues/ProfileItemValueIterator'
import { addItemState } from '../profileItemValues/data'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

export default function ProfileItemForm(props: any) {
  return (
    <>
      <FormWithRedirect
        redirect={false}
        initialValues={{
          addItem: addItemState,
        }}
        {...props}
        render={(formProps: any) => (
          <>
            <Box p={3}>
              <Helmet title={`${WEB_APP_TITLE} - ${formProps.record.vendor?.code}'s profile items`} />
              <SectionTitle label="resources.global.fieldGroups.information" />
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextInput source="vendor.code" fullWidth disabled label="Vendor" />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextInput source="default_gross_weight" fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <ReferenceManyField
                    addLabel={false}
                    pagination={<Pagination />}
                    target="profile_item_fk"
                    reference="profile_item_value"
                    sort={{ field: 'product_variant.product.name', order: 'ASC' }}
                  >
                    <ProfileItemValueIterator />
                  </ReferenceManyField>
                </Grid>
              </Grid>
            </Box>

            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              invalid={formProps.invalid}
              resource="profile_item"
            />
          </>
        )}
      />
    </>
  )
}
