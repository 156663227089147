import React, { FC } from 'react'
import { Datagrid, Filter, FilterProps, List, ListProps, SearchInput, TextField } from 'react-admin'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'

const CountryFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput source="label,code,phone" alwaysOn />
  </Filter>
)
const CountryList: FC<ListProps> = (props) => {
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Countries`} />
      <RouteBreadcrumbs {...props} />
      <List
        {...props}
        perPage={25}
        sort={{ field: 'code', order: 'ASC' }}
        filters={<CountryFilter />}
        bulkActionButtons={false}
      >
        <Datagrid rowClick="edit">
          <TextField source="label" />
          <TextField source="code" />
          <TextField source="phone" />
        </Datagrid>
      </List>
    </>
  )
}

export default CountryList
