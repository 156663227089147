import React, { SetStateAction, useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useDataProvider, useLoading } from 'react-admin'
import moment from 'moment'
import { InsightFilterStates, InsightFinancialStates } from './types'
import InsightDateRange from './components/Tools/InsightDateRange'
import InsightFilters from './components/Tools/InsightFilters'
import { DateRange } from 'materialui-daterange-picker'
import { formatArrayNames } from './utils/formatArrayNames'
import { colors } from 'src/utils/colors'
import FinancialTabPanel from './components/VendorFinancials/FinancialTabPanel'
import { formatDateToAPI } from 'src/utils/formatDate'
import { Order_expanded } from '../orders/types'
import { Invoice_expanded } from '../invoices/types'
import { Payment_fields } from 'src/types/globalTypes'

interface OrderStats {
  shippedout_orders: Order_expanded[]
  pending_orders: Order_expanded[]
  split: boolean
}

interface InvoiceStats {
  invoices: Invoice_expanded[]
  split: boolean
}

interface PaymentStats {
  payments: Payment_fields[]
  split: boolean
}

interface Props {
  state: InsightFilterStates
  setState: (values: SetStateAction<InsightFilterStates>) => void
}

export default function InsightVendorFinancials(props: Props) {
  const { state, setState } = props
  const dataProvider = useDataProvider()
  const loading = useLoading()
  const [tab, setTab] = useState(0)
  const [data, setData] = useState<InsightFinancialStates>({})

  const fetchOrders = useCallback(async () => {
    let vendors: string[] = []
    let monthly: string[] = []

    Object.values(state.filterTags || []).forEach((item) => {
      item.vendors.map((vendor) => vendors.push(vendor))
    })

    let vendor = vendors.length ? { vendor_fk_code: _.uniq(vendors) } : undefined
    let start_date = state.dateRange ? { 'pi_date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'pi_date@_lte': formatDateToAPI(state.dateRange.endDate) } : null

    const { data: orders } = await dataProvider.getList<Order_expanded>('order', {
      filter: { ...vendor, ...start_date, ...end_date },
      sort: { field: 'ref_pi', order: 'DESC' },
      pagination: { page: 1, perPage: vendors.length ? 3000 : 0 },
    })

    let i = 1
    let days = moment.duration(moment(state.dateRange?.endDate).diff(moment(state.dateRange?.startDate))).asDays()

    while (i <= days) {
      monthly.push(moment(state.dateRange?.startDate).clone().add(i, 'day').startOf('month').format('YYYY-MM-DD'))
      i += 31
    }

    const aggregations = state.filterTags?.map((item) => {
      let dataset: OrderStats = {
        shippedout_orders: [],
        pending_orders: [],
        split: item.split || false,
      }

      orders.reduce((stats, order) => {
        if (item.vendors.includes(order.vendor_fk_code)) {
          if (order.status === 'shipped_out') {
            dataset.shippedout_orders.push(order)
          }
          if (order.status !== 'cancelled' && order.status !== 'annulled' && order.status !== 'shipped_out') {
            dataset.pending_orders.push(order)
          }
        }

        return stats
      }, {})

      return dataset
    })

    setData((prev) => ({ ...prev, fetchOrders: aggregations, monthly }))
  }, [state.filterTags, state.dateRange, dataProvider])

  const fetchInvoices = useCallback(async () => {
    let vendors: string[] = []

    Object.values(state.filterTags || []).forEach((item) => {
      item.vendors.map((vendor) => vendors.push(vendor))
    })

    let start_date = state.dateRange ? { 'date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'date@_lte': formatDateToAPI(state.dateRange.endDate) } : null

    const { data: invoices } = await dataProvider.getList<Invoice_expanded>('invoice', {
      filter: { ...start_date, ...end_date, type_no: 3 },
      sort: { field: 'date', order: 'DESC' },
      pagination: { page: 1, perPage: vendors.length ? 3000 : 0 },
    })

    const aggregations = state.filterTags?.map((item) => {
      let dataset: InvoiceStats = {
        invoices: [],
        split: item.split || false,
      }

      invoices.reduce((stats, invoice) => {
        if (item.vendors.includes(invoice.invoice_header?.order.vendor_fk_code || '')) {
          dataset.invoices.push(invoice)
        }

        return stats
      }, {})
      return dataset
    })

    setData((prev) => ({ ...prev, fetchInvoices: aggregations }))
  }, [state.filterTags, state.dateRange, dataProvider])

  const fetchPayment = useCallback(async () => {
    let start_date = state.dateRange ? { 'date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'date@_lte': formatDateToAPI(state.dateRange.endDate) } : null

    const { data: payments } = await dataProvider.getList<Payment_fields>('payment', {
      filter: { ...start_date, ...end_date, type: 'deposit' },
      sort: { field: 'date', order: 'DESC' },
      pagination: { page: 1, perPage: state.filterTags?.length ? 3000 : 0 },
    })

    const aggregations = state.filterTags?.map((item) => {
      let dataset: PaymentStats = {
        payments: [],
        split: item.split || false,
      }
      payments.reduce((stats, payment) => {
        dataset.payments.push(payment)
        return stats
      }, {})
      return dataset
    })

    setData((prev) => ({ ...prev, fetchPayments: aggregations }))
  }, [state.filterTags, state.dateRange, dataProvider])

  useEffect(() => {
    fetchOrders()
    fetchInvoices()
    fetchPayment()

    return () => {
      setData({})
    }
  }, [fetchOrders, fetchInvoices, fetchPayment])

  const handleCustomerChange = (name: string, value: any) => {
    let newValue = { ...state, [name]: value }
    setState(newValue)
  }

  const handleDateRange = (value: DateRange) => {
    setState((prev) => ({ ...prev, dateRange: value }))
  }

  const handleAddFilter = () => {
    let newValues = []

    if (state.split) {
      newValues = Object.values(state.vendors || []).map((vendor) => ({
        vendors: [vendor],
        customers: [],
        split: state.split,
      }))
    } else {
      newValues = [{ vendor: state.vendor, vendors: state.vendors || [], customers: [], split: state.split }]
    }

    let newTags = [...(state.filterTags || []), ...newValues]
    setState((prev) => ({
      ...prev,
      vendor: null,
      vendors: [],
      filterTags: newTags,
    }))
  }
  const handleRemoveFilter = (index: number) => {
    if (state.filterTags) {
      let newValue = [...state.filterTags]
      newValue.splice(index, 1)
      setState({ ...state, filterTags: newValue })
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <InsightFilters
            loading={loading}
            useVendors
            useSplit
            vendors={state.vendors}
            onChange={handleCustomerChange}
            onAddFilter={handleAddFilter}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="right">
            <InsightDateRange dateRange={state.dateRange} setDateRange={handleDateRange} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          {state.filterTags?.length
            ? state.filterTags.map((item, i) => {
                const dataColor = colors[i].join(',')
                return (
                  <Tooltip title={item.customers.join(', ')} key={i}>
                    <Chip
                      variant="outlined"
                      style={{ border: `2px solid rgb(${dataColor})`, marginRight: 8 }}
                      label={`${formatArrayNames(item.vendors || [])}`}
                      onDelete={() => handleRemoveFilter(i)}
                    />
                  </Tooltip>
                )
              })
            : null}
        </Grid>
      </Grid>

      <Box my={2} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {state.filterTags?.length ? (
            <AppBar style={{ boxShadow: 'none' }} position="static" color="inherit">
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {state.filterTags.map((item, index) => (
                  <Tab key={index} label={`${formatArrayNames(item.vendors || [])}`} {...a11yProps(index)} />
                ))}
              </Tabs>
              {tab >= 0 && (
                <FinancialTabPanel
                  value={tab}
                  index={tab}
                  monthly={data.monthly || []}
                  fetchInvoices={data.fetchInvoices && data.fetchInvoices[tab]}
                  fetchOrders={data.fetchOrders && data.fetchOrders[tab]}
                  fetchPayments={data.fetchPayments && data.fetchPayments[tab]}
                />
              )}
            </AppBar>
          ) : (
            <Paper>
              <Box display="flex" alignItems="center" justifyContent="center" height="30em" width="100%">
                No data found
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}
