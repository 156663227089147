export function pi_header3() {
  return [header]
}

const header = [
  { style: 'tcCell', text: 'NO' },
  { style: 'tcCell', text: 'DESCRIPTION OF GOODS' },
  { style: 'tcCell', text: 'PACK' },
  { style: 'trCell', text: `WET/MC (KG.)` },
  { style: 'tcCell', text: 'TOTAL/CTN' },
  { style: 'trCell', text: 'NET/WET (KG.)' },
  { style: 'trCell', text: 'GROSS/WET (KG.)' },
  { style: 'tcCell', text: 'REMARK' },
]
