import React, { FC } from 'react'
import { Create, CreateProps, useNotify, useRedirect } from 'react-admin'
import { Helmet } from 'react-helmet'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'
import InvoiceHeaderCreateForm from './InvoiceHeaderCreateForm'

const InvoiceHeaderCreate: FC<CreateProps> = (props) => {
  const { permission, loaded } = usePermissionByUser('invoice_header')
  const redirect = useRedirect()
  const notify = useNotify()

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  const transform = (data: any) => ({
    id: data.id,
    ref_invoice: data.ref_invoice,
    order_id: data.order.id,
    date: data.date,
    information: data.information,
    author_name: data.author_name,
    bank_fk_code: data.bank_fk_code,
  })

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New invoice header`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props} transform={transform} component="div">
        <InvoiceHeaderCreateForm />
      </Create>
    </>
  )
}

export default InvoiceHeaderCreate
