import React, { FC, ReactElement } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useTranslate } from 'react-admin'

interface Props {
  label: string
  actions?: ReactElement
  mb?: number
}

const SectionTitle: FC<Props> = ({ label, actions, mb }) => {
  const translate = useTranslate()

  return (
    <Box component="div" mb={mb ? mb : 2} display="flex" justifyContent="space-between">
      <Typography style={{ fontWeight: 'bold' }} variant="h6" gutterBottom>
        {translate(label)}
      </Typography>
      {actions && actions}
    </Box>
  )
}

export default SectionTitle
