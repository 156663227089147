import React, { FC } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'

// styles
var useStyles = makeStyles((theme: Theme) => ({
  dotBase: {
    width: 8,
    height: 8,
    backgroundColor: theme.palette.text.hint,
    borderRadius: '50%',
    transition: theme.transitions.create('background-color'),
  },
  dotSmall: {
    width: 5,
    height: 5,
  },
  dotLarge: {
    width: 11,
    height: 11,
  },
}))

interface Props {
  size: 'large' | 'small'
}

const Dot: FC<Props> = ({ size }) => {
  var classes = useStyles()
  var theme = useTheme()

  return (
    <div
      className={clsx(classes.dotBase, {
        [classes.dotLarge]: size === 'large',
        [classes.dotSmall]: size === 'small',
      })}
      style={{
        backgroundColor: theme.palette.grey[400],
      }}
    />
  )
}

export default Dot
