import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { OrderLineItems_expanded } from '../types'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import { FieldArray } from 'react-final-form-arrays'
import { NumberInput, TextInput, useGetList } from 'react-admin'
import { InventoryLineItems_fields } from 'src/types/globalTypes'

const useStyles = makeStyles(() => ({
  number: {
    '& > label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
}))

interface Props {
  vendor_fk_code: string
  lineItems: OrderLineItems_expanded[]
}

export default function OrderLineITemsCreateTable(props: Props) {
  const classes = useStyles()
  const { lineItems, vendor_fk_code } = props
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Product</TableCell>
          <TableCell align="center" width="130">
            WET/MC (KG) & GROSS NET/MC (KG)
          </TableCell>
          <TableCell align="right" width="100">
            IN STOCK
          </TableCell>
          <TableCell align="right">TOTAL MC</TableCell>
          <TableCell align="right">TOTAL NET/WET</TableCell>
          <TableCell>REMARK</TableCell>
          <TableCell>ACTION</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <FieldArray name="order_line_items">
          {({ fields }) =>
            fields.map((name, index) => (
              <OrderLineItem
                key={index}
                name={name}
                lineItem={lineItems[index]}
                index={index}
                fields={fields}
                vendor_fk_code={vendor_fk_code}
              />
            ))
          }
        </FieldArray>
      </TableBody>
    </Table>
  )
}

interface LineItemProps {
  lineItem: OrderLineItems_expanded
  vendor_fk_code: string
  name: string
  index: number
  fields: any
}

function OrderLineItem(props: LineItemProps) {
  const { lineItem, name, index, fields, vendor_fk_code } = props
  const classes = useStyles()
  const [inStock, setStock] = useState(0)

  const { data, ids } = useGetList<OrderLineItems_expanded>(
    'order_line_item',
    { page: 1, perPage: 100000 },
    { field: 'box', order: 'ASC' },
    {
      'order#status': 'shipped_out',
      'order#vendor_fk_code@_eq': vendor_fk_code,
      product_fk: lineItem.product_fk,
      pack_fk: lineItem.pack_fk,
    }
  )

  const { data: inLineItems, ids: inIds } = useGetList<InventoryLineItems_fields>(
    'inventory_line_item',
    { page: 1, perPage: 100000 },
    { field: 'box', order: 'ASC' },
    {
      'inventory#vendor_fk_code@_eq': vendor_fk_code,
      product_fk: lineItem.product_fk,
      pack_fk: lineItem.pack_fk,
    }
  )

  useEffect(() => {
    let totalOrderMC = 0
    let totalInMC = 0
    if (ids.length) {
      totalOrderMC = ids.map((id) => toDecimal(data[id].box)).reduce((a, b) => a + b)
    }

    if (inIds.length) {
      totalInMC = inIds.map((id) => inLineItems[id].box).reduce((a, b) => a + b)
    }

    setStock(totalInMC - totalOrderMC)
  }, [inLineItems, inIds, ids, data])

  return (
    <TableRow>
      <TableCell>
        <Box width="300px">
          <Typography variant="body2">
            {lineItem.product?.name}
            <Typography component="span" variant="caption">
              {lineItem.size ? ` - ${lineItem.size_value?.label} ${lineItem.size?.label}` : ''}
            </Typography>

            <Typography style={{ color: '#b4b4b4' }} variant="body2">
              {lineItem.pack?.name}
            </Typography>
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography align="center" variant="body2">
          {lineItem.weight_mc} / {lineItem.gross_weight}
        </Typography>
      </TableCell>

      <TableCell align="right">
        <Typography variant="body2" color={inStock < 0 ? 'error' : 'inherit'}>
          {toDecimalStr(inStock)}
        </Typography>
        {/* ( sum(sku & pack 2000) - (sum(order(sku & pack * shipped_out))) ) */}
      </TableCell>
      <TableCell align="right">
        <NumberInput
          label=""
          size="small"
          variant="standard"
          source={`${name}.box`}
          style={{ width: '80px' }}
          classes={{ root: classes.number }}
          inputProps={{
            style: {
              textAlign: 'right',
            },
          }}
          helperText={false}
        />
      </TableCell>
      <TableCell align="right">{toDecimalStr(lineItem.weight_mc * lineItem.box)}</TableCell>
      <TableCell>
        <TextInput label="" size="small" variant="standard" source={`${name}.remark`} helperText={false} />
      </TableCell>
      <TableCell>
        <IconButton color="secondary" size="small" onClick={() => fields.remove(index)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
