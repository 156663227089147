import { InvoicePDFSettingsProps } from 'src/pages/invoices/types'
import { formatTimestampToDate } from 'src/utils/formatDate'
import { letterCapitalize } from 'src/utils/letterCapitalize'

interface Props {
  settings: InvoicePDFSettingsProps
  date: string | Date
  bank_details: string
  author_name?: string | null
  description?: string | null
}

export function invoice_authorization(props: Props) {
  const { description, bank_details, author_name, date, settings } = props

  let exportTitle = settings.exportTitle === 'export' ? 'Export Section' : 'CFO'

  return [
    {
      alignment: 'justify',
      columns: [
        {
          style: 'bank',
          text: `${settings.showRemark ? `Remark: ${description}` : ''}
              ${settings.showBank ? `${bank_details}` : ''}`,
        },
        {
          style: 'authorization',
          text: `___________________________________________
              ${letterCapitalize(author_name || '')}
              (${formatTimestampToDate(date, '/')})
              ${exportTitle}`,
        },
      ],
    },
  ]
}
