import { InvoiceLineItem_expanded } from '../../types'
import { InvoiceLineItemInput, InvoiceLineItemProfileItemValueInput_expanded } from '../../types/input'

const initialValues: Omit<InvoiceLineItem_expanded, 'id' | 'invoice_id'> = {
  product_fk: '',
  size_fk: null,
  size_value_fk: null,
  pack_fk: '',
  box: 0,
  remark: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kgs: 0,
  gross_weight_kgs: 0,
  price: 0,
  total_price: 0,
  weight_glazing: 0,
  glazing: '',
  custom_kg: {
    net_weight_kg: 0,
    gross_weight_kg: 0,
  },
  raw_price: 0,
  finished_goods: 0,
  sku_fk: null,
  group_fk: null,
}

export default function formatInvoiceProductVariantToLineItem(
  data: InvoiceLineItemProfileItemValueInput_expanded,
  invoice_id?: string
) {
  let item: InvoiceLineItemInput | null = initialValues

  item = {
    ...initialValues,
    invoice_id: invoice_id || '',
    product_fk: data.product_variant.product_fk,
    size_fk: data.product_variant.size_fk || null,
    size_value_fk: data.product_variant.size_value_fk || null,
    pack_fk: data.pack.id,
    weight_mc: data.weight_mc,
    gross_weight: data.gross_weight,
    box: data.box || 0,
    remark: '',
  }

  return item
}
