import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Dialog from 'src/components/Dialog'
import MTTable from 'src/components/MTTable'
import { useTheme } from '@material-ui/core'
import { InvoiceLineItem_expanded } from './types'
import { toDecimalStr } from 'src/utils/toDecimal'
import { MTableBodyRow } from 'material-table'

interface Props {
  tableRef?: any
  loading?: boolean
  open: boolean
  disabled?: boolean
  lineItems: InvoiceLineItem_expanded[]
  onUpdate: (newData: any, oldData: any) => void
  onSelectionChange?: (row: any) => void
  onSave?: () => void
  onCancel?: () => void
  onClose?: () => void
}

const InvoiceAddProductSampleDialog: FC<Props> = ({
  tableRef,
  loading,
  lineItems,
  onUpdate,
  onSelectionChange,
  onClose,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <Dialog
      size="lg"
      title="Add Product Sample"
      onClose={onClose}
      {...rest}
      action={
        <IconButton size="small" color="secondary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <MTTable
        tableRef={tableRef}
        isLoading={loading}
        data={lineItems}
        columns={[
          {
            title: 'Product',
            field: 'product_variant.product.name',
            editable: 'never',
            render: (rowData: any) => (
              <div style={{ width: '350px' }}>
                <Typography variant="body2">
                  {rowData.product.name}
                  <Typography component="span" variant="caption">
                    {rowData.size_value ? ` - ${rowData.size_value.label} ${rowData.size?.label}` : ''}
                  </Typography>
                </Typography>
                <Typography style={{ color: theme.palette.grey[600] }} variant="caption">
                  {rowData.pack.name}
                </Typography>
              </div>
            ),
          },
          {
            title: 'Total NET/WET (KG)',
            field: 'net_weight_kgs',
            type: 'numeric',
            editable: 'never',
            render: (rowData: any) => toDecimalStr(rowData.weight_mc * rowData.box),
          },
          {
            title: 'Sample net wet (KG)',
            field: 'custom_kg.net_weight_kg',
            type: 'numeric',
          },
          {
            title: 'Sample gross wet (KG)',
            field: 'custom_kg.gross_weight_kg',
            type: 'numeric',
          },
        ]}
        components={{
          Row: (props) => (
            <MTableBodyRow
              {...props}
              onRowClick={(e: any) => {
                props.actions[0]().onClick(e, props.data)
              }}
            />
          ),
        }}
        editable={{
          onRowUpdate: async (newData: any, oldData: any) => {
            let promise = await new Promise((resolve) => {
              resolve(onUpdate(newData, oldData))
            })

            return promise
          },
        }}
        options={{
          selection: true,
          pageSize: 100,
          pageSizeOptions: [5, 10, 20, 100],
          searchFieldStyle: {
            width: '500px',
          },
          searchFieldAlignment: 'left',
        }}
        onSelectionChange={onSelectionChange}
        style={{
          border: '1px solid #e3e3e3',
        }}
      />
    </Dialog>
  )
}

export default InvoiceAddProductSampleDialog
