import React, { FC } from 'react'
import { ReferenceField, ReferenceFieldProps } from 'react-admin'
import OrderRefPIField from './OrderRefPIField'

const OrderReferenceField: FC<
  Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string
  }
> = (props) => {
  return (
    <ReferenceField source="order.id" reference="order" {...props}>
      <OrderRefPIField />
    </ReferenceField>
  )
}

OrderReferenceField.defaultProps = {
  source: 'order.id',
  addLabel: true,
}

export default OrderReferenceField
