import { invoice_header_img } from '../images/image_header'
import { invoice_header_img2 } from '../images/image_header2'
import { invoice_header_img3 } from '../images/image_header3'
import { invoice_header_img4 } from '../images/image_header4'

export function getLetterHeader(value: number) {
  let result = { header: {} }
  switch (value) {
    case 0:
      result = { header: {} }
      break
    case 1:
      result.header = {
        image: invoice_header_img,
        alignment: 'center',
        width: 500,
      }
      break
    case 2:
      result.header = {
        image: invoice_header_img2,
        alignment: 'center',
        width: 500,
      }
      break
    case 3:
      result.header = {
        image: invoice_header_img3,
        alignment: 'center',
        width: 500,
      }
      break
    case 4:
      result.header = {
        image: invoice_header_img4,
        alignment: 'center',
        width: 500,
      }
      break

    default:
      result = { header: {} }
      break
  }

  return result
}
