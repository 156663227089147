import { gql } from '@apollo/client'

export const GET_LIST_COMMENTS = gql`
  {
    id
    text
    user_id
    user {
      id
      name
    }
    vendor_id
    customer_id
    order_id
    invoice_id
    createdAt
    updatedAt
  }
`
