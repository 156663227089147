export const darkTheme = {}

export const lightTheme = {
  palette: {
    background: {
      default: '#fcfcfe',
    },
    type: 'light' as 'light',
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    RaSidebar: {
      root: {
        backgroundColor: '#fff',
        // borderRight: `1px solid #b6b6b6`,
      },
    },
    RaMenuItemLink: {
      root: {
        borderLeft: '3px solid #fff',
      },
      active: {
        borderLeft: '3px solid #4f3cc9',
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      },
    },
    MuiCardHeader: {
      title: {
        fontSize: '1.1rem',
        fontWeight: 600,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
      },
    },
    RaSearchInput: {
      input: { marginTop: 0 },
    },

    MuiFormControl: {
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      },
    },
  },
}
