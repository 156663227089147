import React from 'react'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { Edit } from 'react-admin'
import InvoiceDetailPage from './InvoiceDetailPage'
import { useLocation, useParams } from 'react-router-dom'

const Title = ({ record }: any) => {
  return <span>{record.invoice_header?.ref_invoice}</span>
}

const InvoiceEdit = (props: any) => {
  const { id }: any = useParams()
  const { pathname } = useLocation()
  let type_no = pathname.slice(14, 15)

  return (
    <>
      <RouteBreadcrumbs />
      <Edit
        id={id}
        resource="invoice"
        basePath={`/invoice/type-${type_no}`}
        mutationMode="pessimistic"
        component="div"
        title={<Title />}
      >
        <InvoiceDetailPage redirect={false} typeNo={type_no} />
      </Edit>
    </>
  )
}

export default InvoiceEdit
