import VendorList from './VendorsList'
import VendorCreate from './VendorsCreate'
import VendorEdit from './VendorsEdit'

const vendors = {
  list: VendorList,
  create: VendorCreate,
  edit: VendorEdit,
}

export default vendors
