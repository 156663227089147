import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/CardContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import VendorReferenceInput from '../vendors/VendorReferenceInput'
import { expenseValuesData } from './data'
import { toDecimalStr } from 'src/utils/toDecimal'
import { required, useInput, TextInput } from 'react-admin'

import { FieldArray } from 'react-final-form-arrays'
import { ExpenseValues_field } from 'src/types/globalTypes'

// const useStyles = makeStyles()

interface Props {
  open: boolean
  onSubmit: (values: any) => void
  onClose?: () => void
  onCancel?: () => void
}

const NewExpenseDialog: FC<Props> = ({ onClose, onSubmit, ...rest }) => {
  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} {...rest}>
      <DialogTitle>New expense</DialogTitle>
      <Form
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          title: '',
          vendor_fk_code: null,
          expenses: expenseValuesData,
        }}
        onSubmit={onSubmit}
        render={({ form, pristine, values, handleSubmit }) => (
          <form id="expense-form" onSubmit={handleSubmit}>
            <DialogContent style={{ position: 'relative' }}>
              <TextInput fullWidth source="title" />
              <VendorReferenceInput fullWidth label="Vendor" source="vendor_fk_code" validate={required()} />
              <Box>
                <Typography variant="body2">
                  Shipping expense:{' '}
                  {toDecimalStr(
                    values.expenses.length
                      ? values.expenses
                          .map((item: ExpenseValues_field) => Number(item.value))
                          .reduce((a: number, b: number) => a + b)
                      : 0
                  )}
                </Typography>
              </Box>

              <FieldArray name="expenses">
                {({ fields }) => (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Details of Expenses</TableCell>
                        <TableCell align="right">TOTAL</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fields.map((name, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Field
                              type="srOnly"
                              name={`${name}.label`}
                              style={{ border: 'none', background: 'none' }}
                              disabled
                              component="input"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <BoundedTextField
                              type="number"
                              name={`${name}.value`}
                              inputProps={{ style: { textAlign: 'right' } }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </FieldArray>
            </DialogContent>
            <DialogActions>
              <Button style={{ marginRight: '10px' }} onClick={onClose} variant="outlined">
                Cancel
              </Button>
              <Button
                style={{ marginRight: '20px' }}
                type="submit"
                variant="contained"
                color="primary"
                disabled={pristine || values.vendor_fk_code === null}
              >
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}

export default NewExpenseDialog

const BoundedTextField = (props: any) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  return (
    <TextField
      variant="outlined"
      inputProps={props.inputProps}
      size="small"
      name={name}
      label={props.label}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      {...rest}
    />
  )
}
