import React, { FC, useEffect } from 'react'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { invoice_template1 } from './utils/exportPDF/template1'
import { InvoiceLineItem_expanded, InvoicePDFSettingsProps, Invoice_expanded } from './types'
import { InvoiceGroupItem_fields, InvoiceProductSample_fields } from 'src/types/globalTypes'
import { invoice_template2 } from './utils/exportPDF/template2'
import { invoice_template3 } from './utils/exportPDF/template3'

const pdf = pdfMake
pdf.vfs = pdfFonts.pdfMake.vfs

interface Props {
  template: number
  data: Invoice_expanded
  lineItems: InvoiceLineItem_expanded[]
  groupItems: InvoiceGroupItem_fields[]
  productSamples: InvoiceProductSample_fields[]
  customerPort?: string
  vendorPort?: string
  //   address: AddressBook_ref_data
  settings: InvoicePDFSettingsProps
}

const InvoicePreviewPDF: FC<Props> = ({ template, ...rest }) => {
  useEffect(() => {
    let documentDefinition = invoice_template1({ ...rest }) as any

    if (template === 1) {
      documentDefinition = invoice_template1({ ...rest }) as any
    } else if (template === 2) {
      documentDefinition = invoice_template2({ ...rest }) as any
    } else if (template === 3) {
      documentDefinition = invoice_template3({ ...rest }) as any
    }

    const pdfDocGenerator = pdf.createPdf(documentDefinition as any)
    pdfDocGenerator.getDataUrl((dataUrl) => {
      const iframe = document.querySelector('#preview-pdf') as Element
      iframe.setAttribute('width', '100%')
      iframe.setAttribute('height', '100%')
      iframe.setAttribute('frameborder', '0')
      iframe.setAttribute('src', dataUrl)
    })
  }, [template, rest])

  return (
    <>
      <div style={{ height: `90vh` }} id="iframeContainer">
        <iframe title="preview-pdf" id="preview-pdf" />
      </div>
    </>
  )
}

export default InvoicePreviewPDF
