import ProductList from './ProductList'
import ProductCreate from './ProductCreate'
import ProductEdit from './ProductEdit'

const products = {
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
}

export default products
