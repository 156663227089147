import DestinationPortList from './DestinationPortList'
import DestinationPortCreate from './DestinationPortCreate'
import DestinationPortEdit from './DestinationPortEdit'

const destinationPorts = {
  list: DestinationPortList,
  create: DestinationPortCreate,
  edit: DestinationPortEdit,
}

export default destinationPorts
