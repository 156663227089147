import React, { FC } from 'react'
import { InputProps, useGetList, useInput } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Expenses_fields } from 'src/types/globalTypes'

interface Props extends InputProps {
  customer_fk_code: string
  vendor_fk_code: string
  label?: string
}

const ExpenseReferenceInput: FC<Props> = (props) => {
  const { data, ids } = useGetList<Expenses_fields>(
    'expenses',
    { page: 1, perPage: 10 },
    { field: 'title', order: 'ASC' },
    {
      vendor_fk_code: props.vendor_fk_code,
      customer_fk_code: props.customer_fk_code,
    }
  )

  let options = ids.length ? ids.map((id) => data[id]) : []

  const {
    input: { value, name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  return (
    <Autocomplete
      id="expense-value-group"
      renderTags={() => null}
      noOptionsText="No labels"
      value={value || null}
      onChange={(event, value) => {
        onChange(value)
      }}
      options={options}
      getOptionLabel={(option) => `${option ? `${option.title}` : ''}`}
      getOptionSelected={(option, values) => option.id === values.id}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={!!(touched && error)}
          helperText={touched && error}
          required={isRequired}
          label={props.label || 'Expense'}
          variant="filled"
          size="small"
        />
      )}
    />
  )
}

export default ExpenseReferenceInput
