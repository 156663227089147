import React, { FC } from 'react'
import { InputProps, useInput, useQueryWithStore } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { SizeValue_fields, Size_fields } from 'src/types/globalTypes'

interface Choices extends SizeValue_fields {
  size: Size_fields
}

interface Props extends InputProps {
  label?: string
}

const SizeReferenceInput: FC<Props> = (props) => {
  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'size_value',
    payload: {
      sort: { field: 'label', order: 'ASC' },
    },
  })

  const {
    input: { value, name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  let options = data
    ? data.map((option: Choices) => {
        const letter = option?.size ? option.size.label.toUpperCase() : ''
        return {
          letter: /[0-9]/.test(letter) ? '0-9' : letter,
          ...option,
        }
      })
    : []

  return (
    <Autocomplete
      id="size-value-group"
      disablePortal
      renderTags={() => null}
      noOptionsText="No labels"
      value={value || null}
      options={options.sort((a: Choices, b: Choices) => -b.size.label.localeCompare(a.size.label))}
      groupBy={(option) => option.letter}
      onChange={(event, value) => {
        onChange(value)
      }}
      getOptionLabel={(option) => `${option ? `${option.label} ${option.size.label}` : ''}`}
      getOptionSelected={(option, values) => option.id === values.id}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={!!(touched && error)}
          helperText={touched && error}
          required={isRequired}
          label="Size"
          variant="filled"
          size="small"
        />
      )}
    />
  )
}

export default SizeReferenceInput
