import React from 'react'
import { Authenticated } from 'react-admin'
import { Route } from 'react-router-dom'
import CalendarList from './pages/calendar/CalendarList'
import Configuration from './pages/configuration'
import CustomerAddresses from './pages/customers/CustomerAddresses'
import ExpenseEdit from './pages/expenses/ExpenseEdit'
import ExpenseList from './pages/expenses/ExpenseList'
import Insights from './pages/insights'
import InvoiceEdit from './pages/invoices/InvoiceEdit'
import InvoiceList from './pages/invoices/InvoiceList'
import ProductVariantCreate from './pages/products/ProductVariantCreate'
import ProductVariantEditItem from './pages/products/ProductVariantEditItem'
import ReportItems from './pages/reportItems'
import Statement from './pages/statement'
import BuyerBalance from './pages/statement/BuyerBalance'
import CustomerStatement from './pages/statement/CustomerStatement'
import UserProfile from './pages/users/UserProfile'

export const customRoutes = [
  <Route
    exact
    path="/buyer-balance"
    render={() => (
      <Authenticated>
        <BuyerBalance />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/customer-statement"
    render={() => (
      <Authenticated>
        <CustomerStatement />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/statement"
    render={() => (
      <Authenticated>
        <Statement />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/report_items"
    render={() => (
      <Authenticated>
        <ReportItems />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/profile/:id"
    render={() => (
      <Authenticated>
        <UserProfile />
      </Authenticated>
    )}
  />,

  <Route
    exact
    path="/insights"
    render={() => (
      <Authenticated>
        <Insights />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/configuration"
    render={() => (
      <Authenticated>
        <Configuration />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/customer/:id/addresses"
    render={() => (
      <Authenticated>
        <CustomerAddresses />
      </Authenticated>
    )}
  />,

  <Route
    exact
    path="/product/:id/product_variant/create"
    render={() => (
      <Authenticated>
        <ProductVariantCreate />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/product/:id/product_variant/:variantId"
    render={() => (
      <Authenticated>
        <ProductVariantEditItem />
      </Authenticated>
    )}
  />,

  <Route
    exact
    path="/invoice/type-1"
    render={() => (
      <Authenticated>
        <InvoiceList />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/invoice/type-2"
    render={() => (
      <Authenticated>
        <InvoiceList />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/invoice/type-3"
    render={() => (
      <Authenticated>
        <InvoiceList />
      </Authenticated>
    )}
  />,

  <Route
    exact
    path="/invoice/type-1/:id"
    render={() => (
      <Authenticated>
        <InvoiceEdit />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/invoice/type-2/:id"
    render={() => (
      <Authenticated>
        <InvoiceEdit />
      </Authenticated>
    )}
  />,
  <Route
    exact
    path="/invoice/type-3/:id"
    render={() => (
      <Authenticated>
        <InvoiceEdit />
      </Authenticated>
    )}
  />,

  <Route
    exact
    path="/expenses"
    render={() => (
      <Authenticated>
        <ExpenseList />
      </Authenticated>
    )}
  />,

  <Route
    exact
    path="/expenses/:id"
    render={() => (
      <Authenticated>
        <ExpenseEdit />
      </Authenticated>
    )}
  />,

  <Route
    exact
    path="/calendar_event"
    render={() => (
      <Authenticated>
        <CalendarList />
      </Authenticated>
    )}
  />,
]
