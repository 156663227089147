import { OrderReviseCreateInput } from '../../types/input'

export default function formatNewOrderRevise(data: OrderReviseCreateInput[]) {
  let items: OrderReviseCreateInput[] = []

  if (data.length) {
    items = data.map((item) => ({
      name: `REVISE-0`,
      date: item.date,
      exchange_rate_info: item.exchange_rate_info,
      expense_id: item.expense_id,
      expense_rate_info: item.expense_rate_info,
      revise_key: 0,
      shipping_baht: item.shipping_baht,
      gross_percentage: item.gross_percentage,
      locale_currency_fk: 'USD',
    }))
  }

  return items[0]
}
