import { empty_brackets } from 'src/utils/emptyBrackets'
import { toDecimalStr } from 'src/utils/toDecimal'

interface Props {
  type_no: number
  total_package_cartons: number
  total_net_weight: number
  total_gross_weight: number
  total_amount_value: number
}

export function invoice_totalAmount2(props: Props) {
  const { type_no, total_gross_weight, total_net_weight, total_package_cartons } = props
  let result: Array<any> = []

  switch (type_no) {
    case 1:
    case 2:
    case 3:
      result = [
        [
          ...empty_brackets(3, true),
          {
            style: 'tcCell',
            text: 'TOTAL',
          },
          {
            style: 'tcCell',
            text: toDecimalStr(total_package_cartons, true),
          },
          {
            style: 'trCell',
            text: toDecimalStr(total_net_weight),
          },
          {
            style: 'trCell',
            text: toDecimalStr(total_gross_weight),
          },
        ],
      ]
      break

    // case 2:
    //   result = [
    //     [
    //       {},
    //       {
    //         style: 'tcCell',
    //         text: `TOTAL`,
    //       },

    //       {
    //         style: 'tcCell',
    //         text: `${total_package_cartons}`,
    //       },
    //       {
    //         style: 'trCell',
    //         text: `${toDecimalStr(total_net_weight)}`,
    //       },
    //       {
    //         style: 'trCell',
    //         text: `${toDecimalStr(total_gross_weight)}`,
    //       },
    //     ],
    //   ]
    //   break
  }

  return result
}
