import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import { BooleanInput, DeleteButton, FormWithRedirect, SaveButton, TextInput, Toolbar, useGetMany } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import PermissionGroupReferenceInput from '../permissionGroups/PermissionGroupReferenceInput'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import { Divider } from '@material-ui/core'

export default function UserDetailForm(props: any) {
  const { data } = useGetMany('permission_group', props.record.permission_group_ids)

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        permission_group_choices: data.filter((item) => item),
      }}
      render={(formProps: any) => (
        <>
          <Helmet title={`${WEB_APP_TITLE} - User #${formProps.record.name}`} />
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <SectionTitle label="resources.global.fieldGroups.information" />
                  <Box width={{ xs: '100%', md: '30em' }} display="flex" flexDirection="column">
                    <TextInput disabled fullWidth source="name" />
                  </Box>
                  <Divider />
                  <Box width="360px">
                    <List subheader={<ListSubheader>Settings</ListSubheader>}>
                      <ListItem>
                        <ListItemText id="switch-list-label-wifi" primary="Enable superuser" />
                        <ListItemSecondaryAction>
                          <BooleanInput source="isSuperuser" label="" helperText={false} />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText id="switch-list-label-wifi" primary="Disable this user" />
                        <ListItemSecondaryAction>
                          <BooleanInput source="disable_user" label="" helperText={false} />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Box>
                </CardContent>
                <Toolbar>
                  <Box display="flex" width="100%" justifyContent="space-between">
                    <SaveButton handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} />
                    <DeleteButton
                      confirmTitle={`Delete user #${formProps.record.email}`}
                      record={formProps.record}
                      mutationMode="pessimistic"
                    />
                  </Box>
                </Toolbar>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <SectionTitle label="resources.user.fieldGroups.permissions" />
                  <Typography>User is assigned to:</Typography>
                  <PermissionGroupReferenceInput source="permission_group_choices" />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    />
  )
}
