import { ExpenseValues_field } from 'src/types/globalTypes'

export const expenseValuesData: ExpenseValues_field[] = [
  {
    label: 'Freight',
    value: 0,
  },
  {
    label: 'LSS',
    value: 0,
  },
  {
    label: 'GRI',
    value: 0,
  },
  {
    label: 'War Risk',
    value: 0,
  },
  {
    label: 'AMS',
    value: 0,
  },
  {
    label: 'Certificate',
    value: 0,
  },
  {
    label: 'CSS Carrier',
    value: 0,
  },
  {
    label: 'Surrender',
    value: 0,
  },
  {
    label: 'THC',
    value: 0,
  },
  {
    label: 'Seal',
    value: 0,
  },
  {
    label: 'BL',
    value: 0,
  },
  {
    label: 'Transport Charge',
    value: 0,
  },
  {
    label: 'Transportation Overnight - Day',
    value: 0,
  },

  {
    label: 'Pink Form',
    value: 0,
  },
  {
    label: 'Service Pink Form',
    value: 0,
  },
  {
    label: 'Form C/O CK',
    value: 0,
  },
  {
    label: 'Form C/O PTY',
    value: 0,
  },
  {
    label: 'Legalize @ Embassy',
    value: 0,
  },
  {
    label: 'Service Charge Legalize @ Embassy',
    value: 0,
  },
  {
    label: 'Customs',
    value: 0,
  },

  {
    label: 'DHL',
    value: 0,
  },
  {
    label: `Container 20RF`,
    value: 0,
  },
  {
    label: `Container 40RF`,
    value: 0,
  },
  {
    label: 'Gate Charge',
    value: 0,
  },
  {
    label: 'Lift Off',
    value: 0,
  },
  {
    label: 'Customs Formality',
    value: 0,
  },
  {
    label: 'Customers Fee',
    value: 0,
  },

  {
    label: 'FORM JTEPA',
    value: 0,
  },
  {
    label: 'FORM D',
    value: 0,
  },
  {
    label: 'Many ports',
    value: 0,
  },
]
