import React, { FC, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { FieldProps } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'nowrap',
    // alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))

interface Props extends FieldProps<any> {
  size?: string
}

const ProductTypeNameField: FC<Props> = ({ record }) => {
  const classes = useStyles()
  return record ? <div className={classes.root}>{record.name}</div> : null
}

ProductTypeNameField.defaultProps = {
  source: 'name',
  label: 'Name',
}

export default memo<Props>(ProductTypeNameField)
