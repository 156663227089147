import { createStyles, makeStyles } from '@material-ui/core'
import { InputProps, useInput } from 'react-admin'
import RAPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/plain.css'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      background: 'rgba(0, 0, 0, 0.04) !important',
    },
    input: {
      width: '100% !important',
      position: 'relative',
      backgroundColor: 'none',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      background: 'none !important',
      height: '48px !important',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
      '&::before': {
        left: 0,
        right: 0,
        bottom: 0,
        content: '"00a0"',
        position: 'absolute',
        transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
      '&::after': {},
    },
    dropdown: {
      bottom: '36px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    button: {
      backgroundColor: 'none',
      borderTopLeftRadius: '10px',
      background: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
    },

    searchClass: {
      background: 'none',
    },
  })
)

const PhoneInput = (props: InputProps) => {
  const classes = useStyles()
  const {
    input: { name, value, onChange },
    isRequired,
  } = useInput(props)

  return (
    <RAPhoneInput
      inputProps={{
        name,
        required: isRequired,
      }}
      value={value}
      onChange={onChange}
      country={'us'}
      containerClass={classes.container}
      inputClass={classes.input}
      dropdownClass={classes.dropdown}
      searchClass={classes.searchClass}
      buttonClass={classes.button}
    />
  )
}

export default PhoneInput
