import React from 'react'
import Grid from '@material-ui/core/Grid'
import InsightWidget from '../Tools/InsightWidget'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import { Order_expanded } from 'src/pages/orders/types'
import { ordersByPeriod } from '../../utils/ordersByPeriod'
import getOrderSumValues from 'src/pages/orders/utils/getOrderSumValues'

interface Props {
  data?: {
    shippedout_orders: Order_expanded[]
    pending_orders: Order_expanded[]
    split: boolean
  }
  monthly: string[]
}

export default function RevenueAnalysis(props: Props) {
  const { data, monthly } = props
  if (!data) return null

  const realised = groupAmountByWeek({
    orders: data?.shippedout_orders || [],
    weekly: monthly || [],
    period: 'month',
    split: data.split,
  })

  const unrealised = groupAmountByWeek({
    orders: data?.pending_orders || [],
    weekly: monthly || [],
    period: 'month',
    split: data.split,
  })
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Realised revenue (USD)" value={toDecimalStr(realised.revenue)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Unrealised revenue (USD)" value={toDecimalStr(unrealised.revenue)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Total revenue (USD)" value={toDecimalStr(realised.revenue + unrealised.revenue)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Backlog ratio(USD)" value={toDecimalStr((unrealised.revenue / realised.revenue) * 100)} />
      </Grid>
    </Grid>
  )
}

interface GroupAmount {
  orders: Order_expanded[]
  weekly: string[]
  period: moment.unitOfTime.StartOf
  split?: boolean
}

const groupAmountByWeek = (props: GroupAmount) => {
  const { orders, weekly, period, split } = props
  let groupOrdersByWeek = ordersByPeriod(orders, weekly, period, split)
  let result = { title: '', revenue: 0 }

  Object.values(groupOrdersByWeek).forEach((item) => {
    const amountByGroup: any = { title: '', revenue: 0 }
    Object.keys(item.orders).forEach((date) => {
      let amountOrders = item.orders[date].map((order) => {
        let { total_amount } = getOrderSumValues({
          unit_code: order.unit_code?.code || 'kg',
          lineItems: order.order_line_items,
          revise: order.order_revises[0],
          exchange_rate: order.exchange_rate,
        })
        return toDecimal(total_amount)
      })

      let revenue = amountOrders.length ? toDecimal(amountOrders.reduce((a, b) => a + b)) : 0
      amountByGroup.revenue += revenue || 0
    })

    result = {
      title: item.title,
      revenue: toDecimal(amountByGroup.revenue),
    }
  })

  return result
}
