import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useDataProvider, useLoading, useVersion } from 'react-admin'
import { ProfileItem_expanded } from '../profileItems/types'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import Separate from 'src/components/Separate'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import CountingProfileItems from './CountingProfileItems'
import useCustomers from 'src/hooks/useCustomers'
import { formatDate } from 'src/utils/formatDate'
import { CSVLink } from 'react-csv'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
)

export interface ReportItemState {
  profileItems: {
    id: string
    vendor_code: string
    nbItems: number
    customers?: {
      code: string
      nbItems: number
    }[]
  }[]
}

const ReportItems = () => {
  const version = useVersion()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const loading = useLoading()
  const { data: customers } = useCustomers()
  const [exportData, setExportData] = useState<Array<any>>([])
  const [state, setState] = useState<ReportItemState>()
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const fetchProfileItems = useCallback(async () => {
    const { data: profileItems } = await dataProvider.getList<ProfileItem_expanded>('profile_item', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'vendor.code', order: 'ASC' },
      filter: {},
    })

    const aggregations = [
      ...profileItems
        .filter((item) => item.profile_item_values_aggregate.aggregate.count > 0)
        .reduce((acc, curr) => {
          let key = curr.vendor.code
          let item = acc.get(key) || Object.assign({}, { id: curr.vendor_fk, vendor_code: key, nbItems: 0 })

          item.nbItems += curr.profile_item_values_aggregate.aggregate.count

          return acc.set(key, item)
        }, new Map())
        .values(),
    ]

    setState({
      profileItems: aggregations,
    })
  }, [dataProvider])

  useEffect(() => {
    fetchProfileItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version])

  useEffect(() => {
    let arr: any = []
    if (state?.profileItems.length) {
      Object.values(state.profileItems).forEach((item) => {
        arr.push({ code: item.vendor_code, total: item.nbItems })

        if (item.customers?.length) {
          Object.values(item.customers).forEach((item) => {
            arr.push({ code: item.code, total: item.nbItems })
          })
        }
        arr.push({ code: '', total: '' })
      })
    }

    setExportData(arr)
  }, [state?.profileItems])

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box width={{ xs: '100%', md: 800 }}>
      <RouteBreadcrumbs />
      <Helmet title={`${WEB_APP_TITLE} - Report items`} />
      <Separate value={3} />

      <Card>
        <CardHeader
          title="Total profile items (Include non-customers)"
          subheader={`Date: ${formatDate(new Date())}`}
          action={
            <CSVLink
              data={exportData}
              filename={`report-item-${formatDate(new Date())}`}
              style={{ textDecoration: 'none' }}
            >
              <Button color="primary" variant="outlined" size="small" disabled={loading}>
                Export
              </Button>
            </CSVLink>
          }
        />
        <CardContent>
          {state?.profileItems?.length
            ? _.sortBy(state.profileItems, ['vendor_code']).map((item, index) => (
                <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}bh-content`}
                    id={`panel${index}bh-header`}
                  >
                    <Typography className={classes.heading}>{item.vendor_code}</Typography>
                    <Typography className={classes.secondaryHeading}>{item.nbItems}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CountingProfileItems
                      vendor={item.vendor_code}
                      customers={customers}
                      profiles={state}
                      setProfile={setState}
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            : null}
        </CardContent>
      </Card>
    </Box>
  )
}

export default ReportItems
