import moment from 'moment-timezone'

export function formatDateToTimestamp(value?: Date | string) {
  return moment.tz(value, 'Asia/Bangkok').format()
}

export function formatTimestampToDate(value?: Date | string, format?: string) {
  return moment.tz(value, 'Asia/Bangkok').format(`DD${format ? format : `-`}MM${format ? format : `-`}YY`)
}

export function formatDateToAPI(value?: Date | string, format?: string) {
  return moment.tz(value, 'Asia/Bangkok').format(`YYYY${format ? format : `-`}MM${format ? format : `-`}DD`)
}

export function formatDate(value?: Date | string, delimiter?: string) {
  let hasDelimiter = delimiter ? delimiter : '/'
  return moment.tz(value, 'Asia/Bangkok').format(`DD${hasDelimiter}MM${hasDelimiter}YYYY`)
}

export function formatProductionDate(value?: Date | string, format?: string) {
  return moment.tz(value, 'Asia/Bangkok').format(`DD MMMM.- YYYY`)
}
