import React from 'react'
import { NumberInput } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import SectionTitle from 'src/components/SectionTitle'
import { useFormState } from 'react-final-form'

const PaymentAmount = () => {
  const { values } = useFormState()
  return (
    <Box>
      <SectionTitle label="resources.payment.fieldGroups.amount" />
      {/* <Box display="flex" alignItems="center">
        <SectionTitle label="resources.payment.fieldGroups.amount" />
        <BooleanInput style={{ marginLeft: 8 }} source="isNegative" label="Negative" />
      </Box>
      {values.isNegative ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <NumberInput fullWidth source="negative_adjust" label="Negative adjustment" />
          </Grid>
        </Grid>
      ) : ( */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <NumberInput fullWidth source="amount_deposit" helperText={false} label={`Amount ${values.type}`} />
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberInput fullWidth source="bank_charge" helperText={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberInput fullWidth source="exchange" helperText={false} />
        </Grid>
      </Grid>
      {/* )} */}
    </Box>
  )
}

export default PaymentAmount
