import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import SectionTitle from 'src/components/SectionTitle'
import { toDecimalStr } from 'src/utils/toDecimal'

const useStyles = makeStyles((theme) => ({
  root: {
    width: `50em`,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  leftSide: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginRight: 32,
  },
  rightSide: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
  },
  value: {
    fontSize: 13,
    fontWeight: 300,
  },
}))

interface Props {
  currency_code?: string
  locale_currency_fk?: string
  unit_code: string
  countItems: number
  total_ctn: number
  shipping_usd?: number
  shipping_expense_usd: number
  shipping_cost: number
  etc_cost?: number
  exchange_rate: number
  total_cost_usd: number
  total_amount: number
  total_net_weight_kg: number
  total_gross_weight_kg: number
  total_product_samples_net_wet_kg: number
}

export default function OrderTotal(props: Props) {
  const classes = useStyles()
  const {
    countItems,
    locale_currency_fk,
    currency_code,
    unit_code,
    etc_cost,
    shipping_cost,
    shipping_expense_usd,
    exchange_rate,
    total_cost_usd,
    total_ctn,
    total_net_weight_kg,
    total_gross_weight_kg,
    total_product_samples_net_wet_kg,
  } = props

  return (
    <>
      <Box className={classes.root} display="flex" flexDirection="column">
        <SectionTitle mb={1} label="resources.order.fieldGroups.total" />
        <Box display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}>SUBTOTAL</Typography>
            <Typography className={classes.value}>{countItems} items</Typography>
          </Box>

          <Box className={classes.rightSide}>
            <Typography className={classes.title}>TOTAL/MC</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_ctn)}</Typography>
          </Box>
        </Box>

        <Box mt={1} display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}>NET WEIGHT (KG)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_net_weight_kg || 0)} KG</Typography>
          </Box>
          <Box className={classes.rightSide}>
            <Typography className={classes.title}>GROSS WEIGHT (KG)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_gross_weight_kg)}</Typography>
          </Box>
        </Box>
        <Box mt={1} mb={3} display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}>TOTAL COST (USD)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_cost_usd)}</Typography>
          </Box>
          <Box className={classes.rightSide}>
            {/* <Typography className={classes.title}>Total Unit Price (USD)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_amount)}</Typography> */}
          </Box>
        </Box>

        <SectionTitle mb={1} label="resources.order.fieldGroups.expenses" />
        <Box display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}>
              SHIPPING COST ({currency_code}/{unit_code.toUpperCase()})
            </Typography>
            <Typography className={classes.value}>{toDecimalStr(shipping_cost)}</Typography>
          </Box>
          <Box className={classes.rightSide}>
            <Typography className={classes.title}>
              ETC COST ({locale_currency_fk}/{unit_code.toUpperCase()})
            </Typography>
            <Typography className={classes.value}>{toDecimalStr(etc_cost || 0)}</Typography>
          </Box>
        </Box>
        <Box mt={1} mb={3} display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}>SHIPPING EXPENSE (USD)</Typography>
            <Typography className={classes.value}>{toDecimalStr(shipping_expense_usd)}</Typography>
          </Box>
          <Box className={classes.rightSide}>
            <Typography className={classes.title}>ETC COST (USD/{unit_code.toUpperCase()})</Typography>
            <Typography className={classes.value}>{toDecimalStr((etc_cost || 0) / exchange_rate)}</Typography>
          </Box>
        </Box>

        <SectionTitle mb={1} label="resources.order.fieldGroups.product_samples" />
        <Box display="flex">
          <Box className={classes.leftSide}>
            <Typography className={classes.title}>NET WEIGHT (KG)</Typography>
            <Typography className={classes.value}>{toDecimalStr(total_product_samples_net_wet_kg)} KG</Typography>
          </Box>
          <Box className={classes.rightSide}></Box>
        </Box>
      </Box>
    </>
  )
}
