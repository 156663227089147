import { InvoiceGroupItem_fields } from 'src/types/globalTypes'
import { toDecimal } from 'src/utils/toDecimal'

const initialValues: InvoiceGroupItem_fields = {
  id: '',
  invoice_id: '',
  product: '',
  size: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kgs: 0,
  gross_weight_kgs: 0,
  box: 0,
  amount: 0,
  unit_price: 0,
  slug: '',
}

interface Props {
  data: InvoiceGroupItem_fields
  unit_code: string
}

export default function formatInvoiceUpdateGroupRow(props: Props) {
  const { data, unit_code } = props
  let result: InvoiceGroupItem_fields = initialValues

  if (data) {
    result = {
      ...initialValues,
      id: data.id,
      invoice_id: data.invoice_id,
      product: data.product,
      net_weight_kgs: data.net_weight_kgs,
      gross_weight_kgs: data.gross_weight_kgs,
      unit_price: data.unit_price,
      amount:
        unit_code === 'kg' ? toDecimal(data.net_weight_kgs * data.unit_price) : toDecimal(data.box * data.unit_price),
      box: data.box,
      slug: data.slug,
    }
  }

  return result
}
