import React, { FC } from 'react'
import { ReferenceField, ReferenceFieldProps } from 'react-admin'
import CustomerCodeField from './CustomerCodeField'

const CustomerReferenceField: FC<
  Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string
  }
> = (props) => {
  return (
    <ReferenceField source="customer.id" reference="customer" {...props}>
      <CustomerCodeField />
    </ReferenceField>
  )
}

CustomerReferenceField.defaultProps = {
  source: 'customer.id',
  addLabel: true,
}

export default CustomerReferenceField
