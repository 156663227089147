import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Separate from 'src/components/Separate'
import InvoiceStepper from './InvoiceStepper'
import InvoiceTotal from './InvoiceTotal'
import InvoiceItemsDetail from './InvoiceItemsDetail'
import Divider from '@material-ui/core/Divider'
import { InvoiceLineItem_expanded, Invoice_expanded } from './types'
import formatUpdateInvoiceLineItem from './utils/formats/formatUpdateInvoiceLineItem'
import { useCreate, useDelete, useNotify, useRefresh, useUpdate, useUpdateMany } from 'react-admin'
import InvoiceProductSamples from './InvoiceProductSample'
import { InvoiceGroupItem_fields, InvoiceProductSample_fields } from 'src/types/globalTypes'
import InvoiceGroupItemsDetail from './InvoiceGroupItemsDetailtsx'
import InvoiceAddProductSampleDialog from './InvoiceAddProductSampleDialog'
import formatInvoiceArrayProductVariantsToSamples from './utils/formats/formatArrayProductVariantsToSamples'
import formatInvoiceUpdateProductSample from './utils/formats/formatInvoiceUpdateProductSample'
import InvoiceAddItemDialog from './InvoiceAddItemDialog'
import { InvoiceLineItemProfileItemValueInput_expanded } from './types/input'
import formatInvoiceProductVariantToLineItem from './utils/formats/formatInvoiceProductVariantToLineItem'
import InvoiceAddGroupDialog from './InvoiceAddGroupDialog'
import formatInvoiceArrayLineItemToGroup from './utils/formats/formatInvoiceArrayLineItemsToGroup'
import formatInvoiceUpdateGroupRow from './utils/formats/formatInvoiceUpdateGroupRow'

interface Props {
  loading?: boolean
  record: Invoice_expanded
  unit_code: string
  lineItems: InvoiceLineItem_expanded[]
  groupItems: InvoiceGroupItem_fields[]
  productSamples: InvoiceProductSample_fields[]
  total_ctn: number
  total_amount: number
  total_net_weight_kg: number
  total_gross_weight_kg: number
  total_product_samples_net_wet_kg: number
  setlineItems: (values: InvoiceLineItem_expanded[]) => void
  setGroupItems: (Values: InvoiceGroupItem_fields[]) => void
  setProductSamples: (values: InvoiceProductSample_fields[]) => void
}

const InvoiceLineItemsDetailTab = (props: Props) => {
  const {
    loading,
    record,
    unit_code,
    lineItems,
    groupItems,
    productSamples,
    setlineItems,
    setGroupItems,
    setProductSamples,
    ...totalValues
  } = props
  const tableRefGroup = React.createRef<any>()
  const [tab, setTab] = React.useState(0)
  const [openAddItem, setOpenAddItem] = useState(false)
  const [openAddGroup, setOpenAddGroup] = useState(false)
  const [openAddSample, setOpenAddSample] = useState(false)
  const [groupSelected, setGroupSelected] = useState<any[]>([])
  const [sampleSelected, setSampleSelected] = useState<any[]>([])
  const notify = useNotify()
  const [addItem] = useCreate()
  const [updateItem] = useUpdate()
  const [updateItems] = useUpdateMany()
  const [deleteItem] = useDelete()
  const refresh = useRefresh()

  /* 
    Item Section
  */
  const handleAddItem = (value: InvoiceLineItemProfileItemValueInput_expanded) => {
    let formatValue = formatInvoiceProductVariantToLineItem(value, record.id)
    addItem('invoice_line_item', formatValue, {
      onSuccess: () => {
        notify('resources.global.notifications.success.create', { type: 'info' })
      },
    })
  }

  const handleUpdateItemRow = (newData: any, oldData: any) => {
    let index = oldData.tableData.id
    let dataUpdate = [...lineItems]
    dataUpdate[index] = formatUpdateInvoiceLineItem({
      unit_code,
      data: newData,
    })

    updateItem('invoice_line_item', oldData.id, newData, oldData, {
      onSuccess: () => {
        setlineItems(dataUpdate)
      },
    })
  }

  const handleDeleteItemRow = (oldData: any) => {
    let index = oldData.tableData.id
    let dataDelete = [...lineItems]
    dataDelete.splice(index, 1)
    deleteItem('invoice_line_item', oldData.id, oldData, {
      onSuccess: () => {
        setlineItems(dataDelete)
        notify('resources.global.notifications.success.delete', { type: 'info' })
      },
    })
  }

  /* 
    Group Section
  */
  const handleAddGroupSave = () => {
    let formatValue = formatInvoiceArrayLineItemToGroup(groupSelected, record.id, unit_code)
    let ids: string[] = []
    if (groupSelected.length) {
      ids = groupSelected.map((item) => item.id)
    }

    addItem('invoice_group_item', formatValue, {
      onSuccess: ({ data }) => {
        if (data) {
          notify('resources.global.notifications.success.create', { type: 'info' })
          setGroupItems([...groupItems, { ...formatValue, id: data.id } as any])
          updateItems('invoice_line_item', ids, { group_fk: formatValue.slug })
          refresh()
        }
      },
    })

    setOpenAddGroup(false)
    setGroupSelected([])
    tableRefGroup.current?.onAllSelected(false)
  }

  const handleUpdateGroupRow = (newData: any, oldData: any) => {
    let index = oldData.tableData.id
    let dataUpdate = [...groupItems]
    dataUpdate[index] = formatInvoiceUpdateGroupRow({ data: newData, unit_code: record.unit_code?.code || 'kg' })
    updateItem('invoice_group_item', oldData.id, newData, oldData, {
      onSuccess: () => {
        setGroupItems(dataUpdate)
      },
    })
  }

  const handleDeleteGroupRow = (oldData: any) => {
    let index = oldData.tableData.id
    let dataDelete = [...groupItems]
    dataDelete.splice(index, 1)
    deleteItem('invoice_group_item', oldData.id, oldData, {
      onSuccess: () => {
        setGroupItems(dataDelete)
        notify('resources.global.notifications.success.delete', { type: 'info' })
        refresh()
      },
    })
  }

  /* 
    Sample Section
  */
  const handleAddSampleSave = () => {
    let formatValue = formatInvoiceArrayProductVariantsToSamples(sampleSelected, record.id)
    addItem('invoice_product_sample', formatValue, {
      onSuccess: ({ data }) => {
        if (data) {
          setProductSamples([...productSamples, { ...formatValue, id: data.id } as any])
          notify('resources.global.notifications.success.create', { type: 'info' })
        }
      },
    })

    setOpenAddSample(false)
    setSampleSelected([])
  }

  const handleUpdateProductSampleRow = (newData: any, oldData: any) => {
    let index = oldData.tableData.id
    let dataUpdate = [...productSamples]
    dataUpdate[index] = formatInvoiceUpdateProductSample({ data: newData })
    updateItem('invoice_product_sample', oldData.id, newData, oldData, {
      onSuccess: () => {
        setProductSamples(dataUpdate)
      },
    })
  }

  const handleDeleteProductSampleRow = (oldData: any) => {
    let index = oldData.tableData.id
    let dataDelete = [...productSamples]
    dataDelete.splice(index, 1)
    deleteItem('invoice_product_sample', oldData.id, oldData, {
      onSuccess: () => {
        setProductSamples(dataDelete)
        notify('resources.global.notifications.success.delete', { type: 'info' })
      },
    })
  }

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const handleAddItemClose = () => {
    setOpenAddItem(false)
    refresh()
  }

  const handleGroupSelectedRow = (row: any) => {
    setGroupSelected(row)
  }

  const handleSampleSelectedRow = (row: any) => {
    setSampleSelected(row)
  }

  const handleAddSampleClose = () => {
    setSampleSelected([])
    setOpenAddSample(false)
  }
  const handleAddGroupClose = () => {
    setOpenAddGroup(false)
    setGroupSelected([])
    tableRefGroup.current?.onAllSelected(false)
  }

  return (
    <>
      <Box p={3}>
        <InvoiceTotal unit_code={unit_code} countItems={lineItems?.length || 0} {...totalValues} />
        <Separate value={3} />
        <Divider />

        <Box my={3} display="flex" justifyContent="flex-end">
          {tab === 0 && (
            <Button
              color="primary"
              variant="contained"
              style={{ marginRight: '8px' }}
              onClick={() => setOpenAddItem(true)}
            >
              + Add Product
            </Button>
          )}
          {tab === 1 && (
            <Button color="primary" variant="contained" onClick={() => setOpenAddGroup(true)}>
              Add group
            </Button>
          )}

          {tab === 2 && (
            <Button color="primary" variant="contained" onClick={() => setOpenAddSample(true)}>
              Add product sample
            </Button>
          )}
        </Box>

        <InvoiceStepper loading={loading} value={tab} onChange={handleTabClick} />
        {tab === 0 && (
          <InvoiceItemsDetail
            loading={loading}
            data={lineItems}
            unit_code={unit_code}
            onUpdate={handleUpdateItemRow}
            onDelete={handleDeleteItemRow}
          />
        )}
        {tab === 1 && (
          <InvoiceGroupItemsDetail
            loading={loading}
            data={groupItems}
            lineItems={lineItems}
            unit_code={unit_code}
            onUpdate={handleUpdateGroupRow}
            onDelete={handleDeleteGroupRow}
          />
        )}
        {tab === 2 && (
          <InvoiceProductSamples
            data={productSamples}
            onUpdate={handleUpdateProductSampleRow}
            onDelete={handleDeleteProductSampleRow}
          />
        )}
      </Box>

      {openAddItem && (
        <InvoiceAddItemDialog
          open={openAddItem}
          vendor_fk_code={record.invoice_header?.order.vendor_fk_code}
          customer_fk_code={record.invoice_header?.order.customer_fk_code}
          onAddClick={handleAddItem}
          onClose={handleAddItemClose}
          onCancel={handleAddItemClose}
        />
      )}

      {openAddGroup && (
        <InvoiceAddGroupDialog
          tableRef={tableRefGroup}
          open={openAddGroup}
          data={groupSelected}
          lineItems={lineItems}
          onSelected={handleGroupSelectedRow}
          onClose={handleAddGroupClose}
          onCancel={handleAddGroupClose}
          onSave={handleAddGroupSave}
        />
      )}

      {openAddSample && (
        <InvoiceAddProductSampleDialog
          loading={loading}
          open={openAddSample}
          lineItems={lineItems}
          onUpdate={handleUpdateItemRow}
          onSelectionChange={handleSampleSelectedRow}
          onSave={handleAddSampleSave}
          onCancel={handleAddSampleClose}
          onClose={handleAddSampleClose}
        />
      )}
    </>
  )
}

export default InvoiceLineItemsDetailTab
