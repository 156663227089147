import React, { FC, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom'
import { useGetOne, useNotify, useQueryWithStore, useRefresh, useUpdate, useUpdateMany } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import Separate from 'src/components/Separate'
import { AddressBook_fields, AppState, Customer_fields } from 'src/types/globalTypes'
import AddCustomerAddressDialog from './AddCustomeAddressDialog'
import EditCustomerAddressDialog from './EditCustomeAddressDialog'

const CustomerAddresses: FC<RouteComponentProps> = (props) => {
  const { id: customerId }: any = useParams()
  const [openCreate, setOpenCreate] = useState(false)
  const [selectId, setSelect] = useState('')
  const [openEdit, setOpenEdit] = useState(false)

  const [updateItem] = useUpdate()
  const [updateItems] = useUpdateMany()
  const refresh = useRefresh()
  const notify = useNotify()

  const { data: customer } = useGetOne<Customer_fields>('customer', customerId)
  const { loaded, data } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'address_book',
    payload: {
      sort: { field: 'isDefault', order: 'DESC' },
      filter: { 'customer#id@_eq': customerId },
    },
  })

  let selectIndex = data?.length ? data.findIndex((item: any) => item.id === selectId) : 0

  const handleOpenEdit = (id: string) => {
    setOpenEdit(true)
    setSelect(id)
  }

  const hanldeCloseEdit = () => {
    setOpenEdit(false)
    setSelect('')
  }

  const handleMakeDefault = (id: string) => {
    let ids = data.filter((item: AddressBook_fields) => item.id !== id).map((item: AddressBook_fields) => item.id)
    updateItem(
      'address_book',
      id,
      { isDefault: true },
      {},
      {
        onSuccess: () => {
          notify('resources.global.notifications.success.update', 'info')
          updateItems('address_book', ids, { isDefault: false })
          refresh()
        },
      }
    )
  }

  if (!loaded || !data) return null

  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Separate value={3} />

      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
        <SectionTitle label="resources.customer.fieldGroups.addresses" />
        <Button variant="contained" color="primary" onClick={() => setOpenCreate(true)}>
          create
        </Button>
      </Box>
      <Grid container spacing={3}>
        {data
          ? data.map((item: AddressBook_fields, index: number) => (
              <Grid item xs={12} md={4} key={index}>
                <AddressCard
                  data={item}
                  onEdit={() => handleOpenEdit(item.id)}
                  onMakeDefault={() => handleMakeDefault(item.id)}
                />
              </Grid>
            ))
          : null}
      </Grid>
      {openCreate && (
        <AddCustomerAddressDialog
          open={openCreate}
          customer_fk_code={customer?.code || ''}
          setOpen={setOpenCreate}
          onClose={() => setOpenCreate(false)}
        />
      )}

      {openEdit && (
        <EditCustomerAddressDialog
          data={data[selectIndex]}
          open={openEdit}
          setOpen={setOpenEdit}
          onClose={hanldeCloseEdit}
        />
      )}
    </>
  )
}

interface AddressCardProps {
  data: AddressBook_fields
  onEdit: () => void
  onMakeDefault: () => void
}

const AddressCard: FC<AddressCardProps> = ({ data, onEdit, onMakeDefault }) => {
  const classes = useAddressStyle()

  const { data: country } = useQueryWithStore<AppState>({
    type: 'getOne',
    resource: 'country',
    payload: { code: data && data.country },
  })

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box>
            <SectionTitle label={`resources.customer.title.${data.isDefault ? 'primary_address' : 'address'}`} />
          </Box>
          <Box>
            <Tooltip title="Make default">
              <IconButton
                style={{ marginRight: `3px` }}
                color={data.isDefault ? 'primary' : 'default'}
                size="small"
                onClick={onMakeDefault}
              >
                <BookmarkIcon />
              </IconButton>
            </Tooltip>
            <IconButton size="small" color="primary" onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </Box>
        </Box>

        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell className={classes.cell}>Address 1</TableCell>
              <TableCell>{data.address_1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>Address 2</TableCell>
              <TableCell>{data.address_1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>Country</TableCell>
              <TableCell>{country && country.label}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>Tel</TableCell>
              <TableCell>{data.tel}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cell}>Fax</TableCell>
              <TableCell>{data.fax}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.cell}>Mobile</TableCell>
              <TableCell>{data.phone}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

const useAddressStyle = makeStyles((theme: Theme) => ({
  cell: {
    width: `80px`,
    fontSize: `14px`,
  },
}))

export default withRouter(CustomerAddresses)
