import { OrderLineItemInput } from '../../types/input'

export default function formatNewOrderLineItems(data: OrderLineItemInput[], revise_key?: number) {
  let items: OrderLineItemInput[] = []

  if (data) {
    items = data.map((item) => ({
      product_fk: item.product_fk,
      size_fk: item.size_fk || null,
      size_value_fk: item.size_value_fk || null,
      pack_fk: item.pack_fk,
      weight_mc: item.weight_mc,
      gross_weight: item.gross_weight,
      net_weight_kgs: item.net_weight_kgs,
      gross_weight_kgs: item.gross_weight_kgs || 0,
      remark: item.remark,
      box: item.box,
      cost: item.cost,
      margins: item.margins,
      profit: item.profit,
      unit_price: item.unit_price,
      amount: item.amount,
      revise_key: revise_key ? revise_key : 0,
      import_cost: item.import_cost,
      pack_cost_baht: item.pack_cost_baht,
      total_cost_usd_kg: item.total_cost_usd_kg,
      total_cost_usd: item.total_cost_usd,
      weight_glazing: item.weight_glazing,
      glazing: item.glazing,
      raw_cost: item.raw_cost,
      custom_import_costs: item.custom_import_costs,
      custom_kg: item.custom_kg,
    }))
  }

  return items
}
