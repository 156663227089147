import React, { FC } from 'react'
import { Datagrid, Filter, FilterProps, List, SearchInput, TextField } from 'react-admin'
import { Helmet } from 'react-helmet'
import DateField from 'src/components/DateField'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { WEB_APP_TITLE } from 'src/constants'
import PermissionGroupReferenceField from '../permissionGroups/PermissionGroupReferenceField'
import UserDisableuserrField from './UserDisableuserrField'
import UserSuperuserField from './UserSuperuserField'

const UserFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
  </Filter>
)

const UserList = (props: any) => {
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Users`} />
      <RouteBreadcrumbs />
      <List
        {...props}
        bulkActionButtons={false}
        perPage={25}
        filters={<UserFilter />}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <UserSuperuserField source="isSuperuser" label="Superuser" textAlign="center" />
          <UserDisableuserrField source="disable_user" label="Disable" textAlign="center" />
          <PermissionGroupReferenceField source="permission_group_ids" />
          <DateField source="created_at" />
        </Datagrid>
      </List>
    </>
  )
}

export default UserList
