import React from 'react'
import Dialog from 'src/components/Dialog'
import { makeStyles, Theme } from '@material-ui/core/styles'
import MTTable from 'src/components/MTTable'
import { InvoiceGroupItem_fields } from 'src/types/globalTypes'
import { InvoiceLineItem_expanded } from './types'
import { toDecimalStr } from 'src/utils/toDecimal'

const useStyles = makeStyles((theme: Theme) => ({
  ml: {
    marginLeft: theme.spacing(3),
  },
  mb: {
    marginBottom: theme.spacing(1),
  },
  heading: {
    display: 'flex',
  },
}))

interface Props {
  tableRef?: any
  loading?: boolean
  open: boolean
  data: InvoiceGroupItem_fields[]
  lineItems: InvoiceLineItem_expanded[]
  onSelected: (values: any) => void
  onClose?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export default function InvoiceAddGroupDialog(props: Props) {
  const classes = useStyles()
  const { tableRef, loading, open, data, lineItems, onSelected, onSave, onCancel, onClose } = props

  let newData = lineItems.filter((item) => item.group_fk === undefined || item.group_fk === null)

  const columns = [
    {
      title: 'Product',
      field: 'product.name',
      width: 300,
      render: (rowData: any) => (
        <>
          {rowData.product.name}
          {rowData.size_value ? ` - ${rowData.size_value.label}` : ''}
          {rowData.size ? rowData.size.label : ''}
        </>
      ),
      editable: 'never' as 'never',
    },
    {
      title: 'Pack',
      field: 'pack.name',
      render: (rowData: any) => <div style={{ width: `250px` }}>{rowData.pack.name}</div>,
      editable: 'never' as 'never',
    },
    {
      title: 'Weight MC.',
      field: 'weight_mc',
      type: 'numeric' as 'numeric',
      width: 100,
      editable: 'never' as 'never',
    },
    {
      title: 'Gross Weight',
      field: 'gross_weight',
      type: 'numeric' as 'numeric',
      width: 100,
      editable: 'never' as 'never',
    },
    {
      title: 'Box',
      field: 'box',
      type: 'numeric' as 'numeric',
      width: 100,
      render: (rawData: any) => (rawData.box > 0 ? toDecimalStr(rawData.box, true) : ''),
    },
    {
      title: 'Price',
      field: 'price',
      type: 'numeric' as 'numeric',
      width: 100,
      // render: (rawData: any) => (rawData.box > 0 ? toDecimalStr(rawData.price, true) : ''),
    },
  ]

  return (
    <Dialog
      title="Create Group"
      size="lg"
      disabled={data.length ? false : true}
      open={open}
      onSave={onSave}
      onClose={onClose}
      onCancel={onCancel}
    >
      <div className={classes.mb}></div>
      <MTTable
        tableRef={tableRef}
        isLoading={loading || !newData}
        columns={columns}
        data={newData}
        options={{
          selection: true,
          pageSize: 100,
          pageSizeOptions: [5, 10, 20, 100],
          searchFieldStyle: {
            width: '500px',
          },
          searchFieldAlignment: 'left',
        }}
        onSelectionChange={onSelected}
        style={{
          border: '1px solid #e3e3e3',
        }}
      />
    </Dialog>
  )
}
