import { InvoiceGroupItem_fields } from 'src/types/globalTypes'
import { toDecimal } from 'src/utils/toDecimal'

import { InvoiceLineItem_expanded } from '../types'

interface VALUES {
  total_ctn: number
  total_unit_price: number
  total_amount: number
  total_net_weight_kg: number
  total_gross_weight_kg: number
}

const initialValues: VALUES = {
  total_ctn: 0,
  total_unit_price: 0,
  total_amount: 0,
  total_net_weight_kg: 0,
  total_gross_weight_kg: 0,
}

export interface Props {
  itemView?: number
  unit_code: string
  lineItems: InvoiceLineItem_expanded[]
  groupItems?: InvoiceGroupItem_fields[]
  showSample?: boolean
  total_sample_ctn?: number
}

export default function getInvoiceSumValues(props: Props) {
  const { ...totalValues } = getTotalValues(props)
  return { ...totalValues }
}

function getTotalValues(props: Props) {
  const { lineItems, groupItems, unit_code, itemView, total_sample_ctn, showSample } = props

  let values: VALUES = { ...initialValues }
  let itemValues: VALUES = { ...initialValues }
  let unGroupValues: VALUES = { ...initialValues }
  let groupValues: VALUES = { ...initialValues }
  let total_product_samples_net_wet_kg = 0

  if (lineItems.length) {
    itemValues = lineItems
      .filter((item) => item.box > 0)
      .map((item) => {
        let net_weight_kg = showSample
          ? toDecimal(item.weight_mc * item.box + item.custom_kg.net_weight_kg)
          : toDecimal(item.weight_mc * item.box)

        let total_amount = unit_code === 'mc' ? toDecimal(item.box * item.price) : toDecimal(net_weight_kg * item.price)

        return {
          total_ctn: item.box,
          total_unit_price: toDecimal(item.price),
          total_amount,
          total_net_weight_kg: net_weight_kg,
          total_gross_weight_kg: toDecimal(item.gross_weight * item.box),
        }
      })
      .reduce((a, b) => ({
        total_ctn: a.total_ctn + b.total_ctn,
        total_unit_price: a.total_unit_price + b.total_unit_price,
        total_amount: a.total_amount + b.total_amount,
        total_net_weight_kg: a.total_net_weight_kg + b.total_net_weight_kg,
        total_gross_weight_kg: a.total_gross_weight_kg + b.total_gross_weight_kg,
      }))

    total_product_samples_net_wet_kg = lineItems.map((item) => item.custom_kg.net_weight_kg).reduce((a, b) => a + b)
  }

  if (lineItems.filter((item) => !item.group_fk?.length).length) {
    unGroupValues = lineItems
      .filter((item) => !item.group_fk?.length)
      .map((item) => {
        let net_weight_kg = toDecimal(item.weight_mc * item.box)

        let total_amount = unit_code === 'mc' ? toDecimal(item.box * item.price) : toDecimal(net_weight_kg * item.price)

        return {
          total_ctn: item.box,
          total_unit_price: toDecimal(item.price),
          total_amount,
          total_net_weight_kg: toDecimal(item.box * item.weight_mc),
          total_gross_weight_kg: toDecimal(item.gross_weight * item.box),
        }
      })
      .reduce((a, b) => ({
        total_ctn: a.total_ctn + b.total_ctn,
        total_unit_price: a.total_unit_price + b.total_unit_price,
        total_amount: a.total_amount + b.total_amount,
        total_net_weight_kg: a.total_net_weight_kg + b.total_net_weight_kg,
        total_gross_weight_kg: a.total_gross_weight_kg + b.total_gross_weight_kg,
      }))
  }

  if (groupItems?.length) {
    groupValues = groupItems
      .filter((item) => item.net_weight_kgs > 0)
      .map((item) => {
        return {
          total_ctn: item.box,
          total_unit_price: toDecimal(item.unit_price),
          total_amount: item.amount,
          total_net_weight_kg: item.net_weight_kgs,
          total_gross_weight_kg: item.gross_weight_kgs,
        }
      })
      .reduce((a, b) => ({
        total_ctn: a.total_ctn + b.total_ctn,
        total_unit_price: a.total_unit_price + b.total_unit_price,
        total_amount: a.total_amount + b.total_amount,
        total_net_weight_kg: a.total_net_weight_kg + b.total_net_weight_kg,
        total_gross_weight_kg: a.total_gross_weight_kg + b.total_gross_weight_kg,
      }))
  }

  if (itemView) {
    switch (itemView) {
      case 1:
        let newCtn = showSample ? itemValues.total_ctn + (total_sample_ctn || 0) : itemValues.total_ctn
        values = { ...itemValues, total_ctn: newCtn }
        break
      case 2:
        values = {
          total_ctn: unGroupValues.total_ctn + groupValues.total_ctn,
          total_unit_price: unGroupValues.total_unit_price + groupValues.total_unit_price,
          total_amount: unGroupValues.total_amount + groupValues.total_amount,
          total_net_weight_kg: unGroupValues.total_net_weight_kg + groupValues.total_net_weight_kg,
          total_gross_weight_kg: unGroupValues.total_gross_weight_kg + groupValues.total_gross_weight_kg,
        }
        break
      case 3:
        values = groupValues
        break
      default:
        values = itemValues
        break
    }
  }

  return { ...values, total_product_samples_net_wet_kg }
}
