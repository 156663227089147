import React, { FC } from 'react'
import { Create, CreateProps, required, SimpleForm, TextInput, useNotify, useRedirect } from 'react-admin'
import SectionTitle from '../../components/SectionTitle'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import useCreateUserLog from 'src/hooks/useCreateUserLog'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

const styles: Styles<Theme, any> = {
  field: { maxWidth: 544 },
}

const useStyles = makeStyles(styles)

const CategoryCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props)
  let { createLog } = useCreateUserLog()
  let redirect = useRedirect()
  let notify = useNotify()

  const handleSuccess = ({ data }: any) => {
    createLog('category', 'CREATE', `New category #${data.name}`)
    redirect('edit', '/category', data.id)
    notify('item.created', { type: 'info' })
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New category`} />
      <RouteBreadcrumbs />
      <Create {...props} onSuccess={handleSuccess}>
        <SimpleForm>
          <SectionTitle label="resources.global.fieldGroups.information" />
          <TextInput source="name" validate={required()} fullWidth className={classes.field} />
          <TextInput source="description" fullWidth className={classes.field} />
        </SimpleForm>
      </Create>
    </>
  )
}

export default CategoryCreate
