import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import { useDataProvider, useGetMany } from 'react-admin'
import { Invoice_expanded } from 'src/pages/invoices/types'
import getInvoiceSumValues from 'src/pages/invoices/utils/getInvoiceSumValues'
import { IDateRange, Payment_fields } from 'src/types/globalTypes'
import { formatDateToAPI } from 'src/utils/formatDate'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import moment from 'moment'
import Separate from 'src/components/Separate'
import InsightWidget from '../Tools/InsightWidget'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import InsightCard from '../Tools/InsightCard'
import { OrderLineItems_expanded, Order_expanded } from 'src/pages/orders/types'
import getOrderSumValues from 'src/pages/orders/utils/getOrderSumValues'
import { calculateInsightLineItemsByOrder } from '../../utils/calculateLineItemsByOrder copy'

interface Props {
  vendor: string
  dateRange?: IDateRange
}


interface State {
  topCustomers?: string[]
  topDestinations?: {
    id: string
    total: number
  }[]
  topProducts?: {
    product: string
    size?: string | null
    size_value?: string | null
    pack: string | null
    net_weight_kg: number
  }[]
  total_invoice_amount?: number
  total_invoice_qty?: number
  total_payment_amount?: number
}

export default function OverviewByPeriod(props: Props) {
  const { vendor, dateRange } = props
  const dataProvider = useDataProvider()
  const [state, setState] = useState<State>()

  const fetchTopCustomers = useCallback(async () => {
    let start_date = dateRange ? { 'date@_gte': formatDateToAPI(dateRange.startDate) } : {}
    let end_date = dateRange ? { 'date@_lte': formatDateToAPI(dateRange.endDate) } : {}
    let weekly: string[] = []

    let i = 1
    let days = moment.duration(moment(dateRange?.endDate).diff(moment(dateRange?.startDate))).asDays()

    while (i <= days) {
      weekly.push(moment(dateRange?.startDate).clone().add(i, 'day').startOf('week').format('YYYY-MM-DD'))
      i += 7
    }

    const { data: payments } = await dataProvider.getList<Payment_fields>('payment', {
      filter: { ...start_date, ...end_date },
      sort: { field: 'payment_date', order: 'DESC' },
      pagination: { page: 1, perPage: 1000 },
    })

    const customersCount = [
      ...payments
        .reduce((acc, curr) => {
          let key = curr.customer_fk_code

          let item = acc.get(key) || Object.assign({}, { code: key, total: 0 })
          item.total += 1

          return acc.set(key, item)
        }, new Map())
        .values(),
    ]

    const topCustomers = _.chain(customersCount)
      .orderBy(['total'], ['desc'])
      .map((item) => item.code)
      .slice(0, 10)
      .value()

    setState((prev: any) => ({ ...prev, topCustomers }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, dateRange])

  const fetchInvoices = useCallback(async () => {
    let start_date = dateRange ? { 'date@_gte': formatDateToAPI(dateRange.startDate) } : {}
    let end_date = dateRange ? { 'date@_lte': formatDateToAPI(dateRange.endDate) } : {}
    const { data } = await dataProvider.getList<Invoice_expanded>('invoice', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'date', order: 'DESC' },
      filter: {
        ...start_date,
        ...end_date,
        'invoice_header#order#status': 'shipped_out',
        'invoice_header#order#vendor_fk_code': vendor === 'All' ? undefined : vendor,
        type_no: 3,
      },
    })

    const aggregation = data.length
      ? data
          .map((invoice) => {
            const { total_amount, total_net_weight_kg } = getInvoiceSumValues({
              itemView: 1,
              lineItems: invoice.invoice_line_items,
              unit_code: invoice.unit_code?.code || 'kg',
            })

            return {
              total_amount,
              total_net_weight_kg,
            }
          })
          .reduce((a, b) => ({
            total_amount: a.total_amount + b.total_amount,
            total_net_weight_kg: a.total_net_weight_kg + b.total_net_weight_kg,
          }))
      : null

    setState((prev) => ({
      ...prev,
      total_invoice_amount: aggregation?.total_amount || 0,
      total_invoice_qty: aggregation?.total_net_weight_kg || 0,
    }))
  }, [dateRange, vendor, dataProvider])

  const fetchPayments = useCallback(async () => {
    let start_date = dateRange ? { 'payment_date@_gte': formatDateToAPI(dateRange.startDate) } : {}
    let end_date = dateRange ? { 'payment_date@_lte': formatDateToAPI(dateRange.endDate) } : {}
    const { data } = await dataProvider.getList<Payment_fields>('payment', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'payment_date', order: 'DESC' },
      filter: { ...start_date, ...end_date, type: 'deposit' },
    })

    const aggregation = data.length ? data.map((item) => item.amount_deposit).reduce((a, b) => a + b) : 0

    setState((prev) => ({
      ...prev,
      total_payment_amount: aggregation,
    }))
  }, [dateRange, dataProvider])

  const fetchTopProducts = useCallback(async () => {
    let start_date = dateRange ? { 'pi_date@_gte': formatDateToAPI(dateRange.startDate) } : {}
    let end_date = dateRange ? { 'pi_date@_lte': formatDateToAPI(dateRange.endDate) } : {}
    let vendor_filter = vendor ? { vendor_fk_code: vendor === 'All' ? '' : vendor } : {}

    const { data: orders } = await dataProvider.getList<Order_expanded>('order', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'ref_pi', order: 'DESC' },
      filter: { ...start_date, ...end_date, ...vendor_filter, status: 'shipped_out' },
    })

    let countDestinations = orders.length
      ? [
          ...orders
            .reduce((acc, curr) => {
              let key = curr.shipping_details.destination_port

              let item = acc.get(key) || Object.assign({}, { id: key, total: 0 })
              item.total += 1

              return acc.set(key, item)
            }, new Map())
            .values(),
        ]
      : []

    let sortItems: OrderLineItems_expanded[] = []

    Object.values(orders).forEach((order) => {
      let { shipping_cost } = getOrderSumValues({
        unit_code: order.unit_code?.code || 'kg',
        lineItems: order.order_line_items,
        revise: order.order_revises[0],
        exchange_rate: order.exchange_rate,
      })
      let etc_cost = order.order_revises.length ? order.order_revises[0].exchange_rate_info.etc_cost : 0
      let formatItems = calculateInsightLineItemsByOrder({
        data: order.order_line_items,
        shipping_cost,
        unit_code: order.unit_code?.code || 'kg',
        exchange_rate: order.exchange_rate,
        etc_cost,
      })

      sortItems.push(...(formatItems as any))
    })

    let findTopProducts = [
      ...sortItems
        .reduce((acc, curr) => {
          let key = `${curr.product?.name}${curr.size_value?.label}${curr.size?.label}`
          let item = acc.get(key) || Object.assign({}, curr, { net_weight_kgs: 0, amount: 0, orders: [] })
          item.net_weight_kgs += curr.net_weight_kgs
          item.amount += curr.amount
          item.orders.push(curr.order_id)
          return acc.set(key, item)
        }, new Map())
        .values(),
    ]

    const aggregation = _.orderBy(findTopProducts, ['net_weight_kgs'], ['desc'])
      .slice(0, 10)
      .map((item) => ({
        product: `${item.product.name}`,
        size: item.size?.label || null,
        size_value: item.size_value?.label || null,
        pack: item.pack.name,
        amount: toDecimal(item.amount),
        net_weight_kg: toDecimal(item.net_weight_kgs),
        profit: item.profit,
        orderIds: item.orders,
      }))

    setState((prev) => ({
      ...prev,
      topProducts: aggregation,
      topDestinations: _.orderBy(countDestinations, ['total'], ['desc']),
    }))
  }, [vendor, dateRange, dataProvider])

  useEffect(() => {
    fetchTopCustomers()
    fetchInvoices()
    fetchPayments()
    fetchTopProducts()
  }, [fetchTopCustomers, fetchTopProducts, fetchInvoices, fetchPayments])

  return (
    <div>
      <Typography variant="h6">{dateRange?.label}</Typography>
      <Separate value={2} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InsightWidget
            color="#b3b3b3"
            title="Receviables (USD)"
            value={`${toDecimalStr(state?.total_invoice_amount || 0)}`}
          />

          <Separate value={2} />
          <InsightWidget
            color="#b3b3b3"
            title="Infow (USD)"
            value={`${toDecimalStr(state?.total_payment_amount || 0)}`}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InsightWidget
            color="#b3b3b3"
            title="Quantity (MT)"
            value={`${toDecimalStr(state?.total_invoice_qty || 0)}`}
          />
          <Separate value={2} />
          <InsightWidget
            color="#b3b3b3"
            title="Paid to invoice"
            value={`${toDecimalStr(
              state?.total_invoice_amount ? ((state.total_payment_amount || 0) / state.total_invoice_amount) * 100 : 0
            )} %`}
          />
        </Grid>

        <Grid item xs={12}>
          <InsightCard title="Top 10 destinations">
            <TopDestinationTable data={state?.topDestinations || []} />
          </InsightCard>
        </Grid>

        <Grid item xs={12}>
          <InsightCard title="Top 10 products">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Pack</TableCell>
                  <TableCell align="right">NET/WET (KG)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.topProducts?.length
                  ? state.topProducts.map((item, index) => (
                      <TableRow key={index} hover>
                        <TableCell>
                          {item.product}
                          {item.size ? ` - ${item.size_value} ${item.size}` : ''}
                        </TableCell>
                        <TableCell>{item.pack}</TableCell>
                        <TableCell align="right">{toDecimalStr(item.net_weight_kg)}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </InsightCard>
        </Grid>

        {/* <Grid item xs={12} md={12}>
          <InsightCard title="Top customers">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Customer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.topCustomers?.length
                  ? state.topCustomers.map((customer, index) => (
                      <TableRow hover key={index}>
                        <TableCell>{customer}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </InsightCard>
        </Grid> */}
      </Grid>
    </div>
  )
}

interface DestinationProps {
  data: {
    id: string
    total: number
  }[]
}

function TopDestinationTable(props: DestinationProps) {
  const { data } = props
  const [total, setTotal] = useState(0)
  const [state, setState] = useState<{ name: string; country: string; total: number }[]>([])
  let ids = data.map((item) => item.id)

  const { data: customerPorts, loading, loaded } = useGetMany('customer_port', ids)

  useEffect(() => {
    if (loaded) {
      let dest_arr = customerPorts.map((item, index) => ({
        id: item?.id,
        destination_id: item?.destination_port?.id,
        country: item?.destination_port?.country?.label,
        name: item?.destination_port?.name,
        total: data[index].total,
      }))

      let total_dest = data.length ? data.map((item) => item.total).reduce((a, b) => a + b) : 0

      let countPorts = dest_arr.length
        ? [
            ...dest_arr
              .reduce((acc, curr) => {
                let key = curr.destination_id
                let item = acc.get(key) || Object.assign({}, { name: curr.name, country: curr.country, total: 0 })

                item.total += curr.total

                return acc.set(key, item)
              }, new Map())
              .values(),
          ]
        : []

      setTotal(total_dest)
      setState(_.orderBy(countPorts, ['total'], ['desc']))
    }
  }, [customerPorts, loaded, data])

  if (loading) return <div>Loading...</div>

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Country</TableCell>
          <TableCell align="center">Total</TableCell>
          <TableCell align="center">%</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loaded && state.length
          ? state.slice(0, 10).map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.country}</TableCell>
                <TableCell align="center">{item.total}</TableCell>
                <TableCell align="center">{toDecimal((item.total / total) * 100)}%</TableCell>
              </TableRow>
            ))
          : null}
      </TableBody>
    </Table>
  )
}
