import React, { FC } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import InsightCard from '../Tools/InsightCard'
import moment from 'moment'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import { InsightSalesAndProfitState } from '../../types'
import { Order_expanded } from 'src/pages/orders/types'
import getOrderSumValues from 'src/pages/orders/utils/getOrderSumValues'
import { ordersByPeriod } from '../../utils/ordersByPeriod'
import { makeStyles } from '@material-ui/core'
import { formatArrayNames } from '../../utils/formatArrayNames'

const useStyles = makeStyles(() => ({
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
}))

interface Props {
  stats: InsightSalesAndProfitState
  colors: any
}

const StatsTable: FC<Props> = ({ stats, colors }) => {
  const classes = useStyles()
  return (
    <>
      <InsightCard title="Stats">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Revenue realised (USD)</TableCell>
              <TableCell>Revenue un-realised (USD)</TableCell>
              <TableCell>Gross profit (USD)</TableCell>
              <TableCell>Gross profit margin (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stats.fetchOrders?.length ? (
              stats.fetchOrders.map((stat, index) => {
                const dataColor = colors[index].join(',')
                const realised = groupAmountByWeek({
                  orders: stat.shippedout_orders || [],
                  weekly: stats.weekly || [],
                  period: 'week',
                  split: stat.split,
                })

                let unrealised = groupAmountByWeek({
                  orders: stat.pending_orders || [],
                  weekly: stats.weekly || [],
                  period: 'week',
                  split: stat.split,
                })

                return realised.length
                  ? realised.map((item: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Box display="flex">
                            <span className={classes.color} style={{ backgroundColor: `rgba(${dataColor})` }} />
                            <Tooltip title={`${stat.customers.join(', ')}`}>
                              <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                                {stat.vendor}
                                {formatArrayNames(stat.customers || [])}
                              </Typography>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell>{toDecimalStr(item.revenue)}</TableCell>
                        <TableCell>{toDecimalStr(unrealised.length ? unrealised[index]?.revenue || 0 : 0)}</TableCell>
                        <TableCell>{toDecimalStr(item.gross_profit)}</TableCell>
                        <TableCell>{toDecimalStr(item.gross_profit_margin)}</TableCell>
                      </TableRow>
                    ))
                  : null
              })
            ) : (
              <TableRow style={{ height: 50 * 8 }}>
                <TableCell colSpan={5} align="center">
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </InsightCard>
    </>
  )
}

export default StatsTable

interface GroupAmount {
  orders: Order_expanded[]
  weekly: string[]
  period: moment.unitOfTime.StartOf
  split?: boolean
}

const groupAmountByWeek = (props: GroupAmount) => {
  const { orders, weekly, period, split } = props
  let groupOrdersByWeek = ordersByPeriod(orders, weekly, period, split)

  const result: any = []

  Object.values(groupOrdersByWeek).forEach((item) => {
    const amountByGroup: any = { title: '', revenue: 0, gross_profit: 0, gross_profit_margin: 0 }
    Object.keys(item.orders).forEach((date) => {
      let amountOrders = item.orders[date].map((order) => {
        let { total_gross_profit, total_cost_usd, total_amount } = getOrderSumValues({
          unit_code: order.unit_code?.code || 'kg',
          lineItems: order.order_line_items,
          revise: order.order_revises[0],
          exchange_rate: order.exchange_rate,
        })
        return {
          total_amount,
          total_gross_profit,
          total_gross_profit_margin: toDecimal((total_gross_profit / total_cost_usd) * 100),
        }
      })

      let revenues = amountOrders.length
        ? toDecimal(amountOrders.map((item) => item.total_amount).reduce((a, b) => a + b))
        : 0

      let gross_profits = amountOrders.length
        ? toDecimal(amountOrders.map((item) => item.total_gross_profit).reduce((a, b) => a + b))
        : 0

      let gross_profit_margins = amountOrders.length
        ? toDecimal(amountOrders.map((item) => item.total_gross_profit_margin).reduce((a, b) => a + b))
        : 0

      amountByGroup.revenue += revenues || 0
      amountByGroup.gross_profit += gross_profits || 0
      amountByGroup.gross_profit_margin += gross_profit_margins || 0
    })

    result.push({
      title: item.title,
      revenue: toDecimal(amountByGroup.revenue),
      gross_profit: toDecimal(amountByGroup.gross_profit),
      gross_profit_margin: toDecimal(amountByGroup.gross_profit_margin),
    })
  })

  return result
}
