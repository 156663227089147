import InvoicePrefixList from './InvoicePrefixList'
import InvoicePrefixCreate from './InvoicePrefixCreate'
import InvoicePrefixEdit from './InvoicePrefixEdit'

const invoicePrefix = {
  list: InvoicePrefixList,
  create: InvoicePrefixCreate,
  edit: InvoicePrefixEdit,
}

export default invoicePrefix
