import React, { FC } from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  CreateProps,
  required,
  ToolbarProps,
  Toolbar,
  useRedirect,
  useNotify,
} from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import Separate from 'src/components/Separate'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'

const styles: Styles<Theme, any> = {
  leftField: {
    width: '300px',
    display: 'inline-block',
    marginRight: `16px`,
  },
  rightField: {
    width: '300px',
    display: 'inline-block',
  },
  description: {
    width: '616px',
  },
}

const useStyles = makeStyles(styles)

const CustomToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveWithLogButton action_type="CREATE" source="name" />
  </Toolbar>
)

const ProductCreate: FC<CreateProps> = (props) => {
  const { permission, loaded } = usePermissionByUser('product')
  const redirect = useRedirect()
  const notify = useNotify()
  const classes = useStyles(props)

  if (loaded && !permission.create) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New product`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <SectionTitle label="resources.global.fieldGroups.information" />
          <TextInput
            fullWidth
            source="name"
            formClassName={classes.leftField}
            helperText={false}
            validate={required()}
          />
          <TextInput fullWidth source="short_name" formClassName={classes.rightField} helperText={false} />
          <Separate value={2} />
          <TextInput
            helperText={false}
            fullWidth
            source="full_name"
            label="English name"
            formClassName={classes.leftField}
          />
          <TextInput
            helperText={false}
            fullWidth
            source="other_names.scientific_name1"
            label="Scientific name 1"
            formClassName={classes.rightField}
          />

          <Separate value={2} />
          <TextInput
            helperText={false}
            fullWidth
            source="other_names.scientific_name2"
            label="Scientific name 2(MEX)"
            formClassName={classes.leftField}
          />
          <TextInput
            helperText={false}
            fullWidth
            source="other_names.scientific_name3"
            label="Scientific name 3(USA)"
            formClassName={classes.rightField}
          />
          <Separate value={2} />
          <TextInput fullWidth source="description" formClassName={classes.description} />
        </SimpleForm>
      </Create>
    </>
  )
}

export default ProductCreate
