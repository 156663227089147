import MuiCheckbox from '@material-ui/core/Checkbox'
import { useInput, InputProps } from 'react-admin'

const Checkbox = (props: InputProps) => {
  const {
    input: { name, checked, onChange, ...restInput },
    ...rest
  } = useInput(props)

  return (
    <MuiCheckbox
      {...rest}
      name={props.name}
      size="small"
      checked={Boolean(checked)}
      onChange={onChange}
      inputProps={restInput}
    />
  )
}

export default Checkbox
