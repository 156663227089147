import React, { FC } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { FieldProps, Link, useQueryWithStore } from 'react-admin'
import { AppState, Customer_fields, Order_fields } from 'src/types/globalTypes'
import { toDecimalStr } from 'src/utils/toDecimal'

const CustomerRecentOrder_fieldss: FC<FieldProps<Customer_fields>> = ({ record }) => {
  const { loading, data } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'order',
    payload: {
      filter: { customer_fk_code: record && record.code },
      pagination: { page: 1, perPage: 5 },
      sort: { field: 'pi_date', order: 'DESC' },
    },
  })

  const result: Order_fields[] = data

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>No. of Order</TableCell>
          <TableCell>Date</TableCell>
          <TableCell align="right">Total net weight</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading || !data ? (
          <TableRow>
            <TableCell colSpan={3}>Loading...</TableCell>
          </TableRow>
        ) : result.length ? (
          result.map((item, index) => (
            <TableRow key={index} hover>
              <TableCell>
                <Link to={`/order/${item.id}`}>{item.ref_pi}</Link>
              </TableCell>
              <TableCell>{item.pi_date}</TableCell>
              <TableCell align="right">{toDecimalStr(item.total_net_weight)}</TableCell>
            </TableRow>
          ))
        ) : null}
      </TableBody>
    </Table>
  )
}

export default CustomerRecentOrder_fieldss
