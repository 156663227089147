import { InvoiceLineItem_expanded } from 'src/pages/invoices/types'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'

interface Props {
  mark_nos: string | null
  data: InvoiceLineItem_expanded[]
}

export function invoice_lineItems3(props: Props) {
  let formatted_lineItmes = makeData(props)
  return formatted_lineItmes
}

function makeData(props: Props) {
  const { mark_nos, data } = props
  let formatted_data = data.map((item, index) => {
    let net_weight_kg = toDecimal(item.box * item.weight_mc)
    let product = item.product?.name
    let size = item.size_value ? ` - ${item.size_value.label} ${item.size?.label}` : ''

    let result =
      index === 0
        ? [
            {
              style: 'tcCell',
              text: `${mark_nos || ''}`,
            },
            {
              style: 'tlCell',
              text: `${product || ''}${size}`,
            },
            {
              style: 'tcCell',
              text: `${toDecimalStr(item.box, true)}`,
            },
            {
              style: 'textRight',
              text: `${toDecimalStr(net_weight_kg)}`,
            },

            {
              style: 'textRight',
              text: `${toDecimalStr(net_weight_kg + item.box)}`,
            },

            {
              style: 'textRight',
              text: `${toDecimalStr(item.price)}`,
            },
            {
              style: 'textRight',
              text: `${toDecimalStr(item.total_price)}`,
            },
          ]
        : [
            {
              style: 'tcCell',
              text: ``,
            },
            {
              style: 'tlCell',
              text: `${product}${size}`,
            },
            {
              style: 'tcCell',
              text: `${toDecimalStr(item.box, true)}`,
            },
            {
              style: 'textRight',
              text: `${toDecimalStr(net_weight_kg)}`,
            },

            {
              style: 'textRight',
              text: `${toDecimalStr(net_weight_kg + item.box)}`,
            },

            {
              style: 'textRight',
              text: `${toDecimalStr(item.price)}`,
            },
            {
              style: 'textRight',
              text: `${toDecimalStr(item.total_price)}`,
            },
          ]

    return result
  })

  return formatted_data
}
