import { toDecimal } from 'src/utils/toDecimal'

interface Props {
  raw_cost?: number
  import_cost: number
  pack_cost_baht: number
  etc_cost?: number
  usd_export?: number
  shipping_cost: number
}

export default function getOrderLineUnitCode(props: Props) {
  let result = 0
  const { raw_cost, import_cost, etc_cost, pack_cost_baht, usd_export, shipping_cost } = props

  result = toDecimal(
    ((raw_cost || 0) + (import_cost || 0) + (etc_cost || 0) + (pack_cost_baht || 0) + toDecimal(shipping_cost || 0)) /
      (usd_export || 0)
  )

  return result
}
