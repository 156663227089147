import React, { useEffect, useState } from 'react'
import ShippingIcon from '@material-ui/icons/LocalShipping'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import InsightWidget from '../Tools/InsightWidget'
import OperationDetailsDialog from './OperationDetailsDialog'
import { Order_expanded } from 'src/pages/orders/types'
import { formatArrayNames } from '../../utils/formatArrayNames'

interface OrderStats {
  title: string
  shipped_out: Order_expanded[]
  order_completed: Order_expanded[]
  order_received: Order_expanded[]
  processing_order: Order_expanded[]
  cancelled: Order_expanded[]
  annulled: Order_expanded[]
}

interface Props {
  value: number
  index: number
  vendors: string[]
  customers: string[]
  data?: OrderStats
}

export default function OperationTabPanel(props: Props) {
  const { value, vendors, customers, index, data } = props
  const [status, setStatus] = useState('')
  const [orders, setOrders] = useState<Order_expanded[]>([])
  const [state, setState] = useState<any>({
    shipped_out: false,
    order_received: false,
    order_completed: false,
    processing_order: false,
    cancelled: false,
    annulled: false,
  })

  useEffect(() => {
    let result: any = []
    if (state.shipped_out === true) {
      result = data?.shipped_out
    } else if (state.order_received === true) {
      result = data?.order_received
    } else if (state.order_completed === true) {
      result = data?.order_completed
    } else if (state.processing_order) {
      result = data?.processing_order
    } else if (state.cancelled) {
      result = data?.cancelled
    } else if (state.annulled === true) {
      result = data?.annulled
    } else {
      result = []
    }

    setOrders(result)
  }, [state, data])

  const handleClick = (name: keyof OrderStats, value: boolean) => {
    setState({ ...state, [name]: value })
    setStatus(name)
  }

  const handleClose = () => {
    setState({
      shipped_out: false,
      order_received: false,
      order_completed: false,
      processing_order: false,
      cancelled: false,
      annulled: false,
    })
  }

  if (!data) return null

  return (
    <>
      <TabPanel value={value} index={index}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <InsightWidget
              pointer
              color="#b3b3b3"
              icon={ShippingIcon}
              title="Shipped out"
              value={`${data.shipped_out?.length || 0}`}
              onClick={() => handleClick('shipped_out', true)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InsightWidget
              pointer
              color="#b3b3b3"
              icon={ShippingIcon}
              title="Complete"
              value={`${data.order_completed?.length || 0}`}
              onClick={() => handleClick('order_completed', true)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InsightWidget
              pointer
              color="#b3b3b3"
              icon={ShippingIcon}
              title="Received"
              value={`${data.order_received?.length || 0}`}
              onClick={() => handleClick('order_received', true)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InsightWidget
              pointer
              color="#b3b3b3"
              icon={ShippingIcon}
              title="In process"
              value={`${data.processing_order?.length || 0}`}
              onClick={() => handleClick('processing_order', true)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InsightWidget
              pointer
              color="#b3b3b3"
              icon={ShippingIcon}
              title="Cancelled"
              value={`${data.cancelled?.length || 0}`}
              onClick={() => handleClick('cancelled', true)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InsightWidget
              pointer
              color="#b3b3b3"
              icon={ShippingIcon}
              title="Annulled"
              value={`${data.annulled?.length || 0}`}
              onClick={() => handleClick('annulled', true)}
            />
          </Grid>
        </Grid>
      </TabPanel>
      {(state.shipped_out ||
        state.order_received ||
        state.processing_order ||
        state.order_completed ||
        state.cancelled ||
        state.annulled) && (
        <OperationDetailsDialog
          open={
            state.shipped_out ||
            state.order_received ||
            state.processing_order ||
            state.order_completed ||
            state.cancelled ||
            state.annulled
          }
          title={`${status.toUpperCase()}: ${formatArrayNames(vendors)} - ${formatArrayNames(customers)}`}
          orders={orders}
          onClose={handleClose}
        />
      )}
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
