import React from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

interface Props {
  loading?: boolean
  value: number
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

export default function InvoiceStepper(props: Props) {
  const { value, loading, onChange } = props
  return (
    <Paper square>
      <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={onChange} aria-label="Invocie stepper">
        <Tab disabled={loading} label="Items" />
        <Tab disabled={loading} label="Group" />
        <Tab disabled={loading} label="Product Samples" />
      </Tabs>
    </Paper>
  )
}
