import React, { FC, useEffect, useState } from 'react'
import { AutocompleteInput, AutocompleteInputProps } from 'react-admin'
import useVendors from 'src/hooks/useVendors'
import { Vendor_fields } from 'src/types/globalTypes'

interface Choice {
  id: string
  name: string
}

const VendorReferenceInput: FC<AutocompleteInputProps> = (props) => {
  const [options, setOptions] = useState<Choice[]>([])
  const { data } = useVendors()

  useEffect(() => {
    if (data) {
      let result = data.map((item: Vendor_fields) => ({
        id: item.code,
        name: item.code,
      }))

      setOptions(result)
    }
  }, [data])

  return <AutocompleteInput choices={options} {...props} />
}
export default VendorReferenceInput
