import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ExportIcon from '@material-ui/icons/GetApp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { CloneButton, FormWithRedirect, Identifier, Toolbar, useGetOne } from 'react-admin'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import OrderInformationDetailTab from './OrderInformationDetailTab'
import OrderLineItemsDetailTab from './OrderLineItemsDetailTab'
import OrderSettings from './OrderSettingsTab'
import Separate from 'src/components/Separate'
import exportPDF from '../utils/exportPDF'
import { OrderLineItems_expanded } from '../types'
import { reviseInitialValues } from '../constants/order'
import { OrderProductSample_fields, OrderRevise_fields } from 'src/types/globalTypes'
import { CustomerPort_expanded } from 'src/pages/customers/types'
// import useVendorsByUser from 'src/hooks/useVendorsByUser'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import DeleteWithLogButton from 'src/components/DeleteWithLogButton'
import { CSVLink } from 'react-csv'
import getOrderLineAmount from '../utils/getOrdeLineAmount'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'

const OrderDetailPage = (props: any) => {
  const { permission, record } = props
  const { permission: permissionExpenses } = usePermissionByUser('exchange_rate_expenses')
  const [value, setValue] = React.useState(0)
  const [customerPortId, setCustomerPortId] = useState<Identifier>('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [lineItems, setLineItems] = useState<OrderLineItems_expanded[]>([])
  const [productSamples, setProductSamples] = useState<OrderProductSample_fields[]>([])
  const [csvData, setCsvData] = useState<any[]>([])
  // const redirect = useRedirect()
  // const { data: vendorsByUser, isSuperuser, loading } = useVendorsByUser()

  const [revise, setRevise] = useState<OrderRevise_fields>({
    ...reviseInitialValues,
    id: '',
    order_id: '',
    expense_id: null,
  })

  let { data: customerPort } = useGetOne<CustomerPort_expanded>('customer_port', customerPortId)

  // useEffect(() => {
  //   if (!loading || !isSuperuser) {
  //     if (vendorsByUser.indexOf(record.vendor_fk_code) === -1) {
  //       redirect('/order')
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loading, isSuperuser, vendorsByUser, record.vendor_fk_code])

  useEffect(() => {
    let items: OrderLineItems_expanded[] = []
    let samples: OrderProductSample_fields[] = []

    if (record.order_line_items) {
      items = record.order_line_items.filter(
        (item: OrderLineItems_expanded) => item.revise_key === record.current_revise_num
      )
    }

    if (record.order_product_samples?.length) {
      samples = _.orderBy(record.order_product_samples, ['product'], ['asc'])
    }

    setLineItems(_.orderBy(items, ['product.name', 'size_value.label', 'size.label'], ['asc', 'asc', 'asc']))
    setProductSamples(samples)
  }, [record])

  useEffect(() => {
    let dest = ''
    let result: OrderRevise_fields = { ...reviseInitialValues, id: '', order_id: '', expense_id: null }
    if (record.shipping_details?.destination_port) {
      dest = record.shipping_details.destination_port
    }

    if (record.order_revises) {
      result = record.order_revises.filter((item: any) => item.revise_key === record.current_revise_num)[0]
    }

    setCustomerPortId(dest)
    setRevise(result)
  }, [record])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleExportPDF = (template: number) => {
    exportPDF({
      template,
      data: record,
      lineItems,
      customerPort: customerPort?.destination_port.name,
      revise,
      productSamples,
      hasAccess: permissionExpenses.read,
    })
    setAnchorEl(null)
  }

  function handleExportCsv() {
    if (record.order_line_items?.length) {
      let formattedCsv = record.order_line_items.map((item: any, index: number) => {
        let total = getOrderLineAmount({
          unit_code: record.unit_code?.code || 'kg',
          weight_mc: item.weight_mc,
          box: item.box,
          unit_price: item.unit_price,
        })

        return {
          no: index + 1,
          product: `${item.product?.name}${item.size_value ? ` - ${item.size_value.label}` : ''} ${
            item.size ? item.size.label : ''
          }`,
          pack: `${item.pack?.name || ''}`,
          weight_mc: item.weight_mc,
          gross_weight: item.gross_weight,
          total_ctn: item.box,
          net_weight_kg: item.weight_mc * item.box,
          gross_weight_kg: item.weight_mc * item.box + item.gross_weight,
          total_rw_price: item.raw_cost,
          finished_goods: item.import_cost,
          pack_cost: item.pack_cost_baht,
          unit_price: item.unit_price,
          total_amount: total,
          remark: `${item.remark}`,
        }
      })
      setCsvData(formattedCsv)
    }

    handleExportClose()
    // setCsvData(formattedCsv)
  }

  const handleExportClose = () => {
    setAnchorEl(null)
  }

  return (
    <FormWithRedirect
      {...props}
      initialValues={{ select_expense: null }}
      redirect={props.redirect}
      render={(formProps: any) => (
        <>
          <Helmet title={`${WEB_APP_TITLE} - Order #${props.record.ref_pi}`} />
          <Box display="flex" justifyContent="flex-end">
            <CloneButton variant="outlined" record={props.record} disabled={!permission.update} />
            <Button
              color="primary"
              style={{ marginLeft: '10px' }}
              size="small"
              variant="outlined"
              aria-controls="export-menu"
              aria-haspopup="true"
              onClick={handleExportClick}
              startIcon={<ExportIcon />}
              endIcon={<ArrowDropDownIcon />}
            >
              Export
            </Button>
            <Menu id="export-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleExportClose}>
              <MenuItem onClick={handleExportCsv}>
                <CSVLink
                  filename={`order-${record.ref_pi}`}
                  data={csvData}
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  Export as csv
                </CSVLink>
              </MenuItem>
              <MenuItem onClick={() => handleExportPDF(2)}>Export as pdf (Expenses)</MenuItem>
              <MenuItem onClick={() => handleExportPDF(3)}>Export as pdf (Loading)</MenuItem>
            </Menu>
          </Box>

          <Separate value={2} />
          <Paper>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
            >
              <Tab label="Information" disabled={record ? false : true} />
              <Tab label="Line Items" disabled={record ? false : true} />
              <Tab label="Settings" disabled={record ? false : true} />
            </Tabs>
            <Divider />
            {value === 0 && <OrderInformationDetailTab disabled={!permission.update} {...props} />}
            {value === 1 && (
              <OrderLineItemsDetailTab
                disabled={!permission.update}
                loading={record.order_line_items ? false : true}
                record={record}
                lineItems={lineItems}
                productSamples={productSamples}
                revise={revise}
                unit_code={record.unit_code?.code || ''}
                current_revise_num={record.current_revise_num}
                currency_code={record.currency?.code || ''}
                exchange_rate={record.exchange_rate}
                setlineItems={setLineItems}
                setProductSamples={setProductSamples}
                setRevise={setRevise}
              />
            )}
            {value === 2 && <OrderSettings disabled={!permission.update} edit={true} date={record.pi_date} />}
            <Toolbar>
              <Box display="flex" width="100%" justifyContent="space-between">
                <SaveWithLogButton
                  resource="order"
                  source="ref_pi"
                  action_type="UPDATE"
                  saving={formProps.saving}
                  disabled={formProps.pristine || !permission.update}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  refresh
                />

                <DeleteWithLogButton
                  resource="order"
                  source="ref_pi"
                  confirmTitle={`Delete order #${formProps.record.ref_pi}`}
                  record={formProps.record}
                  mutationMode="pessimistic"
                  disabled={!permission.delete}
                />
              </Box>
            </Toolbar>
          </Paper>
        </>
      )}
    />
  )
}

export default OrderDetailPage
