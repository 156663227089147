import React, { useCallback, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import { Chart, registerables } from 'chart.js'
import { makeStyles } from '@material-ui/core'
import { toDecimal } from 'src/utils/toDecimal'

Chart.register(...registerables)

const useStyles = makeStyles(() => ({
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
}))

interface Props {
  stats: {
    name: string
    counter: number
  }[]
  colors: any
  totalOrders: number
}

export default function DestinationPie(props: Props) {
  const classes = useStyles()
  const { stats, colors, totalOrders } = props
  const chartInstance = useRef<any>(null)
  const downloadChart = useRef<any>(null)

  const loadChart = useCallback(() => {
    if (!downloadChart?.current) return
    if (!chartInstance.current) {
      const ctx = downloadChart?.current.getContext('2d')
      chartInstance.current = new Chart(ctx, { type: 'pie', data: { labels: [''], datasets: [] } })
    }

    let labels = stats.map((item) => item.name)
    let data = stats.map((item) => toDecimal((item.counter / totalOrders) * 100))

    let bgColors = stats.length
      ? stats.map((_, i) => {
          let dataColor = colors[i].join(',')

          return `rgba(${dataColor})`
        })
      : []

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Dataset',
          data,
          backgroundColor: [...bgColors],
          hoverOffset: 4,
        },
      ],
    }

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: true,

      plugins: {
        legend: {
          display: false,
        },
      },
    }

    chartInstance.current.data = chartData
    chartInstance.current.options = chartOptions
    chartInstance.current.update()
  }, [stats, totalOrders, colors])

  useEffect(() => {
    loadChart()
  }, [loadChart])

  return (
    <Box display={{ xs: 'block', md: 'flex' }}>
      <Box>
        <canvas ref={downloadChart} id="destination-port" />
      </Box>
      <Box ml={3} justifyContent="center" alignItems="center">
        {stats.map((item, i) => {
          let dataColor = colors[i].join(',')
          return (
            <Box display="flex">
              <span className={classes.color} style={{ backgroundColor: `rgba(${dataColor})` }} />
              {item.name}: {toDecimal((item.counter / totalOrders) * 100)}%
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
