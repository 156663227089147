import { InvoicePDFSettingsProps } from 'src/pages/invoices/types'
import { formatProductionDate } from 'src/utils/formatDate'
import { toDecimalStr } from 'src/utils/toDecimal'
// import { InvoicePDFSettings } from '../../types'

interface Props {
  production_of?: string | null
  settings: InvoicePDFSettingsProps
  start_date?: string | Date
  end_date?: string | Date
  total_package_cartons: number
  total_net_weight: number
  total_gross_weight: number
}

export function invoice_production(props: Props) {
  const { total_gross_weight, total_net_weight, total_package_cartons, start_date, end_date, production_of, settings } =
    props

  let total_package_cartons_format = new Intl.NumberFormat().format(total_package_cartons)

  let hasExpiryDate =
    settings.showProduction === true && start_date && end_date !== undefined
      ? [
          {
            style: 'production_date',
            text: `PRODUCTION DATE:   ${formatProductionDate(start_date).toUpperCase()}`,
          },
          {
            style: 'production',
            text: `EXPIRY DATE:   ${formatProductionDate(end_date).toUpperCase()}`,
          },
        ]
      : []

  return [
    {
      style: ['production', 'mt1'],
      text: `PRODUCTS OF ${production_of?.toUpperCase() || ''}`,
    },
    {
      style: 'production',
      text: `TOTAL NUMBER OF PACKAGE:   ${total_package_cartons_format} CARTONS.`,
    },
    {
      style: 'production',
      text: `TOTAL NET WEIGHT:   ${toDecimalStr(total_net_weight)} KG.`,
    },
    {
      style: 'production',
      text: `TOTAL GROSS WEIGHT:   ${toDecimalStr(total_gross_weight)} KG.`,
    },
    ...hasExpiryDate,
  ]
}
