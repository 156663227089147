import React, { FC, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'react-admin'
import { Order_fields } from 'src/types/globalTypes'

const useStyles = makeStyles(() => ({
  root: {
    width: `190px`,
  },
}))

type Props = FieldProps<Order_fields>

const OrderRefPIField: FC<Props> = ({ record }) => {
  const classes = useStyles()
  return <div className={classes.root}>{record?.ref_pi}</div>
}

OrderRefPIField.defaultProps = {
  source: 'ref_pi',
  label: 'Ref PI',
}

export default memo<Props>(OrderRefPIField)
