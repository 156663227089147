import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { makeStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from 'src/components/Checkbox'
import { useForm, useFormState } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
  header: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 12,
    cursor: 'pointer',
    color: '#504e4e',
  },
}))

function createData(name: string, permissions: Array<any>) {
  return {
    name,
    permissions,
  }
}

export default function PermissionDataTable() {
  const classes = useStyles()
  const { values } = useFormState()
  const { change } = useForm()
  const { permissions } = values

  const handleSelectOnCell = (column: string) => {
    const newValues = values.permissions.map((item: any) => ({
      ...item,
      [column]: !item[column],
    }))
    change('permissions', newValues)
  }

  const handleSelectOnRow = (id: number) => {
    let name = `permissions.${id}`
    change(`${name}.create`, !values.permissions[id].create)
    change(`${name}.read`, !values.permissions[id].read)
    change(`${name}.update`, !values.permissions[id].update)
    change(`${name}.delete`, !values.permissions[id].delete)
  }

  const rows = [
    createData('General pages', permissions?.length ? permissions.filter((item: any) => item.tab === 1) : []),
    createData('Configuration pages', permissions?.length ? permissions.filter((item: any) => item.tab === 2) : []),
    createData('Admin pages', permissions?.length ? permissions.filter((item: any) => item.tab === 3) : []),
    createData('Order item tabs', permissions?.length ? permissions.filter((item: any) => item.tab === 4) : []),
  ]

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell width="260">Page</TableCell>
          <TableCell className={classes.header}>
            <Tooltip title="Select all on this column">
              <div onClick={() => handleSelectOnCell('create')}>Create</div>
            </Tooltip>
          </TableCell>
          <TableCell className={classes.header}>
            <Tooltip title="Select all on this column">
              <div onClick={() => handleSelectOnCell('read')}>Read</div>
            </Tooltip>
          </TableCell>
          <TableCell className={classes.header}>
            <Tooltip title="Select all on this column">
              <div onClick={() => handleSelectOnCell('update')}>Update</div>
            </Tooltip>
          </TableCell>
          <TableCell className={classes.header}>
            <Tooltip title="Select all on this column">
              <div onClick={() => handleSelectOnCell('delete')}>Delete</div>
            </Tooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <Row key={index} row={row} handleSelectOnRow={handleSelectOnRow} />
        ))}
      </TableBody>
    </Table>
  )
}

function Row(props: { row: ReturnType<typeof createData>; handleSelectOnRow: (id: number) => void }) {
  const { row, handleSelectOnRow } = props
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
        <TableCell colSpan={5}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row.name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {row.permissions.map((permission, index) => (
                  <TableRow key={index}>
                    <TableCell width="260" component="th" scope="row">
                      <Box marginLeft={3}>
                        <Tooltip title="Select all on this row">
                          <div
                            style={{ cursor: 'pointer', color: '#7e7a7a', textTransform: 'uppercase', fontSize: 12 }}
                            onClick={() => handleSelectOnRow(permission.keyId)}
                          >
                            {permission.name}
                          </div>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Checkbox type="checkbox" source={`permissions.${permission.keyId}.create`} />
                    </TableCell>
                    <TableCell>
                      <Checkbox type="checkbox" source={`permissions.${permission.keyId}.read`} />
                    </TableCell>
                    <TableCell>
                      <Checkbox type="checkbox" source={`permissions.${permission.keyId}.update`} />
                    </TableCell>
                    <TableCell>
                      <Checkbox type="checkbox" source={`permissions.${permission.keyId}.delete`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
