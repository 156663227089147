import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Delete'
import { FormWithRedirect, required, TextInput, Toolbar } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import { FieldArray } from 'react-final-form-arrays'

const BankChoiceDetailForm = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      redirect={props.redirect}
      render={(formProps: any) => (
        <form>
          <Box p="1rem">
            <SectionTitle label="resources.global.fieldGroups.information" />
            <Box width="500px">
              <TextInput fullWidth source="label" validate={required()} />

              <SectionTitle label="resources.bank_choice.fieldGroups.detail" />

              <FieldArray name="values">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <Box display="flex" key={index}>
                        <Box mr="1rem">
                          <TextInput label="" source={`${name}.key`} variant="outlined" placeholder="Key" />
                        </Box>
                        <Box width="400px">
                          <TextInput
                            fullWidth
                            label=""
                            source={`${name}.value`}
                            variant="outlined"
                            placeholder="Value"
                          />
                        </Box>

                        <Box pt={1} pl={1}>
                          <IconButton size="small" color="secondary" onClick={() => fields.remove(index)}>
                            <RemoveIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </>
                )}
              </FieldArray>
              <Button variant="outlined" size="small" onClick={() => formProps.form.mutators.push('values', undefined)}>
                + Add
              </Button>
            </Box>
          </Box>
          <Toolbar
            record={formProps.record}
            basePath={formProps.basePath}
            undoable={true}
            invalid={formProps.invalid}
            handleSubmit={formProps.handleSubmit}
            saving={formProps.saving}
            resource="bank_choice"
          />
        </form>
      )}
    />
  )
}

export default BankChoiceDetailForm
