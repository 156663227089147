import React, { createElement, FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  main: {
    overflow: 'inherit',
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .icon': {
      color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
    },
  },
  title: {
    display: 'block',
  },
  subTitle: {
    display: 'block',
  },
  icon: {
    fontSize: '52px',
  },
}))

interface Props {
  icon: any
  title?: string
  subTitle?: string
  value: string
  color?: string
}

const CardWithIcon: FC<Props> = ({ icon, title, subTitle, value, children, color }) => {
  const classes = useStyles(color)
  return (
    <Card className={classes.card}>
      <CardContent className={classes.main}>
        <Box display="flex">
          <Box width="3em">{createElement(icon, { classes: { root: classes.icon } })}</Box>
          <Box pt={1}>
            <Typography className={classes.title}>{title}</Typography>
            {subTitle && (
              <Typography className={classes.subTitle} variant="caption">
                ({subTitle})
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <Typography color="primary" variant="h5">
            {value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default CardWithIcon
