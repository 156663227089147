import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import SaveIcon from '@material-ui/icons/Save'
import { useNotify, useUpdate } from 'react-admin'
import { OrderReviseExpenseRateInfo_field, OrderRevise_fields } from 'src/types/globalTypes'

interface Props {
  id: string
  changes: {
    expense_id?: string | null
    expense_rate_info?: OrderReviseExpenseRateInfo_field[]
    exchange_rate_info: {
      etc_cost: number
      shipping_usd: number
    }
    locale_currency_fk?: string | null
  }
  record?: OrderRevise_fields
  setEditable: (value: boolean) => void
}

export default function SaveButtonOrderRevise(props: Props) {
  const { id, changes, setEditable } = props

  const [updateItem] = useUpdate()
  const notify = useNotify()

  let expense = changes.expense_rate_info ? changes.expense_rate_info.map((item) => ({ ...item })) : []
  let data = {
    locale_currency_fk: changes.locale_currency_fk,
    exchange_rate_info: { ...changes.exchange_rate_info, usd_export: 1 },
    expense_rate_info: expense,
  }

  const handleUpdateRevise = () => {
    updateItem('order_revise', id, data, undefined, {
      onSuccess: () => {
        notify('resources.global.notifications.success.update', { type: 'info' })
        setEditable(false)
      },
    })
  }

  return (
    <IconButton size="small" style={{ marginRight: 24 }} onClick={handleUpdateRevise} color="primary">
      <SaveIcon />
    </IconButton>
  )
}
