import { useAuth0 } from '@auth0/auth0-react'
import { useMemo, useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import { AppState, User_fields } from 'src/types/globalTypes'

const initialValues: User_fields = {
  id: '',
  name: '',
  auth0_id: '',
  isSuperuser: false,
  disable_user: false,
  permission_group_ids: [],
}

export default function useUser() {
  const { user } = useAuth0()
  const [result, setResult] = useState<User_fields>(initialValues)

  const { data, total, loading, loaded, error } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'user',
    payload: {
      filter: { auth0_id: user?.sub || '' },
      sort: { field: 'name', order: 'ASC' },
      limit: 1,
    },
  })

  let users = useMemo(() => {
    if (total === 1) {
      setResult(data[0])
    }

    return result
  }, [total, data, result])

  return {
    data: users,
    isSuperuser: users.isSuperuser,
    total: total || 0,
    isDisable: users.disable_user,
    loading,
    loaded,
    error,
  }
}
