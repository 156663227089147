import React, { useCallback, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import MoneyIcon from '@material-ui/icons/AttachMoney'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useDataProvider, useGetOne, useLoading, useNotify, useRefresh, useUpdate } from 'react-admin'
import moment from 'moment'
import { InsightFilterStates, InsightProformanceStates, InsightWidgetStates } from './types'
import InsightCard from './components/Tools/InsightCard'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import InsightFilters from './components/Tools/InsightFilters'
import InsightDateRange from './components/Tools/InsightDateRange'
import { DateRange } from 'materialui-daterange-picker'
import { colors } from 'src/utils/colors'
import { Order_expanded } from '../orders/types'
import getOrderSumValues from '../orders/utils/getOrderSumValues'
import InsightWidget from './components/Tools/InsightWidget'
import ReachTargetDialog from './components/Proformance/ReachTargetDialog'
import { formatDateToAPI } from 'src/utils/formatDate'

interface Props {
  state: InsightFilterStates
  setState: (values: React.SetStateAction<InsightFilterStates>) => void
}
export default function InsightProformance(props: Props) {
  const { state, setState } = props
  const id = 'e0952e5e-e24a-4002-94bc-d31749fd430f'
  const dataProvider = useDataProvider()
  const loading = useLoading()
  const [open, setOpen] = useState(false)
  const [targetValue, setTargetValue] = useState(0)
  const [data, setData] = useState<InsightProformanceStates>({})

  const { data: insight } = useGetOne<InsightWidgetStates>('widgets', id)

  const [updateOne] = useUpdate()
  const notify = useNotify()
  const refresh = useRefresh()

  const fetchUsers = useCallback(async () => {
    let userIds = state.users ? state.users.map((item) => item.auth0_id) : []
    let user = state.users?.length ? { user_id: userIds } : undefined
    let start_date = state.dateRange ? { 'pi_date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'pi_date@_lte': formatDateToAPI(state.dateRange.endDate) } : null

    const { data: orders } = await dataProvider.getList<Order_expanded>('order', {
      sort: { field: 'pi_date', order: 'DESC' },
      pagination: { page: 1, perPage: state.users?.length ? 3000 : 0 },
      filter: { ...user, ...start_date, ...end_date, status: 'shipped_out' },
    })

    const aggregation = [
      ...orders
        .reduce((stats, order) => {
          let key = `${order.user_id}`

          let item =
            stats.get(key) || Object.assign({}, { user_id: order.user_id, name: `${order.user.name}`, goal_reach: 0 })

          let { total_amount } = getOrderSumValues({
            unit_code: order.unit_code?.code || 'kg',
            lineItems: order.order_line_items,
            revise: order.order_revises[0],
            exchange_rate: order.exchange_rate,
          })

          item.goal_reach += toDecimal(total_amount)
          return stats.set(key, item)
        }, new Map())
        .values(),
    ]

    setData((prev) => ({
      ...prev,
      nbOrdersByUser: aggregation,
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.users, state.dateRange, dataProvider])

  useEffect(() => {
    fetchUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setData({})
    }
  }, [fetchUsers])

  useEffect(() => {
    setTargetValue(insight?.values.individual_sales_target || 0)
  }, [insight])

  const handleDateRange = (value: DateRange) => {
    setState((prev) => ({ ...prev, dateRange: value }))
  }

  const handleFilterChange = (name: string, value: any) => {
    let newValue = { ...state, [name]: value }
    setState(newValue)
  }

  const handleRemoveFilter = (index: number) => {
    if (state.users) {
      let newValue = [...state.users]
      newValue.splice(index, 1)
      setState({ ...state, users: newValue })
    }
  }

  function handleSave() {
    let data = {
      values: { ...insight?.values, individual_sales_target: targetValue },
    }
    updateOne('widgets', id, data, insight, {
      onSuccess: () => {
        notify('resources.global.notifications.success.create', { type: 'info' })
        refresh()
        setOpen(false)
      },
    })
  }

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Box display="flex" alignItems="flex-start">
            <InsightFilters loading={loading} useUsers {...state} onChange={handleFilterChange} />
            <Button size="small" color="primary" variant="outlined" onClick={() => setOpen(true)}>
              Set target
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="right">
            <InsightDateRange dateRange={state.dateRange} setDateRange={handleDateRange} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          {state.users?.length
            ? state.users.map((item, i) => {
                const dataColor = colors[i].join(',')

                return (
                  <Chip
                    key={i}
                    variant="outlined"
                    style={{ border: `2px solid rgb(${dataColor})`, marginRight: 8 }}
                    label={item.name}
                    onDelete={() => handleRemoveFilter(i)}
                  />
                )
              })
            : null}
        </Grid>
      </Grid>

      <Box my={2} />

      <Grid container spacing={3}>
        {data.nbOrdersByUser?.length ? (
          data.nbOrdersByUser.map((item, index) => {
            let dataColor = `rgba(${colors[index].join(',')})`
            return (
              <Grid item xs={12} md={4} key={index}>
                <InsightWidget
                  color={dataColor}
                  title="% of goal reached"
                  icon={MoneyIcon}
                  subTitle={item.name}
                  value={`${toDecimalStr((item.goal_reach / targetValue) * 100)}%`}
                />
              </Grid>
            )
          })
        ) : (
          <Grid item xs={12} md={4}>
            <InsightCard title="% of goal reached">Value: {toDecimalStr(0)}</InsightCard>
          </Grid>
        )}
      </Grid>
      {open && (
        <ReachTargetDialog
          open={open}
          value={targetValue}
          onChange={setTargetValue}
          onSave={handleSave}
          onClose={() => setOpen(false)}
        />
      )}
    </Box>
  )
}
