import React from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'
import usePermissionByUser from 'src/hooks/usePermissionByUser'

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
  },
}))

interface Props {
  loading?: boolean
  value: number
  disableSample?: boolean
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void
}

export default function OrderStepper(props: Props) {
  const { value, loading, disableSample, onChange } = props
  const classes = useStyles()
  const { permissions } = usePermissionByUser()

  return (
    <Paper className={classes.root} square component="div">
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={onChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="disabled tabs example"
      >
        <Tab disabled={loading} label="Items" {...a11yProps(0)} />
        <Tab
          disabled={loading || !permissions['exchange_rate_expenses'].read}
          label="Exchange Rates & Expenses"
          {...a11yProps(1)}
        />
        <Tab disabled={loading || !permissions['items_with_costs'].read} label="Items With Costs" {...a11yProps(2)} />
        <Tab disabled={loading || disableSample} label="Product Samples" {...a11yProps(3)} />
        <Tab disabled={loading || !permissions['pricing'].read} label="Pricing" {...a11yProps(4)} />
        <Tab disabled={loading || !permissions['overview'].read} label="Overview" {...a11yProps(5)} />
      </Tabs>
    </Paper>
  )
}
