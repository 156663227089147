/* eslint-disable no-use-before-define */
import React from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Close'
import { alpha, makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { InputProps, useInput } from 'react-admin'
import { useForm } from 'react-final-form'
import useVendors from 'src/hooks/useVendors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 280,
      fontSize: 13,
    },
    button: {
      fontSize: 13,
      width: '100%',
      textAlign: 'left',
      paddingBottom: 8,
      color: '#586069',
      fontWeight: 600,
      '&:hover,&:focus': {
        color: '#0366d6',
      },
      '& span': {
        width: '100%',
      },
      '& svg': {
        width: 16,
        height: 16,
      },
    },
    tag: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: 8,
      height: 20,
      fontSize: 14,
      padding: '4px 0px',
      borderRadius: 30,
      lineHeight: '15px',
      backgroundColor: '#7057ff',
      color: '#fff',
    },
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 300,
      zIndex: 1,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    iconSelected: {
      width: 17,
      height: 17,
      marginRight: 5,
      marginLeft: -2,
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    text: {
      flexGrow: 1,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  })
)

export default function VendorReferenceArrayInput(props: InputProps) {
  const classes = useStyles()
  const { change } = useForm()

  const { data: vendors } = useVendors()

  const {
    input: { value, name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const handleDelete = (index: number) => {
    let newValue = [...value]
    newValue.splice(index, 1)
    change('vendors', newValue)
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Autocomplete
          multiple
          fullWidth
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={value}
          onChange={(event, newValue) => {
            onChange(newValue)
          }}
          disableCloseOnSelect
          // disablePortal
          renderTags={() => null}
          noOptionsText="No labels"
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <DoneIcon className={classes.iconSelected} style={{ visibility: selected ? 'visible' : 'hidden' }} />
              <span className={classes.color} style={{ backgroundColor: option.color }} />
              <div className={classes.text}>
                {option.code}
                <br />
                {/* {option.description} */}
              </div>
              <CloseIcon className={classes.close} style={{ visibility: selected ? 'visible' : 'hidden' }} />
            </React.Fragment>
          )}
          options={[...vendors].sort((a, b) => {
            // Display the selected labels first.
            let ai = value.indexOf(a)
            ai = ai === -1 ? value.length + vendors.indexOf(a) : ai
            let bi = value.indexOf(b)
            bi = bi === -1 ? value.length + vendors.indexOf(b) : bi
            return ai - bi
          })}
          getOptionLabel={(option) => option.code}
          renderInput={(params) => (
            <TextField
              name={name}
              fullWidth
              error={!!(touched && error)}
              helperText={touched && error}
              required={isRequired}
              size="small"
              label="Vendors"
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              variant="filled"
            />
          )}
        />
        {value?.length
          ? value.map((item: any, index: number) => (
              <div key={index} className={classes.tag}>
                <Box pl={2}>{item.code}</Box>
                <Box pr={3}>
                  <IconButton style={{ color: '#fff' }} size="small" onClick={() => handleDelete(index)}>
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </Box>
              </div>
            ))
          : null}
      </div>
    </React.Fragment>
  )
}
