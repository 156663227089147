import React, { FC } from 'react'
import ArrowDown from '@material-ui/icons/ArrowDownward'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FieldProps, useGetOne, useShowController } from 'react-admin'
import { toDecimalStr } from 'src/utils/toDecimal'
import { Invoice_expanded } from './types'
import getInvoiceSumValues from './utils/getInvoiceSumValues'
import { VendorPort_expanded } from '../vendors/types'

const InvoicePanel: FC<FieldProps<Invoice_expanded>> = ({ ...rest }) => {
  const { record, loading } = useShowController<Invoice_expanded>({ ...rest })
  const classes = useStyles()

  const { data: vendorPort } = useGetOne<VendorPort_expanded>('vendor_port', record?.port || '')

  if (!record) return null

  const { total_amount, total_ctn, total_net_weight_kg } = getInvoiceSumValues({
    itemView: 1,
    lineItems: record.invoice_line_items,
    unit_code: record.unit_code?.code || 'kg',
  })

  return (
    <>
      <ArrowDown className={classes.arrowIcon} />
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography className={classes.headline} variant="h6">
                CKERP-NEXT
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" alignItems="flex-end" position="relative">
                <Typography className={classes.headline} variant="h6" align="right">
                  {record.invoice_header?.ref_invoice}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.textBold}>Information</Typography>
                <Typography className={classes.textLight} variant="caption">
                  Container No: {record.information.cont_no}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Seal No: {record.information.seal_no}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  BL number: {record.information.bl_number}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Vessel: {record.information.vessel}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Feeder: {record.information.feeder}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Mark & Nos: {record.information.mark_nos}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Destinations:{' '}
                  {record.information.destination.length ? record.information.destination.map((text) => text) : ''}
                </Typography>

                <Typography className={classes.textBold}>Total</Typography>
                <Typography className={classes.textLight} variant="caption">
                  {/* Subtotal: {lineItems.length} items */}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  {toDecimalStr(total_net_weight_kg || 0)} KG
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  ${toDecimalStr(total_amount || 0)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.textBold}>Terms</Typography>
                <Typography className={classes.textLight} variant="caption">
                  Terms of pamyent: {record.information.term_of_payment}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Within after vessel arrival: {record.information.within} days
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Before vessel arrival: {record.information.is_before_vessel ? 'Yes' : 'No'}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Production of: {record.information.production_country}
                </Typography>
                <Typography className={classes.textBold}>Options</Typography>
                <Typography className={classes.textLight} variant="caption">
                  Use format: {record.use_format ? 'Yes' : 'No'}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Prefix: {record.invoice_header?.invoice_prefix_fk_code}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Terms of sale: {record.terms_of_sale}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Form port: {vendorPort?.destination_port.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Pack</TableCell>
                    <TableCell align="center">Box</TableCell>
                    <TableCell align="right">Total NET/WET</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading || !record.invoice_line_items.length ? (
                    record.invoice_line_items?.map((item, index: number) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body2">
                            {item.product?.name}
                            <Typography className={classes.size} component="span" variant="caption">
                              {item.size_value ? ` - ${item.size_value.label} ${item.size?.label}` : ''}
                            </Typography>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{item.pack?.name || ''}</Typography>
                        </TableCell>
                        <TableCell align="center">{item.box}</TableCell>
                        <TableCell align="right">{toDecimalStr(item.box * item.weight_mc)}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>Loading...</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      <Typography variant="body2" className={classes.textBold}>
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" className={classes.textBold}>
                        {toDecimalStr(total_ctn)}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2" className={classes.textBold}>
                        {toDecimalStr(total_net_weight_kg)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default InvoicePanel

const useStyles = makeStyles((theme: Theme) => ({
  root: { width: 768, margin: 'auto' },
  mr1: {
    marginRight: theme.spacing(1),
  },

  size: { color: theme.palette.grey[600] },
  arrowIcon: { width: `100%`, color: '#b3b3b3', textAlign: 'center', margin: `0 auto` },
  textLight: { color: theme.palette.grey[600] },
  headline: { fontWeight: 600 },
  textBold: { fontWeight: 'bold' },
  quickEditButton: {
    color: theme.palette.primary.light,
    cursor: 'pointer',
  },
}))
