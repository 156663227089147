import React from 'react'
import Portal from '@material-ui/core/Portal'
import { makeStyles, emphasize, withStyles, Theme } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Chip from '@material-ui/core/Chip'
import HomeIcon from '@material-ui/icons/Home'

import AppLocationContext from '../../context/AppLocationContext'
import { useHistory, useLocation } from 'react-router-dom'

const StyledBreadcrumb = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(4),
    color: theme.palette.grey[800],
    fontWeight: 'lighter',
    textTransform: 'capitalize',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip) as typeof Chip // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

const useStyles = makeStyles((theme: Theme) => ({
  last: {
    backgroundColor: theme.palette.grey[300],
  },
}))

function RouteBreadcrumbs(props: any) {
  const classes = useStyles()
  let { pathname } = useLocation()
  let pathnames: string[] = pathname.split('/').filter((x: string) => x)
  const history = useHistory()

  function handleClick(event: React.MouseEvent<Element, MouseEvent>, path: string) {
    event.preventDefault()
    history.push(path)
  }

  function handleDashboard() {
    history.push('/')
  }

  return (
    <AppLocationContext.Consumer>
      {(anchor) =>
        anchor ? (
          <Portal container={anchor.current}>
            {pathnames.length ? (
              <Breadcrumbs>
                <StyledBreadcrumb
                  component="span"
                  label={'Dashboard'}
                  icon={<HomeIcon fontSize="small" />}
                  onClick={handleDashboard}
                />
                {pathnames.map((path: string, index: number) => {
                  const last = index === pathnames.length - 1
                  const to = `/${pathnames.slice(0, index + 1).join('/')}`

                  return last ? (
                    <StyledBreadcrumb
                      key={index}
                      label={path}
                      component="span"
                      onClick={(e: any) => handleClick(e, to)}
                      className={classes.last}
                    />
                  ) : (
                    <StyledBreadcrumb
                      key={index}
                      label={path}
                      component="span"
                      onClick={(e: any) => handleClick(e, to)}
                    />
                  )
                })}
              </Breadcrumbs>
            ) : null}
          </Portal>
        ) : null
      }
    </AppLocationContext.Consumer>
  )
}

export default RouteBreadcrumbs
