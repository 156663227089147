import { gql } from '@apollo/client'

export const GET_LIST_PROFILE_ITEM_VALUES = gql`
  {
    id
    customer_fk_ids
    product_variant_fk
    product_variant {
      id
      product_fk
      size_fk
      size_value_fk
      product {
        id
        name
      }
      size {
        id
        label
      }
      size_value {
        id
        label
      }
    }
    pack {
      id
      name
    }
    rw_price
    profit
    weight_glazing
    use_default
    weight_mc
    gross_weight
    vendor_code
    vendor {
      id
      code
    }
  }
`
