import React, { FC } from 'react'
import { ReferenceField, ReferenceFieldProps } from 'react-admin'
import CountryCodeField from './CountryCodeField'

const CountryReferenceField: FC<
  Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string
  }
> = (props) => {
  return (
    <ReferenceField source="country.id" reference="country" {...props}>
      <CountryCodeField />
    </ReferenceField>
  )
}

CountryReferenceField.defaultProps = {
  source: 'country.id',
  addLabel: true,
}

export default CountryReferenceField
