import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps } from 'react-admin'
import { Order_expanded } from '../types'
import getOrderSumValues from '../utils/getOrderSumValues'
import { toDecimalStr } from 'src/utils/toDecimal'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'right',
  },
  success: {
    color: theme.palette.success.main,
  },
}))

const OrderTotalNetWetKGField = ({ record }: FieldProps<Order_expanded>) => {
  const classes = useStyles()
  if (!record) return null
  let value = 0

  if (record.order_line_items?.length) {
    let { total_net_weight_kg } = getOrderSumValues({
      unit_code: record.unit_code?.code || 'kg',
      lineItems: record.order_line_items,
      revise: record.order_revises[0],
      exchange_rate: record.exchange_rate,
    })

    value = total_net_weight_kg
  }

  let formatValue = value

  return <div className={classes.root}>{toDecimalStr(formatValue)}</div>
}

export default OrderTotalNetWetKGField
