import React, { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import PaymentDetailPage from './PaymentDetailPage'

const Title = ({ record }: any) => {
  return <div>{record.title || 'Payment'}</div>
}

const PaymentEdit: FC<EditProps> = (props) => {
  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Edit title={<Title />} {...props}>
        <PaymentDetailPage redirect={false} />
      </Edit>
    </>
  )
}

export default PaymentEdit
