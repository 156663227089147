/* eslint-disable no-use-before-define */
import React, { useState } from 'react'
import { alpha, makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import DoneIcon from '@material-ui/icons/Done'
import Autocomplete, {
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import ButtonBase from '@material-ui/core/ButtonBase'
import InputBase from '@material-ui/core/InputBase'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import useVendors from 'src/hooks/useVendors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginRight: `10px`,
      minWidth: 150,
      fontSize: 16,
    },

    button: {
      fontSize: 15,
      width: '100%',
      textAlign: 'left',
      color: '#9ca29',
      fontWeight: 300,
      '&:hover,&:focus': {
        backgroundColor: '#d9dce0',
      },
      '& span': {
        width: '100%',
      },
      '& svg': {
        width: 16,
        height: 16,
      },
      padding: `8px 7px`,
      border: `1px solid #9ca2a9`,
      borderRadius: `3px`,
    },

    tag: {
      marginTop: 3,
      height: 20,
      padding: '.15em 4px',
      fontWeight: 600,
      lineHeight: '15px',
      borderRadius: 2,
    },
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 200,
      zIndex: 130,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    iconSelected: {
      width: 17,
      height: 17,
      marginRight: 5,
      marginLeft: -2,
      color: theme.palette.primary.main,
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    text: {
      flexGrow: 1,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  })
)

interface Props {
  value: string[]
  onChange: (name: string, values: string[]) => void
}

export default function InsightVendorsAutocomplete(props: Props) {
  const { value, onChange } = props
  const classes = useStyles()
  const { data } = useVendors()
  let options = data.map((item) => item.code)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const allSelected = options.length === selectedOptions.length

  const handleClearOptions = () => setSelectedOptions([])

  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setSelectedOptions(options)
    } else {
      handleClearOptions()
    }
  }

  const handleToggleSelectAll = () => {
    handleSelectAll(!allSelected)
  }

  const handleToggleOption = (selectedOptions: string[]) => setSelectedOptions(selectedOptions)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return
    }

    if (value.length === 0) {
      setSelectedOptions([])
    }
    onChange('vendors', selectedOptions)

    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'vendors-label' : undefined

  const filter = createFilterOptions<string>()

  const handleChange = (event: any, selectedOptions: string[], reason: AutocompleteChangeReason) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      if (selectedOptions.find((option: string) => option === 'All')) {
        handleToggleSelectAll()
        // let result: string[] = []
        // result = options.filter((el) => el !== 'All')
        // return onChange('vendors', result)
      } else {
        handleToggleOption && handleToggleOption(selectedOptions)
        // return onChange('vendors', selectedOptions)
      }
    } else if (reason === 'clear') {
      handleClearOptions && handleClearOptions()
    }
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <ButtonBase disableRipple className={classes.button} aria-describedby={id} onClick={handleClick}>
          <span>Vendors: {value.length}</span>
          <KeyboardArrowDownIcon />
        </ButtonBase>
      </div>
      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" className={classes.popper}>
        <Autocomplete
          open
          multiple
          onClose={handleClose}
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={selectedOptions}
          onChange={handleChange}
          disablePortal
          disableCloseOnSelect
          renderTags={() => null}
          noOptionsText="No labels"
          filterOptions={(options, params) => {
            const filtered = filter(options, params)
            let newValue: any[] = []
            if (options.length > 0) {
              newValue = ['All', ...filtered]
            } else if (options.length === 0) {
              const filtered = filter(options, params)
              newValue = [...filtered]
            }
            return newValue
          }}
          renderOption={(option, { selected }) => {
            const selectAllProps =
              options.length > 0 && option === 'All' // To control the state of 'select-all' checkbox
                ? allSelected
                : false
            return (
              <React.Fragment>
                <DoneIcon
                  className={classes.iconSelected}
                  style={{ visibility: selected || selectAllProps ? 'visible' : 'hidden' }}
                />
                <span className={classes.color} />
                <div className={classes.text}>{option}</div>
              </React.Fragment>
            )
          }}
          options={options}
          getOptionLabel={(option) => option}
          renderInput={(params) => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
            />
          )}
        />
      </Popper>
    </React.Fragment>
  )
}
