import React from 'react'
import Box from '@material-ui/core/Box'
import { useListContext } from 'react-admin'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import SizeValue from './SizeValue'
import NewSizeValue from './NewSizeValue'
import Separate from 'src/components/Separate'

const SizeValueIterator = ({ reference }: { reference: 'size' }) => {
  const { data, ids, loaded } = useListContext()

  if (!loaded) return null

  return (
    <>
      <Box mt="0.5em">
        <NewSizeValue reference={reference} />
        <Separate value={3} />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Code</TableCell>
              <TableCell>Default Label</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ids.map((id, index) => (
              <SizeValue key={index} value={data[id]} />
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  )
}

export default SizeValueIterator
