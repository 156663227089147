import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import SectionTitle from 'src/components/SectionTitle'
import { DateInput, FormWithRedirect, required, TextInput, Toolbar } from 'react-admin'
import Separate from 'src/components/Separate'
import InvoiceTermsForm from 'src/pages/invoiceHeaders/InvoiceHeaderTermsForm'
import OrderReferenceInput from '../orders/components/OrderReferenceInput'
import InvoiceHeaderOptionsForm from './InvoiceHeaderOptionsForm'
import { invoiceHeaderInitialValues } from './constants'
import InvoiceHeaderInformationCreateForm from './InvoiceHeaderInformationCreateForm'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import { useForm, useFormState } from 'react-final-form'
import useGetNewRefInvoice from './hooks/useGetNewRefInvoice'
import BankChoiceReferenceInput from '../bankChoices/BankChoiceReferenceInput'

export default function InvoiceHeaderCreateForm(props: any) {
  return (
    <FormWithRedirect
      {...props}
      initialValues={invoiceHeaderInitialValues}
      render={(formProps: any) => (
        <Card>
          <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
              <Box flex={2} mr={{ md: 0, lg: '3em' }}>
                <InvoiceHeaderOveriew />
                <Separate value={3} />
                <InvoiceHeaderInformationCreateForm />
                <Separate value={3} />
                <SectionTitle label="resources.invoice_header.fieldGroups.notes" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextInput source="author_name" fullWidth label="Export Section" helperText={false} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <BankChoiceReferenceInput
                      fullWidth
                      source="bank_fk_code"
                      label="Bank account"
                      validate={[required()]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput source="description" fullWidth label="Remark" helperText={false} />
                  </Grid>
                </Grid>
              </Box>
              <Box flex={2}>
                <InvoiceTermsForm />
                <Separate value={1} />
                <InvoiceHeaderOptionsForm type="create" />
              </Box>
            </Box>
          </CardContent>
          <Toolbar
            basePath={formProps.basePath}
            record={formProps.record}
            invalid={formProps.invalid}
            saving={formProps.saving}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            resource="invoice_header"
          >
            <SaveWithLogButton
              action_type="CREATE"
              source="ref_invoice"
              updateParent={{ resource: 'order', source: 'order_id', data: { isDone: true } }}
            />
          </Toolbar>
        </Card>
      )}
    />
  )
}

const InvoiceHeaderOveriew = () => {
  const { values } = useFormState()
  const { change } = useForm()
  const { ref_invoice } = useGetNewRefInvoice({
    vendor_fk_code: values.order?.vendor_fk_code || '',
    customer_fk_code: values.order?.customer_fk_code || '',
    country: values.order?.vendor.country || '',
  })

  useEffect(() => {
    if (values.order?.vendor_fk_code && values.order?.customer_fk_code) {
      change('ref_invoice', ref_invoice)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.order?.vendor_fk_code, ref_invoice, values.order?.customer_fk_code])

  return (
    <>
      <Box display="flex">
        <SectionTitle label="resources.global.fieldGroups.overview" />

        {values.order?.vendor_fk_code && values.order?.customer_fk_code ? (
          <Typography style={{ marginTop: 8, marginLeft: 8 }} variant="caption">
            CK{values.ref_invoice}
          </Typography>
        ) : null}
        <Box mr={1} />
        <Typography variant="caption" style={{ marginTop: 8 }}></Typography>
      </Box>
      <Box display={{ xs: 'block', sm: 'flex' }}>
        <Box flex={2} mr={{ xs: 0, sm: '1em' }}>
          <OrderReferenceInput source="order" label="Ref order" fullWidth validate={required()} />

          <Separate value={2} />
          <TextInput source="custom_ref_inv" fullWidth label="Custom (Ref INV.)" />
        </Box>
        <Box flex={1}>
          <DateInput source="date" fullWidth />
        </Box>
      </Box>
    </>
  )
}
