import React from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import SectionTitle from 'src/components/SectionTitle'
import SizeReferenceInput from 'src/components/ReferenceInputs/SizeReferenceInput'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Separate from 'src/components/Separate'
import { NumberInput, required, SelectInput, TextInput } from 'react-admin'

const useStyles = makeStyles((theme: Theme) => ({
  fieldGroups: {
    display: 'inline-block',
  },
  field: {
    width: 400,
  },
  mr: {
    marginRight: theme.spacing(2),
  },
}))

const ProductVariantDetailForm = (props: any) => {
  const classes = useStyles()
  return (
    <Card>
      <CardContent>
        <Box>
          <SectionTitle label="resources.global.fieldGroups.information" />
          <div className={classes.field}>
            <SizeReferenceInput source="full_size" label="Size" />
          </div>
        </Box>
        <Separate value={3} />
        <Box>
          <SectionTitle label="resources.global.fieldGroups.options" />
          <NumberInput
            fullWidth
            className={clsx(classes.fieldGroups, classes.field, classes.mr)}
            source="weight_loss"
          />
          <SelectInput className={classes.field} source="glazing" choices={gl_list} />
        </Box>

        <Separate value={3} />
        <Box>
          <SectionTitle label="resources.product_variant.fieldGroups.inventory" />
          <TextInput className={classes.field} source="sku" label="Product code" validate={required()} />
        </Box>
      </CardContent>
      <CardActions>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Button type="submit" color="primary" variant="contained" disabled={props.submitting || props.pristine}>
            Save
          </Button>
          {props.deleteBtn}
        </Box>
      </CardActions>
    </Card>
  )
}

const gl_list = [
  {
    id: 'GL10%',
    name: 'GL10%',
  },
  {
    id: 'GL20%',
    name: 'GL20%',
  },
  {
    id: 'GL30%',
    name: 'GL30%',
  },
  {
    id: 'GL30-35%',
    name: 'GL30-35%',
  },
  {
    id: 'GL30-40%',
    name: 'GL30-40%',
  },
  {
    id: 'GL35-40%',
    name: 'GL35-40%',
  },
  {
    id: 'GL40%',
    name: 'GL40%',
  },

  {
    id: 'GL40-50%',
    name: 'GL40-50%',
  },
]

export default ProductVariantDetailForm
