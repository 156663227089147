import React, { FC } from 'react'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles, Theme } from '@material-ui/core/styles'
import SectionTitle from 'src/components/SectionTitle'
import { useQueryWithStore, useRedirect } from 'react-admin'
import { ProductVariant_expanded } from './types/ProductVariant'
import { useParams } from 'react-router-dom'
import { AppState } from 'src/types/globalTypes'

const useStyles = makeStyles((theme: Theme) => ({
  cardContent: {
    padding: '10px 0',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  row: {
    cursor: 'pointer',
    borderTop: `1px solid #f3f3f3`,
  },
  text: {
    fontSize: 15,
    marginLeft: 20,
    color: '#6b6b6b',
  },
  active: {
    backgroundColor: theme.palette.secondary.contrastText,
  },
}))

interface Props {
  productId: string
}

const ProductVariantExistList: FC<Props> = ({ productId }) => {
  const classes = useStyles()
  const rediredctTo = useRedirect()
  const { variantId }: any = useParams()

  const isSelected = (value: ProductVariant_expanded) => (value.id === variantId ? true : false)

  const { loading, data } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'product_variant',
    payload: {
      filter: { product_fk: productId },
      sort: { field: 'size_value.label', order: 'ASC' },
    },
  })

  function handleAdd() {
    rediredctTo(`/product/${productId}/product_variant/create`)
  }

  function handleRowClick(id: string) {
    rediredctTo(`/product/${productId}/product_variant/${id}`)
  }

  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Box px={{ xs: 2 }} display="flex" alignItems="flex-start" justifyContent="space-between">
          <SectionTitle label="resources.product.fieldGroups.variants" mb={1} />
          <Button onClick={handleAdd} variant="outlined" color="primary" size="small">
            add
          </Button>
        </Box>
        <Table size="small">
          <TableBody>
            {loading || !data ? (
              <TableRow className={classes.row} hover>
                <TableCell>Loading...</TableCell>
              </TableRow>
            ) : data.length ? (
              data.map((item: ProductVariant_expanded, index: number) => {
                let isSelectedItem = isSelected(item)
                return (
                  <TableRow
                    key={index}
                    className={clsx(classes.row)}
                    hover
                    onClick={() => handleRowClick(item.id)}
                    selected={isSelectedItem}
                  >
                    <TableCell>
                      <Typography className={classes.text}>
                        {item.size_value ? `${item.size_value.label} ${item.size?.label}` : 0}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : null}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ProductVariantExistList
