import { formatDate, formatTimestampToDate } from 'src/utils/formatDate'

interface Props {
  ref_pi: string
  pi_date: string | Date
  remark: string | null
  sticker: string | null
  date_shipment?: string | Date
  status: string
  description?: string | null
  shelf_life: number
  start_date?: string | Date
  end_date?: string | Date
}

export function pageHeading_3(props: Props) {
  const { ref_pi, pi_date, status, date_shipment, description, shelf_life, start_date, end_date } = props
  let production_expiry_dates =
    status === 'processing_order' ||
    status === 'revise_tbc' ||
    status === 'rev_order_inprocess' ||
    status === 'order_completed' ||
    status === 'shipped_out'
      ? `Production date: ${formatDate(start_date, '-')} Expiry date: ${formatDate(end_date, '-')}`
      : ''

  return [
    {
      columns: [
        {
          width: 200,
          style: 'subHeaderText',
          text: `${ref_pi}`,
        },
        {
          width: 170,
          style: 'mt5',
          text: `Date: ${formatDate(pi_date)} `,
        },
        {
          width: 150,
          style: 'mt5',
          text: `Status: ${status.toUpperCase()}`,
        },
        {
          width: 300,
          style: 'mt5',
          text: `Date of Shipment: ${formatTimestampToDate(date_shipment)}`,
        },
      ],
    },
    {
      columns: [
        {
          width: 0,
          text: ``,
        },
        {
          width: 370,
          style: 'mtm1',
          text: `Product Sample: ${description || ''}
          Remark: ${props.remark || ''}
          Sticker: ${props.sticker || ''}`,
        },
        {
          width: 150,
          style: 'mtm1',
          text: `Shelf life: ${shelf_life} months`,
        },
        {
          width: 300,
          style: 'mtm1',
          text: production_expiry_dates,
        },
      ],
    },
  ]
}
