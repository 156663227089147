import BankChoiceList from './BankChoiceList'
import BankChoiceCreate from './BankChoiceCreate'
import BankChoiceEdit from './BankChoiceEdit'

const bankChoices = {
  list: BankChoiceList,
  create: BankChoiceCreate,
  edit: BankChoiceEdit,
}

export default bankChoices
