import SizeList from './SizeList'
import SizeCreate from './SizeCreate'
import SizeEdit from './SizeEdit'

const sizes = {
  list: SizeList,
  create: SizeCreate,
  edit: SizeEdit,
}

export default sizes
