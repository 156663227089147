import { gql } from '@apollo/client'

export const GET_LIST_PROFILE_ITEMS = gql`
  {
    id

    vendor_fk
    vendor {
      id
      code
    }
    default_gross_weight
    profile_item_values_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GET_PROFILE_ITEM_ONE = gql`
  {
    id
    vendor_fk
    vendor {
      id
      code
    }
    default_gross_weight

    profile_item_values {
      product_variant {
        product_fk
        size_fk
        size_value_fk
        product {
          id
          name
        }
        size {
          id
          label
        }
        size_value {
          id
          label
        }
      }
      weight_mc
      gross_weight
    }

    profile_item_values_aggregate {
      aggregate {
        count
      }
    }
  }
`
