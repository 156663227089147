import React from 'react'
import Box from '@material-ui/core/Box'
import SectionTitle from '../../components/SectionTitle'
import { BooleanInput, SelectInput } from 'react-admin'
import CountryReferenceInput from 'src/pages/countries/CountryReferenceInput'

export default function InvoiceHeaderTermsForm(props: any) {
  const { disabled } = props
  return (
    <Box display="flex" flexDirection="column" width="25em">
      <SectionTitle label="resources.invoice_header.fieldGroups.terms" />

      <SelectInput
        disabled={disabled}
        source="information.term_of_payment"
        label="Terms of Payment"
        choices={TERMS_OF_PAYMENT_CHOICES}
        fullWidth
        optionText="name"
      />

      <SelectInput
        disabled={disabled}
        source="information.within"
        label="Within after vessel arrival"
        choices={WITHIN_CHOICES}
        fullWidth
        optionText="name"
      />

      <BooleanInput disabled={disabled} source="information.is_before_vessel" label="Before vessel arrival" />
      <CountryReferenceInput
        disabled={disabled}
        source="information.production_country"
        fullWidth
        label="Production of"
        allowEmpty
      />
    </Box>
  )
}

const TERMS_OF_PAYMENT_CHOICES = [
  {
    id: 'tt',
    name: 'TT',
  },
  {
    id: 'tt2',
    name: 'TT2',
  },
  {
    id: 'tt3',
    name: 'TT3',
  },
]

const WITHIN_CHOICES = [
  {
    id: 30,
    name: '30 Days',
  },
  {
    id: 60,
    name: '60 Days',
  },
  {
    id: 90,
    name: '90 Days',
  },
]
