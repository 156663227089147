import { toDecimal } from 'src/utils/toDecimal'

interface Props {
  unit_code: string
  weight_mc: number
  box: number
  unit_price: number
}

export default function getOrderLineAmount(props: Props) {
  const { unit_code, weight_mc, box, unit_price } = props

  let net_weight_kg = toDecimal(weight_mc * (box || 0))

  let result = 0
  if (unit_code) {
    result = unit_code === 'mc' ? toDecimal(unit_price * box) : toDecimal(unit_price * net_weight_kg)
  }

  return result
}
