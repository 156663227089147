import React from 'react'
import Box from '@material-ui/core/Box'
import SectionTitle from 'src/components/SectionTitle'
import { SelectInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import VendorPortReferenceInput from '../vendors/VendorPortReferenceInput'
import Separate from 'src/components/Separate'

export default function InvoiceOptionsForm() {
  const { values } = useFormState()

  return (
    <Box display="flex" flexDirection="column" width="25em">
      <SectionTitle label="resources.global.fieldGroups.options" />
      {values.invoice_header?.use_format && values.invoice_header.format_ref_invoice}
      <Separate value={3} />
      <SelectInput source="terms_of_sale" choices={TEMRS_OF_SALE} />
      <VendorPortReferenceInput
        fullWidth
        allowEmpty
        label="From Port"
        source="port"
        vendor_fk_code={values.invoice_header?.order?.vendor_fk_code}
        helperText={false}
      />
    </Box>
  )
}

const TEMRS_OF_SALE = [
  {
    id: 'cnf',
    name: 'CNF',
  },
  {
    id: 'cif',
    name: 'CIF',
  },
  {
    id: 'fob',
    name: 'FOB',
  },
]
