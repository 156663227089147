import moment from 'moment'
import { Payment_fields } from 'src/types/globalTypes'

interface Data {
  title: string
  payments: { [key: string]: Payment_fields[] }
}

export const paymentsByPeriod = (
  payments: Payment_fields[],
  weekly: string[],
  period: moment.unitOfTime.StartOf = 'week',
  split?: boolean
): Data[] => {
  let result: Data[] = []

  //   if (split === true) {
  result = [
    ...payments
      .reduce((acc, payment) => {
        const key = `${payment.customer_fk_code}`
        const month = moment(payment.date).startOf(period).format('YYYY-MM-DD')
        const item = acc.get(key) || Object.assign({}, { title: key, payments: {} })
        if (!item.payments[month]) {
          item.payments[month] = []
        }
        item.payments[month].push(payment)
        return acc.set(key, item)
      }, new Map())
      .values(),
  ]
  //   } else if (split === false) {
  //     result = [
  //       ...payments
  //         .reduce((acc, payment) => {
  //           const key = ``
  //           const month = moment(invoice.date).startOf(period).format('YYYY-MM-DD')
  //           const item = acc.get(key) || Object.assign({}, { title: key, invoices: {} })
  //           if (!item.invoices[month]) {
  //             item.invoices[month] = []
  //           }
  //           item.invoices[month].push(invoice)
  //           return acc.set(key, item)
  //         }, new Map())
  //         .values(),
  //     ]
  //   }

  weekly.reduce((acc, curr) => {
    if (result.length) {
      Object.values(result).forEach((item: any) => {
        if (!item.payments[curr]) {
          item.payments[curr] = []
        }
      })
    }

    return acc
  }, {})

  return result
}
