import React, { FC, useMemo } from 'react'
import { AutocompleteInput, AutocompleteInputProps, useQueryWithStore } from 'react-admin'
import { AppState, CustomerPort_fields, DestinationPort_fields } from 'src/types/globalTypes'

interface Data extends CustomerPort_fields {
  destination_port: DestinationPort_fields | null
}

interface Props extends AutocompleteInputProps {
  customer_fk_code: string
}

const CustomrPortReferenceInput: FC<Props> = ({ customer_fk_code, ...rest }) => {
  const { data } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'customer_port',
    payload: {
      filter: { customer_fk_code },
      sort: { field: 'destination_port.name', order: 'ASC' },
    },
  })

  let options = useMemo(() => formatItems(data), [data])

  return <AutocompleteInput choices={options} {...rest} />
}

export default CustomrPortReferenceInput

interface Choice {
  id: string
  name: string
}

function formatItems(data: Data[]) {
  let items: Choice[] = []
  if (data) {
    items = data.map((item) => ({
      id: item.id,
      name: item.destination_port?.name || '',
    }))
  }
  return items
}
