import React, { FC } from 'react'
import { DeleteButton, Edit, EditProps, required, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import SectionTitle from '../../components/SectionTitle'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

const styles: Styles<Theme, any> = {
  field: { maxWidth: 544 },
}
const useStyles = makeStyles(styles)

const Title = ({ record }: any) => {
  return record.name
}

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const CustomToolbar = (props: any) => (
  <Toolbar {...props} classes={useToolbarStyles()}>
    <SaveButton disabled={props.pristine} />
    <DeleteButton confirmTitle={`Delete category #${props.record.name}`} mutationMode="pessimistic" />
  </Toolbar>
)

const CategoryEdit: FC<EditProps> = (props) => {
  const classes = useStyles(props)

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - category #${props.id}`} />
      <RouteBreadcrumbs {...props} />
      <Edit {...props} title={<Title />}>
        <SimpleForm redirect={false} toolbar={<CustomToolbar />}>
          <SectionTitle label="resources.global.fieldGroups.information" />
          <TextInput source="name" validate={required()} fullWidth className={classes.field} />
          <TextInput source="description" fullWidth className={classes.field} />
        </SimpleForm>
      </Edit>
    </>
  )
}

export default CategoryEdit
