import { OrderLineItems_expanded } from '../../types'
import { OrderAddLineItemInput } from '../../types/input'

interface Data extends Omit<OrderLineItems_expanded, 'id' | 'order_id'> {
  id?: string
  order_id?: string
}

const initialValues: Data = {
  product_fk: '',
  size_fk: null,
  size_value_fk: null,
  box: 0,
  remark: '',
  pack_fk: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kgs: 0,
  gross_weight_kgs: 0,
  cost: 0,
  margins: 0,
  profit: 0,
  unit_price: 0,
  amount: 0,
  revise_key: 0,
  import_cost: 0,
  pack_cost_baht: 0,
  total_cost_usd_kg: 0,
  total_cost_usd: 0,
  weight_glazing: 0,
  glazing: '',
  raw_cost: 0,
  custom_import_costs: {
    import_cost: 0,
    usd_import: 0,
    expense_cost: 0,
  },
  custom_kg: {
    net_weight_kg: 0,
    gross_weight_kg: 0,
  },
}

export default function formatProductVariantToLineItem(
  data: OrderAddLineItemInput,
  revise_key?: number,
  type?: number,
  order_id?: string
) {
  let item: Data | null = initialValues

  switch (type) {
    case 1:
      item = {
        ...initialValues,
        order_id: order_id || '',
        product_fk: data.product_variant.product_fk,
        size_fk: data.product_variant.size_fk || null,
        size_value_fk: data.product_variant.size_value_fk || null,
        pack_fk: data.pack?.id || null,
        revise_key: revise_key ? revise_key : 0,
        weight_mc: data.weight_mc,
        gross_weight: data.gross_weight,
        box: data.box || 0,
        remark: '',
      }
      break

    case 2:
      item = {
        ...initialValues,
        order_id: order_id || '',
        product_fk: data.product_variant.product_fk,
        size_fk: data.product_variant.size_fk || null,
        size_value_fk: data.product_variant.size_value_fk || null,
        pack_fk: data.pack.id,
        product: data.product_variant.product,
        size: data.product_variant.size || null,
        size_value: data.product_variant.size_value || null,
        pack: data.pack,
        revise_key: revise_key ? revise_key : 0,
        weight_mc: data.weight_mc,
        gross_weight: data.gross_weight,
      }
      break
  }

  return item
}
