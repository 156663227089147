import { InvoicePDFSettingsProps } from 'src/pages/invoices/types'
import { empty_brackets } from 'src/utils/emptyBrackets'
import { toDecimalStr } from 'src/utils/toDecimal'

interface Props {
  type_no: number
  port: string | null
  terms_of_sale: string | null
  total_net_weight_kg?: number
  total_amount: number
  friehgt_charge: number
  settings: InvoicePDFSettingsProps
}

export function invoice_totalAmount(props: Props) {
  const { type_no, terms_of_sale, total_net_weight_kg, total_amount, port, friehgt_charge, settings } = props
  let result: Array<any> = []

  let total =
    terms_of_sale === 'fob'
      ? `TOTAL ${terms_of_sale.toUpperCase()} ${port?.toUpperCase()}`
      : `TOTAL ${terms_of_sale?.toUpperCase()}`

  let showFreightCharge = friehgt_charge > 0 && settings.showFreightCharge ? true : false
  let frieghtCharge = showFreightCharge
    ? [
        [
          ...empty_brackets(2, true),
          {
            style: 'tcCell',
            colSpan: 2,
            text: `FREIGHT CHARGE`,
          },
          {},
          {
            style: 'trCell',
            text: toDecimalStr(friehgt_charge),
          },
        ],
      ]
    : []

  switch (type_no) {
    case 1:
    case 3:
      result = [
        ...frieghtCharge,
        [
          ...empty_brackets(2, true),
          {
            style: 'tcCell',
            colSpan: 2,
            text: total,
          },
          {},
          {
            style: 'trCell',
            text: toDecimalStr(total_amount + friehgt_charge),
          },
        ],
      ]
      break
    case 2:
      result = [
        [
          {
            style: 'tcCell',
            colSpan: 2,
            text: `TOTAL`,
          },
          {},
          {
            style: 'trCell',
            text: `${toDecimalStr(total_net_weight_kg || 0)}`,
          },
          {
            style: 'tcCell',
            text: `CNF PRICE:`,
          },
          {
            style: 'trCell',
            text: toDecimalStr(total_amount),
          },
        ],
      ]
      break
  }
  return result
}
