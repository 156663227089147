import React, { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import VendorDetailForm from './VendorDetailForm'

const Title = ({ record }: any) => {
  return record.company_name
}

const VendorEdit: FC<EditProps> = (props) => {
  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Edit {...props} title={<Title />} mutationMode="pessimistic" component="div">
        <VendorDetailForm edit disabled redirect={false} />
      </Edit>
    </>
  )
}

export default VendorEdit
