import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import { DeleteButton, FormWithRedirect, SaveButton, Toolbar } from 'react-admin'
import PaymentDetailForm from './PaymentDetailForm'
import Separate from 'src/components/Separate'
import PaymentAmount from './PaymentAmount'
import CurrencyForm from 'src/components/CurrencyForm'
import SectionTitle from 'src/components/SectionTitle'
import { toDecimalStr } from 'src/utils/toDecimal'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

const PaymentDetailPage = (props: any) => {
  return (
    <FormWithRedirect
      {...props}
      redirect={props.redirect}
      render={(formProps: any) => (
        <Card>
          <CardContent>
            <Helmet title={`${WEB_APP_TITLE} - Payment #${props.record.customer_fk_code}`} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <PaymentDetailForm />
                <Separate value={3} />
                <PaymentAmount />
              </Grid>
              <Grid item xs={12} md={4}>
                <SectionTitle label="resources.payment.fieldGroups.currency" />
                <CurrencyForm source="rate" />
              </Grid>
            </Grid>
          </CardContent>
          <Toolbar>
            <Box display="flex" width="100%" justifyContent="space-between">
              <SaveButton disabled={formProps.pristine} handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} />
              <DeleteButton
                record={formProps.record}
                confirmTitle={`Payment #${formProps.record.customer_fk_code}: ${toDecimalStr(
                  formProps.record.amount_deposit
                )}`}
                mutationMode="pessimistic"
              />
            </Box>
          </Toolbar>
        </Card>
      )}
    />
  )
}

export default PaymentDetailPage
