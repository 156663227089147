import React from 'react'
import Typography from '@material-ui/core/Typography'
import { toDecimalStr } from 'src/utils/toDecimal'
import MTTable from 'src/components/MTTable'
import { MTableBodyRow } from 'material-table'
import { OrderLineItems_expanded } from '../types'

interface Props {
  loading?: boolean
  data: OrderLineItems_expanded[]
  onUpdate: (newData: any, oldData: any) => void
  onDelete: (oldData: any) => void
}

export default function OrderItemsDetail(props: Props) {
  const { loading, data, onUpdate, onDelete } = props

  return (
    <MTTable
      isLoading={loading}
      data={data || []}
      columns={[
        {
          title: 'Product',
          field: 'product.name',
          editable: 'never',
          render: (rowData: any) => (
            <div style={{ width: '300px' }}>
              <Typography variant="body2">
                {rowData.product.name}
                <Typography component="span" variant="caption">
                  {rowData.size_value ? ` - ${rowData.size_value.label} ${rowData.size?.label}` : ''}
                </Typography>
              </Typography>
            </div>
          ),
        },
        {
          title: 'Pack',
          field: 'pack.name',
          editable: 'never',
          render: (rowData: any) => <div style={{ width: '240px' }}>{rowData.pack?.name || ''}</div>,
        },
        {
          title: 'WET/MC (KG) & GROSS NET/MC (KG)',
          field: 'weight_mc',
          align: 'center',
          editable: 'never',
          render: (rowData: any) => (
            <div style={{ width: '180px' }}>
              {rowData.weight_mc} / {rowData.gross_weight}
            </div>
          ),
        },
        {
          title: 'TOTAL MC',
          field: 'box',
          type: 'numeric',
          render: (rowData: any) => <div>{toDecimalStr(rowData.box, true)}</div>,
        },
        {
          title: 'TOTAL NET/WET',
          field: 'net_weight_kgs',
          type: 'numeric',
          editable: 'never',
          render: (rowData: any) => (
            <div style={{ width: `150px` }}>{toDecimalStr(rowData.weight_mc * rowData.box)}</div>
          ),
        },
        {
          title: 'REMARK',
          field: 'remark',
          render: (rowData: any) => <div style={{ width: '150px' }}>{rowData.remark}</div>,
        },
      ]}
      components={{
        Row: (props) => (
          <MTableBodyRow
            {...props}
            onRowClick={(e: any) => {
              props.actions[0]().onClick(e, props.data)
            }}
          />
        ),
      }}
      editable={{
        onRowUpdate: (newData: any, oldData: any) =>
          new Promise((resolve, reject) => {
            if (oldData.id) {
              resolve(onUpdate(newData, oldData))
            }
          }),
        onRowDelete: (oldData: any) =>
          new Promise((resolve, reject) => {
            if (oldData.id) {
              resolve(onDelete(oldData))
            }
          }),
      }}
      style={{
        border: '1px solid #e3e3e3',
      }}
    />
  )
}
