import React, { FC, useMemo } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { InputProps, useGetList, useInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { Order_expanded } from '../types'
import useVendorsByUser from 'src/hooks/useVendorsByUser'
interface Data {
  id: string
  ref_pi: string
  customer_fk_code: string
  vendor_fk_code: string
}

interface Props extends InputProps {
  label?: string
}

const OrderReferenceInput: FC<Props> = (props) => {
  const { values } = useFormState()
  let currenctYear = new Date().getFullYear()
  const { data: vendorsByUser, isSuperuser } = useVendorsByUser()

  let year = values.date ? `${values.date.toString().slice(0, 4)}` : `${currenctYear}`

  const {
    input: { value, name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const filterVendor = isSuperuser ? undefined : { vendor_fk_code: vendorsByUser }

  const { data, ids, loading } = useGetList<Order_expanded>(
    'order',
    { page: 1, perPage: 10000 },
    { field: 'ref_pi', order: 'asc' },
    {
      ...filterVendor,
      status: ['processing_order', 'shipped_out', 'order_completed'],
      isDone: false,
      'pi_date@_gte': `${year}-01-01`,
    }
  )

  let options = useMemo(() => formatItems(ids.map((id) => data[id])), [ids, data])

  return (
    <Autocomplete
      id="order-autocomplete"
      loading={loading}
      value={value || null}
      options={options}
      onChange={(event, value) => {
        onChange(value)
      }}
      getOptionLabel={(option) => (option ? option.ref_pi : ``)}
      getOptionSelected={(option, values) => option.id === values.id}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={!!(touched && error)}
          helperText={touched && error}
          required={isRequired}
          label={props.label || 'Ref Invoice (PI)'}
          variant="filled"
          size="small"
        />
      )}
    />
  )
}

export default OrderReferenceInput

function formatItems(data: Order_expanded[]) {
  let items: Data[] = []
  if (data) {
    items = data.map((item) => ({
      id: item.id,
      ref_pi: item.ref_pi,
      pi_date: item.pi_date,
      customer_fk_code: item.customer_fk_code,
      vendor_fk_code: item.vendor_fk_code,
      customer: item.customer,
      vendor: item.vendor,
    }))
  }
  return items
}
