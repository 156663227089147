import React, { FC } from 'react'
import Typogrpahy from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

interface Props {
  title?: string
  subTitle?: string
  action?: any
}

const InsightCard: FC<Props> = ({ title, subTitle, action, children }) => {
  return (
    <div>
      {/* <CardHeader title={title || ''} subheader={subTitle} action={action} /> */}
      <Box display="flex" alignItems="flex-start">
        {title && (
          <Typogrpahy style={{ flex: 1 }} variant="h6">
            {title} <Typogrpahy variant="caption">{subTitle}</Typogrpahy>
          </Typogrpahy>
        )}
        {action}
      </Box>

      <Paper>
        <Box p={3} pt={3}>
          <div>{children}</div>
        </Box>
      </Paper>
    </div>
  )
}

export default InsightCard
