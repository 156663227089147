import React, { FC } from 'react'
import { useFormState } from 'react-final-form'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/CardContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import ExpenseReferenceInput from 'src/pages/expenses/ExpenseRerferenceInput'
import { OrderReviseExpenseRateInfo_field, OrderRevise_fields } from 'src/types/globalTypes'
import Separate from 'src/components/Separate'
import { useUpdate } from 'react-admin'
import { toDecimalStr } from 'src/utils/toDecimal'

// const useStyles = makeStyles()

interface Props {
  open: boolean
  record?: OrderRevise_fields
  customer_fk_code: string
  vendor_fk_code: string
  setOpen: (value: boolean) => void
  setRevise: (value: OrderRevise_fields) => void
  onClose?: () => void
  onCancel?: () => void
}

const PullingExpenseDialog: FC<Props> = ({
  open,
  record,
  vendor_fk_code,
  customer_fk_code,
  onClose,
  setOpen,
  setRevise,
}) => {
  const { values } = useFormState()
  let expense: OrderReviseExpenseRateInfo_field[] = values.select_expense
    ? values.select_expense.values.map((item: any) => ({ ...item }))
    : []

  let data = { expense_id: values.select_expense?.id, expense_rate_info: expense }

  const [handleSubmit] = useUpdate('order_revise', record?.id || '', data, record, {
    onSuccess: () => {
      if (record) {
        setRevise({ ...record, ...data })
        setOpen(false)
      }
    },
  })

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle>Pulling Default Values</DialogTitle>
      <DialogContent style={{ position: 'relative' }}>
        <ExpenseReferenceInput
          source="select_expense"
          vendor_fk_code={vendor_fk_code}
          customer_fk_code={customer_fk_code}
        />
        <Separate value={2} />

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Details of Expenses</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expense.length
              ? expense.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.label}</TableCell>
                    <TableCell align="right">{toDecimalStr(item.value)}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button style={{ marginRight: '10px' }} onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          style={{ marginRight: '20px' }}
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PullingExpenseDialog
