import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Separate from 'src/components/Separate'
import OveriewDaily from './OverviewDaily'
import OverviewByPeriod from './OverviewByPeriod'
import { DateRange } from 'materialui-daterange-picker'

interface Props {
  loading?: boolean
  dateRange?: DateRange
  value: number
  index: number
  target: number
  vendor: string
}

export default function OverviewTabPanel(props: Props) {
  const { value, index, target, vendor, dateRange } = props

  return (
    <TabPanel value={value} index={index}>
      <Separate value={3} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <OveriewDaily target={target} vendor={vendor} />
        </Grid>

        <Grid item xs={12} md={8}>
          <OverviewByPeriod dateRange={dateRange} vendor={vendor} />
        </Grid>
      </Grid>
    </TabPanel>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
