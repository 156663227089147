import React, { FC, useState } from 'react'
import Button from '@material-ui/core/Button'
import { DateRangePicker } from 'materialui-daterange-picker'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Popper, { PopperPlacementType } from '@material-ui/core/Popper'
import moment from 'moment'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { IDateRange } from 'src/types/globalTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popper: {
      zIndex: 1000,
    },
    root: {
      // width: '100%',
    },
    dateRange: {
      position: 'absolute',
      width: 700,
      right: 10,
      zIndex: 100,
    },
  })
)

interface Props {
  dateRange?: IDateRange
  setDateRange: (value: IDateRange) => void
  placement?: PopperPlacementType
}

const InsightDateRange: FC<Props> = ({ dateRange, setDateRange, placement }) => {
  const classes = useStyles()
  // const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  function toggle() {
    setAnchorEl(null)
  }

  const handleClickAway = () => {
    setAnchorEl(null)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <Button
          aria-describedby={id}
          type="button"
          onClick={handleClick}
          startIcon={<DateRangeIcon />}
          endIcon={<ArrowDropDownIcon />}
        >
          {dateRange?.label}: {moment(dateRange?.startDate).format('ll')} - {moment(dateRange?.endDate).format('ll')}
        </Button>
        <Popper
          placement={placement ? placement : 'bottom-start'}
          id={id}
          className={classes.popper}
          open={open}
          anchorEl={anchorEl}
        >
          <div>
            <DateRangePicker
              open={true}
              toggle={toggle}
              onChange={(range) => setDateRange(range)}
              definedRanges={definedRanges()}
            />
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default InsightDateRange

interface DefinedRange {
  label: string
  startDate: Date
  endDate: Date
}

function definedRanges() {
  let result: DefinedRange[] = [
    {
      label: 'Today',
      startDate: new Date(),
      endDate: new Date(),
    },
    {
      label: 'This month',
      startDate: moment(new Date()).startOf('month').toDate(),
      endDate: new Date(),
    },
    {
      label: '3 months',
      startDate: moment(new Date()).subtract(2, 'months').toDate(),
      endDate: new Date(),
    },
    {
      label: '6 months',
      startDate: moment(new Date()).subtract(5, 'months').toDate(),
      endDate: new Date(),
    },
    {
      label: 'This year',
      startDate: moment(new Date()).startOf('year').toDate(),
      endDate: new Date(),
    },
    {
      label: 'Last year',
      startDate: moment(new Date()).startOf('year').subtract(1, 'years').toDate(),
      endDate: moment(new Date()).endOf('year').subtract(1, 'years').toDate(),
    },
    {
      label: '2 years',
      startDate: moment(new Date()).subtract(2, 'years').toDate(),
      endDate: new Date(),
    },
  ]

  return result
}
