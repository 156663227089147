import { toDecimal } from 'src/utils/toDecimal'
import { InvoiceGroupInput, InvoiceLineItemInput_expanded } from '../../types/input'

const initialValues: InvoiceGroupInput = {
  invoice_id: '',
  product: '',
  size: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kgs: 0,
  gross_weight_kgs: 0,
  box: 0,
  amount: 0,
  unit_price: 0,
  slug: '',
}

export default function formatInvoiceArrayLineItemToGroup(
  data: InvoiceLineItemInput_expanded[],
  invoice_id?: string,
  unit_code?: string
): InvoiceGroupInput {
  let item: InvoiceGroupInput = initialValues

  if (data.length) {
    let totalValues = data
      .map((item) => {
        let net_weight_kg = toDecimal(item.box * item.weight_mc)
        let amount = unit_code === 'mc' ? toDecimal(item.price * item.box) : toDecimal(item.price * net_weight_kg)

        return {
          total_ctn: item.box,
          total_weight_mc: item.weight_mc,
          total_gross_weight: item.gross_weight,
          total_price: item.price,
          total_amount: toDecimal(amount),
          total_net_weight_kg: toDecimal(item.box * item.weight_mc),
          total_gross_weight_kg: toDecimal(item.box * item.gross_weight),
        }
      })
      .reduce((a, b) => {
        return {
          total_ctn: a.total_ctn + b.total_ctn,
          total_weight_mc: a.total_weight_mc + b.total_weight_mc,
          total_price: a.total_price + b.total_price,
          total_amount: a.total_amount + b.total_amount,
          total_gross_weight: a.total_gross_weight + b.total_gross_weight,
          total_net_weight_kg: a.total_net_weight_kg + b.total_net_weight_kg,
          total_gross_weight_kg: a.total_gross_weight_kg + b.total_gross_weight_kg,
        }
      })

    item = {
      ...initialValues,
      invoice_id: invoice_id || '',
      product: data[0].product?.name || '',
      size: data[0].size_value ? `${data[0].size_value.label} ${data[0].size?.label || ''}` : '',
      box: totalValues.total_ctn,
      unit_price: data[0].price || 0,
      amount: totalValues.total_amount,
      weight_mc: totalValues.total_weight_mc,
      gross_weight: totalValues.total_gross_weight,
      net_weight_kgs: totalValues.total_net_weight_kg,
      gross_weight_kgs: totalValues.total_gross_weight_kg,
      slug: generateChars(),
    }
  }

  return item
}

function generateChars() {
  let r = Math.random().toString(36).substring(7)
  return r
}
