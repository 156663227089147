import React, { FC } from 'react'
import { Edit, EditProps, useNotify, useRedirect } from 'react-admin'
import CustomerForm from './CustomerForm'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import CustomerFullNameField from './CustomerFullNameField'
import usePermissionByUser from 'src/hooks/usePermissionByUser'

const CustomerEdit: FC<EditProps> = (props) => {
  const { permission, loaded } = usePermissionByUser('customer')
  const redirect = useRedirect()
  const notify = useNotify()

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Edit title={<CustomerFullNameField />} mutationMode="pessimistic" component="div" {...props}>
        <CustomerForm permission={permission} redirect={false} />
      </Edit>
    </>
  )
}

export default CustomerEdit
