import React, { FC } from 'react'
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { InputProps, useInput } from 'react-admin'
import { FilledInput } from '@material-ui/core'
import { STATUS_CHOICES } from '../constants/status'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: 4,
      minWidth: 120,
      maxWidth: 210,
    },

    label: {
      marginLeft: 8,
      marginTop: 5,
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
  })
)

const ITEM_HEIGHT = 100
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

const OrderStatusArrayInput: FC<InputProps> = (props) => {
  const classes = useStyles()
  const theme = useTheme()

  const {
    input: { value, name, ...rest },
  } = useInput(props)

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.label} id="status-multiple-select-label">
          Status
        </InputLabel>
        <Select
          margin="dense"
          name={name}
          fullWidth
          labelId="status-multiple-select-label"
          id="status-multiple-select"
          multiple
          value={value || []}
          input={<FilledInput margin="dense" />}
          renderValue={(selected) => (selected as string[]).join(',').toUpperCase()}
          MenuProps={MenuProps}
          {...rest}
        >
          {STATUS_CHOICES.map((item) => (
            <MenuItem key={item.id} value={item.id} style={getStyles(name, value, theme)}>
              <span className={classes.color} style={{ backgroundColor: item.color }} />
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default OrderStatusArrayInput
