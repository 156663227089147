import React, { FC } from 'react'
import { InputProps, useInput } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import usePacks from 'src/hooks/usePacks'

interface Props extends InputProps {
  label?: string
  disabled?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
}

const PackReferenceInput: FC<Props> = (props) => {
  const { data: options } = usePacks()

  const {
    input: { value, name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  return (
    <Autocomplete
      style={props.style}
      id="pack-autocomplete"
      value={value || null}
      disabled={props.disabled}
      options={options}
      onChange={(event, value) => {
        onChange(value)
      }}
      getOptionLabel={(option) => (option ? option.name : ``)}
      getOptionSelected={(option, values) => option.id === values.id}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={!!(touched && error)}
          helperText={touched && error}
          required={isRequired}
          label={props.label ? props.label : 'Pack'}
          variant={props.variant ? props.variant : 'filled'}
          size="small"
        />
      )}
    />
  )
}

export default PackReferenceInput
