import React from 'react'
import Grid from '@material-ui/core/Grid'
import VendorReferenceInput from 'src/pages/vendors/VendorReferenceInput'
import CustomerReferenceInput from 'src/pages/customers/CustomerReferenceInput'
import { DateInput, required, TextInput } from 'react-admin'

const OrderGeneralInformation = (props: any) => {
  const { disabled } = props
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <VendorReferenceInput
          fullWidth
          source="vendor_fk_code"
          label="Vendor"
          validate={required()}
          helperText={false}
          disabled={props.hasEdited}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomerReferenceInput
          fullWidth
          source="customer_fk_code"
          label="Customer"
          validate={required()}
          helperText={false}
          disabled={props.hasEdited}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextInput disabled={disabled} helperText={false} source="ref_excel" fullWidth />
      </Grid>

      <Grid item xs={12} md={6}>
        <DateInput disabled={disabled} helperText={false} fullWidth source="pi_date" label="Date (mm/dd/yyyy)" />
      </Grid>
      <Grid item xs={12}>
        <TextInput disabled={disabled} helperText={false} fullWidth source="description" label="Product sample" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput disabled={disabled} helperText={false} fullWidth source="remark" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput disabled={disabled} helperText={false} fullWidth source="sticker" label="Sticker (Product of)" />
      </Grid>
    </Grid>
  )
}

export default OrderGeneralInformation
