import UnitCodeList from './UnitCodeList'
import UnitCodeCreate from './UnitCodeCreate'
import UnitCodeEdit from './UnitCodeEdit'

const unitCodes = {
  list: UnitCodeList,
  create: UnitCodeCreate,
  edit: UnitCodeEdit,
}

export default unitCodes
