import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { FormWithRedirect, ReferenceInput, SelectInput, TextInput, Toolbar, useRedirect } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import Separate from 'src/components/Separate'
import ProductVariantList from './ProductVariantList'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import DeleteWithLogButton from 'src/components/DeleteWithLogButton'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
// import ProductHistory from './ProductHistory'

export default function ProductDetailForm(props: any) {
  const redirectTo = useRedirect()
  const canEdit = props.permission.update ? false : true

  function handleVariantCreate() {
    redirectTo(`/product/${props.record.id}/product_variant/create`)
  }

  return (
    <FormWithRedirect
      {...props}
      redirect={props.redirect}
      render={(formProps: any) => (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Helmet title={`${WEB_APP_TITLE} - product #${props.record.name}`} />
            <Card>
              <CardContent>
                <SectionTitle label="resources.global.fieldGroups.information" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextInput disabled={canEdit} helperText={false} fullWidth source="name" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput disabled={canEdit} helperText={false} fullWidth source="short_name" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput disabled={canEdit} helperText={false} fullWidth source="full_name" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      disabled={canEdit}
                      helperText={false}
                      fullWidth
                      source="other_names.scientific_name1"
                      label="Scientific name 1"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      disabled={canEdit}
                      helperText={false}
                      fullWidth
                      source="other_names.scientific_name2"
                      label="Scientific name 2(MEX)"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      disabled={canEdit}
                      helperText={false}
                      fullWidth
                      source="other_names.scientific_name3"
                      label="Scientific name 3(USA)"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput disabled={canEdit} helperText={false} fullWidth source="description" />
                  </Grid>
                </Grid>

                <Separate value={5} />
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <SectionTitle label="resources.product.fieldGroups.variants" />
                  <Button variant="text" color="primary" size="small" onClick={handleVariantCreate} disabled={canEdit}>
                    Manage
                  </Button>
                </Box>
                <ProductVariantList record={formProps.record} />
              </CardContent>
              <Toolbar>
                <Box display="flex" width="100%" justifyContent="space-between">
                  <SaveWithLogButton
                    resource="product"
                    source="name"
                    action_type="UPDATE"
                    saving={formProps.saving}
                    disabled={formProps.pristine || canEdit}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />

                  <DeleteWithLogButton
                    resource="product"
                    confirmTitle={`Delete product #${formProps.record.name}`}
                    record={formProps.record}
                    mutationMode="pessimistic"
                    disabled={!props.permission.delete}
                  />
                </Box>
              </Toolbar>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <SectionTitle label="resources.product.fieldGroups.organize_product" />
                <ReferenceInput
                  label="Product Type"
                  source="product_type_fk_id"
                  reference="product_type"
                  sort={{ field: 'name', order: 'ASC' }}
                  allowEmpty
                >
                  <SelectInput fullWidth optionText="name" disabled={canEdit} />
                </ReferenceInput>
                <Separate />
                <ReferenceInput
                  label="Category"
                  source="category_fk_id"
                  reference="category"
                  sort={{ field: 'name', order: 'ASC' }}
                  allowEmpty
                  helperText={false}
                >
                  <SelectInput fullWidth optionText="name" disabled={canEdit} />
                </ReferenceInput>
              </CardContent>
            </Card>

            <Separate value={3} />
            {/* <ProductHistory /> */}
          </Grid>
        </Grid>
      )}
    />
  )
}
