import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import SectionTitle from 'src/components/SectionTitle'
import { DateInput, NumberInput, ReferenceInput, SelectInput, TextInput } from 'react-admin'
import Separate from 'src/components/Separate'

export default function InvoiceSettings() {
  return (
    <>
      <Box p={2} width="40em">
        <SectionTitle label="resources.order.fieldGroups.unit" />
        <ReferenceInput
          style={{ width: '350px' }}
          label="Code"
          source="unit_code_fk_id"
          reference="unit_code"
          sort={{ field: 'code', order: 'ASC' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <Separate value={3} />
        <SectionTitle label="resources.invoice.fieldGroups.shipping_details" />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <DateInput source="config_shipping.etd_date" label="ETD date" fullWidth helperText={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput source="config_shipping.eta_date" label="ETA date" fullWidth helperText={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput source="config_shipping.loading_date" label="Loading date" fullWidth helperText={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberInput
              source="config_shipping.security_deposit"
              label="Security Deposit (%)"
              fullWidth
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberInput source="config_shipping.advance" label="Advance" fullWidth helperText={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberInput source="freight_charge" label="Freight charge" fullWidth helperText={false} />
          </Grid>
        </Grid>

        <Separate value={3} />
        <SectionTitle label="resources.invoice.fieldGroups.dhl_details" />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextInput source="config.dhl_no" label="DHL no." fullWidth helperText={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput source="config.dhl_date" label="DHL date" fullWidth helperText={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput source="config.remark" label="Remark" fullWidth />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
