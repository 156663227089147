import React, { FC } from 'react'
import { Edit, EditProps, useNotify, useRedirect } from 'react-admin'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import usePermissionByUser from 'src/hooks/usePermissionByUser'
import ProductDetailForm from './ProductDetailForm'

const Title = ({ record }: any) => {
  return record.name
}

const ProductEdit: FC<EditProps> = (props) => {
  const { permission, loaded } = usePermissionByUser('product')
  const redirect = useRedirect()
  const notify = useNotify()

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }
  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Edit {...props} mutationMode="pessimistic" title={<Title />} component="div">
        <ProductDetailForm permission={permission} redirect={false} />
      </Edit>
    </>
  )
}

export default ProductEdit
