import React from 'react'
import Dialog from 'src/components/Dialog'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

interface Props {
  open: boolean
  value: string
  onChange: (value: string) => void
  onClose?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export default function CalendarEventDialog(props: Props) {
  const { open, onChange, onSave, onCancel, onClose, value } = props

  return (
    <Dialog title="Create an event" open={open} onSave={onSave} onClose={onClose} onCancel={onCancel}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus={true}
            fullWidth
            variant="filled"
            label="Title"
            name="title"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
