import React from 'react'
import { useListContext } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import NewTimelineComment from './NewTimelineComment'
import { Comment } from './Comment'
import SectionTitle from 'src/components/SectionTitle'

const useStyles = makeStyles({
  root: {
    marginTop: '0.5em',
  },
})

export const TimelineIterator = ({
  reference,
  disabled,
}: {
  disabled?: boolean
  reference: 'order' | 'invoice_header' | 'invoice' | 'vendor' | 'customer'
}) => {
  const classes = useStyles()
  const { data, ids, loaded } = useListContext()
  if (!loaded) return null

  return (
    <>
      <SectionTitle label="resources.invoice_header.fieldGroups.notes" />
      <NewTimelineComment disabled={disabled} reference={reference} />
      <div className={classes.root}>
        {ids.map((id, index) => (
          <Comment note={data[id]} reference={reference} key={index} />
        ))}
      </div>
    </>
  )
}
