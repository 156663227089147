import { useMemo, useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import { Currency_fields } from 'src/types/globalTypes'

export default function useCurrencies() {
  const [result, setResult] = useState<Currency_fields[]>([])

  const { data, loading, error } = useQueryWithStore({
    type: 'getList',
    resource: 'currency',
    payload: {
      sort: { field: 'code', order: 'ASC' },
    },
  })

  let currencies = useMemo(() => {
    if (data) {
      setResult(data)
    }
    return result
  }, [data, result])

  return {
    data: currencies,
    loading,
    error,
  }
}
