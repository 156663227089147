import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { OrderProductSample_fields, OrderRevise_fields } from 'src/types/globalTypes'
import { OrderLineItems_expanded, Order_expanded } from '../../types'
import order_template2 from './template2'
import order_template3 from './template3'

const pdf = pdfMake
pdf.vfs = pdfFonts.pdfMake.vfs

interface Props {
  template: number
  data: Order_expanded
  lineItems: OrderLineItems_expanded[]
  productSamples: OrderProductSample_fields[]
  customerPort?: string
  revise?: OrderRevise_fields
  hasAccess?: boolean
}

export default function exportPDF(props: Props) {
  let gen = null
  switch (props.template) {
    case 2:
      let temp2 = order_template2(props) as any
      gen = pdf.createPdf(temp2).open()
      break
    case 3:
      let temp3 = order_template3(props) as any
      gen = pdf.createPdf(temp3).open()
      break

    default:
      gen = null
      break
  }
  return gen
}
