import { gql } from '@apollo/client'

export const GET_ORDER_LINE_ITEM = gql`
  {
    id
    product_fk
    size_fk
    size_value_fk
    pack_fk
    product {
      id
      name
    }
    size {
      id
      label
    }
    size_value {
      id
      label
    }
    pack {
      id
      name
    }
    order {
      id
      pi_date
      ref_pi
      vendor_fk_code
      customer_fk_code
    }
    amount
    cost
    glazing
    import_cost
    pack_cost_baht
    profit
    raw_cost
    total_cost_usd
    total_cost_usd_kg
    unit_price
    weight_glazing
    margins
    custom_kg
    custom_import_costs
    net_weight_kgs
    weight_mc
    gross_weight
    revise_key
    box
    remark
    custom_import_costs
    custom_kg
  }
`
