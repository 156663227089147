/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import OrderCreate from './views/OrderCreate'
import OrderEdit from './views/OrderEdit'
import OrderList from './views/OrderList'

export default {
  list: OrderList,
  create: OrderCreate,
  edit: OrderEdit,
}
