import React from 'react'
import Button from '@material-ui/core/Button'
import { useRecordContext, useUpdate, useCreate, useNotify, useRedirect } from 'react-admin'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { InvoiceHeader_expanded } from '../invoiceHeaders/types'
import useFetchOrderLineItemsByOrder from '../orders/hooks/useFetchOrderLineItemsByOrder'
import { formatDateToAPI } from 'src/utils/formatDate'
import formatArrayLineItemOrderToInvoice from './utils/formats/formatArrayLineItemOrderToInvoice'
import useFetchOrderSamplesByOrder from '../orders/hooks/useFetchOrderSamplesByOrder'
import formatArraySampleOrderToInvoice from './utils/formats/formatArraySampleOrderToInvoice'
import useCreateUserLog from 'src/hooks/useCreateUserLog'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: theme.spacing(1),
  },
}))

interface Props {
  type: number
  comparator: number
  loading?: boolean
  disabled?: boolean
}

export default function InvoiceQuickCreateButton(props: Props) {
  const classes = useStyles()
  const record = useRecordContext<InvoiceHeader_expanded>()
  const [update] = useUpdate()
  const [create] = useCreate()
  const redirect = useRedirect()
  const notify = useNotify()
  const { createLog } = useCreateUserLog()

  const { data: lineItems } = useFetchOrderLineItemsByOrder({
    order_id: record.order_id,
    revise_key: record.order && record.order.current_revise_num,
  })

  const { data: samples } = useFetchOrderSamplesByOrder({ order_id: record.order_id })

  let changes = {}
  switch (props.type) {
    case 1: {
      changes = { use_header1: 1 }
      break
    }
    case 2: {
      changes = { use_header2: 1 }
      break
    }
    case 3: {
      changes = { use_header3: 1 }
      break
    }
  }

  if (!record) return null

  function handleCreate() {
    if (record) {
      update('invoice_header', record.id, changes, record)
      create(
        'invoice',
        {
          invoice_header_id: record.id,
          description: record.description,
          type_no: props.type,
          information: record.information,
          bank_fk_code: record.bank_fk_code,
          total_net_weight: record.order.total_net_weight,
          terms_of_sale: record.terms_of_sale,
          port: record.port,
          invoice_line_items: {
            data: formatArrayLineItemOrderToInvoice({ data: lineItems, type_no: props.type }),
          },
          invoice_product_samples: {
            data: formatArraySampleOrderToInvoice({ data: samples }),
          },
          currency_fk: record.order?.currency_fk,
          config_shipping: {
            dhl_no: '',
            remark: '',
            advance: 0,
            dhl_date: formatDateToAPI(new Date()),
            eta_date: formatDateToAPI(new Date()),
            etd_date: formatDateToAPI(new Date()),
            loading_date: formatDateToAPI(new Date()),
            security_deposit: 0,
          },
          unit_code_fk_id: record.order?.unit_code_fk_id,
        },
        {
          onSuccess: ({ data }) => {
            notify('resources.global.notifications.success.create', { type: 'info' })
            createLog(`invoice`, 'CREATE', `New invoice-${props.type} #${record.ref_invoice} `)
            redirect(`/invoice/type-${props.type}/${data.id}`)
          },
          onFailure: (error) => notify(`Error: ${error.message}`, { type: 'warning' }),
        }
      )
    }
  }

  return (
    <Button
      color="primary"
      variant="outlined"
      className={classes.root}
      size="small"
      disabled={canCreate(props.comparator) || props.loading || props.disabled}
      onClick={handleCreate}
    >
      Type {props.type}
    </Button>
  )
}

function canCreate(comparator: number) {
  return comparator === 1 ? true : false
}
