import React, { FC } from 'react'
import { Edit, EditProps, useNotify, useRedirect } from 'react-admin'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import usePermissionByUser from 'src/hooks/usePermissionByUser'
import InvoiceHeaderDetailPage from './InvoiceHeaderDetailPage'
import InvoiceHeaderRefField from './InvoiceHeaderRefField'

const InvoiceHeaderEdit: FC<EditProps> = (props) => {
  const { permission, loaded } = usePermissionByUser('invoice_header')
  const redirect = useRedirect()
  const notify = useNotify()

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }
  const transform = (data: any) => ({ ...data, invoice_prefix_fk_code: data.invoice_prefix?.format || null })
  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Edit title={<InvoiceHeaderRefField />} mutationMode="pessimistic" transform={transform} {...props}>
        <InvoiceHeaderDetailPage permission={permission} redirect={false} />
      </Edit>
    </>
  )
}

export default InvoiceHeaderEdit
