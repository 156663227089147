import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { FieldProps, useGetList } from 'react-admin'
import { AddressBook_fields, AppState, Customer_fields } from 'src/types/globalTypes'
import SectionTitle from 'src/components/SectionTitle'
import { useHistory } from 'react-router-dom'

interface Props extends FieldProps<Customer_fields> {
  disabled?: boolean
}

const CustomerPrimaryAddress: FC<Props> = ({ record, disabled }) => {
  const history = useHistory()
  const { data, ids } = useGetList<AddressBook_fields>(
    'address_book',
    { page: 1, perPage: 10 },
    { field: 'customer_fk_code', order: 'ASC' },
    { customer_fk_code: record && record.code, isDefault: true }
  )

  function handleManage() {
    history.push(`/customer/${record?.id}/addresses`)
  }

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <SectionTitle label="resources.customer.title.primary_address" />
          <Button size="small" variant="outlined" color="primary" onClick={handleManage} disabled={disabled}>
            Manage
          </Button>
        </Box>
        {ids.map((id) => (
          <>
            <Typography variant="body2">{data ? data[id].address_1 : ''}</Typography>
            <Typography variant="body2">{data ? data[id].address_2 : ''}</Typography>
            <Typography variant="body2">{data ? `Tel: ${data[id].tel}` : ''}</Typography>
            <Typography variant="body2">{data ? `Fax: ${data[id].fax}` : ''}</Typography>
            <Typography variant="body2">{data ? `Mobile: ${data[id].phone}` : ''}</Typography>
          </>
        ))}
      </CardContent>
    </Card>
  )
}

export default CustomerPrimaryAddress
