import moment from 'moment'

interface Props {
  code: string
  customer: string
  totalPrefix: number
  totalCustomer: number
  date: string | Date
}

const formatNewInvoicePrefix = (props: Props) => {
  const { code, customer, date, totalCustomer, totalPrefix } = props
  let year = moment(date).format('YY')

  let format = ''
  if (code && customer) {
    format = `${code} ${(totalPrefix + 1).toString().padStart(3, '0')}/${(totalCustomer + 1)
      .toString()
      .padStart(3, '0')}/${year}`
  }

  return format
}

export default formatNewInvoicePrefix
