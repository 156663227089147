import React from 'react'
import Dialog from 'src/components/Dialog'
import Grid from '@material-ui/core/Grid'

interface Props {
  title?: string
  open: boolean
  onClose?: () => void
  onSave?: () => void
  onCancel?: () => void
}

export default function CalendarEventDeleteDialog(props: Props) {
  const { open, title, onSave, onCancel, onClose } = props

  return (
    <Dialog title={title || ''} open={open} onSave={onSave} onClose={onClose} onCancel={onCancel}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Are you sure you want to delete this event?
        </Grid>
      </Grid>
    </Dialog>
  )
}
