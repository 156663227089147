import React, { useCallback } from 'react'
import { SaveButton, SaveButtonProps, useNotify, useRedirect, useRefresh, useUpdate } from 'react-admin'
import useCreateUserLog from 'src/hooks/useCreateUserLog'

interface Props extends SaveButtonProps {
  refresh?: boolean
  action_type?: 'CREATE' | 'READ' | 'UPDATE' | 'DELETE' | 'HEADER'
  message?: string
  source?: any
  target?: string
  updateParent?: {
    resource: string
    source: string
    data: any
  }
  onUpdate?: () => void
}

const SaveWithLogButton = (props: Props) => {
  const { resource, action_type, source, target, record, refresh, message, onUpdate, updateParent } = props
  const { createLog } = useCreateUserLog()
  const [updateItem] = useUpdate()
  const notify = useNotify()
  const redirectTo = useRedirect()
  const doRefresh = useRefresh()

  const onSuccess = useCallback(
    ({ data }: any) => {
      let msg = ''
      let notifyText = ''

      if (data) {
        if (action_type) {
          switch (action_type) {
            case 'CREATE':
              msg = `New ${resource} #${data[source]}`
              notifyText = 'resources.global.notifications.success.create'
              break
          }
          switch (action_type) {
            case 'UPDATE':
              msg = `Update ${resource} #${data[source]}`
              notifyText = 'resources.global.notifications.success.update'
              break
          }
          switch (action_type) {
            case 'HEADER':
              msg = `Update from ${record && record[source]} to #${data[source]}`
              notifyText = 'resources.global.notifications.success.update'
              break
          }
        }
      }

      notify(notifyText, { type: 'info' })
      if (action_type === 'CREATE') {
        redirectTo('edit', `/${resource}`, target ? data[target] : data.id)
      }

      if (refresh) {
        doRefresh()
      }

      if (onUpdate) {
        onUpdate()
      }
      // Fix update all the resource undefined
      if (updateParent && data[updateParent.source]) {
        updateItem(updateParent.resource, data[updateParent.source], updateParent.data)
      }

      createLog(resource, action_type, message ? message : msg)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createLog, updateParent, updateItem, source, action_type, resource, record, message, target, refresh]
  )

  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSuccess={onSuccess} />
}

export default SaveWithLogButton
