import { OrderLineItems_fields } from 'src/types/globalTypes'
import { toDecimal } from 'src/utils/toDecimal'
import { OrderLineItems_expanded } from '../types'
import { OrderReviseCreateInput } from '../types/input'
import getOrderLineUnitCode from './getOrderLineUnitCost'
import getOrderShippingValues from './getOrderShippingValues'

export interface Props {
  unit_code: string
  lineItems: OrderLineItems_expanded[]
  revise?: OrderReviseCreateInput
  exchange_rate: number
  total_sample_ctn?: number
  total_sample_net_weight_kg?: number
}

export default function getOrderSumValues(props: Props) {
  const { unit_code, lineItems, revise, exchange_rate, total_sample_ctn, total_sample_net_weight_kg } = props

  let total_net_weight_kg = 0
  let total_ctn = 0
  let total_product_samples_net_wet_kg = 0

  if (lineItems.length) {
    total_net_weight_kg = lineItems
      .map((item: OrderLineItems_expanded) => toDecimal(item.weight_mc * item.box))
      .reduce((a: number, b: number) => a + b)

    total_ctn = lineItems
      .map((item: OrderLineItems_expanded) => toDecimal(item.box))
      .reduce((a: number, b: number) => a + b)

    total_product_samples_net_wet_kg = lineItems.map((item) => item.custom_kg.net_weight_kg).reduce((a, b) => a + b)
  }

  const { shipping_cost, ...shippingValues } = getOrderShippingValues({
    unit_code,
    expense_rate_info: revise?.expense_rate_info || [],
    shipping_usd: revise?.exchange_rate_info.shipping_usd || 0,
    total_ctn: total_ctn + (total_sample_ctn || 0),
    total_net_weight_kg: total_net_weight_kg + (total_sample_net_weight_kg || 0),
  })

  const { ...orderTotalValues } = getOrderTotalValues({
    unit_code,
    lineItems,
    etc_cost: revise?.exchange_rate_info.etc_cost || 0,
    exchange_rate: exchange_rate,
    shipping_cost,
  })

  return {
    shipping_cost,
    ...shippingValues,
    ...orderTotalValues,
    total_product_samples_net_wet_kg,
  }
}

interface TotalValues {
  unit_code: string
  lineItems: Omit<OrderLineItems_fields, 'id' | 'order_id'>[]
  exchange_rate: number
  etc_cost: number
  shipping_cost: number
}

function getOrderTotalValues(props: TotalValues) {
  const { lineItems, unit_code, exchange_rate, etc_cost, shipping_cost } = props

  let values: VALUES = {
    total_ctn: 0,
    total_unit_cost: 0,
    total_cost_usd: 0,
    total_unit_price: 0,
    total_amount: 0,
    total_profit: 0,
    total_net_weight_kg: 0,
    total_gross_weight_kg: 0,
    total_gross_profit: 0,
  }

  if (lineItems) {
    let items = lineItems
      .filter((item) => item.box > 0)
      .map((item) => {
        let net_weight_kg = toDecimal(item.weight_mc * item.box)
        let unit_cost = getOrderLineUnitCode({
          import_cost: item.import_cost,
          pack_cost_baht: item.pack_cost_baht,
          etc_cost,
          shipping_cost,
          usd_export: exchange_rate,
        })

        let total_cost_usd = unit_code === 'mc' ? toDecimal(item.box * unit_cost) : toDecimal(net_weight_kg * unit_cost)
        let total_amount =
          unit_code === 'mc' ? toDecimal(item.box * item.unit_price) : toDecimal(net_weight_kg * item.unit_price)
        let total_gross_profit = toDecimal(total_amount - total_cost_usd || 0)

        return {
          total_ctn: item.box,
          total_unit_cost: toDecimal(unit_cost),
          total_cost_usd,
          total_unit_price: toDecimal(item.unit_price),
          total_amount,
          total_profit: toDecimal(((item.unit_price - unit_cost) / unit_cost) * 100),
          total_net_weight_kg: toDecimal(item.box * item.weight_mc),
          total_gross_weight_kg: toDecimal(item.gross_weight * item.box),
          total_gross_profit,
        }
      })

    if (items.length) {
      values = items.reduce((a, b) => ({
        total_ctn: a.total_ctn + b.total_ctn,
        total_unit_cost: a.total_unit_cost + b.total_unit_cost,
        total_cost_usd: a.total_cost_usd + b.total_cost_usd,
        total_unit_price: a.total_unit_price + b.total_unit_price,
        total_amount: a.total_amount + b.total_amount,
        total_profit: a.total_profit + b.total_profit,
        total_net_weight_kg: a.total_net_weight_kg + b.total_net_weight_kg,
        total_gross_weight_kg: a.total_gross_weight_kg + b.total_gross_weight_kg,
        total_gross_profit: a.total_gross_profit + b.total_gross_profit,
      }))
    }
  }

  return { ...values }
}

interface VALUES {
  total_ctn: number
  total_unit_cost: number
  total_cost_usd: number
  total_profit: number
  total_unit_price: number
  total_amount: number
  total_net_weight_kg: number
  total_gross_weight_kg: number
  total_gross_profit: number
}
