import ProductTypeList from './ProductTypeList'
import ProducTypeCreate from './ProducTypeCreate'
import ProductTypeEdit from './ProducTypeEdit'

const productTypes = {
  list: ProductTypeList,
  create: ProducTypeCreate,
  edit: ProductTypeEdit,
}

export default productTypes
