import moment from 'moment'
import { Invoice_expanded } from 'src/pages/invoices/types'

interface Data {
  title: string
  invoices: { [key: string]: Invoice_expanded[] }
}

export const invoicesByPeriod = (
  invoices: Invoice_expanded[],
  weekly: string[],
  period: moment.unitOfTime.StartOf = 'week',
  split?: boolean
): Data[] => {
  let result: Data[] = []

  if (split === true) {
    result = [
      ...invoices
        .reduce((acc, invoice) => {
          const key = `${invoice.invoice_header?.order.vendor_fk_code}-${invoice.invoice_header?.order.customer_fk_code}`
          const month = moment(invoice.date).startOf(period).format('YYYY-MM-DD')
          const item = acc.get(key) || Object.assign({}, { title: key, invoices: {} })
          if (!item.invoices[month]) {
            item.invoices[month] = []
          }
          item.invoices[month].push(invoice)
          return acc.set(key, item)
        }, new Map())
        .values(),
    ]
  } else if (split === false) {
    result = [
      ...invoices
        .reduce((acc, invoice) => {
          const key = ``
          const month = moment(invoice.date).startOf(period).format('YYYY-MM-DD')
          const item = acc.get(key) || Object.assign({}, { title: key, invoices: {} })
          if (!item.invoices[month]) {
            item.invoices[month] = []
          }
          item.invoices[month].push(invoice)
          return acc.set(key, item)
        }, new Map())
        .values(),
    ]
  }

  weekly.reduce((acc, curr) => {
    if (result.length) {
      Object.values(result).forEach((item: any) => {
        if (!item.invoices[curr]) {
          item.invoices[curr] = []
        }
      })
    }

    return acc
  }, {})

  return result
}
