import React, { FC } from 'react'
import { Create, CreateProps, required, SimpleForm, TextInput } from 'react-admin'
import SectionTitle from '../../components/SectionTitle'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from '@material-ui/styles/withStyles'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import CountryReferenceInput from '../countries/CountryReferenceInput'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

const styles: Styles<Theme, any> = {
  field: { maxWidth: 544 },
  fieldGroups: { display: 'inline-block', marginRight: 32 },
}

const useStyles = makeStyles(styles)

const CategoryCreate: FC<CreateProps> = (props) => {
  const classes = useStyles(props)

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New destination port`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props}>
        <SimpleForm>
          <SectionTitle label="resources.global.fieldGroups.information" />
          <TextInput source="name" validate={required()} fullWidth className={classes.field} />
          <TextInput source="city" formClassName={classes.fieldGroups} />
          <CountryReferenceInput label="Country" formClassName={classes.fieldGroups} source="country_fk_code" />
          <TextInput source="description" fullWidth className={classes.field} />
        </SimpleForm>
      </Create>
    </>
  )
}

export default CategoryCreate
