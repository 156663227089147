import { gql } from '@apollo/client'

export const GET_LIST_SIZES = gql`
  {
    id
    label
    code
    size_values_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GET_SIZE = gql`
  {
    id
    label
    code
    description
  }
`
