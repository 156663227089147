import React, { FC, useState } from 'react'
import classnames from 'classnames'
import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterProps,
  List,
  ListProps,
  NumberField,
  SearchInput,
  TextField,
  useNotify,
  useRedirect,
} from 'react-admin'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import RouteBreadcrumbs from '../../../components/RouteBreadcrumbs'
import OrderPanel from '../components/OrderPanel'
import OrderStatusField from '../fields/OrderStatusField'
import OrderQuickShow from '../components/OrderQuickShow'
import OrderReferenceField from '../fields/OrderReferenceField'
import CustomerReferenceInput from 'src/pages/customers/CustomerReferenceInput'
import VendorReferenceInput from 'src/pages/vendors/VendorReferenceInput'
import OrderStatusArrayInput from '../components/OrderStatusArrayInput'
// import OrderExpenseDetectField from '../components/OrderExpenseDetectField'
import useVendorsByUser from 'src/hooks/useVendorsByUser'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'
import CustomerPortReferenceField from 'src/pages/customerPorts/CustomerPortReferenceField'
import OrderTotalNetWetKGField from '../components/OrderTotalNetWetKGField'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
  },
}))

const OrderFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput source="ref_pi@_ilike" alwaysOn />
    <DateInput source="pi_date@_gte" alwaysOn />
    <DateInput source="pi_date@_lte" />
    <VendorReferenceInput source="vendor_fk_code" label="Vendor" alwaysOn />
    <CustomerReferenceInput source="customer_fk_code" label="Customer" alwaysOn />
    <OrderStatusArrayInput source="status" alwaysOn />
  </Filter>
)

const OrderList: FC<ListProps> = (props) => {
  const { permission, loaded } = usePermissionByUser('order')
  const redirect = useRedirect()
  const notify = useNotify()
  let year = new Date().getFullYear()
  const classes = useStyles()
  const [selectedId, setSelected] = useState('')
  const [open, setOpen] = useState(false)
  const { data: vendorsByUser, isSuperuser } = useVendorsByUser()
  let vendor_filter = isSuperuser ? undefined : { vendor_fk_code: vendorsByUser }

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  function handleOpen(id: string) {
    setOpen(true)
    setSelected(id)
  }

  function handleClose() {
    setSelected('')
    setOpen(false)
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Orders`} />
      <RouteBreadcrumbs {...props} />
      <List
        {...props}
        className={classnames(classes.list, { [classes.listWithDrawer]: open })}
        perPage={25}
        sort={{ field: 'ref_pi', order: 'DESC' }}
        filters={<OrderFilter />}
        filterDefaultValues={{
          'pi_date@_gte': `${year}-01-01`,
          ...vendor_filter,
          status: [
            'order_received',
            'order_approved',
            'processing_order',
            'rev_order_inprocess',
            'order_completed',
            'hold',
          ],
        }}
        bulkActionButtons={false}
        hasCreate={permission.create}
      >
        <Datagrid rowClick="expand" expand={<OrderPanel onOpen={handleOpen} />} optimized>
          <DateField source="pi_date" label="Created date" />
          <OrderReferenceField source="id" label="Ref PI" />
          <DateField source="date_shipment" label="Shipment date" />
          <NumberField source="current_revise_num" label="Revise" textAlign="center" />
          <TextField source="customer_fk_code" label="Customer" align="center" />
          {/* <OrderExpenseDetectField source="order_expenses" textAlign="right" label="Expense" /> */}
          <OrderTotalNetWetKGField source="total_net_weight" label="Total NET/WET (KG)" />

          <CustomerPortReferenceField
            target="name"
            source="shipping_details.destination_port"
            link={false}
            label="Destination country"
          />

          <OrderStatusField label="Status" />
        </Datagrid>
      </List>

      <Drawer
        variant="persistent"
        open={open}
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {open && selectedId ? (
          <OrderQuickShow
            id={selectedId}
            mutationMode="pessimistic"
            resource={props.resource}
            basePath={props.basePath}
            onCancel={handleClose}
          />
        ) : null}
      </Drawer>
    </>
  )
}

export default OrderList
