import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import BuildIcon from '@material-ui/icons/Build'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { Layout as RALayout, LayoutProps } from 'react-admin'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import CustomAppBar from './AppBar'
import Menu from './Menu'
import { AppState } from '../types/globalTypes'
import { lightTheme } from './theme'
import { useAppLocationContext } from '../context/WrapperProvider'
import CustomSidebar from './Sidebar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import useUser from 'src/hooks/useUser'
import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles((theme) => ({
  helper: {
    position: 'fixed',
    left: 20,
    bottom: 20,
    [theme.breakpoints.down('sm')]: {
      bottom: 130,
    },
  },
  paper: {
    position: 'absolute',
    width: '220px',
    left: 60,
    bottom: 1,
  },
  fab: {
    backgroundColor: theme.palette.common.white,
  },
}))

export default function Layout({ children, ...props }: LayoutProps) {
  const classes = useStyles()
  const { logout } = useAuth0()
  const { isDisable } = useUser()
  const [open, setOpen] = useState(false)
  const theme = useSelector((state: AppState) => (state.theme === 'light' ? lightTheme : lightTheme))
  const appLocation = useAppLocationContext()

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  const handleHandBookClick = () => {
    window.open(
      'https://docs.google.com/document/d/1qeVJ1LqVHhtAIIOZP7ijnGBhQhU9k4yyCHkSwhmilgU/edit?usp=sharing',
      '_blank'
    )
    setOpen(false)
  }

  const handleAcceptClick = () => {
    localStorage.removeItem('token')
    logout()
  }

  return isDisable ? (
    <Dialog open={true} aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        Your account doesn't have access this website
      </DialogTitle>
      <DialogContent>
        <DialogContentText>To access to this website, please the administration</DialogContentText>
      </DialogContent>
      <DialogActions onClick={handleAcceptClick}>
        <Button color="primary">ACCEPT</Button>
      </DialogActions>
    </Dialog>
  ) : (
    <RALayout {...props} title="CKOrder" appBar={CustomAppBar} sidebar={CustomSidebar} menu={Menu} theme={theme}>
      <Helmet title={`${WEB_APP_TITLE}`} />
      <div ref={appLocation} />
      {children}
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.helper}>
          <Fab className={classes.fab} aria-label="Helper" onClick={handleClick}>
            <HelpOutlineIcon />
          </Fab>
          {open && (
            <Paper className={classes.paper}>
              <List dense>
                <ListItem button onClick={handleHandBookClick}>
                  <ListItemIcon>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary="Visit the handbook" />
                </ListItem>
              </List>
            </Paper>
          )}
        </div>
      </ClickAwayListener>
    </RALayout>
  )
}
