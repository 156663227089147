import { gql } from '@apollo/client'

export const GET_LIST_INVOICES = gql`
  {
    id
    date
    type_no
    information
    invoice_header_id
    terms_of_sale
    freight_charge
    invoice_header {
      id
      ref_invoice
      custom_ref_inv
      use_format
      order {
        id
        status
        customer_fk_code
        vendor_fk_code
      }
    }

    invoice_line_items {
      id
      invoice_id
      product_fk
      size_fk
      size_value_fk
      pack_fk
      box
      net_weight_kgs
      price
      total_price
      gross_weight_kgs
      weight_glazing
      gross_weight
      weight_mc
      remark
      glazing
      group_fk
      custom_kg
      sku_fk
      raw_price
      finished_goods
      product {
        id
        name
      }
      size {
        id
        label
      }
      size_value {
        id
        label
      }
      pack {
        id
        name
      }
    }
    total_price
    config_shipping
  }
`

export const GET_INVOICE_ONE = gql`
  {
    id
    date
    description
    type_no
    bank_fk_code
    author_name
    terms_of_sale
    port
    exchange_rate
    currency_fk
    config_shipping
    invoice_header_id
    unit_code_fk_id
    information
    freight_charge
    total_price

    bank_choice {
      id
      description
    }

    unit_code {
      id
      name
      code
    }

    invoice_header {
      id
      ref_invoice
      use_format
      custom_ref_inv
      format_ref_invoice
      order {
        id
        ref_pi
        vendor_fk_code
        customer_fk_code
        start_date
        end_date

        customer {
          id
          company_name
          address_books(where: { isDefault: { _eq: true } }) {
            id
            address_1
            address_2
            tel
            fax
            phone
            isDefault
          }
        }
      }
    }

    invoice_group_items {
      id
      invoice_id
      product
      slug
      weight_mc
      box
      net_weight_kgs
      gross_weight_kgs
      size
      gross_weight
      unit_price
      amount
    }

    invoice_product_samples {
      id
      product
      size
      pack
      weight_mc
      gross_weight
      net_weight_kg
      gross_weight_kg
      amount
      box
      unit_price
    }

    invoice_line_items {
      id
      invoice_id
      product_fk
      size_fk
      size_value_fk
      pack_fk
      box
      net_weight_kgs
      price
      total_price
      gross_weight_kgs
      weight_glazing
      gross_weight
      weight_mc
      remark
      glazing
      group_fk
      custom_kg
      sku_fk
      raw_price
      finished_goods
      product {
        id
        name
      }
      size {
        id
        label
      }
      size_value {
        id
        label
      }
      pack {
        id
        name
      }
    }
  }
`

export const DELETE_INVOICE = gql`
  {
    id
    date
    description
    type_no
    bank_fk_code
    author_name
    terms_of_sale
    port
    exchange_rate
    currency_fk
    config_shipping
    invoice_header_id
    unit_code_fk_id
    information
    freight_charge
    total_price

    bank_choice {
      id
      description
    }

    unit_code {
      id
      name
      code
    }

    invoice_header {
      id
      ref_invoice
      use_format
      custom_ref_inv
      format_ref_invoice
      order {
        id
        ref_pi
        vendor_fk_code
        customer_fk_code
        start_date
        end_date
      }
    }
  }
`
