import React from 'react'
import Grid from '@material-ui/core/Grid'
import { DateInput, required } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import OrderStatusSelectInput from './OrderStatusSelectInput'

const OrderOptions = ({ disabled }: { disabled?: boolean }) => {
  return (
    <>
      <SectionTitle mb={0.1} label="resources.global.fieldGroups.options" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <OrderStatusSelectInput disabled={disabled} source="status" label="Status" validate={required()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            helperText={false}
            disabled={disabled}
            fullWidth
            source="date_shipment"
            label="Date shipment (mm/dd/yyyy)"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput disabled={disabled} fullWidth source="start_date" label="Production date (mm/dd/yyyy)" />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput disabled={disabled} fullWidth source="end_date" label="Expiry date (mm/dd/yyyy)" />
        </Grid>
      </Grid>
    </>
  )
}

export default OrderOptions
