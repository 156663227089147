import { gql } from '@apollo/client'

export const GET_LIST_PAYMENTS = gql`
  {
    id
    date
    title
    rate
    payment_date
    amount_deposit
    bank
    bank_charge
    exchange
    remark
    type
    customer_fk_code
    customer {
      id
      code
    }
    isNegative
    negative_adjust
  }
`
