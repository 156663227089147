export function letterCapitalize(str: string) {
  let arr = str.split('-')
  let result: string[] = []

  for (let i = 0; i <= arr.length; i++) {
    if (arr[i]) {
      result.push(arr[i].charAt(0).toUpperCase() + arr[i].slice(1))
    }
  }

  return result.join(' ')
}
