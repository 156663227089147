import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Order_expanded } from 'src/pages/orders/types'
import MTTable from 'src/components/MTTable'
import { toDecimalStr } from 'src/utils/toDecimal'
import { formatDate } from 'src/utils/formatDate'

interface Props {
  open: boolean
  orders: Order_expanded[]
  title?: string
  onClose: () => void
}

const OperationDetailsDialog = (props: Props) => {
  const { title, open, orders, onClose } = props

  return (
    <Dialog open={open} maxWidth="lg" fullWidth onClose={onClose}>
      <DialogTitle>{title || ''}</DialogTitle>
      <DialogContent>
        <MTTable
          data={orders}
          columns={[
            {
              field: 'pi_date',
              title: 'Date',
              render: (rowData: any) => <div>{formatDate(rowData.pi_date)}</div>,
            },
            {
              field: 'ref_pi',
              title: 'Ref PI',
            },
            {
              field: 'vendor_fk_code',
              title: 'Vendor',
            },
            {
              field: 'customer_fk_code',
              title: 'Customer',
            },
            {
              field: 'status',
              title: 'status',
            },
            {
              field: 'total_net_weight',
              title: 'NET WET /(KG)',
              type: 'numeric',
              render: (rowData: any) => <div>{toDecimalStr(rowData.total_net_weight)}</div>,
            },
          ]}
          options={{
            pageSize: 50,
            pageSizeOptions: [10, 25, 50],
          }}
        />
      </DialogContent>
    </Dialog>
  )
}

export default OperationDetailsDialog
