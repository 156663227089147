/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import PaymentCreate from './PaymentCreate'
import PaymentEdit from './PaymentEdit'
import PaymentList from './PaymentList'

export default {
  list: PaymentList,
  create: PaymentCreate,
  edit: PaymentEdit,
}
