import { useCallback, useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'

interface State {
  vendorOrders?: number
  countryOrders?: number
  customerOrders?: number
  ref_invoice?: string
}

interface Props {
  vendor_fk_code?: string
  country?: string
  customer_fk_code?: string
  date?: string
}

export default function useGetNewRefInvoice(props: Props) {
  const [state, setState] = useState<State>({})

  const { vendor_fk_code, country, customer_fk_code, date } = props
  const dataProvider = useDataProvider()

  const year = new Date(date || new Date()).getFullYear()

  const fetchVendorInvoices = useCallback(async () => {
    const { total: vendorCount } = await dataProvider.getList('invoice_header', {
      filter: {
        'order#vendor_fk_code@_eq': vendor_fk_code,
        'date@_gte': `${year}-01-01`,
        'date@_lte': `${year}-12-31`,
      },
      sort: { field: 'date', order: 'asc' },
      pagination: { page: 1, perPage: 1 },
    })

    setState((state) => ({
      ...state,
      vendorOrders: vendor_fk_code ? vendorCount + 1 : 0,
    }))
  }, [dataProvider, vendor_fk_code, year])

  const fetchCountryInvoices = useCallback(async () => {
    const { total: countryCount } = await dataProvider.getList('invoice_header', {
      filter: {
        'order#vendor#country@_eq': country,
        'date@_gte': `${year}-01-01`,
        'date@_lte': `${year}-12-31`,
      },
      sort: { field: 'date', order: 'asc' },
      pagination: { page: 1, perPage: 1 },
    })

    setState((state) => ({
      ...state,
      countryOrders: country ? countryCount + 1 : 0,
    }))
  }, [dataProvider, country, year])

  const fetchCustomerInvoices = useCallback(async () => {
    let customerOrders = 0
    const { total: customerCount } = await dataProvider.getList('invoice_header', {
      filter: {
        'order#customer_fk_code@_eq': customer_fk_code,
        'date@_gte': `${year}-01-01`,
        'date@_lte': `${year}-12-31`,
      },

      sort: { field: 'date', order: 'asc' },
      pagination: { page: 1, perPage: 1 },
    })

    customerOrders = customer_fk_code ? customerCount + 1 : 0

    setState((state) => ({
      ...state,
      customerOrders,
    }))
  }, [dataProvider, customer_fk_code, year])

  useEffect(() => {
    fetchVendorInvoices()
    fetchCountryInvoices()
    fetchCustomerInvoices()

    return () => {
      setState({})
    }
  }, [fetchCustomerInvoices, fetchCountryInvoices, fetchVendorInvoices])

  useEffect(() => {
    let year = new Date().getFullYear().toString().slice(2, 4)
    if (vendor_fk_code || customer_fk_code) {
      let vendorOrders = state.vendorOrders?.toString().padStart(3, '0')
      let countryOrders = state.countryOrders?.toString().padStart(3, '0')
      let customerOrders = state.customerOrders?.toString().padStart(3, '0')
      setState((state) => ({
        ...state,
        ref_invoice: `${vendor_fk_code} ${vendorOrders}/${countryOrders}/${customer_fk_code}${customerOrders}/${year}`,
      }))
    }
  }, [state.vendorOrders, state.countryOrders, state.customerOrders, vendor_fk_code, customer_fk_code])

  return state
}
