import { makeStyles } from '@material-ui/core'
import { Sidebar } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  fixed: {
    position: 'relative',
  },
}))

export default function CustomSidebar(props: any) {
  const classes = useStyles()
  return <Sidebar classes={{ fixed: classes.fixed }} style={{ height: 'inherit', position: 'relative' }} {...props} />
}
