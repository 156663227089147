import React, { FC } from 'react'
import { ReferenceField, ReferenceFieldProps } from 'react-admin'
import CategoryNameField from './CategoryNameField'

const CategoryReferenceField: FC<
  Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string
  }
> = (props) => {
  return (
    <ReferenceField source="category.id" reference="category" {...props}>
      <CategoryNameField />
    </ReferenceField>
  )
}

CategoryReferenceField.defaultProps = {
  source: 'category.id',
  addLabel: true,
}

export default CategoryReferenceField
