import React, { FC } from 'react'
import _ from 'lodash'
import { InputProps, useInput, useQueryWithStore } from 'react-admin'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ProductVariant_expanded } from '../products/types/ProductVariant'

interface Props extends InputProps {
  label?: string
  disabled?: boolean
}

const ProductVariantReferenceInput: FC<Props> = (props) => {
  const { data, loading } = useQueryWithStore({
    type: 'getList',
    resource: 'product_variant',
    payload: {
      sort: { field: 'product.name', order: 'ASC' },
    },
  })

  const {
    input: { value, name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  let options = data
    ? _.orderBy(data, ['product.name', 'size.label', 'size_value.label'], ['asc', 'asc', 'asc']).map(
        (option: ProductVariant_expanded) => {
          const letter = option?.product ? option.product.name[0] : ''
          return {
            letter: /[0-9]/.test(letter) ? '0-9' : letter,
            ...option,
          }
        }
      )
    : []

  return (
    <Autocomplete
      id="product-variant-value-group"
      disablePortal
      disabled={props.disabled}
      loading={loading}
      renderTags={() => null}
      noOptionsText="No labels"
      value={value || null}
      options={options.sort(
        (a: ProductVariant_expanded, b: ProductVariant_expanded) => -b.product.name.localeCompare(a.product.name)
      )}
      groupBy={(option) => (option ? option.letter : ' ')}
      onChange={(event, value) => {
        onChange(value)
      }}
      getOptionLabel={(option) =>
        option
          ? `${option.product.name}${option.size_value ? `- ${option.size_value.label} ${option.size?.label}` : ''}`
          : ''
      }
      getOptionSelected={(option, values) => option.id === values.id}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={!!(touched && error)}
          helperText={touched && error}
          required={isRequired}
          label="Product"
          variant="filled"
          size="small"
        />
      )}
    />
  )
}

export default ProductVariantReferenceInput
