export const STATUS_CHOICES = [
  {
    id: 'order_received',
    name: 'ORDER RECEIVED',
    color: `#fff59d`,
  },
  {
    id: 'order_approved',
    name: 'ORDER APPROVED',
    color: '#42ffe5',
  },
  {
    id: 'processing_order',
    name: 'PROCESSING ORDER',
    color: `#90caf9`,
  },
  {
    id: 'revise_tbc',
    name: 'REVISE (TBC)',
    color: '#d876e3',
  },
  {
    id: 'rev_order_inprocess',
    name: 'REV. ORDER INPROCESS',
    color: '#fec1c1',
  },
  {
    id: 'order_completed',
    name: 'ORDER COMPLETED',
    color: `#a5d6a7`,
  },
  {
    id: 'shipped_out',
    name: 'SHIPPED OUT',
    color: '#215cea',
  },
  {
    id: 'hold',
    name: 'HOLD',
    color: '#969ba5',
  },
  {
    id: 'cancelled',
    name: 'CANCELLED',
    color: '#d73a4a',
  },
  {
    id: 'annulled',
    name: 'ANNULLED',
    color: '#fef2c0',
  },
]
