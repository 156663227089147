import React, { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import ProfileItemForm from './ProfileItemForm'

const Title = ({ record }: any) => {
  return <>{record.vendor.code}'s profile items</>
}

const ProfileItemEdit: FC<EditProps> = (props) => {
  return (
    <>
      <RouteBreadcrumbs {...props} />
      <Edit {...props} title={<Title />}>
        <ProfileItemForm />
      </Edit>
    </>
  )
}

export default ProfileItemEdit
