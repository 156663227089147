import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { FormWithRedirect, ReferenceManyField, TextInput, Toolbar } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import CustomerDocument from './CustomerDocument'
import Separate from 'src/components/Separate'
import CustomerRecentOrders from './CustomerRecentOrders'
import CustomerCreditLimit from './CustomerCreditLimit'
import CustomerDestinations from './CustomerDestinations'
import CustomerPrimaryAddress from './CustomerPrimaryAddress'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import DeleteWithLogButton from 'src/components/DeleteWithLogButton'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import { TimelineIterator } from '../timelineComments/TimelineIterator'

const CustomerForm = (props: any) => {
  const canEdit = props.permission.update ? false : true

  return (
    <FormWithRedirect
      {...props}
      redirect={props.redirect}
      render={(formProps: any) => (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Helmet title={`${WEB_APP_TITLE} - Customer #${props.record.code}`} />
            <Card>
              <CardContent>
                <SectionTitle label="resources.global.fieldGroups.overview" />
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextInput fullWidth source="company_name" helperText={false} disabled={canEdit} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput fullWidth source="code" helperText={false} disabled />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextInput fullWidth source="first_name" helperText={false} disabled={canEdit} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput fullWidth source="last_name" helperText={false} disabled={canEdit} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput fullWidth source="email" helperText={false} disabled={canEdit} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Separate value={5} />
                  <SectionTitle label="resources.customer.fieldGroups.recent_orders" />
                  <CustomerRecentOrders record={formProps.record} />
                  <Separate value={3} />
                  <ReferenceManyField
                    target="customer_id"
                    reference="timeline_comment"
                    sort={{ field: 'createdAt', order: 'DESC' }}
                  >
                    <TimelineIterator disabled={canEdit} reference="customer" />
                  </ReferenceManyField>
                </Grid>
              </CardContent>

              <Toolbar>
                <Box display="flex" width="100%" justifyContent="space-between">
                  <SaveWithLogButton
                    resource="customer"
                    source="code"
                    action_type="UPDATE"
                    saving={formProps.saving}
                    disabled={formProps.pristine || canEdit}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />

                  <DeleteWithLogButton
                    resource="customer"
                    confirmTitle={`Delete customer #${formProps.record.code}`}
                    record={formProps.record}
                    mutationMode="pessimistic"
                    disabled={!props.permission.delete}
                  />
                </Box>
              </Toolbar>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomerPrimaryAddress disabled={canEdit} record={formProps.record} />
            <Separate value={3} />
            <CustomerDocument disabled={canEdit} record={formProps.record} />
            <Separate value={3} />
            <CustomerCreditLimit disabled={canEdit} />
            <Separate value={3} />
            <CustomerDestinations
              disabled={canEdit}
              data={formProps.record.customer_ports || []}
              customer_fk_code={formProps.record.code}
            />
          </Grid>
        </Grid>
      )}
    />
  )
}

export default CustomerForm
