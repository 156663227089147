import { gql } from '@apollo/client'

export const GET_LIST_CUSTOMERS = gql`
  {
    id
    first_name
    last_name
    email
    company_name
    code
    document

    customer_ports(where: { isDefault: { _eq: true } }, limit: 1) {
      id
      destination_port {
        name
      }
      isDefault
    }

    expenses_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GET_CUSTOMER = gql`
  {
    id
    first_name
    last_name
    email
    document
    company_name
    code
    credit_limit

    expenses {
      id
      title
      values
      vendor_fk_code
      customer_fk_code
      shipping_expense
    }

    customer_ports {
      id
      destination_port {
        id
        name
      }
      isDefault
    }
  }
`
