import React, { FC, useMemo } from 'react'
import { AutocompleteInput, AutocompleteInputProps, useQueryWithStore } from 'react-admin'
import { VendorPort_fields } from 'src/types/globalTypes'

interface Choice {
  id: string
  name: string
}

interface Props extends AutocompleteInputProps {
  vendor_fk_code: string
}

const VendorPortReferenceInput: FC<Props> = ({ vendor_fk_code, ...rest }) => {
  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'vendor_port',
    payload: {
      filter: { vendor_fk_code },
      sort: { field: 'destination_port.name', order: 'ASC' },
    },
  })

  let options = useMemo(() => formatItems(data), [data])

  return <AutocompleteInput choices={options} {...rest} />
}

export default VendorPortReferenceInput

function formatItems(data: VendorPort_fields[]) {
  let result: Choice[] = []
  if (data) {
    result = data.map((item: VendorPort_fields) => ({
      id: item.id,
      name: item.destination_port.name,
    }))
  }

  return result
}
