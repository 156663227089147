import React, { ChangeEvent } from 'react'
import Typography from '@material-ui/core/Typography'
import { createStyles, withStyles, Theme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Collapse from '@material-ui/core/Collapse'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import NativeSelect from '@material-ui/core/NativeSelect'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'
import { Divider, MenuItem } from '@material-ui/core'
import { InvoicePDFSettingsProps } from './types'

const useStyles = makeStyles(() => ({
  primary: {
    fontSize: 14,
    color: '#202124',
  },
  visibilityWidth: {
    width: '88px',
    padding: '1px',
  },
  icon: {
    fontSize: '1.3rem',
  },
}))

interface Props {
  template: number
  settings: InvoicePDFSettingsProps
  onTemplate: (event: ChangeEvent<{ value: unknown }>) => void
  onSettings: (name: keyof InvoicePDFSettingsProps, value: any) => void
}

export default function InvoicePDFSettings(props: Props) {
  const { template, settings, onTemplate, onSettings } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <>
      <Typography style={{ paddingLeft: 16 }}>Print</Typography>
      <List>
        <ListItem>
          <ListItemText primary="Template" classes={{ primary: classes.primary }} />
          <ListItemSecondaryAction>
            <NativeSelect id="template-select" value={template} onChange={onTemplate} input={<BootstrapInput />}>
              <option value={1}>Commercial invoice</option>
              <option value={2}>Packing list</option>
              <option value={3}>Comm & Packing</option>
            </NativeSelect>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary="Item view" classes={{ primary: classes.primary }} />
          <ListItemSecondaryAction>
            <NativeSelect
              id="item-view"
              input={<BootstrapInput />}
              value={settings.itemView}
              onChange={(e) => onSettings('itemView', Number(e.target.value))}
            >
              <option value={1}>All</option>
              <option value={2}>Items and groups</option>
              <option value={3}>Group only</option>
            </NativeSelect>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary="Letterhead" classes={{ primary: classes.primary }} />
          <ListItemSecondaryAction>
            <NativeSelect
              id="Letterhead-select"
              input={<BootstrapInput />}
              value={settings.letterHead}
              onChange={(e) => onSettings('letterHead', Number(e.target.value))}
            >
              <option value={0}>No header</option>
              <option value={1}>C.K FROZEN FISH</option>
              <option value={2}>PTY SUNTECH</option>
              <option value={3}>C.K HOLDINGS</option>
              <option value={4}>C.K FROZEN YANGON</option>
            </NativeSelect>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText
            classes={{ primary: classes.primary }}
            id="switch-list-label-custom-invoice"
            primary="Custom Ref Invoice"
          />
          <ListItemSecondaryAction>
            <ToggleButtonGroup
              size="small"
              exclusive
              aria-label="CustomRef visibility"
              value={settings.showCustomRefInv}
              onChange={(e, value) => onSettings('showCustomRefInv', value)}
            >
              <ToggleButton value={true} aria-label="Show custom-ref" className={classes.visibilityWidth}>
                <VisibilityIcon className={classes.icon} />
              </ToggleButton>
              <ToggleButton value={false} aria-label="Not show custom-ref" className={classes.visibilityWidth}>
                <VisibilityOffIcon className={classes.icon} />
              </ToggleButton>
            </ToggleButtonGroup>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText
            classes={{ primary: classes.primary }}
            id="switch-list-label-format-invoice"
            primary="Format invoice"
          />
          <ListItemSecondaryAction>
            <ToggleButtonGroup
              size="small"
              exclusive
              aria-label="FormatInf visibility"
              value={settings.showFormatRef}
              onChange={(e, value) => onSettings('showFormatRef', value)}
            >
              <ToggleButton value={true} aria-label="Show format-ref" className={classes.visibilityWidth}>
                <VisibilityIcon className={classes.icon} />
              </ToggleButton>
              <ToggleButton value={false} aria-label="Not show format-ref" className={classes.visibilityWidth}>
                <VisibilityOffIcon className={classes.icon} />
              </ToggleButton>
            </ToggleButtonGroup>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      <Divider />
      <ListItem button onClick={handleClick}>
        <ListItemText primary="More settings" classes={{ primary: classes.primary }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem>
            <ListItemText primary="Product" classes={{ primary: classes.primary }} />
            <ListItemSecondaryAction>
              <Select
                style={{ width: 174 }}
                multiple
                value={settings.showProduct}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e
                  let newValue = typeof value === 'string' ? value.split(',') : value

                  onSettings('showProduct', newValue)
                }}
                input={<BootstrapInput />}
              >
                <MenuItem value={'local'}>Local</MenuItem>
                <MenuItem value={'english'}>English</MenuItem>
                <MenuItem value={'sci1'}>Scientific name 1</MenuItem>
                <MenuItem value={'sci2'}>Scientific name 2</MenuItem>
                <MenuItem value={'sci3'}>Scientific name 3</MenuItem>
              </Select>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.primary }}
              id="switch-list-label-product-sample"
              primary="Product sample"
            />
            <ListItemSecondaryAction>
              <ToggleButtonGroup
                size="small"
                exclusive
                aria-label="Sample visibility"
                value={settings.showProductSample}
                onChange={(e, value) => onSettings('showProductSample', value)}
              >
                <ToggleButton value={true} aria-label="Show product sample" className={classes.visibilityWidth}>
                  <VisibilityIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton value={false} aria-label="Not show product sample" className={classes.visibilityWidth}>
                  <VisibilityOffIcon className={classes.icon} />
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Remark" classes={{ primary: classes.primary }} />
            <ListItemSecondaryAction>
              <ToggleButtonGroup
                size="small"
                exclusive
                aria-label="Remark visibility"
                value={settings.showRemark}
                onChange={(e, value) => onSettings('showRemark', value)}
              >
                <ToggleButton value={true} aria-label="Show remark" className={classes.visibilityWidth}>
                  <VisibilityIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton value={false} aria-label="Not show remark" className={classes.visibilityWidth}>
                  <VisibilityOffIcon className={classes.icon} />
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.primary }}
              id="switch-list-label-production-date"
              primary="Production date"
            />
            <ListItemSecondaryAction>
              <ToggleButtonGroup
                size="small"
                exclusive
                aria-label="Production visibility"
                value={settings.showProduction}
                onChange={(e, value) => onSettings('showProduction', value)}
              >
                <ToggleButton value={true} aria-label="Show produdction date" className={classes.visibilityWidth}>
                  <VisibilityIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton value={false} aria-label="Not show production date" className={classes.visibilityWidth}>
                  <VisibilityOffIcon className={classes.icon} />
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText classes={{ primary: classes.primary }} id="switch-list-label-bank" primary="Bank account" />
            <ListItemSecondaryAction>
              <ToggleButtonGroup
                size="small"
                exclusive
                aria-label="Bank visibility"
                value={settings.showBank}
                onChange={(e, value) => onSettings('showBank', value)}
              >
                <ToggleButton value={true} aria-label="Show bank" className={classes.visibilityWidth}>
                  <VisibilityIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton value={false} aria-label="Not show bank" className={classes.visibilityWidth}>
                  <VisibilityOffIcon className={classes.icon} />
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.primary }}
              id="switch-list-label-authorization"
              primary="Export section"
            />
            <ListItemSecondaryAction>
              <ToggleButtonGroup
                size="small"
                exclusive
                aria-label="Exporter visibility"
                value={settings.showExport}
                onChange={(e, value) => onSettings('showExport', value)}
              >
                <ToggleButton value={true} aria-label="Show exporter" className={classes.visibilityWidth}>
                  <VisibilityIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton value={false} aria-label="Not show exporter" className={classes.visibilityWidth}>
                  <VisibilityOffIcon className={classes.icon} />
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              classes={{ primary: classes.primary }}
              id="switch-list-label-freight-charge"
              primary="Freight charge"
            />
            <ListItemSecondaryAction>
              <ToggleButtonGroup
                size="small"
                exclusive
                aria-label="Frieght-charge-visibility"
                value={settings.showFreightCharge}
                onChange={(e, value) => onSettings('showFreightCharge', value)}
              >
                <ToggleButton value={true} aria-label="Show freight charge" className={classes.visibilityWidth}>
                  <VisibilityIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton value={false} aria-label="Not show freight charge" className={classes.visibilityWidth}>
                  <VisibilityOffIcon className={classes.icon} />
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText classes={{ primary: classes.primary }} id="switch-list-label-footer" primary="Footer" />
            <ListItemSecondaryAction>
              <ToggleButtonGroup
                size="small"
                exclusive
                aria-label="Footer"
                value={settings.showFooter}
                onChange={(e, value) => onSettings('showFooter', value)}
              >
                <ToggleButton value={true} aria-label="Show footer" className={classes.visibilityWidth}>
                  <VisibilityIcon className={classes.icon} />
                </ToggleButton>
                <ToggleButton value={false} aria-label="Not show footer" className={classes.visibilityWidth}>
                  <VisibilityOffIcon className={classes.icon} />
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Collapse>
    </>
  )
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      minWidth: 140,
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#f1f3f4',
      fontSize: 14,
      padding: '5px 26px 8px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.1rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase)
