import React from 'react'
import Typography from '@material-ui/core/Typography'
import { toDecimalStr } from 'src/utils/toDecimal'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MTTable from 'src/components/MTTable'
import { MTableBodyRow } from 'material-table'
import { OrderProductSample_fields } from 'src/types/globalTypes'

interface Props {
  loading?: boolean
  data: OrderProductSample_fields[]
  onUpdate?: (newData: any, oldData: any) => void
  onDelete?: (oldData: any) => void
}

export default function OrderProductSamples(props: Props) {
  const { data, onUpdate, onDelete } = props

  return (
    <MTTable
      data={data || []}
      columns={[
        {
          title: 'Product',
          field: 'product',
          editComponent: (props) => (
            <TextField
              style={{ width: '600px' }}
              inputProps={{
                style: {
                  fontSize: 12,
                },
              }}
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
          render: (rowData: any) => (
            <div style={{ width: '600px' }}>
              <Typography variant="caption">{rowData.product}</Typography>
            </div>
          ),
        },
        {
          title: 'Group',
          field: 'box',
          editComponent: (props) => (
            <Select fullWidth native value={props.value} onChange={(e) => props.onChange(Number(e.target.value))}>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </Select>
          ),
        },
        {
          title: 'NET WET (KG)',
          field: 'net_weight_kg',
          type: 'numeric',
          align: 'center',
          editComponent: (props) => (
            <TextField
              type="number"
              value={props.value || ''}
              onChange={(e) => props.onChange(Number(e.target.value))}
            />
          ),
          render: (rowData: any) => <div>{toDecimalStr(rowData.net_weight_kg)}</div>,
        },
        {
          title: 'GROSS WET (KG)',
          field: 'gross_weight_kg',
          type: 'numeric',
          align: 'center',
          editComponent: (props) => (
            <TextField
              type="number"
              value={props.value || ''}
              onChange={(e) => props.onChange(Number(e.target.value))}
            />
          ),
          render: (rowData: any) => <div>{toDecimalStr(rowData.gross_weight_kg)}</div>,
        },
      ]}
      components={{
        Row: (props) => (
          <MTableBodyRow
            {...props}
            onRowClick={(e: any) => {
              props.actions[0]().onClick(e, props.data)
            }}
          />
        ),
      }}
      editable={{
        onRowUpdate: (newData: any, oldData: any) =>
          new Promise((resolve, reject) => {
            if (oldData.id) {
              if (onUpdate) {
                resolve(onUpdate(newData, oldData))
              }
            }
          }),
        onRowDelete: (oldData: any) =>
          new Promise((resolve, reject) => {
            if (oldData.id) {
              if (onDelete) {
                resolve(onDelete(oldData))
              }
            }
          }),
      }}
      style={{
        border: '1px solid #e3e3e3',
      }}
    />
  )
}
