import React, { FC } from 'react'
import { Record } from 'react-admin'
import { Customer_fields } from 'src/types/globalTypes'

type CustomerRecord = Record & Customer_fields

interface Props {
  record?: CustomerRecord
}

const CustomerFullNameField: FC<Props> = ({ record }) => {
  return record ? <span>{record.company_name}</span> : null
}

export default CustomerFullNameField
