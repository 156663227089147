import React, { FC } from 'react'
import { Create, CreateProps, FormTab, TabbedForm, Toolbar, ToolbarProps } from 'react-admin'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { orderInitialValuesCreate } from '../constants/order'
import OrderLineItemsCreateTab from '../components/OrderLineItemsCreateTab'
import OrderSettingsTab from '../components/OrderSettingsTab'
import OrderInformationCreateTab from '../components/OrderInformationCreateTab'
import formatNewOrderLineItems from '../utils/formats/formatNewOrderLineItems'
import formatNewOrderRevise from '../utils/formats/formatNewOrderRevise'
import formatTotalNetWeight from '../utils/formats/formatTotalNetWeight'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

const CustomToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveWithLogButton action_type="CREATE" source="ref_pi" />
  </Toolbar>
)

const OrderCreate: FC<CreateProps> = (props) => {
  const transform = (data: any) => ({
    id: data.id,
    ref_pi: data.ref_pi,
    pi_date: data.pi_date,
    description: data.description,
    customer_fk_code: data.customer_fk_code,
    status: data.status,
    vendor_fk_code: data.vendor_fk_code,
    shipping_details: data.shipping_details,
    unit_code_fk_id: data.unit_code_fk_id,
    shipment_type_fk_id: data.shipment_type_fk_id,
    currency_fk: data.currency_fk,
    product_sample: data.product_sample,
    total_net_weight: formatTotalNetWeight(data.order_line_items),
    order_line_items: {
      data: formatNewOrderLineItems(data.order_line_items),
    },
    order_revises: {
      data: formatNewOrderRevise(data.order_revises),
    },
  })

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New order`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props} transform={transform}>
        <TabbedForm initialValues={orderInitialValuesCreate} submitOnEnter={false} toolbar={<CustomToolbar />}>
          <FormTab label="resources.global.fieldGroups.information">
            <OrderInformationCreateTab />
          </FormTab>
          <FormTab path="line-items" label="resources.order.tabTitle.line_items">
            <OrderLineItemsCreateTab />
          </FormTab>
          <FormTab path="settings" label="resources.global.fieldGroups.settings">
            <OrderSettingsTab />
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  )
}

export default OrderCreate
