import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useDataProvider, useGetIdentity } from 'react-admin'
import Separate from 'src/components/Separate'
import CardWithIcon from 'src/components/CardWithIcon'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import { Order_expanded } from '../orders/types'
import UserOrdersChart from './UserOrdersChart'

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 200,
    width: 200,
    [theme.breakpoints.down('sm')]: {
      height: 80,
      width: 80,
    },
  },
}))

interface OrderStats {
  shippedout_orders: Order_expanded[]
  total_orders: number
  total_invoices: number
}

interface State {
  shippedout_orders?: Order_expanded[]
  total_orders?: number
  total_invoices?: number
}

const UserProfile = () => {
  const classes = useStyles()
  const { id }: any = useParams()
  const { identity } = useGetIdentity()
  const dataProvider = useDataProvider()
  const [state, setState] = useState<State>({})

  const fetchOrders = useCallback(async () => {
    const { data: orders } = await dataProvider.getList<Order_expanded>('order', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'pi_date', order: 'DESC' },
      filter: {},
    })

    if (orders.length) {
      const aggregations = orders.reduce(
        (acc: OrderStats, order) => {
          if (order.status === 'shipped_out') {
            acc.shippedout_orders.push(order)
            acc.total_orders += 1
          }

          return acc
        },
        { shippedout_orders: [], total_orders: 0, total_invoices: 0 } as OrderStats
      )

      setState((prev) => ({
        ...prev,
        shippedout_orders: aggregations.shippedout_orders,
        total_orders: aggregations.total_orders,
      }))
    }
  }, [dataProvider])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  if (identity?.fullName !== id) return null

  return (
    <Container maxWidth="lg">
      <Separate value={5} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Avatar alt={`${identity?.fullName}`} src={`${identity?.avatar}`} className={classes.avatar} />

          <Separate value={2} />
          <Typography>{identity?.fullName || ''}</Typography>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <CardWithIcon icon={AddShoppingCartIcon} color="primary" title="Total orders" value={'20000'} />
            </Grid>
            <Grid item xs={12} md={5}>
              <CardWithIcon icon={AddShoppingCartIcon} value={'20000'} />
            </Grid>

            <Grid item xs={12} md={10}>
              <UserOrdersChart orders={state.shippedout_orders || []} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserProfile
