import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import SectionTitle from 'src/components/SectionTitle'
import { DateInput, FormWithRedirect, ReferenceManyField, TextInput, Toolbar } from 'react-admin'
import Separate from 'src/components/Separate'
import InvoiceTermsForm from 'src/pages/invoiceHeaders/InvoiceHeaderTermsForm'
import InvoiceHeaderOptionsForm from './InvoiceHeaderOptionsForm'
import InvoiceHeaderInformationCreateForm from './InvoiceHeaderInformationCreateForm'
import OrderReferenceField from '../orders/fields/OrderReferenceField'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import DeleteWithLogButton from 'src/components/DeleteWithLogButton'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import { TimelineIterator } from '../timelineComments/TimelineIterator'

export default function InvoiceHeaderDetailPage(props: any) {
  const { permission } = props
  return (
    <FormWithRedirect
      {...props}
      redirect={props.redirect}
      render={(formProps: any) => (
        <Card>
          <CardContent>
            <Helmet title={`${WEB_APP_TITLE} - Invoice header #${props.record.ref_invoice}`} />
            <Box display={{ md: 'block', lg: 'flex' }}>
              <Box flex={2} mr={{ md: 0, lg: '3em' }}>
                <SectionTitle label="resources.global.fieldGroups.overview" />
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={2} mr={{ xs: 0, sm: '1em' }}>
                    <TextInput source="ref_invoice" disabled fullWidth helperText={false} />
                    <Box ml={1.5} mt={0.3}>
                      <OrderReferenceField source="order.id" />
                    </Box>
                    <Separate value={2} />
                    <TextInput
                      disabled={!permission.update}
                      source="custom_ref_inv"
                      fullWidth
                      label="Custom (Ref INV.)"
                    />
                  </Box>
                  <Box flex={1}>
                    <DateInput disabled={!permission.update} source="date" fullWidth />
                  </Box>
                </Box>

                <Separate value={3} />
                <InvoiceHeaderInformationCreateForm disabled={!permission.update} />

                <Separate value={3} />
                <SectionTitle label="resources.invoice_header.fieldGroups.export_details" />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      disabled={!permission.update}
                      source="author_name"
                      fullWidth
                      label="Export Section"
                      helperText={false}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      disabled={!permission.update}
                      source="bank_fk_code"
                      fullWidth
                      label="Bank Account"
                      helperText={false}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      disabled={!permission.update}
                      source="description"
                      fullWidth
                      label="Remark"
                      helperText={false}
                    />
                  </Grid>
                </Grid>

                <Separate value={3} />

                <ReferenceManyField
                  target="invoice_header_id"
                  reference="timeline_comment"
                  sort={{ field: 'createdAt', order: 'DESC' }}
                >
                  <TimelineIterator disabled={!permission.update} reference="invoice_header" />
                </ReferenceManyField>
              </Box>
              <Box flex={2}>
                <InvoiceTermsForm disabled={!permission.update} />
                <Separate value={1} />
                <InvoiceHeaderOptionsForm disabled={!permission.update} type="edit" />
              </Box>
            </Box>
          </CardContent>
          <Toolbar>
            <Box display="flex" width="100%" justifyContent="space-between">
              <SaveWithLogButton
                resource="invoice_header"
                source="ref_invoice"
                action_type="UPDATE"
                saving={formProps.saving}
                disabled={formProps.pristine}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />

              <DeleteWithLogButton
                resource="invoice_header"
                source="ref_invoice"
                confirmTitle={`Delete invoice header #${formProps.record.ref_invoice}`}
                record={formProps.record}
                mutationMode="pessimistic"
                updateParent={{ resource: 'order', source: 'order_id', data: { isDone: false } }}
              />
            </Box>
          </Toolbar>
        </Card>
      )}
    />
  )
}
