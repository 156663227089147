import { toDecimalStr } from 'src/utils/toDecimal'

interface Props {
  total_package_cartons: number
  total_net_weight: number
  total_gross_weight: number
  total_amount: number
  freight_charge: number
}

export function invoice_totalAmount3(props: Props) {
  const { total_gross_weight, total_net_weight, total_package_cartons, freight_charge, total_amount } = props

  return [
    [
      {},
      { text: 'TOTAL' },
      {
        style: 'tcCell',
        text: `${toDecimalStr(total_package_cartons, true)}`,
      },
      {
        style: 'textRight',
        text: `${toDecimalStr(total_net_weight)}`,
      },
      {
        style: 'textRight',
        text: `${toDecimalStr(total_gross_weight)}`,
      },
      {
        style: 'textRight',
        text: `CNF PRICE`,
      },
      {
        style: 'textRight',
        text: `${toDecimalStr(total_amount + freight_charge)}`,
      },
    ],
  ]
}
