import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { BrowserRouter as Router } from 'react-router-dom'
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import { WrapperProvider } from './context/WrapperProvider'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <WrapperProvider>
          <App />
        </WrapperProvider>
      </MuiPickersUtilsProvider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
