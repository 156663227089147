import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Form } from 'react-final-form'
import CountryReferenceInput from '../countries/CountryReferenceInput'
import { TextInput, useCreate, useNotify, useRefresh } from 'react-admin'
import PhoneInput from 'src/components/PhoneInput'

interface Props {
  open: boolean
  customer_fk_code: string
  setOpen: (value: boolean) => void
  onClose?: () => void
}

const AddCustomerAddressDialog: FC<Props> = ({ open, customer_fk_code, setOpen, onClose }) => {
  const [createItem] = useCreate()
  const refresh = useRefresh()
  const notify = useNotify()

  const onSubmit = (values: any) => {
    let data = {
      customer_fk_code,
      address_1: values.address_1 || '',
      address_2: values.address_2 || '',
      country: values.select_country || null,
      tel: values.tel || '',
      fax: values.fax || '',
      phone: values.phone || '',
    }

    createItem('address_book', data, {
      onSuccess: () => {
        notify('resources.global.notifications.success.create', 'info')
        refresh()
      },
    })
    setOpen(false)
  }

  return (
    <Dialog title="Add an address" maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Add an address</DialogTitle>
      <Form
        initialValues={{ select_country: null }}
        onSubmit={onSubmit}
        render={({ form, handleSubmit }) => (
          <form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput source="address_1" fullWidth helperText={false} />
                </Grid>
                <Grid item xs={12}>
                  <TextInput source="address_2" fullWidth helperText={false} />
                </Grid>
                <Grid item xs={12}>
                  <CountryReferenceInput fullWidth source="select_country" helperText={false} />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput source="tel" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput source="fax" fullWidth helperText={false} />
                </Grid>
                <Grid item xs={12}>
                  <TextInput source="phone" label="Mobile" fullWidth helperText={false} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit}>
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  )
}

export default AddCustomerAddressDialog
