import { OrderProductSample_fields } from 'src/types/globalTypes'
import { InvoiceProductSampleInput } from '../../types/input'

interface Props {
  data: OrderProductSample_fields[]
}

export default function formatArraySampleOrderToInvoice(props: Props): InvoiceProductSampleInput[] {
  const { data } = props
  let result: InvoiceProductSampleInput[] = []

  if (data.length) {
    result = data.map((item) => {
      return {
        product: item.product,
        size: '',
        pack: '',
        weight_mc: item.weight_mc,
        gross_weight: item.gross_weight,
        net_weight_kg: item.net_weight_kg,
        gross_weight_kg: item.gross_weight_kg,
        box: item.box,
        amount: item.amount,
        unit_price: 0,
      }
    })
  }

  return result
}
