import { useMemo } from 'react'
import { useQueryWithStore } from 'react-admin'

export default function usePacks() {
  const { data, loading, error } = useQueryWithStore({
    type: 'getList',
    resource: 'pack',
    payload: {
      sort: { field: 'name', order: 'ASC' },
    },
  })

  let result = useMemo(() => {
    return data
  }, [data])

  return {
    data: result || [],
    loading,
    error,
  }
}
