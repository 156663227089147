import moment from 'moment'
import { Order_expanded } from 'src/pages/orders/types'

interface Data {
  title: string
  orders: { [key: string]: Order_expanded[] }
}

export const ordersByPeriod = (
  orders: Order_expanded[],
  weekly: string[],
  period: moment.unitOfTime.StartOf = 'week',
  split?: boolean
): Data[] => {
  let result: Data[] = []

  if (split === true) {
    result = [
      ...orders
        .reduce((acc, order) => {
          const key = `${order.vendor_fk_code}-${order.customer_fk_code}`
          const month = moment(order.pi_date).startOf(period).format('YYYY-MM-DD')
          const item = acc.get(key) || Object.assign({}, { title: key, orders: {} })
          if (!item.orders[month]) {
            item.orders[month] = []
          }
          item.orders[month].push(order)
          return acc.set(key, item)
        }, new Map())
        .values(),
    ]
  } else if (split === false) {
    result = [
      ...orders
        .reduce((acc, order) => {
          const key = ``
          const month = moment(order.pi_date).startOf(period).format('YYYY-MM-DD')
          const item = acc.get(key) || Object.assign({}, { title: key, orders: {} })
          if (!item.orders[month]) {
            item.orders[month] = []
          }
          item.orders[month].push(order)
          return acc.set(key, item)
        }, new Map())
        .values(),
    ]
  }

  weekly.reduce((acc, curr) => {
    if (result.length) {
      Object.values(result).forEach((item: any) => {
        if (!item.orders[curr]) {
          item.orders[curr] = []
        }
      })
    }

    return acc
  }, {})

  return result
}
