import React, { FC } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import ProductVariantReferenceInput from '../productVariants/ProductVariantReferenceInput'
import PackReferenceInput from 'src/components/ReferenceInputs/PackReferenceInput'
import { BooleanInput, NumberInput, required } from 'react-admin'
import CustomersReferenceInput from 'src/components/ReferenceInputs/CustomersReferenceInput'
import Separate from 'src/components/Separate'
import { useFormState } from 'react-final-form'

interface Props {
  id: string
  open: boolean
  disabled?: boolean
  onClose: () => void
  onCancel: () => void
  onSave: () => void
}

const EditProfileItemValueDialog: FC<Props> = ({ id, open, disabled, onClose, onCancel, onSave }) => {
  const { values } = useFormState()
  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography>Edit item</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProductVariantReferenceInput source="editItem.product_variant" disabled={true} validate={required()} />
          </Grid>
          <Grid item xs={12}>
            <PackReferenceInput source="editItem.pack" label="Pack" disabled validate={required()} />
          </Grid>
          <Grid item xs={12} md={6}>
            <NumberInput source="editItem.weight_mc" fullWidth label="Weight MC" validate={required()} />
          </Grid>
          <Grid item xs={12} md={6}>
            {values.editItem?.use_default ? (
              <TextField
                label="Gross Weight"
                value={values.editItem.weight_mc + values.default_gross_weight}
                fullWidth
                variant="filled"
                size="small"
                disabled
              />
            ) : (
              <NumberInput
                source="editItem.gross_weight"
                min={1}
                fullWidth
                label="Gross Weight"
                validate={required()}
                helperText={false}
              />
            )}

            <BooleanInput source="editItem.use_default" label="Use default" helperText={false} />
          </Grid>
          <Grid item xs={12}>
            <CustomersReferenceInput source="editItem.customer_fk_ids" label="Customers" fullWidth />
          </Grid>
        </Grid>
      </DialogContent>
      <Separate value={3} />
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Cancel
        </Button>

        <Button variant="contained" color="primary" onClick={onSave} disabled={disabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditProfileItemValueDialog
