import React, { MouseEvent, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useCreate, useGetIdentity, useNotify, useRecordContext, useRefresh, useResourceContext } from 'react-admin'
import { sanitize } from 'src/utils/sanitize'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
}))

const NewSizeValue = ({ reference }: { reference: 'size' }) => {
  const classes = useStyles()
  const [text, setText] = useState('')
  const [create] = useCreate()
  const refresh = useRefresh()
  const notify = useNotify()
  const resource = useResourceContext()
  const record = useRecordContext()
  const { identity } = useGetIdentity()

  if (!record || !identity) return null

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const data: any = {
      [foreignKeyMapping[reference]]: record.id,
      label: text,
      code: sanitize(text),
    }
    create(resource, data, {
      onSuccess: () => {
        setText('')
        notify('resources.global.notifications.success.create', { type: 'info' })
        refresh()
      },
    })
  }

  return (
    <>
      <TextField
        variant="filled"
        value={text}
        onChange={(e) => setText(e.target.value)}
        label="Add a value"
        size="small"
        fullWidth
      />
      <div className={classes.toolbar}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Add this value
        </Button>
      </div>
    </>
  )
}

const foreignKeyMapping = {
  size: 'size_fk_id',
}

export default NewSizeValue
