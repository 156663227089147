import InvoiceHeaderCreate from './InvoiceHeaderCreate'
import InvoiceHeaderEdit from './InvoiceHeaderEdit'
import InvoiceHeaderList from './InvoiceHeaderList'

const invoiceHeaders = {
  list: InvoiceHeaderList,
  create: InvoiceHeaderCreate,
  edit: InvoiceHeaderEdit,
}

export default invoiceHeaders
