import React, { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { Form } from 'react-final-form'
import CountryReferenceInput from '../countries/CountryReferenceInput'
import { TextInput, useDelete, useNotify, useRefresh, useUpdate } from 'react-admin'
import { AddressBook_fields } from 'src/types/globalTypes'
import PhoneInput from 'src/components/PhoneInput'

interface Props {
  open: boolean
  data: AddressBook_fields
  setOpen: (value: boolean) => void
  onClose?: () => void
}

const EditCustomerAddressDialog: FC<Props> = ({ open, data, setOpen, onClose }) => {
  const [updateItem] = useUpdate()
  const [deleteItem] = useDelete()
  const refresh = useRefresh()
  const notify = useNotify()

  const onSubmit = (values: any) => {
    let newData = {
      address_1: values.address_1 || '',
      address_2: values.address_2 || '',
      country: values.select_country || null,
      tel: values.tel || '',
      fax: values.fax || '',
      phone: values.phone || '',
    }

    updateItem('address_book', data.id, newData, data, {
      onSuccess: () => {
        notify('resources.global.notifications.success.create', 'info')
        refresh()
      },
    })

    setOpen(false)
  }

  const handleDelete = () => {
    deleteItem(
      'address_book',
      data.id,
      { ...data, id: data.id || '' },
      {
        undoable: true,
        onSuccess: () => {
          notify('resources.global.notifications.success.delete', 'info', undefined, true)
          setOpen(false)
          refresh()
        },
      }
    )
  }

  return (
    <Dialog title="Edit address" maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Edit an address</DialogTitle>
      <Form
        initialValues={{ ...data, select_country: data.country }}
        onSubmit={onSubmit}
        render={({ form, handleSubmit }) => (
          <form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextInput source="address_1" fullWidth helperText={false} />
                </Grid>
                <Grid item xs={12}>
                  <TextInput source="address_2" fullWidth helperText={false} />
                </Grid>
                <Grid item xs={12}>
                  <CountryReferenceInput fullWidth source="select_country" helperText={false} />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput source="tel" />
                </Grid>
                <Grid item xs={12}>
                  <TextInput source="fax" fullWidth helperText={false} />
                </Grid>
                <Grid item xs={12}>
                  <TextInput source="phone" label="Mobile" fullWidth helperText={false} />
                </Grid>
              </Grid>
            </DialogContent>
            <Box p={2} display="flex" alignItems="flex-start" justifyContent="space-between">
              <Box>
                <Button variant="contained" color="secondary" onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
              <Box>
                <Button variant="outlined" onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      />
    </Dialog>
  )
}

export default EditCustomerAddressDialog
