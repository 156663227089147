import { OrderLineItems_expanded } from 'src/pages/orders/types'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import _ from 'lodash'

interface Props {
  data: OrderLineItems_expanded[]
}

export function pi_lineItems3(props: Props) {
  let formatted_lineItmes = makeData(props)
  return formatted_lineItmes
}

function makeData(props: Props) {
  const { data } = props

  let formatted_data = _.orderBy(data, ['product.name', 'size_value.label', 'size.label'], ['asc', 'asc', 'asc']).map(
    (item, index) => {
      let net_weight_kg = toDecimal(item.weight_mc * item.box)
      return [
        {
          style: 'textCenter',
          text: `${index + 1}`,
        },
        {
          text: `${item.product?.name}${item.size_value ? ` - ${item.size_value.label}` : ''} ${
            item.size ? item.size.label : ''
          }`,
        },
        {
          text: `${item.pack?.name}`,
        },
        {
          style: 'textRight',
          text: `${toDecimalStr(item.weight_mc)}`,
        },
        {
          style: 'textRight',
          text: `${toDecimalStr(item.box, true)}`,
        },
        {
          style: 'textRight',
          text: `${toDecimalStr(net_weight_kg)}`,
        },
        {
          style: 'textRight',
          text: `${toDecimalStr(item.gross_weight * item.box)}`,
        },
        {
          style: 'textCenter',
          text: `${item.remark ? item.remark : ''}`,
        },
      ]
    }
  )

  return formatted_data
}
