import React, { FC, useCallback, useEffect, useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {
  Datagrid,
  DatagridProps,
  DateInput,
  Filter,
  FilterProps,
  Identifier,
  List,
  ListContextProvider,
  ListProps,
  NumberField,
  SearchInput,
  TextField,
  useGetList,
  useListContext,
} from 'react-admin'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import CustomerReferenceInput from '../customers/CustomerReferenceInput'
import DateField from 'src/components/DateField'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import BankChoiceReferenceInput from '../bankChoices/BankChoiceReferenceInput'

const PaymentFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
    <DateInput source="date@_gte" alwaysOn />
    <DateInput source="date@_lte" />
    <CustomerReferenceInput source="customer_fk_code" label="Customer" alwaysOn />
    <BankChoiceReferenceInput source="bank" alwaysOn />
  </Filter>
)

const PaymentList: FC<ListProps> = (props) => {
  let year = new Date().getFullYear()
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Payments`} />
      <RouteBreadcrumbs {...props} />
      <List
        {...props}
        filterDefaultValues={{
          type: 'deposit',
          'date@_gte': `${year}-01-01`,
        }}
        sort={{ field: 'date', order: 'DESC' }}
        perPage={25}
        filters={<PaymentFilter />}
        bulkActionButtons={false}
      >
        <TabbedDatagrid />
      </List>
    </>
  )
}

const tabs = [
  { id: 'deposit', name: 'deposit' },
  { id: 'withdraw', name: 'withdraw' },
  { id: 'adjust', name: 'adjust' },
]

const useGetTotals = (filterValues: any) => {
  const { total: totalDeposit } = useGetList(
    'payment',
    { perPage: 1, page: 1 },
    { field: 'date', order: 'DESC' },
    { ...filterValues, type: 'deposit' }
  )

  const { total: totalWithdraw } = useGetList(
    'payment',
    { perPage: 1, page: 1 },
    { field: 'date', order: 'DESC' },
    { ...filterValues, type: 'withdraw' }
  )

  const { total: totalAdjust } = useGetList(
    'payment',
    { perPage: 1, page: 1 },
    { field: 'date', order: 'DESC' },
    { ...filterValues, type: 'adjust' }
  )

  return {
    deposit: totalDeposit,
    withdraw: totalWithdraw,
    adjust: totalAdjust,
  }
}

interface TabbedDatagridProps extends DatagridProps {}

const TabbedDatagrid: FC<TabbedDatagridProps> = (props) => {
  const listContext = useListContext()
  const { ids, filterValues, setFilters, displayedFilters } = listContext
  //   const classes = useDatagridStyles()
  //   const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'))

  const [deposits, setDeposits] = useState<Identifier[]>([] as Identifier[])
  const [withdraws, setWithdraws] = useState<Identifier[]>([] as Identifier[])
  const [adjusts, setAdjusts] = useState<Identifier[]>([] as Identifier[])

  const totals = useGetTotals(filterValues) as any

  useEffect(() => {
    if (ids && ids !== filterValues.type) {
      switch (filterValues.type) {
        case 'deposit':
          setDeposits(ids)
          break
        case 'withdraw':
          setWithdraws(ids)
          break
        case 'adjust':
          setAdjusts(ids)
          break
      }
    }
  }, [ids, filterValues.type])

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters && setFilters({ ...filterValues, type: value }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )

  return (
    <>
      <Tabs variant="fullWidth" centered value={filterValues.type} onChange={handleChange} indicatorColor="primary">
        {tabs.map((choice, index) => (
          <Tab
            key={index}
            label={totals[choice.name] ? `${choice.name} (${totals[choice.name]})` : `${choice.name} (0)`}
            value={choice.id}
          />
        ))}
      </Tabs>
      <div>
        {filterValues.type === 'deposit' && (
          <ListContextProvider value={{ ...listContext, ids: deposits }}>
            <Datagrid {...props} optimized rowClick="edit">
              <DateField source="date" locales="fr-FR" />
              <TextField source="customer_fk_code" label="Customer" />
              <TextField source="bank" />
              <NumberField source="rate" />
              <DateField source="payment_date" />
              <NumberField source="amount_deposit" />
              <NumberField source="bank_charge" />
              <NumberField source="exchange" />
              {/* <NumberField source="negative_adjust" label="Negative adjustment" /> */}
              <TextField source="remark" />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.type === 'withdraw' && (
          <ListContextProvider value={{ ...listContext, ids: withdraws }}>
            <Datagrid {...props} rowClick="edit">
              <DateField source="date" />
              <TextField source="customer_fk_code" label="Customer" />
              <TextField source="bank" />
              <NumberField source="rate" />
              <DateField source="payment_date" />
              <NumberField source="amount_deposit" label="Amount withdraw" />
              <NumberField source="bank_charge" />
              <NumberField source="exchange" />
              {/* <NumberField source="negative_adjust" label="Negative adjustment" /> */}
              <TextField source="remark" />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.type === 'adjust' && (
          <ListContextProvider value={{ ...listContext, ids: adjusts }}>
            <Datagrid {...props} rowClick="edit">
              <DateField source="date" />
              <TextField source="customer_fk_code" label="Customer" />
              <TextField source="bank" />
              <NumberField source="rate" />
              <DateField source="payment_date" />
              <NumberField source="amount_deposit" label="Amount adjust" />
              <NumberField source="bank_charge" />
              <NumberField source="exchange" />
              {/* <NumberField source="negative_adjust" label="Negative adjustment" /> */}
              <TextField source="remark" />
            </Datagrid>
          </ListContextProvider>
        )}
      </div>
    </>
  )
}

export default PaymentList
