import React from 'react'
import { forwardRef } from 'react'
import { AppBar, UserMenu } from 'react-admin'
import Typography from '@material-ui/core/Typography'
// import PersonIcon from '@material-ui/icons/Person'
import { makeStyles } from '@material-ui/core/styles'
// import PermissionIterator from 'src/pages/permissions/PermissionIterator'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
})

const YouProfileMenu = forwardRef<any, any>((props, ref) => {
  // const { identity } = useGetIdentity()

  return (
    <>
      {/* <MenuItemLink
        ref={ref}
        to={`/profile/${identity?.fullName}`}
        primaryText={'Your profile'}
        leftIcon={<PersonIcon />}
        onClick={props.onClick}
        sidebarIsOpen
      /> */}
      {/* <MenuItemLink
        ref={ref}
        to="/settings"
        primaryText={'Settings'}
        leftIcon={<SettingsIcon />}
        onClick={props.onClick}
        sidebarIsOpen
      /> */}
    </>
  )
})

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <YouProfileMenu />
  </UserMenu>
)

const CustomAppBar = (props: any) => {
  const classes = useStyles()
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <span className={classes.spacer} />
    </AppBar>
  )
}

export default CustomAppBar
