import React, { FC } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Dialog from 'src/components/Dialog'
import DestinationPortReferenceInput from '../destinationPorts/DestinationPortReferenceInput'
import { CustomerPort_expanded } from './types'

interface Props {
  open: boolean
  data: CustomerPort_expanded[]
  value: string
  onAdd: () => void
  onChange: (value: string) => void
  onSubmit: () => void
  onClose?: () => void
  onCancel?: () => void
}

const EditCustomerPortDialog: FC<Props> = ({ open, data, value, onAdd, onSubmit, onChange, onClose, onCancel }) => {
  return (
    <Dialog
      title="Edit destination port"
      open={open}
      onClose={onClose}
      onCancel={onCancel}
      onSave={onSubmit}
      disabled={!value ? true : false}
    >
      <Box display="flex">
        <DestinationPortReferenceInput source="select_port_fk_id" label="Port" fullWidth />
        <Box mt={1} ml={2}>
          <Button variant="outlined" color="primary" onClick={onAdd}>
            Add
          </Button>
        </Box>
      </Box>
      <FormControl component="fieldset">
        <FormLabel component="legend">Choose default: </FormLabel>
        {data.length ? (
          <RadioGroup aria-label="isDefault" name="isDefault" value={value} onChange={(e) => onChange(e.target.value)}>
            {data.map((item, index) => (
              <FormControlLabel
                value={item.id}
                color="primary"
                control={<Radio />}
                label={item.destination_port.name}
                key={index}
              />
            ))}
          </RadioGroup>
        ) : null}
      </FormControl>
    </Dialog>
  )
}

export default EditCustomerPortDialog
