import React, { FC, useMemo } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { InputProps, useInput, useQueryWithStore } from 'react-admin'
import { AppState, InvoicePrefix_fields } from 'src/types/globalTypes'

interface Props extends InputProps {
  disabled?: boolean
  label?: string
}

const InvoicePrefixReferenceInput: FC<Props> = ({ disabled, ...props }) => {
  const {
    input: { value, name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  const { data, loading } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'invoice_prefix',
    payload: {
      sort: { field: 'format', order: 'ASC' },
    },
  })

  let options = useMemo(() => formatItems(data), [data])

  return (
    <Autocomplete
      id="invoice-prefix-autocomplete"
      loading={loading}
      value={value || null}
      options={options}
      onChange={(event, value) => {
        onChange(value)
      }}
      disabled={disabled}
      getOptionLabel={(option) => (option ? option.format : ``)}
      getOptionSelected={(option, values) => option.id === values.id}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          error={!!(touched && error)}
          helperText={touched && error}
          required={isRequired}
          label={props.label || 'Prefix'}
          variant="filled"
          size="small"
        />
      )}
    />
  )
}

export default InvoicePrefixReferenceInput

interface Choice {
  id: string
  format: string
}

function formatItems(data: InvoicePrefix_fields[]) {
  let items: Choice[] = []
  if (data) {
    items = data.map((item) => ({
      id: item.id,
      format: item.format,
    }))
  }
  return items
}
