import { gql } from '@apollo/client'

export const GET_INVENTORY_LINE_ITEMS = gql`
  {
    id
    inventory_id
    product_fk
    size_fk
    size_value_fk
    pack_fk
    box
    amount
    weight_mc
    sku_fk
    glazing
    net_weight_kg

    inventory {
      vendor_fk_code
    }
    product {
      id
      name
    }
    size {
      id
      label
    }
    size_value {
      id
      label
    }
    pack {
      id
      name
    }
  }
`
