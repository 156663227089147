import PermissionGroupCreate from './PermissionGroupCreate'
import PermissionGroupEdit from './PermissionGroupEdit'
import PermissionGroupList from './PermissionGroupList'

const permissionGroups = {
  list: PermissionGroupList,
  create: PermissionGroupCreate,
  edit: PermissionGroupEdit,
}

export default permissionGroups
