import { InvoiceLineItem_expanded, InvoicePDFSettingsProps, Invoice_expanded } from '../../types'
import { invoice_header } from './template1/header'
import { invoice_pdf_style } from './styles/general'
import { invoice_lineItems } from './template1/lineItems'
import { invoice_totalAmount } from './template1/totalAmount'
import { invoice_authorization } from './footer/authorization'
import { invoice_production } from './footer/production'
import getInvoiceSumValues from '../getInvoiceSumValues'
import { invoice_groupItems } from './template1/groupItems'
import { InvoiceGroupItem_fields, InvoiceProductSample_fields } from 'src/types/globalTypes'
import { invoice_lineItemsGroupItems } from './template1/LineItemsGroupItems'
import { invoice_footer_img2 } from './images/image_footer2'
import { getLetterHeader } from './utils/getLetterHeader'

const INVOICE_HEADER_COL_SPAN = 5
const LEFT_HEADER_COL_SPAN = 2
const RIGHT_HEADER_COL_SPAN = 3
const HEADER_ROWS = 5

interface Props {
  data: Invoice_expanded
  lineItems: InvoiceLineItem_expanded[]
  groupItems: InvoiceGroupItem_fields[]
  productSamples: InvoiceProductSample_fields[]
  vendorPort?: string
  customerPort?: string
  settings: InvoicePDFSettingsProps
}

export function invoice_template1(props: Props) {
  const { data, lineItems, groupItems, productSamples, customerPort, vendorPort, settings } = props

  let bank_details = ''
  let displayLineItems: any[] = []
  let displayHeader = getLetterHeader(settings.letterHead)

  let { total_ctn, total_net_weight_kg, total_gross_weight_kg, total_amount } = getInvoiceSumValues({
    unit_code: data.unit_code?.code || '',
    lineItems,
    groupItems,
    itemView: settings.itemView,
    showSample: settings.showProductSample,
    total_sample_ctn: productSamples.length,
  })

  if (data.bank_choice) {
    for (let [key, value] of Object.entries(props.data.bank_choice?.description as any)) {
      bank_details += `${key}: ${value}\n`
    }
  }

  let invoiceHeader = invoice_header({
    data: props.data,
    customerPort,
    invoiceColSpan: INVOICE_HEADER_COL_SPAN,
    leftColSpan: LEFT_HEADER_COL_SPAN,
    rightColSpan: RIGHT_HEADER_COL_SPAN,
    settings,
  })

  let lineItemsTable = invoice_lineItems({
    mark_nos: props.data.information.mark_nos,
    unit_code: data.unit_code?.code || '',
    data: lineItems,
    settings,
  })

  let lineItemsGroupItems = invoice_lineItemsGroupItems({
    mark_nos: data.information.mark_nos,
    data: lineItems,
    groupData: groupItems,
    unit_code: data.unit_code?.code || 'kg',
    showSample: settings.showProductSample,
  })

  let groupItemsTable = invoice_groupItems({
    data: groupItems,
    mark_nos: props.data.information.mark_nos,
  })

  let totalAmount = invoice_totalAmount({
    type_no: data.type_no,
    total_net_weight_kg: total_net_weight_kg,
    terms_of_sale: data.terms_of_sale,
    port: vendorPort || '',
    total_amount: total_amount,
    friehgt_charge: data.freight_charge,
    settings,
  })

  let production = invoice_production({
    settings,
    start_date: data.invoice_header?.order.start_date,
    end_date: data.invoice_header?.order.end_date,
    total_net_weight: total_net_weight_kg,
    total_gross_weight: total_gross_weight_kg,
    total_package_cartons: total_ctn,
    production_of: data.information.production_country,
  })

  let authorization =
    settings.showExport === true ? invoice_authorization({ ...props.data, bank_details, settings }) : []

  switch (settings.itemView) {
    case 1:
      displayLineItems = lineItemsTable
      break
    case 2:
      displayLineItems = lineItemsGroupItems
      break
    case 3:
      displayLineItems = groupItemsTable
      break
    default:
      displayLineItems = lineItemsTable
      break
  }

  let displayFooter = settings.showFooter
    ? {
        image: invoice_footer_img2,
        alignment: 'center',
        width: 500,
      }
    : {}

  const documentDefinition = {
    pageSize: 'A4',
    pageMargins: [45, 100, 10, 80],

    info: {
      title: `${data.invoice_header?.ref_invoice}`,
    },

    ...displayHeader,

    footer: (currentPage: number, pageCount: number) => {
      return [
        displayFooter,
        {
          fontSize: 9,
          margin: [0, settings.showFooter ? 0 : 60, 10, 0],
          text: `${currentPage}/${pageCount}`,
          alignment: 'right',
        },
      ]
    },
    content: [
      {
        margin: [0, 0, 0, 0],
        style: 'tableFontSizeDefault',
        table: {
          widths: [80, 190, 65, 65, 55],
          headerRows: HEADER_ROWS,
          body: [...invoiceHeader, ...displayLineItems, ...totalAmount],
        },
      },
      ...production,
      ...authorization,
    ],

    styles: invoice_pdf_style,
  }

  return documentDefinition
}
