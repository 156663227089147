import React, { SetStateAction, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useGetOne, useLoading } from 'react-admin'
import { DateRange } from 'materialui-daterange-picker'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import InsightDateRange from './components/Tools/InsightDateRange'
import SectionTitle from 'src/components/SectionTitle'
import { InsightOverviewState, InsightWidgetStates } from './types'
import useVendors from 'src/hooks/useVendors'
import OverviewTabPanel from './components/Overview/OverviewTabPanel'

interface Props {
  state: InsightOverviewState
  setState: (values: SetStateAction<InsightOverviewState>) => void
}

export default function InsightOverview(props: Props) {
  const widgetId = 'e0952e5e-e24a-4002-94bc-d31749fd430f'
  const { state, setState } = props
  let loading = useLoading()

  const { data: insight } = useGetOne<InsightWidgetStates>('widgets', widgetId)
  const { data: vendors } = useVendors()
  const [tab, setTab] = useState(0)

  const handleDateRange = (value: DateRange) => {
    setState((prev) => ({ ...prev, dateRange: value }))
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Box display={{ xs: 'block', md: 'flex' }}>
            <SectionTitle label="pos.insight.results" />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="right">
            <InsightDateRange dateRange={state.dateRange} setDateRange={handleDateRange} />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AppBar style={{ boxShadow: 'none' }} position="static" color="inherit">
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab disabled={loading} label="All" {...a11yProps(0)} />
              {vendors.map((item, index) => (
                <Tab disabled={loading} key={index + 1} label={item.code} {...a11yProps(index + 1)} />
              ))}
            </Tabs>
            <OverviewTabPanel
              loading={loading}
              value={tab}
              index={tab}
              dateRange={state.dateRange}
              target={insight?.values.revenue_target || 0}
              vendor={tab === 0 ? 'All' : vendors[tab - 1].code}
            />
          </AppBar>
        </Grid>
      </Grid>
    </Box>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}
