import React from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Expenses_fields } from 'src/types/globalTypes'
import { toDecimalStr } from 'src/utils/toDecimal'
import { useDelete, useNotify, useRefresh, useUpdate } from 'react-admin'
import EditExpenseDialog from './EditExpenseDialog'
import { expenseValuesData } from './data'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  })
)

export default function ExpenseCard({ data }: { data: Expenses_fields }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [editable, setEditable] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const isMenuOpen = Boolean(anchorEl)

  const [update] = useUpdate()

  const [handleDeleteExpense] = useDelete('expenses', data.id, data, {
    mutationMode: 'undoable',
    onSuccess: () => {
      notify('resources.global.notifications.success.delete', undefined, undefined, true)
      setAnchorEl(null)
      refresh()
    },
  })

  const handleSubmit = async (values: any) => {
    let shipping_expense = (await values.expenses.length)
      ? values.expenses.map((item: any) => Number(item.value)).reduce((a: number, b: number) => a + b)
      : 0

    let expenses = (await values.expenses.length)
      ? values.expenses.map((item: any) => {
          return {
            label: item.label,
            value: Number(item.value),
          }
        })
      : expenseValuesData

    const object: any = {
      title: values.title,
      shipping_expense,
      values: expenses,
    }

    update('expenses', data.id, object, data, {
      onSuccess: () => {
        notify('resources.global.notifications.success.update', { type: 'info' })
        setEditable(false)
        refresh()
      },
    })
  }

  const handleEditOpen = () => {
    setEditable(true)
    handleMenuClose()
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const menuId = 'more-function-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
      <MenuItem onClick={handleDeleteExpense}>Delete</MenuItem>
    </Menu>
  )

  return (
    <Card>
      <CardHeader
        title={data.title ? data.title : `${data.vendor_fk_code}-${data.customer_fk_code}`}
        subheader={`Shipping expense ${toDecimalStr(data.shipping_expense)}`}
        action={
          <>
            <IconButton onClick={handleOpen}>
              <MoreVertIcon />
            </IconButton>
            {renderMenu}
          </>
        }
      />
      <CardActions disableSpacing>
        <Box width="100%" justifyContent="center" display="flex">
          <IconButton
            className={clsx(classes.expand, { [classes.expandOpen]: expanded })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Details of Expenses</TableCell>
                <TableCell align="right">Total(THB).</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.values.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.label}</TableCell>
                  <TableCell align="right">{item.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>

      <EditExpenseDialog
        id={data.id}
        open={editable}
        onSubmit={handleSubmit}
        onClose={() => setEditable(false)}
        onCancel={() => setEditable(false)}
      />
    </Card>
  )
}
