import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { toDecimalStr } from 'src/utils/toDecimal'
import MTTable from 'src/components/MTTable'
import { MTableBodyRow } from 'material-table'
import { InvoiceGroupItem_fields } from 'src/types/globalTypes'
import InvoiceGroupDetailPanel from './InvoiceGroupDetailPanel'
import { InvoiceLineItem_expanded } from './types'

const useStyles = makeStyles((theme) => ({
  product: {
    width: '600px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  pack: {
    width: '240px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  weight: {
    width: '150px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
}))

interface Props {
  loading?: boolean
  data: InvoiceGroupItem_fields[]
  lineItems: InvoiceLineItem_expanded[]
  unit_code: string
  onUpdate?: (newData: any, oldData: any) => void
  onDelete?: (oldData: any) => void
}

export default function InvoiceGroupItemsDetail(props: Props) {
  const classes = useStyles()
  const { loading, data, lineItems, onUpdate, onDelete } = props

  return (
    <MTTable
      isLoading={loading}
      data={data || []}
      columns={[
        {
          title: 'Product',
          field: 'product',
          editComponent: (props) => (
            <TextField
              style={{ width: '600px' }}
              inputProps={{
                style: {
                  fontSize: 12,
                },
              }}
              value={props.value}
              onChange={(e) => props.onChange(e.target.value)}
            />
          ),
          render: (rowData: any) => (
            <div className={classes.product}>
              <Typography variant="body2">
                {rowData.product}
                <Typography component="span" variant="caption">
                  {rowData.size_value ? ` - ${rowData.size_value.label} ${rowData.size?.label}` : ''}
                </Typography>
              </Typography>
            </div>
          ),
        },
        {
          title: 'Box',
          field: 'box',
          type: 'numeric',
          render: (rowData: any) => <div>{toDecimalStr(rowData.box, true)}</div>,
        },
        {
          title: 'TOTAL NET/WET (KG)',
          field: 'net_weight_kgs',
          type: 'numeric',
          render: (rowData: any) => <div>{toDecimalStr(rowData.net_weight_kgs)}</div>,
        },
        {
          title: 'GROSS WET (KG)',
          field: 'gross_weight_kgs',
          type: 'numeric',
          render: (rowData: any) => <div>{toDecimalStr(rowData.gross_weight_kgs)}</div>,
        },
        {
          title: 'Price',
          field: 'unit_price',
          type: 'numeric',
          render: (rowData: any) => <div>{toDecimalStr(rowData.unit_price)}</div>,
        },
        {
          title: 'Amount',
          field: 'amount',
          type: 'numeric',
          render: (rowData: any) => <div>{toDecimalStr(rowData.amount)}</div>,
        },
      ]}
      detailPanel={(rowData: any) => <InvoiceGroupDetailPanel slug={rowData.slug} lineItems={lineItems} />}
      components={{
        Row: (props) => (
          <MTableBodyRow
            {...props}
            onRowClick={(e: any) => {
              props.actions[0]().onClick(e, props.data)
            }}
          />
        ),
      }}
      editable={{
        onRowUpdate: (newData: any, oldData: any) =>
          new Promise((resolve, reject) => {
            if (newData.id) {
              if (onUpdate) {
                resolve(onUpdate(newData, oldData))
              }
            }
          }),
        onRowDelete: (oldData: any) =>
          new Promise((resolve, reject) => {
            if (oldData.id) {
              if (onDelete) {
                resolve(onDelete(oldData))
              }
            }
          }),
      }}
      style={{
        border: '1px solid #e3e3e3',
      }}
    />
  )
}
