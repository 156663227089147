import { OrderCreateInput, OrderReviseCreateInput } from '../types/input'

export const reviseInitialValues: OrderReviseCreateInput = {
  name: 'REVISE-0',
  date: new Date(),
  exchange_rate_info: {
    usd_export: 31,
    etc_cost: 8,
    shipping_usd: 32.87,
  },
  revise_key: 0,
  expense_rate_info: [],
  shipping_baht: 0,
  expense_id: null,
  gross_percentage: 3,
  locale_currency_fk: 'USD',
}

export const orderInitialValuesCreate: OrderCreateInput = {
  isDone: false,
  current_revise_num: 0,
  vendor_fk_code: '',
  customer_fk_code: '',
  ref_pi: '',
  description: '',
  pi_date: new Date(),
  date_shipment: new Date(),
  start_date: new Date(),
  end_date: new Date(),
  total_amount: 0,
  total_net_weight: 0,
  status: 'order_received',
  order_line_items: [],
  shipping_details: {
    from_port: '',
    destination_port: '',
    documents_required: [],
    exporter_bank_account: 'bbl_bank',
    payment_terms: '',
    terms_of_sales: 'cnf',
    est_date_of_loading: new Date(),
  },
  shipping_addresses: {
    buyer_address: '',
    notify_address: '',
  },
  exchange_rate: 31,
  unit_code_fk_id: '0959d6e8-6fe4-4ab8-81da-a40c61726226',
  currency_fk: '',
  shipment_type_fk_id: '5fb96cf6-7252-415b-bc3f-f63db1268cb6',
  order_revises: [reviseInitialValues],
  remark: '',
  sticker: '',
}
