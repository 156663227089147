import React, { FC } from 'react'
import { Create, CreateProps } from 'react-admin'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import {
  permissionPages,
  permissionsCofigurations,
  permissionAdmin,
  permissionOrderLineItemsTab,
} from '../permissions/data'
import PermissionGroupForm from './PermissionGroupForm'

const PermissionGroupCreate: FC<CreateProps> = (props) => {
  const transform = (data: any) => ({
    ...data,
    permissions: {
      data: [...data.permissions],
    },
  })
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New permission group`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props} component="div" transform={transform}>
        <PermissionGroupForm
          create
          initialValues={{
            vendors: [],
            permissions: [
              ...permissionPages,
              ...permissionsCofigurations,
              ...permissionAdmin,
              ...permissionOrderLineItemsTab,
            ],
          }}
        />
      </Create>
    </>
  )
}

export default PermissionGroupCreate
