import React, { ChangeEvent } from 'react'
import useCurrencies from 'src/hooks/useCurrencies'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

interface Props {
  name?: string
  label?: string
  value: string
  disabled?: boolean
  onChange: (event: ChangeEvent<{ value: unknown }>) => void
  loading?: boolean
}

export default function FilledCurrencySelect(props: Props) {
  const { name, value, label, loading, onChange, disabled } = props
  const { data } = useCurrencies()

  return (
    <div style={{ width: `100%` }}>
      {!loading && (
        <FormControl size="small" fullWidth variant="filled">
          <InputLabel htmlFor="filled-currency-native-simple">{label}</InputLabel>
          <Select
            label={label}
            native
            disabled={disabled}
            name={name}
            value={value}
            onChange={onChange}
            inputProps={{
              name: 'Expense currecy',
              id: 'filled-currency-native-simple',
            }}
          >
            <option value="">Select currency</option>
            {data.length ? (
              data.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.code}
                </option>
              ))
            ) : (
              <option>No data</option>
            )}
          </Select>
        </FormControl>
      )}
    </div>
  )
}
