import React, { FC } from 'react'
import {
  Datagrid,
  EmailField,
  Filter,
  FilterProps,
  List,
  ListProps,
  SearchInput,
  TextField,
  useNotify,
  useRedirect,
} from 'react-admin'
import { Helmet } from 'react-helmet'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'

const CustomerFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput source="company_name,code@_ilike" alwaysOn />
  </Filter>
)

const CustomerList = (props: ListProps) => {
  const { permission, loaded } = usePermissionByUser('customer')
  const redirect = useRedirect()
  const notify = useNotify()

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Customers`} />
      <RouteBreadcrumbs {...props} />
      <List
        {...props}
        perPage={25}
        sort={{ field: 'code', order: 'ASC' }}
        filters={<CustomerFilter />}
        bulkActionButtons={false}
        hasCreate={permission.create}
      >
        <Datagrid rowClick="edit">
          <TextField source="company_name" />
          <TextField source="code" />
          <EmailField source="email" />
        </Datagrid>
      </List>
    </>
  )
}

export default CustomerList
