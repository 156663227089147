import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import SectionTitle from 'src/components/SectionTitle'
import { ReferenceInput, SelectInput, useGetList } from 'react-admin'
import Separate from 'src/components/Separate'
import CurrencyForm from 'src/components/CurrencyForm'
import { useForm, useFormState } from 'react-final-form'
import { Widgets_fields } from 'src/types/globalTypes'

interface Props {
  disabled?: boolean
  edit?: boolean
  date?: string | Date
}

export default function OrderSettingsTab(props: Props) {
  const { edit, disabled } = props
  const { values } = useFormState()
  const { change } = useForm()

  const { data, ids, loaded } = useGetList<Widgets_fields>(
    'widgets',
    { page: 1, perPage: 1 },
    { field: 'name', order: 'ASC' },
    { name: values.currency_fk || 'USD' }
  )

  useEffect(() => {
    if (loaded) {
      let currencies = ids.length ? ids.map((id) => data[id]) : []
      let buy_rate = currencies[0].values.buy_rate

      if (!edit) {
        change('exchange_rate', buy_rate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, ids, data])

  return (
    <>
      <Box p={2} width="40em">
        <SectionTitle label="resources.order.fieldGroups.unit" />
        <ReferenceInput
          style={{ width: '350px' }}
          label="Code"
          source="unit_code_fk_id"
          reference="unit_code"
          sort={{ field: 'code', order: 'ASC' }}
        >
          <SelectInput disabled={disabled} optionText="name" />
        </ReferenceInput>
        <Separate value={2} />
        <SectionTitle mb={0.2} label="resources.order.fieldGroups.currency" />
        <CurrencyForm required disabled={disabled} {...props} />
      </Box>
    </>
  )
}
