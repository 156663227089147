import { InvoiceGroupItem_fields, InvoiceProductSample_fields } from 'src/types/globalTypes'
import { InvoiceLineItem_expanded, InvoicePDFSettingsProps, Invoice_expanded } from '../../types'
import getInvoiceSumValues from '../getInvoiceSumValues'
import { invoice_authorization } from './footer/authorization'
import { invoice_production } from './footer/production'
import { invoice_pdf_style } from './styles/general'
import { invoice_groupItems2 } from './template2/groupItems'
import { invoice_header2 } from './template2/header'
import { invoice_lineItems2 } from './template2/lineItems'
import { invoice_lineItemsGroupItems2 } from './template2/LineItemsGroupItems'
import { invoice_productSample2 } from './template2/productSample'
import { invoice_totalAmount2 } from './template2/totalAmount'
import { getLetterHeader } from './utils/getLetterHeader'

const INVOICE_HEADER_COL_SPAN = 7
const LEFT_HEADER_COL_SPAN = 2
const RIGHT_HEADER_COL_SPAN = 5
const RIGHT_HEADER_COL_SPAN_TYPE_2 = 3
const HEADER_ROWS = 5

interface Props {
  data: Invoice_expanded
  customerPort?: string
  vendorPort?: string
  lineItems: InvoiceLineItem_expanded[]
  groupItems: InvoiceGroupItem_fields[]
  productSamples: InvoiceProductSample_fields[]
  settings: InvoicePDFSettingsProps
}

export function invoice_template2(props: Props) {
  const { data, vendorPort, customerPort, lineItems, groupItems, productSamples, settings } = props

  let displayLineItems: any[] = []
  let bank_details = ''
  let displayHeader = getLetterHeader(settings.letterHead)

  let { total_ctn, total_net_weight_kg, total_gross_weight_kg, total_amount } = getInvoiceSumValues({
    unit_code: data.unit_code?.code || '',
    lineItems,
    groupItems,
    itemView: settings.itemView,
    showSample: settings.showProductSample,
    total_sample_ctn: productSamples.length,
  })

  if (data.bank_choice) {
    for (let [key, value] of Object.entries(props.data.bank_choice?.description as any)) {
      bank_details += `${key}: ${value}\n`
    }
  }

  // let showProductSample = settings?.showProductSample === 'show' ? true : false

  // let pageMargins: number[] = []
  // let widths: number[] = []
  // let count: { [k: number]: number } = { 0: 0 }
  // let countMc = 0

  // let showLineItems: any[] = []
  // let showAmount: any[] = []
  // let total_amount_value = 0
  // let total_package_cartons = 0
  // let total_net_weight = 0
  // let total_gross_weight = 0
  // let hasProductSample = false

  // if (data.type_no === 2) {
  //   pageMargins = [50, 100, 15, 80]
  //   widths = [55, 188, 70, 70, 70]
  // } else {
  //   pageMargins = [isShowSize ? 37 : 70, 100, 10, 80]
  //   widths = isShowSize ? [55, 170, 70, 40, 40, 45, 45] : [60, 130, 60, 45, 50, 50]
  // }

  // if (data.bank_choice) {
  //   for (let [key, value] of Object.entries(data.bank_choice.description)) {
  //     bank_details += `${key}: ${value}\n`
  //   }
  // }

  // if (data.invoice_product_samples.length) {
  //   data.invoice_product_samples.forEach((x) => {
  //     count[x.box] = (count[x.box] || 0) + 1
  //   })
  // }

  let header = invoice_header2({
    data: props.data,
    customerPort,
    invoiceColSpan: INVOICE_HEADER_COL_SPAN,
    leftColSpan: LEFT_HEADER_COL_SPAN,
    rightColSpan: RIGHT_HEADER_COL_SPAN,
    settings,
  })

  let lineItemsTable = invoice_lineItems2({
    mark_nos: data.information.mark_nos,
    data: lineItems,
    settings,
  })

  let lineItemsGroupItems = invoice_lineItemsGroupItems2({
    mark_nos: data.information.mark_nos,
    data: lineItems,
    groupData: groupItems,
    unit_code: data.unit_code?.code || 'kg',
    showSample: settings.showProductSample,
  })

  let groupItemsTable = invoice_groupItems2({
    data: groupItems,
    mark_nos: props.data.information.mark_nos,
  })

  let productSamplesTable = settings.showProductSample ? invoice_productSample2(productSamples) : []

  let totalAmount = invoice_totalAmount2({
    type_no: data.type_no,
    total_package_cartons: total_ctn,
    total_net_weight: total_net_weight_kg,
    total_gross_weight: total_gross_weight_kg,
    total_amount_value: total_amount,
  })

  let production = invoice_production({
    settings,
    start_date: data.invoice_header?.order.start_date,
    end_date: data.invoice_header?.order.end_date,
    total_net_weight: total_net_weight_kg,
    total_gross_weight: total_gross_weight_kg,
    total_package_cartons: total_ctn,
    production_of: data.information.production_country,
  })

  let authorization =
    settings.showExport === true ? invoice_authorization({ ...props.data, bank_details, settings }) : []

  switch (settings.itemView) {
    case 1:
      displayLineItems = lineItemsTable
      break
    case 2:
      displayLineItems = lineItemsGroupItems
      break
    case 3:
      displayLineItems = groupItemsTable
      break
    default:
      displayLineItems = lineItemsTable
      break
  }

  const documentDefinition = {
    pageSize: 'A4',
    pageMargins: [50, 100, 15, 20],

    ...displayHeader,

    footer: (currentPage: number, pageCount: number) => {
      return [
        {
          fontSize: 9,
          margin: [0, 0, 10, 0],
          text: `${currentPage}/${pageCount}`,
          alignment: 'right',
        },
      ]
    },

    info: {
      title: `${data.invoice_header?.ref_invoice}`,
    },
    content: [
      {
        margin: [0, 0, 0, 0],
        style: 'tableFontSizeDefault',

        table: {
          widths: [55, 170, 70, 40, 40, 45, 45],
          headerRows: HEADER_ROWS,
          body: [...header, ...displayLineItems, ...totalAmount],
        },
      },
      ...production,
      ...authorization,
    ],
    styles: invoice_pdf_style,
  }

  return documentDefinition
}
