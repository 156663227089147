import React, { useCallback, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import InsightWidget from '../Tools/InsightWidget'
import Separate from 'src/components/Separate'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import { useDataProvider } from 'react-admin'
import { formatDateToAPI } from 'src/utils/formatDate'
import { Payment_fields } from 'src/types/globalTypes'
import { Invoice_expanded } from 'src/pages/invoices/types'
import getInvoiceSumValues from 'src/pages/invoices/utils/getInvoiceSumValues'

interface Props {
  target: number
  vendor: string
}

interface State {
  total_invoice_amount?: number
  total_invoice_qty?: number
  total_payment_amount?: number
}

export default function OveriewDaily(props: Props) {
  const { target, vendor } = props
  const [state, setState] = useState<State>()
  const dataProvider = useDataProvider()

  const fetchInvoices = useCallback(async () => {
    const { data } = await dataProvider.getList<Invoice_expanded>('invoice', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'date', order: 'DESC' },
      filter: {
        'invoice_header#order#status': 'shipped_out',
        'invoice_header#order#vendor_fk_code': vendor === 'All' ? undefined : vendor,
        'date@_gte': formatDateToAPI(new Date()),
        type_no: 3,
      },
    })

    const aggregation = data.length
      ? data
          .map((invoice) => {
            const { total_amount, total_net_weight_kg } = getInvoiceSumValues({
              itemView: 1,
              lineItems: invoice.invoice_line_items,
              unit_code: invoice.unit_code?.code || 'kg',
            })

            return {
              total_amount,
              total_net_weight_kg,
            }
          })
          .reduce((a, b) => ({
            total_amount: a.total_amount + b.total_amount,
            total_net_weight_kg: a.total_net_weight_kg + b.total_net_weight_kg,
          }))
      : null

    setState((prev) => ({
      ...prev,
      total_invoice_amount: toDecimal(aggregation?.total_amount || 0),
      total_invoice_qty: toDecimal(aggregation?.total_net_weight_kg || 0),
    }))
  }, [vendor, dataProvider])

  const fetchPayments = useCallback(async () => {
    const { data } = await dataProvider.getList<Payment_fields>('payment', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'payment_date', order: 'DESC' },
      filter: { payment_date: formatDateToAPI(new Date()), type: 'deposit' },
    })

    const aggregation = data.length ? data.map((item) => item.amount_deposit).reduce((a, b) => a + b) : 0

    setState((prev) => ({
      ...prev,
      total_payment_amount: aggregation,
    }))
  }, [dataProvider])

  useEffect(() => {
    fetchInvoices()
    fetchPayments()
  }, [fetchInvoices, fetchPayments])

  return (
    <div>
      <Typography variant="h6">Today</Typography>
      <Separate value={2} />
      <InsightWidget color="#b3b3b3" title="Target" value={`${toDecimalStr(target)}`} />
      <Separate value={2} />
      <InsightWidget
        color="#b3b3b3"
        title="Receviables (USD)"
        value={`${toDecimalStr(state?.total_invoice_amount || 0)}`}
      />
      <Separate value={2} />
      <InsightWidget color="#b3b3b3" title="Quantity (MT)" value={`${toDecimalStr(state?.total_invoice_qty || 0)}`} />
      <Separate value={2} />
      <InsightWidget color="#b3b3b3" title="Infow (USD)" value={`${toDecimalStr(state?.total_payment_amount || 0)}`} />
      <Separate value={2} />
      <InsightWidget
        color="#b3b3b3"
        title="Paid to invoice"
        value={`${toDecimalStr(
          state?.total_invoice_amount ? ((state.total_payment_amount || 0) / state.total_invoice_amount) * 100 : 0
        )} %`}
      />
    </div>
  )
}
