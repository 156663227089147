import React, { FC, useEffect, useState } from 'react'
import { AutocompleteInput, AutocompleteInputProps, Identifier, useQueryWithStore } from 'react-admin'
import { AppState, DestinationPort_fields } from 'src/types/globalTypes'

interface Choices {
  id: string
  name: string
}
const DestinationPortReferenceInput: FC<AutocompleteInputProps> = (props) => {
  const [choices, setChoices] = useState<Choices[]>([])
  const { data: ports } = useQueryWithStore<AppState>(
    {
      type: 'getList',
      resource: 'destination_port',
      payload: {
        sort: { field: 'name', order: 'ASC' },
      },
    },
    {},
    (state) => {
      let ids: Identifier[] = Object.keys(state.admin.resources.destination_port.data)
      let data = state.admin.resources.destination_port.data
      let result = ids.map((id) => data[id])
      return result
    }
  )

  useEffect(() => {
    if (ports.length) {
      let newValues = ports.map((item: DestinationPort_fields) => ({
        id: item.id,
        name: item.name,
      }))

      setChoices(newValues)
    }
  }, [ports])

  return (
    <>
      <AutocompleteInput source="destination_port_fk" size="small" optionText="name" choices={choices} {...props} />
    </>
  )
}

export default DestinationPortReferenceInput
