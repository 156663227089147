import React, { FC, useState } from 'react'
import classnames from 'classnames'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Datagrid,
  DateInput,
  Filter,
  FilterProps,
  List,
  ListProps,
  SearchInput,
  TextField,
  useNotify,
  useRedirect,
} from 'react-admin'
import CheckField from 'src/components/CheckField'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import InvoiceHeaderReferenceField from './InvoiceHeaderReferenceField'
import InvoiceHeaderPanel from './InvoiceHeaderPanel'
import InvoiceHeaderQuickView from './InvoiceHeaderQuickView'
import DateField from 'src/components/DateField'
import CustomerReferenceInput from '../customers/CustomerReferenceInput'
import VendorReferenceInput from '../vendors/VendorReferenceInput'
import OrderReferenceField from '../orders/fields/OrderReferenceField'
import useVendorsByUser from 'src/hooks/useVendorsByUser'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'
import CustomerPortReferenceField from '../customerPorts/CustomerPortReferenceField'
import CustomerPortCountryField from '../customerPorts/CustomerPortCountryField'

interface CustomFilterProps extends Omit<FilterProps, 'children'> {
  vendorsByUser: string[]
}

const InvoiceHeaderFilter: FC<CustomFilterProps> = (props) => (
  <Filter {...props}>
    <SearchInput source="ref_invoice" alwaysOn />
    <DateInput source="date@_gte" alwaysOn />
    <DateInput source="date@_lte" />
    <VendorReferenceInput source="order#vendor_fk_code" alwaysOn label="Vendor" emptyValue={props.vendorsByUser} />
    <CustomerReferenceInput source="order#customer_fk_code@_eq" alwaysOn label="Customer" />
  </Filter>
)

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
  },
}))

const InvoiceHeaderList: FC<ListProps> = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [selectedId, setSelected] = useState('')
  let year = new Date().getFullYear()
  const { data: vendorsByUser, isSuperuser } = useVendorsByUser()
  const { permission, loaded } = usePermissionByUser('invoice_header')
  const redirect = useRedirect()
  const notify = useNotify()

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  function handleOpen(id: string) {
    setSelected(id)
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Invoice header`} />
      <RouteBreadcrumbs {...props} />
      <List
        {...props}
        className={classnames(classes.list, { [classes.listWithDrawer]: open })}
        filterDefaultValues={{
          'date@_gte': `${year}-01-01`,
          'order#vendor_fk_code': isSuperuser ? undefined : vendorsByUser.length ? vendorsByUser : '',
        }}
        sort={{ field: 'ref_invoice', order: 'DESC' }}
        perPage={25}
        filters={<InvoiceHeaderFilter vendorsByUser={vendorsByUser} />}
        bulkActionButtons={false}
      >
        <Datagrid rowClick="expand" expand={<InvoiceHeaderPanel onOpen={handleOpen} />} optimized>
          <DateField source="date" label="Create date" />
          <DateField source="order.date_shipment" label="Shipping date" />
          <InvoiceHeaderReferenceField source="id" label="Ref Invoice" />
          <OrderReferenceField source="order_id" label="Order" />
          <TextField source="custom_ref_inv" label="Custom Invoice" style={{ width: '200px' }} />
          <CustomerPortReferenceField
            source="order.shipping_details.destination_port"
            link={false}
            label="Destination country"
          >
            <CustomerPortCountryField />
          </CustomerPortReferenceField>

          <CheckField textAlign="center" source="use_header1" comparator={1} label="Type 1" />
          <CheckField textAlign="center" source="use_header2" comparator={1} label="Type 2" />
          <CheckField textAlign="center" source="use_header3" comparator={1} label="Type 3" />
          {/* <CheckField textAlign="center" source="use_format" comparator={true} label="Format" /> */}
        </Datagrid>
      </List>

      <Drawer
        variant="persistent"
        open={open}
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {open && selectedId ? (
          <InvoiceHeaderQuickView
            mutationMode="pessimistic"
            id={selectedId}
            resource={props.resource}
            basePath={props.basePath}
            onCancel={handleClose}
          />
        ) : null}
      </Drawer>
    </>
  )
}

export default InvoiceHeaderList
