import React, { FC } from 'react'
import {
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FilterProps,
  List,
  NumberField,
  SearchInput,
  TextField,
  useNotify,
  useRedirect,
} from 'react-admin'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import CheckField from 'src/components/CheckField'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'
import useVendorsByUser from 'src/hooks/useVendorsByUser'
import CustomerReferenceInput from '../customers/CustomerReferenceInput'
import VendorReferenceInput from '../vendors/VendorReferenceInput'
import InvoicePanel from './InvoicePanel'
import InvoiceRefField from './InvoiceRefField'

interface CustomFilterProps extends Omit<FilterProps, 'children'> {
  vendorsByUser: string[]
}

const InvoiceFilter: FC<CustomFilterProps> = (props) => (
  <Filter {...props}>
    <SearchInput source="invoice_header#ref_invoice@_ilike" alwaysOn />
    <DateInput source="date@_gte" alwaysOn />
    <DateInput source="date@_lte" />
    <VendorReferenceInput
      source="invoice_header#order#vendor_fk_code"
      label="Vendor"
      alwaysOn
      emptyValue={props.vendorsByUser}
    />
    <CustomerReferenceInput source="invoice_header#order#customer_fk_code@_eq" label="Customer" alwaysOn />
  </Filter>
)

const InvoiceList = () => {
  const { pathname } = useLocation()
  let type_no = pathname.slice(14, 15)
  let year = new Date().getFullYear()
  const { data: vendorsByUser, isSuperuser } = useVendorsByUser()
  const { permission, loaded } = usePermissionByUser('invoice-1')
  const redirect = useRedirect()
  const notify = useNotify()

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Invoices-${type_no}`} />
      <RouteBreadcrumbs />
      <List
        resource="invoice"
        basePath={`/invoice/type-${type_no}`}
        perPage={25}
        filters={<InvoiceFilter vendorsByUser={vendorsByUser} />}
        sort={{
          field: 'invoice_header.ref_invoice',
          order: 'DESC',
        }}
        filterDefaultValues={{
          type_no: Number(type_no),
          'invoice_header#order#vendor_fk_code': isSuperuser ? undefined : vendorsByUser.length ? vendorsByUser : '',
          'date@_gte': `${year}-01-01`,
        }}
        bulkActionButtons={false}
      >
        <Datagrid rowClick="expand" expand={<InvoicePanel />} optimized>
          <DateField source="date" label="Create date" />
          <InvoiceRefField label="Ref Invoice" type_no={type_no} />
          <TextField source="invoice_header.custom_ref_inv" label="Custom Invoice" />
          <CheckField textAlign="center" source="terms_of_sale" comparator={'cnf' || 'fob' || 'cif'} />
          <TextField source="information.cont_no" label="Container No" />
          <TextField source="information.seal_no" label="Seal No" />
          <NumberField source="freight_charge" />
          <NumberField source="config_shipping.advance" label="Advance" />
        </Datagrid>
      </List>
    </>
  )
}

export default InvoiceList
