import React, { FC } from 'react'
import { Create, CreateProps } from 'react-admin'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import PackDetailForm from './PackDetailForm'

const PackCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New pack`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props}>
        <PackDetailForm />
      </Create>
    </>
  )
}

export default PackCreate
