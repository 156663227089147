import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import { MTableBodyRow } from 'material-table'
import MTTable from 'src/components/MTTable'
import { useTheme } from '@material-ui/core'
import getOrderTotalListCost from '../utils/getOrderLineUnitCost'
import { OrderLineItemsTableProps, OrderLineItems_expanded } from '../types'
import getOrderLineAmount from '../utils/getOrdeLineAmount'
import OrderPreviousPriceDialog from './OrderPreviousPriceDialog'

interface Props extends OrderLineItemsTableProps {
  loading?: boolean
  data?: any
  exchange_rate: number
  shipping_cost: number
}

export default function OrderItemsPricingDetail(props: Props) {
  const { loading, data, unit_code, revise, exchange_rate, shipping_cost, onUpdate, onDelete } = props
  const theme = useTheme()
  const [lineItem, setLineItem] = useState<OrderLineItems_expanded | null>(null)
  const [open, setOpen] = useState(false)

  const handleOpen = (values: any) => {
    setOpen(true)
    setLineItem(values)
  }

  const handleClose = () => {
    setLineItem(null)
    setOpen(false)
  }

  return (
    <>
      <MTTable
        isLoading={loading}
        data={data}
        columns={[
          {
            title: 'Product',
            field: 'product.name',
            editable: 'never',
            render: (rowData: any) => (
              <div style={{ width: '350px' }}>
                <Typography variant="body2">
                  {rowData.product.name}
                  <Typography component="span" variant="caption">
                    {rowData.size_value ? ` - ${rowData.size_value.label} ${rowData.size?.label}` : ''}
                  </Typography>
                </Typography>
                <Typography style={{ color: theme.palette.grey[600] }} variant="caption">
                  {rowData.pack?.name || ''}
                </Typography>
              </div>
            ),
          },

          {
            title: 'WET/MC (KG) & GROSS NET/MC (KG)',
            field: 'weight_mc',
            align: 'center',
            editable: 'never',
            render: (rowData: any) => (
              <div style={{ width: '140px' }}>
                {rowData.weight_mc} / {rowData.gross_weight}
              </div>
            ),
          },
          {
            title: 'Total MC',
            field: 'box',
            type: 'numeric',
            render: (rowData: any) => <div>{toDecimalStr(rowData.box, true)}</div>,
          },
          {
            title: 'Total NET/WET (KG)',
            field: 'net_weight_kgs',
            type: 'numeric',
            editable: 'never',
            render: (rowData: any) => toDecimalStr(rowData.weight_mc * rowData.box),
          },
          {
            title: `Unit Cost (USD/${unit_code})`,
            // field: '',
            type: 'numeric',
            editable: 'never',
            render: (rowData: any) => {
              let total_line_cost = getOrderTotalListCost({
                raw_cost: rowData.raw_cost,
                import_cost: rowData.import_cost,
                pack_cost_baht: rowData.pack_cost_baht,
                etc_cost: revise?.exchange_rate_info.etc_cost,
                usd_export: exchange_rate,
                shipping_cost,
              })

              return <div style={{ width: '100px' }}>{toDecimalStr(total_line_cost)}</div>
            },
          },
          {
            title: 'Target Profit(%)',
            field: 'profit',
            type: 'numeric',
            render: (rowData: any) => toDecimalStr(rowData.profit),
          },
          {
            title: 'Suggested Price  (USD)',
            // field: 'margins',
            type: 'numeric',
            render: (rowData: any) => {
              let result = 0
              let unit_cost = getOrderTotalListCost({
                raw_cost: rowData.raw_cost,
                import_cost: rowData.import_cost,
                pack_cost_baht: rowData.pack_cost_baht,
                etc_cost: revise?.exchange_rate_info.etc_cost,
                usd_export: exchange_rate,
                shipping_cost,
              })

              result = toDecimal(unit_cost * (1 + rowData.profit / 100))

              return <div>{toDecimalStr(result)}</div>
            },
          },
          {
            title: 'Previous price (USD)',
            align: 'center',
            render: (rowData: any) => (
              <div style={{ width: '120px' }}>
                <IconButton color="primary" onClick={() => handleOpen(rowData)}>
                  <SearchIcon />
                </IconButton>
              </div>
            ),
          },
          {
            title: `Unit Price (USD/${unit_code})`,
            field: 'unit_price',
            type: 'numeric',
            render: (rowData: any) => <div style={{ width: '100px' }}>{toDecimalStr(rowData.unit_price)}</div>,
          },
          {
            title: 'Amount (USD)',
            field: 'amount',
            type: 'numeric',
            editable: 'never',
            render: (rowData: any) =>
              toDecimalStr(
                getOrderLineAmount({
                  unit_code,
                  weight_mc: rowData.weight_mc,
                  box: rowData.box,
                  unit_price: rowData.unit_price,
                })
              ),
          },
        ]}
        components={{
          Row: (props) => (
            <MTableBodyRow
              {...props}
              onRowClick={(e: any) => {
                props.actions[0]().onClick(e, props.data)
              }}
            />
          ),
        }}
        editable={{
          onRowUpdate: (newData: any, oldData: any) =>
            new Promise((resolve, reject) => {
              if (oldData.id) {
                if (onUpdate) {
                  resolve(onUpdate(newData, oldData))
                }
              }
            }),
          onRowDelete: (oldData: any) =>
            new Promise((resolve, reject) => {
              if (oldData.id) {
                if (onDelete) {
                  resolve(onDelete(oldData))
                }
              }
            }),
        }}
        style={{
          border: '1px solid #e3e3e3',
        }}
      />
      {open && <OrderPreviousPriceDialog open={open} data={lineItem} onClose={handleClose} onCancel={handleClose} />}
    </>
  )
}
