import React, { FC, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import _ from 'lodash'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Dialog from 'src/components/Dialog'
import { useFormState } from 'react-final-form'
import { useQueryWithStore } from 'react-admin'
import MTTable from 'src/components/MTTable'
import { OrderAddLineItemInput } from '../types/input'

interface Props {
  open: boolean
  vendor_fk_code?: string
  customer_fk_code?: string
  tableRef?: any
  disabled?: boolean
  onAddClick: (values: OrderAddLineItemInput) => void
  onSave?: () => void
  onCancel?: () => void
  onClose?: () => void
}

const OrderAddItemDialog: FC<Props> = ({ tableRef, onAddClick, onClose, ...rest }) => {
  const { values } = useFormState()
  const [data, setData] = useState<OrderAddLineItemInput[]>([])

  const { data: productVariants, loading } = useQueryWithStore({
    type: 'getList',
    resource: 'profile_item_value',
    payload: {
      filter: {
        vendor_code: values.vendor_fk_code,
        'customer_fk_ids@_contains': values.customer_fk_code,
      },
    },
  })

  useEffect(() => {
    if (productVariants) {
      let items = _.orderBy(
        productVariants,
        ['product_variant.product.name', 'product_variant.size_value.label', 'product_variant.size.label', 'pack.name'],
        ['asc', 'asc', 'asc', 'asc']
      ).map((item) => ({
        ...item,
        box: 0,
      }))

      setData(items)
    }
  }, [productVariants]) //ts-ignored

  const handleChange = (index: number, value: any) => {
    let newValue = [...data]
    newValue[index].box = value
    setData(newValue)
  }

  return (
    <Dialog
      size="lg"
      title="Add item"
      onClose={onClose}
      {...rest}
      action={
        <IconButton size="small" color="secondary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
    >
      <MTTable
        tableRef={tableRef}
        isLoading={loading}
        data={data}
        columns={[
          {
            title: 'Product',
            field: 'product_variant.product.name',
            editable: 'never',
            render: (rowData: any) => (
              <div style={{ width: '300px' }}>
                <Typography variant="body2">
                  {rowData.product_variant.product.name}
                  <Typography component="span" variant="caption">
                    {rowData.product_variant.size_value
                      ? ` - ${rowData.product_variant.size_value?.label} ${rowData.product_variant.size?.label}`
                      : ''}
                  </Typography>
                </Typography>
              </div>
            ),
          },
          {
            title: 'Pack',
            field: 'pack.name',
            editable: 'never',
            render: (rowData: any) => <div style={{ width: '240px' }}>{rowData.pack?.name || ''}</div>,
          },
          {
            title: 'WET/MC (KG) & GROSS NET/MC (KG)',
            field: 'weight_mc',
            align: 'center',
            editable: 'never',
            render: (rowData: any) => (
              <div>
                {rowData.weight_mc} / {rowData.gross_weight}
              </div>
            ),
          },
          {
            title: 'TOTAL MC',
            field: 'Box',
            render: (rowData: any) => (
              <div>
                <TextField
                  type="number"
                  name={`${rowData.tableData.id}.box`}
                  value={rowData.box || ''}
                  onChange={(e) => handleChange(rowData.tableData.id, Number(e.target.value))}
                />
              </div>
            ),
          },
        ]}
        actions={[
          {
            tooltip: 'Add',
            icon: AddIcon,
            onClick: (e, rowData: any) => onAddClick(rowData),
          },
        ]}
        components={{
          Action: (props: any) => (
            <Button
              onClick={(event) => props.action.onClick(event, props.data)}
              variant="contained"
              color="primary"
              size="small"
            >
              Add
            </Button>
          ),
        }}
        options={{
          pageSize: 100,
          pageSizeOptions: [5, 10, 20, 100],
          searchFieldStyle: {
            width: '500px',
          },
          searchFieldAlignment: 'left',
        }}
        style={{
          border: '1px solid #e3e3e3',
        }}
      />
    </Dialog>
  )
}

export default OrderAddItemDialog
