import React from 'react'
import moment from 'moment'
import MTTable from 'src/components/MTTable'
import { Order_expanded } from 'src/pages/orders/types'
import { Payment_fields } from 'src/types/globalTypes'
import { Invoice_expanded } from 'src/pages/invoices/types'
import { ordersByPeriod } from '../../utils/ordersByPeriod'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import getOrderSumValues from 'src/pages/orders/utils/getOrderSumValues'
import { paymentsByPeriod } from '../../utils/paymentsByPeriod'
import getInvoiceSumValues from 'src/pages/invoices/utils/getInvoiceSumValues'
import { invoicesByPeriod } from '../../utils/invoicesByPeriod'

function createData(name: string, period: { [key: string]: number }) {
  return { name, period }
}

interface Props {
  monthly: string[]
  shipeedout_orders: Order_expanded[]
  payments: Payment_fields[]
  invoices: Invoice_expanded[]
  split: boolean
}

export default function FinancialCorporateTable(props: Props) {
  const { monthly, shipeedout_orders, payments, invoices, split } = props
  const rows: any = []
  // let currentMonth = moment(new Date()).startOf('month').format('YYYY-MM-DD')
  // let currentIndex = monthly.findIndex((date) => date === currentMonth)
  let newMonthly = monthly.slice(0).reverse()
  let moreColumns = newMonthly.map((date) => ({
    title: moment(date).format('MMM YYYY'),
    field: `period.${moment(date).format('MMM').toLowerCase()}`,
    type: 'numeric' as 'numeric',
    render: (rowData: any) => {
      let dateKey = moment(date).format('MMM').toLowerCase()
      let result = toDecimalStr(rowData.period[dateKey] || 0)

      return result
    },
  }))

  let groupedPayments = groupPaymentByMonth({ payments, monthly })
  let groupedOrders = groupAmountByWeek({ orders: shipeedout_orders, weekly: newMonthly, period: 'month', split })
  let groupedInvoices = groupInvoiceByMonth({ invoices, monthly, period: 'month', split })

  let sales_cashflow = Object.keys(groupedInvoices).reduce((a, k) => {
    a[k] = groupedInvoices[k] - groupedPayments[k]
    return a
  }, {} as any)

  let sales_credit = Object.keys(groupedInvoices).reduce((a, k) => {
    let formula = sales_cashflow[k] / groupedPayments[k]
    a[k] = formula === Infinity ? 0 : formula
    return a
  }, {} as any)

  rows.push(createData('Payment recevied (USD)', groupedPayments))
  rows.push(createData('Sales Cashflow (USD)', sales_cashflow))
  rows.push(createData('Sales credit to invoice ratio (USD)', sales_credit))
  rows.push(createData('Realised revenue (USD)', groupedOrders.orders.revenues))
  rows.push(createData('Cost (USD)', groupedOrders.orders.costs_usd))
  rows.push(createData('Gross Profit (USD)', groupedOrders.orders.gross_profits))
  rows.push(createData('Gross Profit Margin (%)', groupedOrders.orders.gross_profit_margins))

  return (
    <>
      <MTTable
        columns={[
          {
            title: 'Title',
            field: 'name',
            width: `200px`,
            cellStyle: {
              width: '200px',
            },
          },
          // {
          //   title: 'Today',
          //   field: 'period.today',
          //   type: 'numeric',
          // },
          ...moreColumns,
        ]}
        data={rows}
        options={{
          paging: false,
          search: false,
          // fixedColumns: {
          //   left: 1,
          // },
        }}
      />
    </>
  )
}

interface GroupPaymentProps {
  payments: Payment_fields[]
  monthly: string[]
}

const groupPaymentByMonth = (props: GroupPaymentProps) => {
  const { payments, monthly } = props
  const groupPaymentsByPeriod = paymentsByPeriod(payments, monthly, 'month')
  const result: { [key: string]: number } = {}
  Object.values(groupPaymentsByPeriod).forEach((item) => {
    Object.keys(item.payments).forEach((date) => {
      let dateKey = moment(date).format('MMM').toLowerCase()
      let total_amount = item.payments[date].length
        ? item.payments[date].map((item) => toDecimal(item.amount_deposit)).reduce((a, b) => a + b)
        : 0

      result[dateKey] = result[dateKey] ? result[dateKey] + total_amount : total_amount
    })
  })

  return result
}

interface GroupAmount {
  orders: Order_expanded[]
  weekly: string[]
  period: moment.unitOfTime.StartOf
  split?: boolean
}

const groupAmountByWeek = (props: GroupAmount) => {
  const { orders, weekly, period, split } = props
  let groupOrdersByWeek = ordersByPeriod(orders, weekly, period, split)

  const amountByGroup: any = {
    title: '',
    orders: { revenues: {}, costs_usd: {}, gross_profits: {}, gross_profit_margins: {} },
  }

  Object.values(groupOrdersByWeek).forEach((item) => {
    Object.keys(item.orders).forEach((date) => {
      let amountOrders = item.orders[date].map((order) => {
        let { total_amount, total_cost_usd, total_gross_profit } = getOrderSumValues({
          unit_code: order.unit_code?.code || 'kg',
          lineItems: order.order_line_items,
          revise: order.order_revises[0],
          exchange_rate: order.exchange_rate,
        })
        return {
          total_amount,
          total_cost_usd,
          total_gross_profit,
          total_gross_profit_margin: toDecimal((total_gross_profit / total_cost_usd) * 100),
        }
      })

      let revenue = amountOrders.length
        ? toDecimal(amountOrders.map((item) => toDecimal(item.total_amount)).reduce((a, b) => a + b))
        : 0

      let cost_usd = amountOrders.length
        ? toDecimal(amountOrders.map((item) => toDecimal(item.total_cost_usd)).reduce((a, b) => a + b))
        : 0

      let gross_profits = amountOrders.length
        ? toDecimal(amountOrders.map((item) => toDecimal(item.total_gross_profit)).reduce((a, b) => a + b))
        : 0

      let gross_profit_margins = amountOrders.length
        ? toDecimal(amountOrders.map((item) => toDecimal(item.total_gross_profit_margin)).reduce((a, b) => a + b))
        : 0

      let dateKey = moment(date).format('MMM').toLowerCase()
      amountByGroup.orders.revenues[dateKey] = amountByGroup.orders.revenues[dateKey]
        ? amountByGroup.orders.revenues[dateKey] + revenue || 0
        : revenue || 0
      amountByGroup.orders.costs_usd[dateKey] = amountByGroup.orders.costs_usd[dateKey]
        ? amountByGroup.orders.costs_usd[dateKey] + cost_usd || 0
        : cost_usd || 0
      amountByGroup.orders.gross_profits[dateKey] = amountByGroup.orders.gross_profits[dateKey]
        ? amountByGroup.orders.gross_profits[dateKey] + gross_profits || 0
        : gross_profits || 0
      amountByGroup.orders.gross_profit_margins[dateKey] = amountByGroup.orders.gross_profit_margins[dateKey]
        ? amountByGroup.orders.gross_profit_margins[dateKey] + gross_profit_margins || 0
        : gross_profit_margins || 0
    })
  })

  return amountByGroup
}

interface GroupInvoiceAmount {
  invoices: Invoice_expanded[]
  monthly: string[]
  period: moment.unitOfTime.StartOf
  split?: boolean
}

const groupInvoiceByMonth = (props: GroupInvoiceAmount) => {
  const { invoices, monthly, split } = props
  const groupPaymentsByPeriod = invoicesByPeriod(invoices, monthly, 'month', split)
  const result: { [key: string]: number } = {}
  Object.values(groupPaymentsByPeriod).forEach((item) => {
    Object.keys(item.invoices).forEach((date) => {
      let totals = item.invoices[date].map((invoice) => {
        let { total_amount } = getInvoiceSumValues({
          itemView: 1,
          lineItems: invoice.invoice_line_items,
          unit_code: invoice.unit_code?.code || 'kg',
        })

        return {
          total_amount,
        }
      })

      let dateKey = moment(date).format('MMM').toLowerCase()

      let total_amount = totals.length
        ? toDecimal(totals.map((item) => toDecimal(item.total_amount)).reduce((a, b) => a + b))
        : 0

      result[dateKey] = result[dateKey] ? result[dateKey] + total_amount || 0 : total_amount || 0
    })
  })

  return result
}
