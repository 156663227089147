import { OrderLineItems_expanded } from 'src/pages/orders/types'
import { toDecimal } from 'src/utils/toDecimal'
import { InvoiceLineItemInput } from '../../types/input'

interface Props {
  data: OrderLineItems_expanded[]
  type_no: number
  unit_code?: string
}

export default function formatArrayLineItemOrderToInvoice(props: Props): InvoiceLineItemInput[] {
  const { data, type_no } = props
  let result: InvoiceLineItemInput[] = []

  if (data.length) {
    result = data.map((item) => {
      let net_weight_kg = toDecimal(item.weight_mc * (item.box || 0))
      return {
        product_fk: item.product_fk,
        size_fk: item.size_fk || null,
        size_value_fk: item.size_value_fk || null,
        pack_fk: item.pack_fk || null,
        box: item.box,
        net_weight_kgs: net_weight_kg,
        weight_mc: item.weight_mc,
        gross_weight: item.gross_weight,
        price: type_no === 3 ? item.unit_price : 0,
        total_price: type_no === 3 ? item.amount : 0,
        gross_weight_kgs: item.gross_weight_kgs,
        weight_glazing: 0,
        remark: '',
        glazing: '',
        group_fk: null,
        custom_kg: item.custom_kg,
        sku_fk: null,
        raw_price: item.raw_cost,
        finished_goods: item.import_cost,
      }
    })
  }

  return result
}
