import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { InvoiceLineItem_expanded } from './types'
import { toDecimalStr } from 'src/utils/toDecimal'

const InvoiceGroupDetailPanel = ({ slug, lineItems }: { slug: string; lineItems: InvoiceLineItem_expanded[] }) => {
  let items = lineItems.filter((item) => item.group_fk === slug)

  return (
    <Box p={3}>
      <Typography variant="h6">Group panel</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>PRODUCT</TableCell>
            <TableCell align="center">BOX</TableCell>
            <TableCell align="right">TOTAL NET/WET (KG)</TableCell>
            <TableCell align="right">GROSS WET (KG)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length
            ? items.map((item, index) => (
                <TableRow key={index} hover>
                  <TableCell style={{ width: `600px` }}>
                    <Typography variant="body2">
                      {item.product?.name}
                      <Typography component="span" variant="caption">
                        {item.size_value ? ` - ${item.size_value.label} ${item.size?.label}` : ''}{' '}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell align="center">{item.box}</TableCell>
                  <TableCell align="right">{toDecimalStr(item.box * item.weight_mc)}</TableCell>
                  <TableCell align="right">{toDecimalStr(item.box * item.gross_weight)}</TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Box>
  )
}

export default InvoiceGroupDetailPanel
