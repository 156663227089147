import { gql } from '@apollo/client'

export const GET_LIST_CUSTOMER_PORTS = gql`
  {
    id
    customer_fk_code
    port_fk_id
    destination_port {
      id
      name
      country {
        id
        label
      }
    }
    customer {
      id
    }
    isDefault
  }
`
