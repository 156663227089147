import React, { FC } from 'react'
import { ReferenceField, ReferenceFieldProps } from 'react-admin'
import InvoiceHeaderRefField from './InvoiceHeaderRefField'

const InvoiceHeaderReferenceField: FC<
  Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string
  }
> = (props) => {
  return (
    <ReferenceField source="invoice_header.id" reference="invoice_header" {...props}>
      <InvoiceHeaderRefField />
    </ReferenceField>
  )
}

InvoiceHeaderReferenceField.defaultProps = {
  source: 'invoice_header.id',
  addLabel: true,
}

export default InvoiceHeaderReferenceField
