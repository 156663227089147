import React from 'react'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CurrencySelect from 'src/components/CurrencySelect'

export default function VendorCurrency() {
  return (
    <Card>
      <CardHeader title="Currency" />
      <CardContent>
        <Typography variant="caption">Set default currency for this vendor</Typography>
        <CurrencySelect source="currency_default" />
      </CardContent>
    </Card>
  )
}
