import { OrderProductSample_fields } from 'src/types/globalTypes'
import { toDecimalStr } from 'src/utils/toDecimal'

type Counter = { [k: number]: number }

export function pi_productSamples(lastNumber: number, data: OrderProductSample_fields[]) {
  let countSamples = data.length

  let rowSpanCounter = data.reduce((r: Counter, o) => {
    r[o.box] = (r[o.box] || 0) + 1
    return r
  }, {})

  let margin = countSamples >= 3 ? 'rowSpan4' : countSamples >= 2 ? 'rowSpan2' : countSamples > 1 ? 'rowSpan' : ''

  let aggregation = [
    ...data
      .reduce((acc, curr) => {
        let key = curr.box
        let item = acc.get(key) || Object.assign({}, curr, { net_weight_kg: 0 })
        item.net_weight_kg += curr.net_weight_kg

        return acc.set(key, item)
      }, new Map())
      .values(),
  ]

  let formatted_rows = data.map((item) => {
    let index = aggregation.findIndex((agg) => agg.box === item.box)

    let result = [
      {
        style: 'tcCell',
        text: `${lastNumber + 1}`,
      },
      {
        style: ['tlCell'],
        text: `${item.product}`,
      },
      {
        style: ['tcCell'],
        text: '',
      },
      {
        rowSpan: countSamples,
        style: ['tcCell', margin],
        text: `SAMPLE`,
      },
      {
        rowSpan: rowSpanCounter[item.box],
        style: ['tcCell', margin],
        text: `1`,
      },
      {
        rowSpan: rowSpanCounter[item.box],
        style: ['trCell', margin],
        text: `${toDecimalStr(aggregation[index].net_weight_kg)}`,
      },
      {
        style: 'trCell',
        text: ``,
      },
      {
        style: 'trCell',
        text: ``,
      },
      {
        style: 'trCell',
        text: ``,
      },

      {
        style: 'trCell',
        text: ``,
      },

      {
        style: 'trCell',
        text: ``,
      },

      {
        style: 'trCell',
        text: ``,
      },

      {
        style: 'trCell',
        text: ``,
      },
    ]
    lastNumber++
    return result
  })

  return formatted_rows
}
