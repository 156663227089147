import React, { SetStateAction, useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useDataProvider, useLoading } from 'react-admin'
import { InsightCustomerFinancialStates, InsightFilterStates } from './types'
import InsightDateRange from './components/Tools/InsightDateRange'
import InsightFilters from './components/Tools/InsightFilters'
import { DateRange } from 'materialui-daterange-picker'
import { formatArrayNames } from './utils/formatArrayNames'
import { colors } from 'src/utils/colors'
import { formatDateToAPI } from 'src/utils/formatDate'
import { Invoice_expanded } from '../invoices/types'
import { Payment_fields } from 'src/types/globalTypes'
import getInvoiceSumValues from '../invoices/utils/getInvoiceSumValues'
import { toDecimal } from 'src/utils/toDecimal'
import CustomerFinancialTabPanel from './components/CustomerFInancials/CustomerFinancialTabPanel'
import { Order_expanded } from '../orders/types'
import moment from 'moment'

interface OrderStats {
  shippedout_orders: Order_expanded[]
  pending_orders: Order_expanded[]
  split: boolean
}

interface PaymentStats {
  payments: Payment_fields[]
  split: boolean
}

interface Props {
  state: InsightFilterStates
  setState: (values: SetStateAction<InsightFilterStates>) => void
}

export default function InsightCustomerFinancials(props: Props) {
  const { state, setState } = props
  const dataProvider = useDataProvider()
  const loading = useLoading()
  const [tab, setTab] = useState(0)
  const [data, setData] = useState<InsightCustomerFinancialStates>({})

  const fetchOrders = useCallback(async () => {
    let customers: string[] = []
    let monthly: string[] = []

    Object.values(state.filterTags || []).forEach((item) => {
      item.customers.map((customer) => customers.push(customer))
    })

    let vendor = customers.length ? { customer_fk_code: _.uniq(customers) } : undefined
    let start_date = state.dateRange ? { 'pi_date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'pi_date@_lte': formatDateToAPI(state.dateRange.endDate) } : null

    const { data: orders } = await dataProvider.getList<Order_expanded>('order', {
      filter: { ...vendor, ...start_date, ...end_date },
      sort: { field: 'ref_pi', order: 'DESC' },
      pagination: { page: 1, perPage: customers.length ? 3000 : 0 },
    })

    let i = 1
    let days = moment.duration(moment(state.dateRange?.endDate).diff(moment(state.dateRange?.startDate))).asDays()

    while (i <= days) {
      monthly.push(moment(state.dateRange?.startDate).clone().add(i, 'day').startOf('month').format('YYYY-MM-DD'))
      i += 31
    }

    const aggregations = state.filterTags?.map((item) => {
      let dataset: OrderStats = {
        shippedout_orders: [],
        pending_orders: [],
        split: item.split || false,
      }

      orders.reduce((stats, order) => {
        if (item.customers.includes(order.customer_fk_code)) {
          if (order.status === 'shipped_out') {
            dataset.shippedout_orders.push(order)
          }
          if (order.status !== 'cancelled' && order.status !== 'annulled' && order.status !== 'shipped_out') {
            dataset.pending_orders.push(order)
          }
        }

        return stats
      }, {})

      return dataset
    })

    setData((prev) => ({ ...prev, fetchOrders: aggregations, monthly }))
  }, [state.filterTags, state.dateRange, dataProvider])

  const fetchInvoices = useCallback(async () => {
    let customers: string[] = []
    Object.values(state.filterTags || []).forEach((item) => {
      item.customers.map((customer) => customers.push(customer))
    })

    // let customer = customers.length ? { 'invoice_header#order#customer_fk_code@_in': _.uniq(customers) } : undefined
    let start_date = state.dateRange ? { 'date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'date@_lte': formatDateToAPI(state.dateRange.endDate) } : null
    const { data: invoices } = await dataProvider.getList<Invoice_expanded>('invoice', {
      filter: { ...start_date, ...end_date, type_no: 3 },
      sort: { field: 'date', order: 'DESC' },
      pagination: { page: 1, perPage: customers.length ? 3000 : 0 },
    })

    const aggregations = state.filterTags?.map((item) => {
      let dataset = 0
      invoices.reduce((stats, invoice) => {
        if (item.customers.includes(invoice.invoice_header?.order.customer_fk_code || '')) {
          const { total_amount } = getInvoiceSumValues({
            itemView: 1,
            unit_code: invoice.unit_code?.code || 'kg',
            lineItems: invoice.invoice_line_items,
          })
          dataset += toDecimal(total_amount)
        }
        return stats
      }, {})

      return dataset
    })

    setData((prev) => ({ ...prev, total_invoice_value: aggregations, count_invoices: invoices.length || 0 }))
  }, [state.filterTags, state.dateRange, dataProvider])

  const fetchPayment = useCallback(async () => {
    let customers: string[] = []
    Object.values(state.filterTags || []).forEach((item) => {
      item.customers.map((customer) => customers.push(customer))
    })
    let customer = customers.length ? { customer_fk_code: _.uniq(customers) } : undefined
    let start_date = state.dateRange ? { 'date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'date@_lte': formatDateToAPI(state.dateRange.endDate) } : null
    const { data: payments } = await dataProvider.getList<Payment_fields>('payment', {
      filter: { ...customer, ...start_date, ...end_date, type: 'deposit' },
      sort: { field: 'date', order: 'DESC' },
      pagination: { page: 1, perPage: customers.length ? 3000 : 0 },
    })

    const aggregations = state.filterTags?.map((item) => {
      let dataset: PaymentStats = {
        payments: [],
        split: item.split || false,
      }
      payments.reduce((stats, payment) => {
        dataset.payments.push(payment)
        return stats
      }, {})
      return dataset
    })

    const total_paid = state.filterTags?.map((item) => {
      let dataset = 0

      payments.reduce((stats, payment) => {
        if (item.customers.includes(payment.customer_fk_code || '')) {
          dataset += payment.amount_deposit
        }
        return stats
      }, {})
      return dataset
    })

    setData((prev) => ({ ...prev, total_paid, fetchPayments: aggregations }))
  }, [state.filterTags, state.dateRange, dataProvider])

  useEffect(() => {
    fetchInvoices()
    fetchPayment()
    fetchOrders()

    return () => {
      setData({})
    }
  }, [fetchInvoices, fetchPayment, fetchOrders])

  const handleCustomerChange = (name: string, value: any) => {
    let newValue = { ...state, [name]: value }
    setState(newValue)
  }

  const handleDateRange = (value: DateRange) => {
    setState((prev) => ({ ...prev, dateRange: value }))
  }

  const handleAddFilter = () => {
    let newValues = []

    if (state.split) {
      newValues = Object.values(state.customers || []).map((customer) => ({
        vendor: state.vendor,
        vendors: [],
        customers: [customer],
        split: state.split,
      }))
    } else {
      newValues = [{ vendor: state.vendor, vendors: [], customers: state.customers || [], split: state.split }]
    }

    let newTags = [...(state.filterTags || []), ...newValues]
    setState((prev) => ({
      ...prev,
      vendor: null,
      customers: [],
      filterTags: newTags,
    }))
  }

  const handleRemoveFilter = (index: number) => {
    if (state.filterTags) {
      let newValue = [...state.filterTags]
      newValue.splice(index, 1)
      setState({ ...state, filterTags: newValue })
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <InsightFilters
            loading={loading}
            useCustomers
            customers={state.customers}
            onChange={handleCustomerChange}
            onAddFilter={handleAddFilter}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="right">
            <InsightDateRange dateRange={state.dateRange} setDateRange={handleDateRange} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          {state.filterTags?.length
            ? state.filterTags.map((item, i) => {
                const dataColor = colors[i].join(',')
                return (
                  <Tooltip title={item.customers.join(', ')} key={i}>
                    <Chip
                      variant="outlined"
                      style={{ border: `2px solid rgb(${dataColor})`, marginRight: 8 }}
                      label={`${formatArrayNames(item.customers || [])}`}
                      onDelete={() => handleRemoveFilter(i)}
                    />
                  </Tooltip>
                )
              })
            : null}
        </Grid>
      </Grid>

      <Box my={2} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {state.filterTags?.length ? (
            <AppBar style={{ boxShadow: 'none' }} position="static" color="inherit">
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {state.filterTags.map((item, index) => (
                  <Tab key={index} label={`${formatArrayNames(item.customers || [])}`} {...a11yProps(index)} />
                ))}
              </Tabs>
              {tab >= 0 && (
                <CustomerFinancialTabPanel
                  loading={loading}
                  value={tab}
                  index={tab}
                  monthly={data.monthly}
                  fetchOrders={data.fetchOrders && data.fetchOrders[tab]}
                  fetchPayments={data.fetchPayments && data.fetchPayments[tab]}
                  total_invoice_values={data.total_invoice_value || []}
                  total_paids={data.total_paid || []}
                  count_invoies={data.count_invoices || 0}
                />
              )}
            </AppBar>
          ) : (
            <Paper>
              <Box display="flex" alignItems="center" justifyContent="center" height="30em" width="100%">
                No data found
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}
