import React, { useCallback } from 'react'
import { DeleteButton, DeleteButtonProps, useNotify, useRedirect, useUpdate } from 'react-admin'
import useCreateUserLog from 'src/hooks/useCreateUserLog'

interface Props extends DeleteButtonProps {
  message?: string
  source?: any
  target?: string
  updateParent?: {
    resource: string
    source: string
    data: any
  }
}

const DeleteWithLogButton = (props: Props) => {
  const { resource, updateParent, target, source, message } = props
  const { createLog } = useCreateUserLog()
  const [updateItem] = useUpdate()
  const notify = useNotify()
  const redirectTo = useRedirect()

  const onSuccess = useCallback(
    ({ data }: any) => {
      let msg = `Delete ${resource} #${data[source]}`
      let notifyText = 'resources.global.notifications.success.delete'

      // Fix update all the resource undefined
      if (updateParent && data[updateParent.source]) {
        updateItem(updateParent.resource, data[updateParent.source], updateParent.data)
      }

      createLog(resource, 'DELETE', message ? message : msg)

      notify(notifyText, { type: 'info' })
      redirectTo('list', `/${target ? target : resource}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createLog, source, resource, target, message]
  )

  // set onSave props instead of handleSubmitWithRedirect
  return <DeleteButton {...props} onSuccess={onSuccess} />
}

export default DeleteWithLogButton
