import { useQueryWithStore } from 'react-admin'
import moment from 'moment'

const useInvoiceHeaderPrefixCustomerCount = (code: string, customer: string, date: string | Date) => {
  let year = moment(date).year()
  const { total } = useQueryWithStore({
    type: 'getList',
    resource: 'invoice_header',
    payload: {
      filter: {
        use_format: true,
        invoice_prefix_fk_code: code,
        'order#customer_fk_code@_eq': customer,
        'date@_gte': `${year}-01-01`,
        'date@_lte': `${year}-12-31`,
      },
    },
  })

  return total
}

export default useInvoiceHeaderPrefixCustomerCount
