import { OrderProductSample_fields } from 'src/types/globalTypes'

const initialValues: OrderProductSample_fields = {
  id: '',
  order_id: '',
  product: '',
  size: '',
  pack: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kg: 0,
  gross_weight_kg: 0,
  box: 0,
  amount: 0,
}

interface Props {
  data: OrderProductSample_fields
}

export default function formatUpdateOrderProductSample(props: Props) {
  const { data } = props
  let result: OrderProductSample_fields = initialValues

  if (data) {
    result = {
      ...initialValues,
      id: data.id,
      order_id: data.order_id,
      product: data.product,
      net_weight_kg: data.net_weight_kg,
      gross_weight_kg: data.gross_weight_kg,
      box: data.box,
    }
  }

  return result
}
