import React, { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import { sanitize } from 'src/utils/sanitize'
import BankChoiceDetailForm from './BankChoiceDetailForm'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

const Title = ({ record }: any) => {
  return record.label
}

const BankChoiceEdit: FC<EditProps> = (props) => {
  const transform = (data: any) => ({
    ...data,
    code: sanitize(data.label),
  })

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Bank #${props.id}`} />
      <RouteBreadcrumbs {...props} />
      <Edit {...props} title={<Title />} transform={transform}>
        <BankChoiceDetailForm redirect={false} />
      </Edit>
    </>
  )
}

export default BankChoiceEdit
