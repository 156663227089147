import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'

interface Props {
  title?: string
  onClick: () => void
}

export default function RemoveDialog(props: Props) {
  const [open, setOpen] = useState(false)

  function handleClick() {
    props.onClick()
    setOpen(false)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <>
      <IconButton size="small" color="secondary" onClick={() => setOpen(true)}>
        <DeleteIcon />
      </IconButton>

      <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
        <DialogTitle>Remove item {props.title && `#${props.title}`}</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this data</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="default" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
