import { OrderLineItems_expanded } from 'src/pages/orders/types'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import getOrderLineUnitCode from '../../../getOrderLineUnitCost'
import _ from 'lodash'

interface Props {
  unit_code?: string
  etc_cost: number
  exchange_rate: number
  shipping_baht: number
  data: OrderLineItems_expanded[]
  shipping_cost: number
}

export function pi_lineItems(props: Props) {
  let formatted_lineItmes = makeData(props)
  return formatted_lineItmes
}

function makeData(props: Props) {
  const { data, shipping_baht, unit_code, shipping_cost, exchange_rate, etc_cost } = props

  let formatted_data = _.orderBy(data, ['product.name', 'size_value.label', 'size.label'], ['asc', 'asc', 'asc']).map(
    (item, index) => {
      let net_weight_kg = toDecimal(item.weight_mc * item.box)

      let unit_cost_usd = getOrderLineUnitCode({
        import_cost: item.import_cost,
        pack_cost_baht: item.pack_cost_baht,
        usd_export: exchange_rate,
        etc_cost,
        shipping_cost,
      })

      let total_cost_usd =
        unit_code === 'kg' ? toDecimal(net_weight_kg * unit_cost_usd) : toDecimal(item.box * unit_cost_usd)

      return [
        {
          style: 'tcCell',
          text: `${index + 1}`,
        },
        {
          style: 'tlCell',
          text: `${item.product?.name}${item.size_value ? ` - ${item.size_value.label}` : ''} ${
            item.size ? item.size.label : ''
          }`,
        },
        {
          style: 'tcCell',
          text: `${toDecimalStr(item.weight_mc, true)}`,
        },
        {
          style: ['tlCell', 'pack'],
          text: `${item.pack?.name.slice(0, 16)}`,
        },
        {
          style: 'tcCell',
          text: `${toDecimalStr(item.box, true)}`,
        },
        {
          style: 'trCell',
          text: `${toDecimalStr(net_weight_kg)}`,
        },
        {
          style: 'trCell',
          text: `${item.raw_cost ? toDecimalStr(item.raw_cost) : ''}`,
        },
        {
          style: 'trCell',
          text: `${item.import_cost ? toDecimalStr(item.import_cost) : ''}`,
        },
        {
          style: 'trCell',
          text: `${toDecimalStr(etc_cost)}`,
        },

        {
          style: 'trCell',
          text: `${item.pack_cost_baht ? toDecimalStr(item.pack_cost_baht) : ''}`,
        },

        {
          style: 'trCell',
          text: `${toDecimalStr(shipping_baht || 0)}`,
        },

        {
          style: 'trCell',
          text: `${toDecimalStr(unit_cost_usd)}`,
        },

        {
          style: 'trCell',
          text: `${toDecimalStr(total_cost_usd)}`,
        },
      ]
    }
  )

  return formatted_data
}
