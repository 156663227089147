import { gql } from '@apollo/client'

export const GET_LIST_SIZE_VALUES = gql`
  {
    id
    label
    code
    size_fk_id
    size {
      id
      label
    }
  }
`
