import React, { FC, useEffect, useState } from 'react'
import { AutocompleteArrayInput, AutocompleteArrayInputProps, useQueryWithStore } from 'react-admin'
import { Customer_fields } from 'src/types/globalTypes'

interface Choice {
  id: string
  label: string
}

const CustomersReferenceInput: FC<AutocompleteArrayInputProps> = (props) => {
  const [options, setOptions] = useState<Choice[]>([])

  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'customer',
    payload: {
      sort: { field: 'code', order: 'ASC' },
    },
  })

  useEffect(() => {
    if (data) {
      let result = data.map((item: Customer_fields) => ({
        id: item.code,
        name: item.code,
      }))

      setOptions(result)
    }
  }, [data])

  return <AutocompleteArrayInput choices={options} {...props} allowEmpty={false} />
}
export default CustomersReferenceInput
