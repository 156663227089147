import CategoryList from './CategoryList'
import CategoryCreate from './CategoryCreate'
import CategoryEdit from './CategoryEdit'

const categories = {
  list: CategoryList,
  create: CategoryCreate,
  edit: CategoryEdit,
}

export default categories
