import { OrderLineItems_expanded } from '../../types'
import { OrderProductSampleInput } from '../../types/input'

const initialValues: OrderProductSampleInput = {
  order_id: '',
  product: '',
  size: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kg: 0,
  gross_weight_kg: 0,
  pack: '',
  box: 1,
  amount: 0,
}

export default function formatArrayLineItemsToSamples(
  data: OrderLineItems_expanded[],
  order_id?: string
): OrderProductSampleInput {
  let item: OrderProductSampleInput = initialValues

  if (data.length) {
    let names = data.map((item) => item.product?.name).join(',')

    let net_weight_kg = data.map((item) => item.custom_kg.net_weight_kg).reduce((a, b) => a + b)
    let gross_weight_kg = data.map((item) => item.custom_kg.gross_weight_kg).reduce((a, b) => a + b)

    item = {
      ...initialValues,
      order_id: order_id || '',
      product: names,
      net_weight_kg,
      gross_weight_kg,
    }
  }

  return item
}
