import { gql } from '@apollo/client'

export const GET_INVENTORY = gql`
  {
    id
    vendor_fk_code
    title
    description
    created_at
    packing_date
    inventory_line_items {
      id
      product_fk
      size_fk
      size_value_fk
      pack_fk
      sku_fk
      weight_mc
      box
      net_weight_kg
      glazing

      product {
        id
        name
      }
      size {
        id
        label
      }
      size_value {
        id
        label
      }
      pack {
        id
        name
      }
    }
  }
`
