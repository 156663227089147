import React, { useCallback, useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import Separate from 'src/components/Separate'
import { useDataProvider, useLoading } from 'react-admin'
import { Invoice_expanded } from '../invoices/types'
import { Payment_fields } from 'src/types/globalTypes'
import moment from 'moment'
import InsightDateRange from '../insights/components/Tools/InsightDateRange'
import { DateRange } from 'materialui-daterange-picker'
import InsightCustomerAutocomplete from '../insights/components/Tools/InsightCustomerAutocomplete'
import getInvoiceSumValues from '../invoices/utils/getInvoiceSumValues'
import { formatDate, formatDateToAPI } from 'src/utils/formatDate'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'

const dateInitial = {
  label: 'This year',
  startDate: moment(new Date()).startOf('year').toDate(),
  endDate: moment(new Date()).endOf('year').toDate(),
}

interface State {
  total_invoice_amount_bf?: number
  total_payment_amount_bf?: number
  total_invoice_amount?: number
  total_payment_amount?: number
  invoices?: {
    date: string
    ref_invoice: string
    cont_no: string | null
    amount: number
  }[]

  payments?: {
    date: string
    amount: number
  }[]
}

const CustomerStatement = () => {
  const [dateRange, setDateRange] = useState(dateInitial)
  const isLoading = useLoading()
  const [customer, setCustomer] = useState<string | null>(null)
  const [state, setState] = useState<State>({})

  const dataProvider = useDataProvider()

  const fetchInvoices = useCallback(async () => {
    let start_date = dateRange ? { 'date@_gte': formatDateToAPI(dateRange.startDate) } : {}
    let end_date = dateRange ? { 'date@_lte': formatDateToAPI(dateRange.endDate) } : {}

    const { data: bInvoices } = await dataProvider.getList<Invoice_expanded>('invoice', {
      pagination: { page: 1, perPage: 50000 },
      sort: { field: 'date', order: 'ASC' },
      filter: {
        type_no: 3,
        'date@_lt': formatDateToAPI(dateRange.startDate),
        'invoice_header#order#customer_fk_code': customer,
      },
    })

    let total_invoice_amount_bf = bInvoices.length
      ? bInvoices.reduce((acc, curr) => {
          let { total_amount } = getInvoiceSumValues({
            itemView: 1,
            lineItems: curr.invoice_line_items,
            unit_code: curr.unit_code?.code || 'kg',
          })

          acc += toDecimal(curr.total_price > 0 ? curr.total_price : total_amount)

          return acc
        }, 0)
      : 0

    const { data: invoices } = await dataProvider.getList<Invoice_expanded>('invoice', {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'date', order: 'ASC' },
      filter: { ...start_date, ...end_date, type_no: 3, 'invoice_header#order#customer_fk_code': customer },
    })

    let aggregations = Object.values(invoices).map((invoice) => {
      let amount = 0
      if (invoice.invoice_line_items.length) {
        let { total_amount } = getInvoiceSumValues({
          itemView: 1,
          lineItems: invoice.invoice_line_items,
          unit_code: invoice.unit_code?.code || 'kg',
        })
        amount = toDecimal(total_amount)
      }
      amount = toDecimal(invoice.total_price > 0 ? invoice.total_price : amount)

      return {
        date: formatDate(invoice.date),
        ref_invoice: invoice.invoice_header?.ref_invoice || '',
        cont_no: invoice.information.cont_no,
        amount,
      }
    })

    let total_invoice_amount = aggregations.length ? aggregations.map((item) => item.amount).reduce((a, b) => a + b) : 0

    setState((prev) => ({
      ...prev,
      total_invoice_amount_bf,
      invoices: aggregations,
      total_invoice_amount,
    }))
  }, [dataProvider, customer, dateRange])

  const fetchPayments = useCallback(async () => {
    const { data: bPayments } = await dataProvider.getList<Payment_fields>('payment', {
      pagination: { page: 1, perPage: 50000 },
      sort: { field: 'payment_date', order: 'ASC' },
      filter: {
        'payment_date@_lt': formatDateToAPI(dateRange.startDate),
        type: ['deposit', 'adjust'],
        customer_fk_code: customer,
      },
    })

    let total_payment_amount_bf = bPayments.length
      ? bPayments.reduce((acc, curr) => (acc += toDecimal(curr.amount_deposit)), 0)
      : 0

    const { data: payments } = await dataProvider.getList<Payment_fields>('payment', {
      pagination: { page: 1, perPage: 50000 },
      sort: { field: 'payment_date', order: 'ASC' },
      filter: {
        'payment_date@_gte': formatDateToAPI(dateRange.startDate),
        'payment_date@_lte': formatDateToAPI(dateRange.endDate),
        type: ['deposit', 'adjust'],
        customer_fk_code: customer,
      },
    })

    let aggregations = payments.map((item) => ({
      date: formatDate(item.payment_date),
      amount: item.amount_deposit,
    }))

    let total_payment_amount = aggregations.length ? aggregations.map((item) => item.amount).reduce((a, b) => a + b) : 0

    setState((prev) => ({
      ...prev,
      total_payment_amount_bf,
      payments: aggregations,
      total_payment_amount,
    }))
  }, [dataProvider, customer, dateRange])

  useEffect(() => {
    fetchInvoices()
    fetchPayments()
  }, [fetchInvoices, fetchPayments])

  const handleDateRange = (value: DateRange) => {
    setDateRange((prev) => ({ ...prev, ...value }))
  }

  const handleCustomerChange = (name: string, value: string | null) => {
    setCustomer(value)
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - customer statement`} />
      <RouteBreadcrumbs />
      <Separate value={3} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <InsightDateRange dateRange={dateRange} setDateRange={handleDateRange} />
              <InsightCustomerAutocomplete value={customer} onChange={handleCustomerChange} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Separate value={1} />
      <Paper>
        <Box display="inline-table">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>NO.INV</TableCell>
                <TableCell>CONT.NO</TableCell>
                <TableCell align="right">INV.AMOUNT (USD)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    loading...
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={3} />
                <TableCell style={{ fontWeight: 600 }}>Brought forward:</TableCell>
                <TableCell align="right">
                  {toDecimalStr((state.total_invoice_amount_bf || 0) - (state.total_payment_amount_bf || 0))}
                </TableCell>
              </TableRow>

              {state.invoices?.length
                ? state.invoices.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.date}</TableCell>
                      <TableCell>{item.ref_invoice}</TableCell>
                      <TableCell>{item.cont_no}</TableCell>
                      <TableCell align="right">{toDecimalStr(item.amount)}</TableCell>
                    </TableRow>
                  ))
                : null}
              <TableRow style={{ backgroundColor: '#dedede' }}>
                <TableCell colSpan={3} />
                <TableCell style={{ fontWeight: 600 }}>Total</TableCell>
                <TableCell align="right">
                  {toDecimalStr(
                    (state.total_invoice_amount || 0) +
                      ((state.total_invoice_amount_bf || 0) - (state.total_payment_amount_bf || 0))
                  )}
                </TableCell>
              </TableRow>
              <TableRow style={{ backgroundColor: '#dedede' }}>
                <TableCell colSpan={3} />
                <TableCell style={{ fontWeight: 600 }}>Balance</TableCell>
                <TableCell align="right">
                  {toDecimalStr(
                    (state.total_invoice_amount || 0) +
                      ((state.total_invoice_amount_bf || 0) - (state.total_payment_amount_bf || 0)) -
                      (state.total_payment_amount || 0)
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box display="inline-table">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">SL.NO</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    loading...
                  </TableCell>
                </TableRow>
              )}
              {state.payments?.length
                ? state.payments.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell>{item.date}</TableCell>
                      <TableCell align="right"> {toDecimalStr(item.amount)}</TableCell>
                    </TableRow>
                  ))
                : null}
              <TableRow style={{ backgroundColor: '#dedede' }}>
                <TableCell align="center" colSpan={2} style={{ fontWeight: 600 }}>
                  Total
                </TableCell>
                <TableCell align="right">{toDecimalStr(state.total_payment_amount || 0)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </>
  )
}

export default CustomerStatement
