import React, { FC, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { FieldProps } from 'react-admin'
import { CustomerPort_expanded } from '../customers/types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: 120,
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))

interface Props extends FieldProps<CustomerPort_expanded> {
  size?: string
}

const CustomerPortNameField: FC<Props> = ({ record }) => {
  const classes = useStyles()
  if (!record) return null
  return record ? <div className={classes.root}>{record.destination_port?.name || ''}</div> : null
}

CustomerPortNameField.defaultProps = {
  source: 'destination_port.name',
  label: 'Port',
}

export default memo<Props>(CustomerPortNameField)
