import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { toDecimalStr } from 'src/utils/toDecimal'
import MTTable from 'src/components/MTTable'
import { MTableBodyRow } from 'material-table'
import { InvoiceLineItem_expanded } from './types'
import getInvoiceLineAmount from './utils/getInvoiceLineAmount'

const useStyles = makeStyles((theme) => ({
  product: {
    width: '280px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  pack: {
    width: '240px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  weight: {
    width: '130px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
}))

interface Props {
  loading?: boolean
  data: InvoiceLineItem_expanded[]
  unit_code: string
  onUpdate: (newData: any, oldData: any) => void
  onDelete: (oldData: any) => void
}

export default function InvoiceItemsDetail(props: Props) {
  const classes = useStyles()
  const { loading, data, unit_code, onUpdate, onDelete } = props

  return (
    <MTTable
      isLoading={loading}
      data={data || []}
      columns={[
        {
          title: 'Product',
          field: 'product.name',
          editable: 'never',
          render: (rowData: any) => (
            <div className={classes.product}>
              <Typography variant="body2">
                {rowData.product.name}
                <Typography component="span" variant="caption">
                  {rowData.size_value ? ` - ${rowData.size_value.label} ${rowData.size?.label}` : ''}
                </Typography>
              </Typography>
            </div>
          ),
        },
        {
          title: 'Pack',
          field: 'pack.name',
          editable: 'never',
          render: (rowData: any) => <div className={classes.pack}>{rowData.pack?.name || ''}</div>,
        },
        {
          title: 'WET MC (KG) &  Gross WET (KG)',
          field: 'weight_mc',
          align: 'center',
          editable: 'never',
          render: (rowData: any) => (
            <div className={classes.weight}>
              {rowData.weight_mc} / {rowData.gross_weight}
            </div>
          ),
        },
        {
          title: 'Box',
          field: 'box',
          type: 'numeric',
          render: (rowData: any) => <div>{toDecimalStr(rowData.box, true)}</div>,
        },
        {
          title: 'Total NET/WET',
          field: 'net_weight_kgs',
          type: 'numeric',
          editable: 'never',
          render: (rowData: any) => (
            <div style={{ width: `150px` }}>{toDecimalStr(rowData.weight_mc * rowData.box)}</div>
          ),
        },
        {
          title: 'Unit Price (USD)',
          field: 'price',
          type: 'numeric',
          render: (rowData: any) => <div style={{ width: `100px` }}>{toDecimalStr(rowData.price)}</div>,
        },

        {
          title: 'Amount (USD)',
          field: 'total_price',
          type: 'numeric',
          editable: 'never',
          render: (rowData: any) => {
            let total_price = getInvoiceLineAmount({
              weight_mc: rowData.weight_mc,
              unit_code,
              price: rowData.price,
              box: rowData.box,
              custom_kg: rowData.custom_kg,
            })

            return <div style={{ width: `80px` }}>{toDecimalStr(total_price)}</div>
          },
        },
        {
          title: 'Remark',
          field: 'remark',
          render: (rowData: any) => <div style={{ width: '150px' }}>{rowData.remark}</div>,
        },
      ]}
      components={{
        Row: (props) => (
          <MTableBodyRow
            {...props}
            onRowClick={(e: any) => {
              props.actions[0]().onClick(e, props.data)
            }}
          />
        ),
      }}
      editable={{
        onRowUpdate: (newData: any, oldData: any) =>
          new Promise((resolve, reject) => {
            if (newData.id) {
              resolve(onUpdate(newData, oldData))
            }
          }),
        onRowDelete: (oldData: any) =>
          new Promise((resolve, reject) => {
            if (oldData.id) {
              resolve(onDelete(oldData))
            }
          }),
      }}
      style={{
        border: '1px solid #e3e3e3',
      }}
    />
  )
}
