import React, { SetStateAction, useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useDataProvider, useLoading } from 'react-admin'
import { InsightFilterStates, InsightProductStates, InsightProduct_topProducts } from './types'
import { OrderLineItems_expanded, Order_expanded } from '../orders/types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import InsightDateRange from './components/Tools/InsightDateRange'
import { toDecimal } from 'src/utils/toDecimal'
import { colors } from 'src/utils/colors'
import getOrderSumValues from '../orders/utils/getOrderSumValues'
import { calculateInsightLineItemsByOrder } from './utils/calculateLineItemsByOrder'
import ProductTabPanel from './components/Products/ProductTabPanel'
import { DateRange } from 'materialui-daterange-picker'
import InsightFilters from './components/Tools/InsightFilters'
import { formatArrayNames } from './utils/formatArrayNames'
import { formatDateToAPI } from 'src/utils/formatDate'

interface OrderStats {
  shippedout_orders: Order_expanded[]
  topProducts: InsightProduct_topProducts[]
}

interface Props {
  state: InsightFilterStates
  setState: (values: SetStateAction<InsightFilterStates>) => void
}

export default function InsightProducts(props: Props) {
  let loading = useLoading()
  const dataProvider = useDataProvider()
  const { state, setState } = props
  const [tab, setTab] = useState(0)
  const [data, setData] = useState<InsightProductStates>({})

  const fetchTopProducts = useCallback(async () => {
    let vendors: string[] = []
    let customers: string[] = []

    Object.values(state.filterTags || []).forEach((item) => {
      item.vendors.map((vendor) => vendors.push(vendor))
      item.customers.map((customer) => customers.push(customer))
    })

    let vendor = vendors.length ? { vendor_fk_code: _.uniq(vendors) } : undefined
    let customer = customers.length ? { customer_fk_code: _.uniq(customers) } : undefined
    let start_date = state.dateRange ? { 'pi_date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'pi_date@_lte': formatDateToAPI(state.dateRange.endDate) } : null

    const { data: orders } = await dataProvider.getList<Order_expanded>('order', {
      filter: { ...vendor, ...customer, ...start_date, ...end_date },
      sort: { field: 'ref_pi', order: 'DESC' },
      pagination: { page: 1, perPage: vendors.length || customers.length ? 3000 : 0 },
    })

    const aggregations = state.filterTags?.map((item) => {
      let sortItems: OrderLineItems_expanded[] = []
      let dataset: OrderStats = { shippedout_orders: [], topProducts: [] }

      orders.reduce((stats, order) => {
        if (item.vendors.includes(order.vendor_fk_code) && item.customers.includes(order.customer_fk_code)) {
          if (order.status === 'shipped_out') {
            dataset.shippedout_orders.push(order)
          }
        }

        return stats
      }, {})

      Object.values(dataset.shippedout_orders).forEach((order) => {
        let { shipping_cost } = getOrderSumValues({
          unit_code: order.unit_code?.code || 'kg',
          lineItems: order.order_line_items,
          revise: order.order_revises[0],
          exchange_rate: order.exchange_rate,
        })
        let etc_cost = order.order_revises.length ? order.order_revises[0].exchange_rate_info.etc_cost : 0
        let formatItems = calculateInsightLineItemsByOrder({
          data: order.order_line_items,
          shipping_cost,
          unit_code: order.unit_code?.code || 'kg',
          exchange_rate: order.exchange_rate,
          etc_cost,
        })

        sortItems.push(...(formatItems as any))
      })
      let findTopProducts = [
        ...sortItems
          .reduce((acc, curr) => {
            let key = `${curr.product?.name}${curr.size_value?.label}${curr.size?.label}`
            let item = acc.get(key) || Object.assign({}, curr, { net_weight_kgs: 0, amount: 0, orders: [] })
            item.net_weight_kgs += curr.net_weight_kgs
            item.amount += curr.amount
            item.orders.push(curr.order_id)
            return acc.set(key, item)
          }, new Map())
          .values(),
      ]

      _.orderBy(findTopProducts, ['net_weight_kgs'], ['desc']).map((item) =>
        dataset.topProducts.push({
          product: `${item.product.name}`,
          size: item.size?.label || null,
          size_value: item.size_value?.label || null,
          pack: item.pack.name,
          amount: toDecimal(item.amount),
          net_weight_kg: toDecimal(item.net_weight_kgs),
          profit: item.profit,
          orderIds: item.orders,
        })
      )

      return dataset
    })

    setData((prev) => ({ ...prev, fetchOrders: aggregations }))
  }, [dataProvider, state.filterTags, state.dateRange])

  useEffect(() => {
    fetchTopProducts()
    return () => {
      setData({})
    }
  }, [fetchTopProducts]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCustomerChange = (name: string, value: any) => {
    let newValue = { ...state, [name]: value }
    setState(newValue)
  }

  const handleDateRange = (value: DateRange) => {
    setState((prev) => ({ ...prev, dateRange: value }))
  }

  const handleAddFilter = () => {
    let newTags = [...(state.filterTags || []), { vendors: state.vendors || [], customers: state.customers || [] }]
    setState((prev) => ({
      ...prev,
      vendors: [],
      customers: [],
      filterTags: newTags,
    }))
  }

  const handleRemoveFilter = (index: number) => {
    if (state.filterTags) {
      let newValue = [...state.filterTags]
      newValue.splice(index, 1)
      setState({ ...state, filterTags: newValue })
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }
  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <InsightFilters
            loading={loading}
            useVendors
            useCustomers
            {...state}
            onChange={handleCustomerChange}
            onAddFilter={handleAddFilter}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="right">
            <InsightDateRange dateRange={state.dateRange} setDateRange={handleDateRange} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          {state.filterTags?.length
            ? state.filterTags.map((item, i) => {
                const dataColor = colors[i].join(',')
                return (
                  <Tooltip title={item.customers.join(', ')} key={i}>
                    <Chip
                      variant="outlined"
                      style={{ border: `2px solid rgb(${dataColor})`, marginRight: 8 }}
                      label={`${formatArrayNames(item.vendors || [])}: ${formatArrayNames(item.customers || [])}`}
                      onDelete={() => handleRemoveFilter(i)}
                    />
                  </Tooltip>
                )
              })
            : null}
        </Grid>
      </Grid>

      <Box my={2} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {state.filterTags?.length ? (
            <AppBar style={{ boxShadow: 'none' }} position="static" color="inherit">
              <Tabs
                value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {state.filterTags.map((item, index) => (
                  <Tab
                    key={index}
                    label={`${formatArrayNames(item.vendors || [])}: ${formatArrayNames(item.customers || [])}`}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
              {tab >= 0 && (
                <ProductTabPanel
                  loading={loading}
                  value={tab}
                  index={tab}
                  data={data.fetchOrders && data.fetchOrders[tab]}
                />
              )}
            </AppBar>
          ) : (
            <Paper>
              <Box display="flex" alignItems="center" justifyContent="center" height="30em" width="100%">
                No data found
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}
