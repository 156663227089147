import React, { FC, memo } from 'react'
import Chip from '@material-ui/core/Chip'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { FieldProps } from 'react-admin'
import { Order_fields } from 'src/types/globalTypes'

type Props = FieldProps<Order_fields>

const OrderStatusField: FC<Props> = ({ record }) => {
  const classes = useStyles()

  return record ? (
    <Chip
      style={{ backgroundColor: checkColor(record.status) }}
      className={classes.chip}
      label={record.status.toUpperCase()}
      variant="outlined"
      size="small"
    />
  ) : null
}

OrderStatusField.defaultProps = {
  source: 'status',
  label: 'Status',
}

export default memo<Props>(OrderStatusField)

const checkColor = (status: string) => {
  let result = ''
  switch (status) {
    case 'order_received':
      result = '#fff59d'
      break
    case 'order_approved':
      result = '#42ffe5'
      break
    case 'processing_order':
      result = '#90caf9'
      break
    case 'revise_tbc':
      result = '#ffcc80'
      break
    case 'rev_order_inprocess':
      result = '#fec1c1'
      break
    case 'order_completed':
      result = '#a5d6a7'
      break

    case 'shipped_out':
      result = '#a5d6a7'
      break
    case 'hold':
      result = `#a5d6a7`
      break
    case 'cancelled':
      result = '#ef9a9a'
      break
    case 'annulled':
      result = '#ef9a9a'
      break
  }
  return result
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      textAlign: 'center',
      border: `none`,
      color: theme.palette.common.black,
    },
  })
)
