import React, { FC } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import history from 'src/utils/history'

const Auth0ProviderWithHistory: FC = ({ children }) => {


  const onRedirectCallback = (appState: any) => {
    // Use the router's history module to replace the url
    history.replace(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUDIENCE,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={'localstorage'}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
