import React, { FC } from 'react'
import {
  Datagrid,
  DateInput,
  Filter,
  FilterProps,
  List,
  SearchInput,
  SelectInput,
  TextField,
  useNotify,
  useRedirect,
} from 'react-admin'
import { Helmet } from 'react-helmet'
import DateField from 'src/components/DateField'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { WEB_APP_TITLE } from 'src/constants'
import useUser from 'src/hooks/useUser'

const UserFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput source="action_table,action_type,action_subject" alwaysOn />
    <DateInput source="timestamp" size="small" alwaysOn label="Date" />
    <SelectInput source="action_table" size="small" choices={RESOURCE_CHOICES} label="Resource" alwaysOn />
    <SelectInput source="action_type" size="small" choices={TYPE_CHOIES} label="Type" alwaysOn />
  </Filter>
)

const UserLogList = (props: any) => {
  let year = new Date().getFullYear()
  const { isSuperuser, loading } = useUser()
  const redirect = useRedirect()
  const notify = useNotify()

  if (loading) return <div>Loading...</div>

  if (!isSuperuser) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Logs`} />
      <RouteBreadcrumbs />
      <List
        {...props}
        bulkActionButtons={false}
        perPage={50}
        filters={<UserFilter />}
        sort={{ field: 'timestamp', order: 'DESC' }}
        filterDefaultValues={{
          'timestamp@_gte': `${year}-01-01`,
        }}
      >
        <Datagrid>
          <DateField source="timestamp" label="Date" />
          <TextField source="username" />
          <TextField source="action_table" label="Resource" />
          <TextField source="action_type" label="Type" />
          <TextField source="action_subject" label="Subject" />
        </Datagrid>
      </List>
    </>
  )
}

export default UserLogList

const RESOURCE_CHOICES = [
  {
    id: 'customer',
    name: 'CUSTOMER',
  },
  {
    id: 'product',
    name: 'PRODUCT',
  },
  {
    id: 'order',
    name: 'ORDER',
  },
  {
    id: 'invoice_header',
    name: 'INVOICE_HEADER',
  },
  {
    id: 'invoice',
    name: 'INVOICE',
  },
  {
    id: 'profile_item_value',
    name: 'PROFILE_ITEM_VALUE',
  },
]

const TYPE_CHOIES = [
  {
    id: 'CREATE',
    name: 'CREATE',
  },
  {
    id: 'HEADER',
    name: 'HEADER',
  },
  {
    id: 'UPDATE',
    name: 'UPDATE',
  },
  {
    id: 'DELETE',
    name: 'DELETE',
  },
]
