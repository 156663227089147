import { useMemo, useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import { AppState, Vendor_fields } from 'src/types/globalTypes'
import useVendorsByUser from './useVendorsByUser'

export default function useVendors() {
  const [result, setResult] = useState<Vendor_fields[]>([])
  const { data: vendorsByUser, isSuperuser } = useVendorsByUser()

  let filter = isSuperuser ? undefined : { code: vendorsByUser }

  const { data, total, loading, error } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'vendor',
    payload: {
      filter,
      sort: { field: 'code', order: 'ASC' },
    },
  })

  let vendors = useMemo(() => {
    if (data) {
      setResult(data)
    }

    return result
  }, [data, result])

  return { data: vendors, total: total || 0, loading, error }
}
