import { OrderLineItems_expanded } from 'src/pages/orders/types'
import getOrderLineAmount from 'src/pages/orders/utils/getOrdeLineAmount'
import { toDecimal } from 'src/utils/toDecimal'

interface Props {
  data: OrderLineItems_expanded[]
  exchange_rate: number
  etc_cost: number
  shipping_cost: number
  unit_code: string
}

export const calculateInsightLineItemsByOrder = (props: Props) => {
  const { data, unit_code } = props
  let result: OrderLineItems_expanded[] = []

  if (data.length) {
    result = [
      ...data
        .reduce((acc, curr) => {
          const key = `${curr.product?.name}${curr.size_value?.label || ''}${curr.size?.label || ''}`
          const item = acc.get(key) || Object.assign({}, curr, { net_weight_kgs: 0, amount: 0, profit: 0 })

          let net_weight_kg = toDecimal(curr.weight_mc * curr.box)
          let amount = getOrderLineAmount({
            unit_code,
            weight_mc: item.weight_mc,
            box: item.box,
            unit_price: item.unit_price,
          })

          item.net_weight_kgs += net_weight_kg
          item.amount += amount
          item.profit += curr.profit

          return acc.set(key, item)
        }, new Map())
        .values(),
    ]
  }

  return result
}
