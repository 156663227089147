import { toDecimal } from 'src/utils/toDecimal'
import { OrderLineItems_expanded } from '../../types'
import getOrderLineUnitCode from '../getOrderLineUnitCost'

const initialValues: OrderLineItems_expanded = {
  id: '',
  order_id: '',
  product_fk: '',
  size_fk: null,
  size_value_fk: null,
  size: null,
  size_value: null,
  box: 0,
  remark: '',
  pack_fk: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kgs: 0,
  gross_weight_kgs: 0,
  cost: 0,
  margins: 0,
  profit: 0,
  unit_price: 0,
  amount: 0,
  revise_key: 0,
  import_cost: 0,
  pack_cost_baht: 0,
  total_cost_usd_kg: 0,
  total_cost_usd: 0,
  weight_glazing: 0,
  glazing: '',
  raw_cost: 0,
  custom_import_costs: {
    import_cost: 0,
    usd_import: 0,
    expense_cost: 0,
  },
  custom_kg: {
    net_weight_kg: 0,
    gross_weight_kg: 0,
  },
}

interface Props {
  data: OrderLineItems_expanded
  revise_key?: number
  unit_code?: string
  etc_cost: number
  shipping_cost: number
}

export default function formatUpdateOrderLineItem(props: Props) {
  const { data, revise_key, unit_code, ...rest } = props
  let result: OrderLineItems_expanded = initialValues

  let net_weight_kg = toDecimal(data.weight_mc * (data.box || 0))

  let unit_cost_usd = getOrderLineUnitCode({
    raw_cost: data.raw_cost,
    import_cost: data.import_cost,
    pack_cost_baht: data.pack_cost_baht,
    ...rest,
  })

  let amount = unit_code === 'mc' ? toDecimal(data.unit_price * data.box) : toDecimal(data.unit_price * net_weight_kg)

  if (data) {
    result = {
      ...initialValues,
      id: data.id,
      order_id: data.order_id,
      product_fk: data.product_fk,
      size_fk: data.size_fk || null,
      size_value_fk: data.size_value_fk || null,
      pack_fk: data.pack_fk,
      product: data.product,
      size: data.size,
      size_value: data.size_value,
      pack: data.pack,
      revise_key: revise_key ? revise_key : 0,
      raw_cost: data.raw_cost,
      import_cost: data.import_cost,
      pack_cost_baht: data.pack_cost_baht,
      weight_mc: data.weight_mc,
      net_weight_kgs: net_weight_kg,
      gross_weight: data.gross_weight,
      profit: data.profit,
      unit_price: data.unit_price,
      amount,
      total_cost_usd_kg: unit_cost_usd || 0,
      total_cost_usd:
        unit_code === 'mc' ? toDecimal(data.box * unit_cost_usd || 0) : toDecimal(net_weight_kg * unit_cost_usd || 0),
      box: data.box || 0,
      remark: data.remark,
      custom_kg: data.custom_kg,
    }
  }

  return result
}
