import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import SectionTitle from 'src/components/SectionTitle'
import { BooleanInput, SelectInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import VendorPortReferenceInput from '../vendors/VendorPortReferenceInput'
import InvoicePrefixReferenceInput from '../invoicePrefix/InvoicePrefixReferenceInput'
import Separate from 'src/components/Separate'
import useInvoiceHeaderVendorCount from './hooks/useInvoiceHeaderVendorCount'
import useInvoiceHeaderCustomerCount from './hooks/useInvoiceHeaderCustomerCount'
import formatNewRefInvoice from './utils/formatNewRefInvoice'
import useInvoiceHeaderPrefixCount from './hooks/useInvoiceHeaderPrefixCount'
import useInvoiceHeaderPrefixCustomerCount from './hooks/useInvoiceHeaderPrefixCustomerCount'
import formatNewInvoicePrefix from './utils/formatNewInvoicePrefix'

export default function InvoiceHeaderOptionsForm({ disabled, type }: { disabled?: boolean; type: 'create' | 'edit' }) {
  const { values } = useFormState()
  const { change } = useForm()

  const totalInvoiceHeadersVendor = useInvoiceHeaderVendorCount(
    values.order?.vendor_fk_code || '',
    values.order?.pi_date || new Date()
  )
  const totalInvoiceHeadersCustomer = useInvoiceHeaderCustomerCount(
    values.order?.customer_fk_code,
    values.order?.pi_date || new Date()
  )

  const totalPrefix = useInvoiceHeaderPrefixCount(values.invoice_prefix_fk_code || '')
  const totalInvoicePrefixCustomer = useInvoiceHeaderPrefixCustomerCount(
    values.invoice_prefix_fk_code || 'code',
    values.order?.customer_fk_code,
    values.order?.pi_date || new Date()
  )

  let invoicePrefixText = formatNewInvoicePrefix({
    code: values.invoice_prefix_fk_code,
    customer: values.order?.vendor_fk_code || '',
    totalPrefix: totalPrefix || 0,
    totalCustomer: totalInvoicePrefixCustomer || 0,
    date: values.date,
  })

  useEffect(() => {
    if (type === 'create') {
      change('format_ref_invoice', invoicePrefixText)
    }

    if (type === 'edit') {
      let checked = values?.invoice_prefix?.format === values.invoice_prefix_fk_code
      let newPrefix = formatNewInvoicePrefix({
        code: values.invoice_prefix?.format,
        customer: values.order?.vendor_fk_code || '',
        totalPrefix: totalPrefix || 0,
        totalCustomer: totalInvoicePrefixCustomer || 0,
        date: values.date,
      })

      change('format_ref_invoice', checked ? values.format_ref_invoice : newPrefix)
    }
  }, [change, values, totalInvoicePrefixCustomer, totalPrefix, invoicePrefixText, type])

  return (
    <Box display="flex" flexDirection="column" width="25em">
      <SectionTitle label="resources.global.fieldGroups.options" />
      <BooleanInput disabled={disabled} source="use_format" />
      <InvoicePrefixReferenceInput source="invoice_prefix" disabled={values.use_format ? false : true} />
      <Typography style={{ marginTop: 3, marginLeft: 8, color: '#b3b3b3' }} variant="caption">
        {type === 'create' && invoicePrefixText}
        {type === 'edit' && (values.format_ref_invoice || '')}
      </Typography>
      <Separate value={3} />
      <SelectInput disabled={disabled} source="terms_of_sale" choices={TEMRS_OF_SALE} />
      <VendorPortReferenceInput
        disabled={disabled}
        fullWidth
        allowEmpty
        label="From Port"
        source="port"
        vendor_fk_code={values.order?.vendor_fk_code}
        helperText={false}
      />
    </Box>
  )
}

const TEMRS_OF_SALE = [
  {
    id: 'cnf',
    name: 'CNF',
  },
  {
    id: 'cif',
    name: 'CIF',
  },
  {
    id: 'fob',
    name: 'FOB',
  },
]
