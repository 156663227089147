import React, { useCallback, useEffect, useState } from 'react'
import { useDataProvider, useLoading, useVersion } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { DatePicker } from '@material-ui/pickers'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import moment from 'moment'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import Separate from 'src/components/Separate'
import { Payment_fields } from 'src/types/globalTypes'
import { Customer_expanded } from '../customers/types'
import { Invoice_expanded } from '../invoices/types'
import getInvoiceSumValues from '../invoices/utils/getInvoiceSumValues'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'

const bgColor = ['#96c193', '#ff9966', '#cbc1e1', '#d0b49f']

export default function BuyerBalance() {
  const version = useVersion()
  const dataProvider = useDataProvider()
  const [customers, setCustomers] = useState<Customer_expanded[]>([])
  const [selectedDate, handleDateChange] = useState<any>(new Date())

  const endOfMonth = moment(selectedDate).endOf('month').format('YYYY-MM-DD')

  const fetchCustomers = useCallback(async () => {
    const { data } = await dataProvider.getList<Customer_expanded>('customer', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'code', order: 'ASC' },
      filter: {},
    })

    setCustomers(data)
  }, [dataProvider])

  useEffect(() => {
    fetchCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version])

  return (
    <div>
      <RouteBreadcrumbs />
      <Separate value={3} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <DatePicker
                autoOk
                variant="inline"
                openTo="year"
                views={['year', 'month']}
                label="Month and year"
                value={selectedDate}
                onChange={handleDateChange}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Separate value={1} />
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="center">
                BALANCE AMOUNT 4th week
              </TableCell>
              <TableCell colSpan={2} align="center">
                BALANCE AMOUNT 3rd WEEK
              </TableCell>
              <TableCell colSpan={2} align="center">
                BALANCE AMOUNT 2nd WEEK
              </TableCell>
              <TableCell colSpan={2} align="center">
                BALANCE AMOUNT 1st WEEK
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="center">BUYER</TableCell>
              <TableCell align="right">AMOUNT</TableCell>
              <TableCell align="center">BUYER</TableCell>
              <TableCell align="right">AMOUNT</TableCell>
              <TableCell align="center">BUYER</TableCell>
              <TableCell align="right">AMOUNT</TableCell>
              <TableCell align="center">BUYER</TableCell>
              <TableCell align="right">AMOUNT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.length
              ? customers.map((item, index) => <Row key={index} customer={item.code} endOfMonth={endOfMonth} />)
              : null}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

interface RowProps {
  customer: string
  endOfMonth: any
}

interface RowState {
  invoices: {
    week_1: number
    week_2: number
    week_3: number
    week_4: number
  }
  payments: {
    week_1: number
    week_2: number
    week_3: number
    week_4: number
  }
}

function Row(props: RowProps) {
  const { customer, endOfMonth } = props
  const loading = useLoading()
  const dataProvider = useDataProvider()
  const [state, setState] = useState<RowState>({
    invoices: { week_1: 0, week_2: 0, week_3: 0, week_4: 0 },
    payments: { week_1: 0, week_2: 0, week_3: 0, week_4: 0 },
  })
  const startOfMonth = moment(endOfMonth).startOf('month')
  let weekly: string[] = []

  let i = 1
  while (i <= 3) {
    weekly.push(startOfMonth.add(i === 1 ? 6 : 7, 'days').format('YYYY-MM-DD'))
    i++
  }
  weekly.push(endOfMonth)

  const fetchInvoices = useCallback(async () => {
    const { data } = await dataProvider.getList<Invoice_expanded>('invoice', {
      pagination: { page: 1, perPage: 50000 },
      sort: { field: 'date', order: 'DESC' },
      filter: { 'invoice_header#order#customer_fk_code': customer, type_no: 3, 'date@_lte': endOfMonth },
    })

    const aggregations = data.length
      ? Object.values(weekly).map((date, index) => {
          let dataset: { week: number; date: string; total_amount: 0 } = {
            week: index + 1,
            date,
            total_amount: 0,
          }

          data.reduce((acc, invoice) => {
            if (invoice.date <= date) {
              const { total_amount } = getInvoiceSumValues({
                itemView: 1,
                lineItems: invoice.invoice_line_items,
                unit_code: invoice.unit_code?.code || 'kg',
              })

              dataset.total_amount += toDecimal(invoice.total_price > 0 ? invoice.total_price : total_amount)
            }
            return acc
          }, {})

          return dataset
        })
      : []

    let result = aggregations.length
      ? {
          week_1: aggregations[0].total_amount,
          week_2: aggregations[1].total_amount,
          week_3: aggregations[2].total_amount,
          week_4: aggregations[3].total_amount,
        }
      : { week_1: 0, week_2: 0, week_3: 0, week_4: 0 }

    setState((prev) => ({
      ...prev,
      invoices: result,
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, dataProvider, endOfMonth])

  const fetchPayments = useCallback(async () => {
    const { data } = await dataProvider.getList<Payment_fields>('payment', {
      pagination: { page: 1, perPage: 50000 },
      sort: { field: 'payment_date', order: 'DESC' },
      filter: { customer_fk_code: customer, type: ['deposit', 'adjust'], 'payment_date@_lte': endOfMonth },
    })

    const aggregations = data.length
      ? Object.values(weekly).map((date, index) => {
          let dataset: { week: number; date: string; total_amount: 0 } = {
            week: index + 1,
            date,
            total_amount: 0,
          }

          data.reduce((acc, payment) => {
            if (payment.payment_date <= date) {
              dataset.total_amount += toDecimal(payment.amount_deposit)
            }
            return acc
          }, {})

          return dataset
        })
      : []

    let result = aggregations.length
      ? {
          week_1: aggregations[0].total_amount,
          week_2: aggregations[1].total_amount,
          week_3: aggregations[2].total_amount,
          week_4: aggregations[3].total_amount,
        }
      : { week_1: 0, week_2: 0, week_3: 0, week_4: 0 }

    setState((prev) => ({
      ...prev,
      payments: result,
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, dataProvider, endOfMonth])

  useEffect(() => {
    fetchInvoices()
    fetchPayments()
  }, [fetchInvoices, fetchPayments])

  return (
    <TableRow>
      <TableCell style={{ backgroundColor: bgColor[0] }} align="center">
        {customer}
      </TableCell>
      <TableCell style={{ backgroundColor: bgColor[0] }} align="right">
        {loading ? 'Loading...' : toDecimalStr(state.invoices.week_4 - state.payments.week_4)}
      </TableCell>
      <TableCell style={{ backgroundColor: bgColor[1] }} align="center">
        {customer}
      </TableCell>
      <TableCell style={{ backgroundColor: bgColor[1] }} align="right">
        {loading ? 'Loading...' : toDecimalStr(state.invoices.week_3 - state.payments.week_3)}
      </TableCell>
      <TableCell style={{ backgroundColor: bgColor[2] }} align="center">
        {customer}
      </TableCell>
      <TableCell style={{ backgroundColor: bgColor[2] }} align="right">
        {loading ? 'Loading...' : toDecimalStr(state.invoices.week_2 - state.payments.week_2)}
      </TableCell>
      <TableCell style={{ backgroundColor: bgColor[3] }} align="center">
        {customer}
      </TableCell>
      <TableCell style={{ backgroundColor: bgColor[3] }} align="right">
        {loading ? 'Loading...' : toDecimalStr(state.invoices.week_1 - state.payments.week_1)}
      </TableCell>
    </TableRow>
  )
}
