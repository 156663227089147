const NORMAL_FONT = 9

export const pi_style = {
  header: {
    fontSize: 14,
    margin: [0, 0, 0, 10],
  },
  heading: {
    fontSize: 9,
  },
  mt5: {
    margin: [0, 5, 0, 0],
    fontSize: 9,
  },
  mtm1: {
    margin: [0, 2, 0, 0],
    fontSize: 9,
  },

  subHeader2: {
    fontSize: 10,
  },
  production: {
    fontSize: NORMAL_FONT,
  },
  subHeaderText: {
    fontSize: 14,
    bold: true,
    margin: [0, 1, 0, 3],
  },
  production_date: {
    margin: [0, 20, 0, 0],
    fontSize: NORMAL_FONT,
  },
  bank: {
    fontSize: NORMAL_FONT,
    margin: [0, 50, 30, 0],
  },
  authorization: {
    fontSize: NORMAL_FONT,
    lineHeight: 1.5,
    margin: [40, 50, 0, 0],
    alignment: 'center',
  },
  authorizationText: {
    fontSize: NORMAL_FONT,
    alignment: 'center',
  },
  tableFontSizeDefault: {
    fontSize: NORMAL_FONT,
  },
  tableHeader: {
    fontSize: NORMAL_FONT,
    margin: [0, 0, 0, 3],
  },
  tableHeader2: {
    alignment: 'center',
    fontSize: NORMAL_FONT,
    bold: true,
    verticalAlign: 'center',
  },
  tColumn: {
    fontSize: NORMAL_FONT,
    alignment: 'center',
    bold: true,
  },
  tlCell: {
    fontSize: NORMAL_FONT,
    alignment: 'left',
  },
  tcCell: {
    fontSize: NORMAL_FONT,
    alignment: 'center',
  },
  trCell: {
    fontSize: NORMAL_FONT,
    alignment: 'right',
  },
  rowSpan: {
    margin: [0, 4, 0, 0],
  },
  rowSpan2: {
    margin: [0, 6, 0, 0],
  },
  rowSpan3: {
    margin: [0, 12, 0, 0],
  },
  rowSpan4: {
    margin: [0, 18, 0, 0],
  },
  textCenter: {
    alignment: 'center',
  },

  textRight: {
    alignment: 'right',
  },
  sample: {
    fontSize: 7,
  },
  textBold: {
    bold: true,
  },
  pack: {
    fontSize: 8,
  },
  mt3: {
    margin: [0, 20, 0, 0],
  },
  endLine: {
    fontSize: 14,
    bold: true,
    margin: [0, 20, 0, 0],
    alignment: 'right',
  },
}
