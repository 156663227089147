import React, { FC } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Separate from 'src/components/Separate'
import PackReferenceInput from 'src/components/ReferenceInputs/PackReferenceInput'
import { BooleanInput, NumberInput, required, useGetList } from 'react-admin'
import ProductVariantReferenceInput from '../productVariants/ProductVariantReferenceInput'
import CustomersReferenceInput from 'src/components/ReferenceInputs/CustomersReferenceInput'
import { useFormState } from 'react-final-form'

interface Props {
  open: boolean
  onSave: () => void
  onSaveAndAdd: () => void
  onClose: () => void
  onCancel: () => void
  disabled?: boolean
}

const AddProductProfileItemValueDialog: FC<Props> = ({ open, disabled, onSave, onSaveAndAdd, onClose, onCancel }) => {
  const { values } = useFormState()

  const { total } = useGetList(
    'profile_item_value',
    { page: 1, perPage: 1 },
    { field: 'product_variant.product.name', order: 'ASC' },
    {
      vendor_code: values.vendor.code,
      product_variant_fk: values.addItem.product_variant?.id,
      pack_fk: values.addItem.pack?.id,
      weight_mc: values.addItem.pack?.weight_mc,
      gross_weight: values.addItem.use_default
        ? values.addItem.pack?.weight_mc + values.default_gross_weight
        : values.addItem.pack?.gross_weight,
    }
  )

  let isExisted = itemExisted(values, total || 0)
  let hasPack = values.addItem.pack === null ? true : false

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography>Add item</Typography>
          {isExisted && <Typography color="secondary">*This profile has already existed.</Typography>}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ProductVariantReferenceInput source="addItem.product_variant" validate={required()} />
          </Grid>
          <Grid item xs={12}>
            <PackReferenceInput source="addItem.pack" label="Pack" validate={required()} />
          </Grid>

          <Grid item xs={12} md={6}>
            <NumberInput
              source="addItem.pack.weight_mc"
              fullWidth
              label="Weight MC"
              validate={required()}
              helperText={false}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {values.addItem.use_default ? (
              <TextField
                label="Gross Weight"
                value={values.addItem.pack?.weight_mc + values.default_gross_weight}
                fullWidth
                variant="filled"
                size="small"
                disabled
              />
            ) : (
              <NumberInput
                source="addItem.pack.gross_weight"
                min={1}
                fullWidth
                label="Gross Weight"
                validate={required()}
                helperText={false}
              />
            )}

            <BooleanInput source="addItem.use_default" label="Use default" helperText={false} />
          </Grid>
          <Grid item xs={12}>
            <CustomersReferenceInput source="addItem.customer_fk_ids" label="Customers" fullWidth />
          </Grid>
        </Grid>
      </DialogContent>
      <Separate value={3} />
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={onSaveAndAdd} disabled={disabled || isExisted || hasPack}>
          Save and add
        </Button>
        <Button variant="contained" color="primary" onClick={onSave} disabled={disabled || isExisted || hasPack}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddProductProfileItemValueDialog

function itemExisted(values: any, total: number) {
  let result = false

  if (
    values.id !== null &&
    values.addItem.product_variant !== null &&
    values.addItem.pack !== null &&
    values.addItem.pack?.weight_mc &&
    total >= 1
  ) {
    result = true
  }

  return result
}
