import React, { FC } from 'react'
import { Create, CreateProps, SaveButton, SimpleForm, Toolbar, useNotify, useRedirect, useRefresh } from 'react-admin'
import { Helmet } from 'react-helmet'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { WEB_APP_TITLE } from 'src/constants'
import { paymentInitialValues } from './data'
import PaymentCreatePage from './PaymentCreatePage'

const PaymentCreateToolbar = (props: any) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const onSuccess = () => {
    notify(`resources.global.notifications.success.create`, { type: 'info' })
    redirect('/payment/create')
    refresh()
  }

  return (
    <Toolbar {...props}>
      <SaveButton
        label="resources.payment.action.save"
        redirect="edit"
        submitOnEnter={false}
        style={{ marginRight: 16 }}
      />
      <SaveButton
        label="resources.payment.action.save_and_add"
        onSuccess={onSuccess}
        submitOnEnter={false}
        variant="text"
      />
    </Toolbar>
  )
}

const PaymentCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New payment`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props}>
        <SimpleForm initialValues={paymentInitialValues} toolbar={<PaymentCreateToolbar />}>
          <PaymentCreatePage />
        </SimpleForm>
      </Create>
    </>
  )
}

export default PaymentCreate
