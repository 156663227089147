import { InvoiceHeaderInput } from '../types/input'
import moment from 'moment'

export const invoiceHeaderInitialValues: InvoiceHeaderInput = {
  order_id: '',
  order: null,
  ref_invoice: '',
  date: moment(new Date()).format('YYYY-MM-DD'),
  format_ref_invoice: '',
  invoice_header: null,
  information: {
    cont_no: '',
    seal_no: '',
    bl_number: '',
    vessel: '',
    feeder: '',
    mark_nos: '',
    destination: [],
    within: 30,
    term_of_payment: 'tt',
    is_before_vessel: false,
    production_country: '',
  },
  use_format: false,
  invoice_prefix_fk_code: null,
  custom_ref_inv: '',
  port: '',
  use_header1: 0,
  use_header2: 0,
  use_header3: 0,
  author_name: 'muhammad-jubayer-hasan-akhanda',
  terms_of_sale: 'cnf',
  bank_fk_code: 'bbl-bank',
  description: '',
}
