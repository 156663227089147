import React, { FC } from 'react'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  DateField,
  EditContextProvider,
  EditProps,
  SimpleForm,
  TextInput,
  Toolbar,
  useEditController,
} from 'react-admin'
import { Invoice_fields } from 'src/types/globalTypes'
import CustomerReferenceField from 'src/pages/customers/CustomerReferenceField'
import Separate from 'src/components/Separate'
import SaveWithLogButton from 'src/components/SaveWithLogButton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 60,
    width: 400,
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
}))

const useCustomToolbarStyle = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

const CustomToolbar = (props: any) => (
  <Toolbar {...props} classes={useCustomToolbarStyle()}>
    <SaveWithLogButton action_type="HEADER" source="ref_invoice" disabled={props.pristine} />
  </Toolbar>
)
interface Props extends EditProps {
  onCancel: () => void
}

const InvoiceHeaderQuickView: FC<Props> = ({ onCancel, ...props }) => {
  const classes = useStyles()
  const controllerProps = useEditController<Invoice_fields>(props)

  if (!controllerProps.record) return null

  return (
    <div className={classes.root}>
      <Box px={2} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">Invoice detail</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Box>
      <EditContextProvider value={controllerProps}>
        <SimpleForm
          basePath={controllerProps.basePath}
          save={controllerProps.save}
          version={controllerProps.version}
          resource="invoice_header"
          record={controllerProps.record}
          toolbar={<CustomToolbar />}
        >
          <CustomerReferenceField source="order.customer.id" label="Customer" formClassName={classes.inlineField} />
          <DateField source="date" label="Date" formClassName={classes.inlineField} />
          <Separate />
          <TextInput fullWidth source="ref_invoice" />
          <Separate value={2} />
        </SimpleForm>
      </EditContextProvider>
    </div>
  )
}

export default InvoiceHeaderQuickView
