import { gql } from '@apollo/client'

export const GET_LIST_DESTINATION_PORTS = gql`
  {
    id
    name
    city
    country_fk_code

    country {
      id
      label
      code
    }
  }
`
