import React from 'react'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FormWithRedirect, NumberInput, required, TextInput, Toolbar } from 'react-admin'
import SectionTitle from '../../components/SectionTitle'
import Separate from '../../components/Separate'

const useStyles = makeStyles((theme: Theme) => ({
  normalInput: { width: 290 },
  lInput: {
    marginRight: 32,
  },
  description: { width: 612 },
}))

const PackDetailForm = (props: any) => {
  const classes = useStyles()
  return (
    <FormWithRedirect
      {...props}
      redirect={props.redirect}
      render={(formProps: any) => (
        <form>
          <Box p="1rem">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <SectionTitle label="resources.global.fieldGroups.information" />
                <TextInput source="name" validate={required()} className={clsx(classes.normalInput, classes.lInput)} />
                <TextInput source="short_name" className={classes.normalInput} />
                <Separate />

                <TextInput source="code" className={clsx(classes.normalInput, classes.lInput)} />
                <NumberInput source="cost" className={classes.normalInput} />
                <TextInput source="description" className={classes.description} />
              </Grid>

              <Grid item xs={12} md={6}>
                <Box>
                  <SectionTitle label="resources.global.fieldGroups.options" />
                  <Box display="flex" flexDirection="column">
                    <NumberInput source="weight_mc" className={classes.normalInput} />
                    <NumberInput source="gross_weight" className={classes.normalInput} />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <SectionTitle label="resources.global.fieldGroups.options" />
                <Box display="flex" alignItems="center">
                  <TextInput label="Length" source="dimension_weight.length" />
                  <Box component="span" mx={0.5}>
                    X
                  </Box>
                  <TextInput label="Width" source="dimension_weight.width" />

                  <Box component="span" mx={0.5}>
                    X
                  </Box>
                  <TextInput label="Height" source="dimension_weight.height" />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Toolbar
            record={formProps.record}
            basePath={formProps.basePath}
            undoable={true}
            invalid={formProps.invalid}
            handleSubmit={formProps.handleSubmit}
            saving={formProps.saving}
          />
        </form>
      )}
    />
  )
}

export default PackDetailForm
