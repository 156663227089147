import React from 'react'
import { Edit, EditProps } from 'react-admin'
import { Helmet } from 'react-helmet'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { WEB_APP_TITLE } from 'src/constants'
import PermissionGroupForm from './PermissionGroupForm'

const Title = ({ record }: any) => {
  return <>{record.name}</>
}

export default function PermissionGroupEdit(props: EditProps) {
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Permission #${props.id}`} />
      <RouteBreadcrumbs />
      <Edit title={<Title />} {...props} component="div">
        <PermissionGroupForm edit redirect={false} />
      </Edit>
    </>
  )
}
