import React, { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import { makeStyles, Theme } from '@material-ui/core/styles'
import SectionTitle from 'src/components/SectionTitle'
import Separate from 'src/components/Separate'
import { CustomerPort_expanded } from './types'
import EditCustomerPortDialog from './EditCustomerPortDialog'
import { useCreate, useDelete, useNotify, useRefresh, useUpdate, useUpdateMany } from 'react-admin'
import { useFormState } from 'react-final-form'

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    '.MuiChip-deleteIcon': {
      color: '#fff',
      backgroundColor: '#fff',
    },
  },
  isDefault: {
    backgroundColor: '#3f51b5',
    color: '#fff',
  },
}))

interface Props {
  customer_fk_code: string
  data: CustomerPort_expanded[]
  disabled?: boolean
}

const CustomerDestinations: FC<Props> = ({ data, customer_fk_code, disabled }) => {
  const classes = useStyles()
  const { values } = useFormState()
  const [editPort, setEditPort] = useState(false)
  const [value, setValue] = useState('')

  const [addItem] = useCreate()
  const [updateItem] = useUpdate()
  const [updateItems] = useUpdateMany()
  const [deleteItem] = useDelete()
  const notify = useNotify()
  const refresh = useRefresh()

  useEffect(() => {
    if (data) {
      let values = data.filter((item) => item.isDefault === true)
      setValue(values.length ? values[0].id : '')
    }
  }, [data])

  const handleSubmit = () => {
    let previousData = data.find((item) => item.id === value)
    let ids = data.filter((item) => item.id !== value).map((item) => item.id)

    updateItem('customer_port', value, { isDefault: true }, previousData, {
      onSuccess: () => {
        updateItems('customer_port', ids, { isDefault: false })
        refresh()
        setValue('')
        setEditPort(false)
      },
    })
  }

  const handleOpen = () => {
    let id = data.filter((item) => item.isDefault === true)[0]?.id
    setValue(id)
    setEditPort(true)
  }

  const handleClose = () => {
    setValue('')
    setEditPort(false)
  }

  const handleAssignSubmit = () => {
    addItem(
      'customer_port',
      { customer_fk_code, port_fk_id: values && values.select_port_fk_id },
      {
        onSuccess: () => {
          setValue('')
          refresh()
        },
      }
    )
  }

  const handleDelete = (id: string, data: CustomerPort_expanded) => {
    deleteItem('customer_port', id, data, {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('resources.global.notifications.success.delete', 'info', undefined, true)
        refresh()
      },
    })
  }

  return (
    <>
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <SectionTitle label="resources.customer.fieldGroups.ports" />
            <Box display="flex">
              <Button size="small" variant="outlined" color="primary" onClick={handleOpen} disabled={disabled}>
                Manage
              </Button>
            </Box>
          </Box>
          <Separate />
          {!data ? (
            <span>Loading...</span>
          ) : data.length ? (
            data.map((port) => (
              <Chip
                className={clsx(classes.chip, { [classes.isDefault]: port.isDefault })}
                key={port.destination_port.id}
                label={port.destination_port.name}
                onDelete={() => handleDelete(port.id, port)}
              />
            ))
          ) : null}
        </CardContent>
      </Card>

      {editPort && (
        <EditCustomerPortDialog
          open={editPort}
          data={data}
          value={value}
          onAdd={handleAssignSubmit}
          onChange={setValue}
          onSubmit={handleSubmit}
          onClose={handleClose}
          onCancel={handleClose}
        />
      )}
    </>
  )
}

export default CustomerDestinations
