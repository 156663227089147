import React, { FC, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FieldProps, Link } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: `240px`,
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))

interface Props extends FieldProps<any> {
  size?: string
  type_no?: string
}

const InvoiceRefField: FC<Props> = ({ record, type_no }) => {
  const classes = useStyles()
  return record ? (
    <Link to={`/invoice/type-${type_no}/${record.id}`} className={classes.root}>
      {record.invoice_header?.ref_invoice}
    </Link>
  ) : null
}

InvoiceRefField.defaultProps = {
  source: 'invoice_header.ref_invoice',
  label: 'Ref Invoice',
}

export default memo<Props>(InvoiceRefField)
