import _ from 'lodash'
import { InvoiceGroupItem_fields } from 'src/types/globalTypes'
import { toDecimalStr } from 'src/utils/toDecimal'

interface Props {
  mark_nos: string | null
  data: InvoiceGroupItem_fields[]
}

export function invoice_groupItems2(props: Props) {
  let formatted_lineItmes = makeData(props)

  return formatted_lineItmes
}

function makeData(props: Props) {
  const { data, mark_nos } = props

  let formatted_data = _.orderBy(data, ['product', 'size'], ['asc', 'asc']).map((item, index) => {
    let result =
      index === 0
        ? [
            {
              style: 'tcCell',
              text: `${mark_nos || ''}`,
            },
            {
              style: 'tlCell',
              text: `${item.product || ''}`,
            },
            {
              style: ['tlCell', 'sizeText'],
              text: item.size,
            },
            {
              style: ['tcCell'],
              text: `${item.weight_mc || 0}`,
            },
            {
              style: 'tcCell',
              text: `${item.box}`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(item.net_weight_kgs)}`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(item.net_weight_kgs + item.box)}`,
            },
          ]
        : [
            {
              style: 'tcCell',
              text: ``,
            },
            {
              style: 'tlCell',
              text: `${item.product || ''}`,
            },
            {
              style: ['tlCell', 'sizeText'],
              text: `${item.size || ''}`,
            },
            {
              style: 'tcCell',
              text: item.weight_mc,
            },
            {
              style: 'tcCell',
              text: `${item.box}`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(item.net_weight_kgs)}`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(item.net_weight_kgs + item.box)}`,
            },
          ]

    return result
  })

  return formatted_data
}
