import { useMemo } from 'react'
import { useGetMany } from 'react-admin'
import useUser from './useUser'

interface Data {
  resource: string
  create: boolean
  read: boolean
  update: boolean
  delete: boolean
}

type ObjectData = {
  [key: string]: Data
}

export default function usePermissionByUser(resource?: string) {
  const { data: user, isSuperuser } = useUser()
  const { data, loading, loaded, error } = useGetMany('permission_group', user.permission_group_ids)
  const initialValues = makeData(isSuperuser)

  let enableConfig = useMemo(() => {
    let result = false
    if (loaded || data.length) {
      result = (data.filter((item) => item?.enable_configuration)?.length || 0) > 0
    }

    return result
  }, [loaded, data])

  let enableReport = useMemo(() => {
    let result = false
    if (loaded || data.length) {
      result = (data.filter((item) => item?.enable_report_items)?.length || 0) > 0
    }

    return result
  }, [loaded, data])

  let result = useMemo(() => {
    const obj: ObjectData = { ...initialValues }

    if (loaded || data.length) {
      let arr: Array<any> = []
      Object.values(data).forEach((item) => arr.push(...(item?.permissions || [])))

      let mergePermission = [
        ...arr
          .reduce((acc, permission) => {
            const key = `${permission.name}`
            let item =
              acc.get(key) ||
              Object.assign({
                resource: '',
                countCreate: 0,
                countRead: 0,
                countUpdate: 0,
                countDelete: 0,
                countEnable: 0,
              })

            item.resource = key
            item.countCreate += permission.create ? 1 : 0
            item.countRead += permission.read ? 1 : 0
            item.countUpdate += permission.update ? 1 : 0
            item.countDelete += permission.delete ? 1 : 0

            return acc.set(key, item)
          }, new Map())
          .values(),
      ]

      Object.values(mergePermission).forEach((item) => {
        obj[item.resource] = {
          resource: item.resource,
          create: (isSuperuser || item.countCreate) > 0 ? true : false,
          read: (isSuperuser || item.countRead) > 0 ? true : false,
          update: (isSuperuser || item.countUpdate) > 0 ? true : false,
          delete: (isSuperuser || item.countDelete) > 0 ? true : false,
        }
      })
    }

    return obj
  }, [initialValues, isSuperuser, data, loaded])

  return {
    permissions: result,
    permission: result[resource || ''] as Data,
    enableConfig,
    enableReport,
    loading,
    loaded,
    error,
  }
}

const RESOURCES: string[] = [
  'invoice-3',
  'size',
  'order',
  'payment',
  'shipment_type',
  'product',
  'vendor',
  'unit_code',
  'profile_item',
  'product_type',
  'pack',
  'invoice_prefix',
  'destination_port',
  'invoice-1',
  'expenses',
  'currency',
  'customer',
  'exchange_rate_expenses',
  'items_with_costs',
  'pricing',
  'overview',
  'invoice_header',
  'invoice-2',
  'calendar_event',
  'country',
  'user',
  'permission_group',
  'category',
  'bank_choice',
  'insight',
]

const makeData = (isSuperuser?: boolean) => {
  const obj: ObjectData = {}

  Object.values(RESOURCES).forEach((page) => {
    if (!obj[page]) {
      obj[page] = {
        resource: page,
        create: isSuperuser ? isSuperuser : false,
        read: isSuperuser ? isSuperuser : false,
        update: isSuperuser ? isSuperuser : false,
        delete: isSuperuser ? isSuperuser : false,
      }
    }
  })
  return obj
}
