import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ExportIcon from '@material-ui/icons/GetApp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Divider from '@material-ui/core/Divider'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { FormWithRedirect, Toolbar, useGetOne, useLoading } from 'react-admin'
import Paper from '@material-ui/core/Paper'
import Separate from 'src/components/Separate'
import InvoiceDetailForm from './invoiceDetailForm'
import InvoiceSettings from './InvoiceSettings'
import InvoiceLineItemsDetailTab from './InvoiceLineItemsDetailTab'
import { InvoiceLineItem_expanded } from './types'
import { InvoiceGroupItem_fields, InvoiceProductSample_fields } from 'src/types/globalTypes'
import InvoicePDFDialog from './InvoicePDFDialog'
import getInvoiceSumValues from './utils/getInvoiceSumValues'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import DeleteWithLogButton from 'src/components/DeleteWithLogButton'
import { CSVLink } from 'react-csv'
import getInvoiceLineAmount from './utils/getInvoiceLineAmount'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import { VendorPort_expanded } from '../vendors/types'
import { CustomerPort_expanded } from '../customers/types'

const InvoiceDetailPage = (props: any) => {
  const { record } = props
  const loading = useLoading()
  const [customerPortId, setCustomerPortId] = useState('')
  const [vendorPortId, setVendorPortId] = useState('')
  const [openPreviewPDF, setOpenPreviewPDF] = useState(false)
  const [lineItems, setLineItems] = useState<InvoiceLineItem_expanded[]>([])
  const [groupItems, setGroupItems] = useState<InvoiceGroupItem_fields[]>([])
  const [productSamples, setProductSamples] = useState<InvoiceProductSample_fields[]>([])
  const [tab, setTab] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [csvData, setCsvData] = useState<any>([])

  let { ...totalValues } = getInvoiceSumValues({
    itemView: 1,
    unit_code: record.unit_code?.code,
    lineItems,
  })

  let { data: customerPort } = useGetOne<CustomerPort_expanded>('customer_port', customerPortId)
  let { data: vendorPort } = useGetOne<VendorPort_expanded>('vendor_port', vendorPortId)

  useEffect(() => {
    if (record) {
      setLineItems(
        _.orderBy(record.invoice_line_items, ['product.name', 'size_value.label', 'size.label'], ['asc', 'asc', 'asc'])
      )
      setGroupItems(_.orderBy(record.invoice_group_items, ['product'], ['asc']))
      setProductSamples(_.orderBy(record.invoice_product_samples, ['product'], ['asc']))

      setCustomerPortId(record.information.destination_port || '')
      setVendorPortId(record.port || '')
    }
  }, [record])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue)
  }

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePreviewPDF = () => {
    setOpenPreviewPDF(true)
    setAnchorEl(null)
  }

  const handleExportCsv = () => {
    if (record.invoice_line_items?.length) {
      let formattedCsv = _.orderBy(record.invoice_line_items, ['product.name'], ['asc']).map(
        (item: any, index: number) => {
          let total_price = getInvoiceLineAmount({
            weight_mc: item.weight_mc,
            unit_code: item.unit_code?.code || 'kg',
            price: item.price,
            box: item.box,
            custom_kg: item.custom_kg,
          })

          return {
            no: index + 1,
            item: `${item.product?.name}${item.size_value ? ` - ${item.size_value.label}` : ''} ${
              item.size ? item.size.label : ''
            }`,
            pack: item.pack?.name || '',
            weight_mc: item.weight_mc,
            gross_weight: item.gross_weight,
            box: item.box,
            net_weight_kg: item.net_weight_kgs,
            unit_price: item.price,
            amount: total_price,
          }
        }
      )

      setCsvData(formattedCsv)
    }

    setAnchorEl(null)
  }

  const handleExportClose = () => {
    setAnchorEl(null)
  }

  return (
    <FormWithRedirect
      {...props}
      redirect={props.redirect}
      render={(formProps: any) => (
        <>
          <Helmet title={`${WEB_APP_TITLE} - invoice-${props.typeNo} #${record.invoice_header?.ref_invoice}`} />
          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={loading}
              color="primary"
              style={{ marginLeft: '10px' }}
              size="small"
              variant="outlined"
              aria-controls="export-menu"
              aria-haspopup="true"
              onClick={handleExportClick}
              startIcon={<ExportIcon />}
              endIcon={<ArrowDropDownIcon />}
            >
              Export
            </Button>
            <Menu id="export-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleExportClose}>
              <MenuItem onClick={handleExportCsv}>
                <CSVLink
                  filename={`invoice-${record.type_no}-${record.invoice_header?.ref_invoice}`}
                  data={csvData}
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    width: '100%',
                  }}
                >
                  Export as csv
                </CSVLink>
              </MenuItem>
              <MenuItem onClick={handlePreviewPDF}>Export as pdf</MenuItem>
            </Menu>
          </Box>

          <Separate value={2} />
          <Paper>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              aria-label="disabled tabs example"
            >
              <Tab label="Information" disabled={record ? false : true} />
              <Tab label="Line Items" disabled={record ? false : true} />
              <Tab label="Settings" disabled={record ? false : true} />
            </Tabs>
            <Divider />
            {tab === 0 && <InvoiceDetailForm />}
            {tab === 1 && (
              <InvoiceLineItemsDetailTab
                loading={!record}
                record={record}
                lineItems={lineItems}
                groupItems={groupItems}
                productSamples={productSamples}
                unit_code={record.unit_code?.code}
                setlineItems={setLineItems}
                setGroupItems={setGroupItems}
                setProductSamples={setProductSamples}
                {...totalValues}
              />
            )}
            {tab === 2 && <InvoiceSettings />}

            <Toolbar>
              <Box display="flex" width="100%" justifyContent="space-between">
                <SaveWithLogButton
                  refresh
                  resource="invoice"
                  source="invoice_header.ref_invoice"
                  action_type="UPDATE"
                  saving={formProps.saving}
                  disabled={formProps.pristine}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />

                <DeleteWithLogButton
                  resource="invoice"
                  source="invoice_header.ref_invoice"
                  message={`Delete invoice-1 #${record.invoice_header?.ref_invoice}`}
                  confirmTitle={`Delete invoice #${formProps.record.invoice_header?.ref_invoice}`}
                  target={`invoice/type-${record.type_no}`}
                  updateParent={{
                    resource: 'invoice_header',
                    source: 'invoice_header_id',
                    data: resetInvoiceHeader(record.type_no),
                  }}
                  record={formProps.record}
                  mutationMode="pessimistic"
                />
              </Box>
            </Toolbar>

            {openPreviewPDF && (
              <InvoicePDFDialog
                typeNo={formProps.record.type_no}
                record={formProps.record}
                lineItems={lineItems}
                groupItems={groupItems}
                productSamples={productSamples}
                customerPort={customerPort?.destination_port.name || ''}
                vendorPort={vendorPort?.destination_port.name || ''}
                open={openPreviewPDF}
                onClose={() => setOpenPreviewPDF(false)}
                onCancel={() => setOpenPreviewPDF(false)}
              />
            )}
          </Paper>
        </>
      )}
    />
  )
}

export default InvoiceDetailPage

function resetInvoiceHeader(type_no: number) {
  let data = {}

  switch (type_no) {
    case 1:
      data = { use_header1: 0 }
      break
    case 2:
      data = { use_header2: 0 }
      break
    case 3:
      data = { use_header3: 0 }
      break
    default:
      data = {}
      break
  }

  return data
}
