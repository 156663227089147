import React, { useCallback, useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { useDataProvider, useTranslate } from 'react-admin'
import moment from 'moment'
import Separate from 'src/components/Separate'
import { InvoiceHeader_fields } from 'src/types/globalTypes'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline'
import useVendorsByUser from 'src/hooks/useVendorsByUser'
import { InvoiceHeader_expanded } from '../invoiceHeaders/types'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
}))

const RecentInvoices = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const [state, setState] = useState<InvoiceHeader_fields[]>([])
  const { data: vendorsByUser, isSuperuser } = useVendorsByUser()

  const fetchInvoices = useCallback(async () => {
    const vendors = !isSuperuser ? { 'order#vendor_fk_code': vendorsByUser } : undefined
    let year = new Date().getFullYear()
    const { data } = await dataProvider.getList<InvoiceHeader_expanded>('invoice_header', {
      filter: {
        ...vendors,
        'date@_gte': `${year}-01-01`,
      },
      sort: { field: 'date', order: 'DESC' },
      pagination: { page: 1, perPage: 5 },
    })

    setState(data)
  }, [isSuperuser, vendorsByUser, dataProvider])

  useEffect(() => {
    fetchInvoices()
  }, [fetchInvoices])

  return (
    <Card className={classes.root}>
      <CardHeader title={translate('pos.dashboard.recent_invoices')} />
      <Separate />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Ref Invoice</TableCell>
            <TableCell align="center">Type 1</TableCell>
            <TableCell align="center">Type 2</TableCell>
            <TableCell align="center">Type 3</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.length
            ? state.map((item, index) => (
                <TableRow hover key={index}>
                  <TableCell>{moment(item?.date).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{item.ref_invoice}</TableCell>
                  <TableCell align="center">
                    <HasCreatedType value={item.use_header1} />
                  </TableCell>
                  <TableCell align="center">
                    <HasCreatedType value={item.use_header2} />
                  </TableCell>
                  <TableCell align="center">
                    <HasCreatedType value={item.use_header3} />
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </Card>
  )
}

export default RecentInvoices

function HasCreatedType({ value }: { value: number }) {
  const theme = useTheme()
  return value ? (
    <CheckCircleIcon style={{ color: theme.palette.success.main }} />
  ) : (
    <RemoveCircleIcon color="disabled" />
  )
}
