import React, { FormEvent, useState } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { useCreate, useGetIdentity, useNotify, useRecordContext, useRefresh, useResourceContext } from 'react-admin'

interface Props {
  disabled?: boolean
  reference: 'order' | 'invoice_header' | 'invoice' | 'customer' | 'vendor'
}

const NewTimelineComment = (props: Props) => {
  const { reference, disabled } = props
  const record = useRecordContext()
  const resource = useResourceContext()
  const [text, setText] = useState('')
  const [create, { loading }] = useCreate()
  // FIXME: use refetch instead when ReferenceManyField exposes it in the ListContext
  const refresh = useRefresh()
  const notify = useNotify()
  const { identity } = useGetIdentity()

  if (!record || !identity) return null

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data: any = {
      resource: reference,
      [foreignKeyMapping[reference]]: record.id,
      text,
    }

    create(resource, data, {
      onSuccess: () => {
        setText('')
        notify('Note added successfully')
        refresh()
      },
    })
    return false
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" justifyContent="space-between">
        <TextField
          label="Leave a comment"
          variant="filled"
          size="small"
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
          disabled={disabled}
        />
        <Button
          type="submit"
          style={{ marginLeft: 8, width: '120px' }}
          size="small"
          variant="outlined"
          color="primary"
          disabled={!text || loading || disabled}
        >
          Add note
        </Button>
      </Box>
    </form>
  )
}

export default NewTimelineComment

const foreignKeyMapping = {
  order: 'order_id',
  invoice_header: 'invoice_header_id',
  invoice: 'invoice_id',
  customer: 'customer_id',
  vendor: 'vendor_id',
}
