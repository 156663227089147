import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import InsightCustomerAutocomplete from './InsightCustomerAutocomplete'
import InsightVendorAutocomplete from './InsightVendorAutocomplete'
import SectionTitle from 'src/components/SectionTitle'
import InsightCustomersAutocomplete from './InsightCustomersAutocomplete'
import InsightVendorsAutocomplete from './InsightVendorsAutocomplete'
import InsightUsersAutocomplete from './InsightUsersAutocomplete'
import { InsightUserChoice } from '../../types'

interface Props {
  useCustomer?: boolean
  useCustomers?: boolean
  useVendor?: boolean
  useVendors?: boolean
  useUsers?: boolean
  useSplit?: boolean
  loading?: boolean
  vendor?: string | null
  vendors?: string[]
  customer?: string | null
  customers?: string[]
  users?: InsightUserChoice[]
  split?: boolean
  onChange: (name: string, value: any) => void
  onAddFilter?: () => void
}

export default function InsightFilters(props: Props) {
  const {
    useVendor,
    useVendors,
    useCustomer,
    useCustomers,
    useUsers,
    useSplit,
    loading,
    vendor,
    vendors,
    customer,
    customers,
    users,
    split,
    onChange,
    onAddFilter,
  } = props
  return (
    <Box display={{ xs: 'block', md: 'flex' }} alignItems="flex-start">
      <SectionTitle label="pos.insight.results" />
      <Box mr={3} />
      {useUsers && <InsightUsersAutocomplete value={users || []} onChange={onChange} />}
      {useVendor && <InsightVendorAutocomplete value={vendor || null} onChange={onChange} />}
      {useVendors && <InsightVendorsAutocomplete value={vendors || []} onChange={onChange} />}
      <Box mb={{ xs: 1, md: 0 }} />
      {useCustomer && <InsightCustomerAutocomplete value={customer || null} onChange={onChange} />}
      {useCustomers && <InsightCustomersAutocomplete value={customers || []} onChange={onChange} />}
      <Box mb={{ xs: 1, md: 0 }} />
      {useSplit && (
        <Tooltip title="Split data">
          <Checkbox
            size="small"
            checked={split}
            onChange={(e) => onChange('split', e.target.checked)}
            color="primary"
          />
        </Tooltip>
      )}
      <Box mr={1} />
      {onAddFilter && (
        <div>
          <Button variant="outlined" disabled={loading} onClick={onAddFilter} color="primary">
            Add filter
          </Button>
        </div>
      )}
    </Box>
  )
}
