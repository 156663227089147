import React, { FC, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { useGetList, useTranslate } from 'react-admin'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Widgets_fields } from 'src/types/globalTypes'
import { toDecimalStr } from 'src/utils/toDecimal'
import { formatDate } from 'src/utils/formatDate'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  row: {
    cursor: 'pointer',
  },
  value: {
    color: theme.palette.grey[700],
  },
}))

interface Props {
  isSuperuser: boolean
  onClick: (id: string) => void
}

const ExchangeRatesDaily: FC<Props> = ({ isSuperuser, onClick }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [state, setState] = useState<Widgets_fields[]>([])
  const [currentState, setCurrentState] = useState(0)

  const { data, ids } = useGetList<Widgets_fields>(
    'widgets',
    { page: 1, perPage: 15 },
    { field: 'name', order: 'ASC' },
    { type: 'exchange_rate' }
  )

  useEffect(() => {
    const widgets = ids.map((id) => data[id])
    let thb = widgets.length ? widgets.findIndex((item) => item?.name === 'THB') : 0

    setState(widgets)
    setCurrentState(thb)
  }, [ids, data])

  const handleBack = () => {
    if (currentState <= state.length - 1) {
      setCurrentState((prev) => prev - 1)
    }
  }

  const handleForward = () => {
    if (currentState < state.length - 1) {
      setCurrentState((prev) => prev + 1)
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader title={translate('pos.dashboard.exchange_rates')} />
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <IconButton onClick={handleBack} disabled={currentState === 0}>
            <ChevronLeft />
          </IconButton>
          <Typography>{state[currentState]?.name}-USD</Typography>
          <IconButton onClick={handleForward} disabled={currentState === state.length - 1}>
            <ChevronRight />
          </IconButton>
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Buy</TableCell>
              <TableCell>Sale</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.length ? (
              <TableRow
                hover
                style={{
                  cursor: isSuperuser ? 'pointer' : 'inherit',
                }}
                onClick={() => isSuperuser && onClick(state[currentState].id)}
              >
                <TableCell>
                  <div className={classes.flex}>
                    <Typography variant="body2">{state[currentState].name}/USD: </Typography>
                    <Typography className={classes.value} variant="body2">
                      {toDecimalStr(state[currentState].values.buy_rate)}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell>
                  <div className={classes.flex}>
                    <Typography variant="body2">{state[currentState].name}/USD: </Typography>
                    <Typography className={classes.value} variant="body2">
                      {toDecimalStr(state[currentState].values.sale_rate)}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}

            <TableRow hover>
              <TableCell>Date: {formatDate(state[currentState]?.values.date_buy)}</TableCell>
              <TableCell>Date: {formatDate(state[currentState]?.values.date_sale)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default ExchangeRatesDaily
