import React, { FC } from 'react'
import { Edit, EditProps, useNotify, useRedirect } from 'react-admin'
import RouteBreadcrumbs from '../../../components/RouteBreadcrumbs'
import OrderRefPIField from '../fields/OrderRefPIField'
import OrderDetailPage from '../components/OrderDetailPage'
import getTotalNetWeight from '../utils/getTotalNetWeight'
import usePermissionByUser from 'src/hooks/usePermissionByUser'

const OrderEdit: FC<EditProps> = (props) => {
  const { permission, loaded } = usePermissionByUser('order')
  const redirect = useRedirect()
  const notify = useNotify()

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  const transform = (values: any) => {
    return {
      ...values,
      total_net_weight: getTotalNetWeight(values.order_line_items, values.current_revise_num),
    }
  }

  return (
    <>
      <RouteBreadcrumbs {...props} components="div" />
      <Edit title={<OrderRefPIField />} {...props} mutationMode="pessimistic" component="div" transform={transform}>
        <OrderDetailPage permission={permission} redirect={false} />
      </Edit>
    </>
  )
}

export default OrderEdit
