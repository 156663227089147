import ShipmentTypeList from './ShipmentTypeList'
import ShipmentTypeCreate from './ShipmentTypeCreate'
import ShipmentTypeEdit from './ShipmentTypeEdit'

const shipmentTypes = {
  list: ShipmentTypeList,
  create: ShipmentTypeCreate,
  edit: ShipmentTypeEdit,
}

export default shipmentTypes
