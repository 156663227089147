import React from 'react'
import Grid from '@material-ui/core/Grid'
import InsightWidget from '../Tools/InsightWidget'
import { toDecimalStr } from 'src/utils/toDecimal'
import { Typography } from '@material-ui/core'

interface Props {
  count_invoices: number
  total_invoice_value: number
  total_paid: number
}

export default function CustomerFinancials(props: Props) {
  const { total_invoice_value, total_paid, count_invoices } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Customer Financials Overview</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Total invoice value (USD)" value={toDecimalStr(total_invoice_value)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Total paid (USD)" value={toDecimalStr(total_paid)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Total payables (USD)" value={toDecimalStr(total_invoice_value - total_paid)} />
      </Grid>

      <Grid item xs={12} md={4}>
        <InsightWidget
          title="Paid to invoice (%)"
          value={`${toDecimalStr(total_invoice_value ? (total_paid / total_invoice_value) * 100 : 0)} %`}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Amount due (USD)" value={toDecimalStr(total_invoice_value - total_paid)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Credit rating" value={creditChecking((total_paid / total_invoice_value) * 100)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Count orders" value={`${count_invoices}`} />
      </Grid>
    </Grid>
  )
}

function creditChecking(value: number) {
  let letter = ''

  if (value > 0.9) {
    letter = 'A'
  } else if (value > 0.8) {
    letter = 'B'
  } else if (value > 0.69) {
    letter = 'C'
  } else if (value > 0.6) {
    letter = 'D'
  } else if (value < 0.59) {
    letter = 'F'
  }
  return letter
}
