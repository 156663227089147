import React, { useCallback, useEffect, useState } from 'react'
import { useDataProvider, useTranslate } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Separate from 'src/components/Separate'
import { toDecimalStr } from 'src/utils/toDecimal'
import { Order_expanded } from '../orders/types'
import useVendorsByUser from 'src/hooks/useVendorsByUser'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  cost: {
    marginRight: '1em',
    fontSize: '14px',
    color: theme.palette.text.primary,
  },
}))

const RecentOrders = () => {
  const classes = useStyles()
  const [state, setState] = useState<Order_expanded[]>([])
  const dataProvider = useDataProvider()
  const translate = useTranslate()
  const { data: vendorsByUser, isSuperuser } = useVendorsByUser()

  const fetchOrders = useCallback(async () => {
    const vendors = !isSuperuser ? { vendor_fk_code: vendorsByUser } : undefined
    let year = new Date().getFullYear()
    const { data } = await dataProvider.getList<Order_expanded>('order', {
      filter: {
        ...vendors,
        'pi_date@_gte': `${year}-01-01`,
        status: ['order_received', 'order_approved', 'processing_order', 'rev_order_inprocess'],
      },
      sort: {
        field: 'pi_date',
        order: 'DESC',
      },
      pagination: { page: 1, perPage: 5 },
    })

    setState(data)
  }, [isSuperuser, vendorsByUser, dataProvider])

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  return (
    <Card className={classes.root}>
      <CardHeader title="Recent Orders" />
      <Separate />
      <List dense={true}>
        {state.map((record) => (
          <ListItem key={record.id} button component={Link} to={`/order/${record.id}`}>
            <ListItemText
              primary={`#${record.ref_pi}`}
              secondary={translate('pos.dashboard.order.items', {
                created_at: moment(record.pi_date).format('DD/MM/YYYY'),
                smart_count: record.order_line_items?.length || 0,
                nb_items: record.order_line_items?.length || 0,
                username: record.user.name?.split('@')[0],
                customer_name: record.customer_fk_code,
              })}
            />
            <ListItemSecondaryAction>
              <span className={classes.cost}>{toDecimalStr(record.total_net_weight)} KG.</span>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  )
}

export default RecentOrders
