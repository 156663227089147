import { InvoiceLineItem_expanded, InvoicePDFSettingsProps } from 'src/pages/invoices/types'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import getInvoiceLineAmount from '../../getInvoiceLineAmount'

interface Props {
  mark_nos: string | null
  unit_code: string
  data: InvoiceLineItem_expanded[]
  settings: InvoicePDFSettingsProps
}

export function invoice_lineItems(props: Props) {
  let formatted_lineItmes = makeData(props)
  return formatted_lineItmes
}

function makeData(props: Props) {
  const { mark_nos, unit_code, data, settings } = props

  let formatted_data = data.map((item, index) => {
    let products: string[] = []
    let productText = ''

    // let productNames = {
    //   local: item.product?.name,
    //   english: item.product?.english_name,
    //   sci1: item.product?.other_names.scientific_name1,
    //   sci2: item.product?.other_names.scientific_name2,
    //   sci3: item.product?.other_names.scientific_name3,
    //   short: item.product?.short_name,
    // }

    // if (settings) {
    //   for (const [key, value] of Object.entries(productNames)) {
    //     if (settings.showProduct.includes(key)) {
    //       products.push(value || '')
    //     }
    //   }
    // }

    let net_weight_kg = settings.showProductSample
      ? toDecimal(item.box * item.weight_mc + item.custom_kg.net_weight_kg)
      : toDecimal(item.box * item.weight_mc)

    let total_price = getInvoiceLineAmount({
      unit_code,
      weight_mc: item.weight_mc,
      box: item.box,
      price: item.price,
      custom_kg: item.custom_kg,
      showSample: settings.showProductSample,
    })

    productText = products.length ? products.join(', ') : ''

    let result =
      index === 0
        ? [
            {
              style: 'tcCell',
              text: `${mark_nos || ''}`,
            },
            {
              style: ['tlCell'],
              text: `${item.product?.name || ''}${
                item.size_value ? ` - ${item.size_value.label} ${item.size?.label}` : ''
              }`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(net_weight_kg)}`,
            },

            {
              style: 'trCell',
              text: `${toDecimalStr(item.price)}`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(total_price)}`,
            },
          ]
        : [
            {
              style: 'tcCell',
              text: ``,
            },
            {
              style: 'tlCell',
              text: `${item.product?.name || ''}${
                item.size_value ? ` - ${item.size_value.label} ${item.size?.label}` : ''
              }`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(net_weight_kg)}`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(item.price)}`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(total_price)}`,
            },
          ]

    return result
  })

  return formatted_data
}
