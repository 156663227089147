import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { FormWithRedirect, ReferenceManyField, required, TextInput, Toolbar } from 'react-admin'
import SectionTitle from '../../components/SectionTitle'
import CountryReferenceInput from '../countries/CountryReferenceInput'
import VendorDestinations from './VendorDestinations'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import PhoneInput from 'src/components/PhoneInput'
import { TimelineIterator } from '../timelineComments/TimelineIterator'
import VendorCurrency from './VendorCurrency'
import Separate from 'src/components/Separate'
import SaveWithLogButton from 'src/components/SaveWithLogButton'
import DeleteWithLogButton from 'src/components/DeleteWithLogButton'

const VendorDetailForm = (props: any) => {
  const { record } = props
  return (
    <FormWithRedirect
      {...props}
      initialValues={{ select_port_fk_id: null }}
      redirect={props.redirect}
      render={(formProps: any) => (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Helmet title={`${WEB_APP_TITLE} - Vendor #${props.record.code}`} />
            <Card>
              <CardContent>
                <Box>
                  <SectionTitle label="resources.global.fieldGroups.information" />
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextInput fullWidth source="company_name" validate={props.create && required()} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        disabled={props.disabled}
                        fullWidth
                        source="code"
                        validate={props.create && required()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput fullWidth source="first_name" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput fullWidth source="last_name" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput fullWidth source="email" />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <SectionTitle label="resources.global.fieldGroups.primaryAddress" />
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextInput fullWidth source="address_1" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput fullWidth source="address_2" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CountryReferenceInput source="country" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PhoneInput source="tel" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput fullWidth source="fax" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextInput fullWidth source="phone" label="Mobile" />
                    </Grid>
                  </Grid>
                </Box>

                {props.edit && (
                  <Box>
                    <ReferenceManyField
                      target="vendor_id"
                      reference="timeline_comment"
                      sort={{ field: 'createdAt', order: 'DESC' }}
                    >
                      <TimelineIterator reference="vendor" />
                    </ReferenceManyField>
                  </Box>
                )}
              </CardContent>
              <Toolbar>
                <Box display="flex" width="100%" justifyContent="space-between">
                  <SaveWithLogButton
                    resource="vendor"
                    source="code"
                    action_type="UPDATE"
                    saving={formProps.saving}
                    disabled={formProps.pristine}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />

                  <DeleteWithLogButton
                    resource="vendor"
                    source="code"
                    message={`Delete vendor #${record.code}`}
                    confirmTitle={`Delete vendor #${record.code}`}
                    record={formProps.record}
                    mutationMode="pessimistic"
                  />
                </Box>
              </Toolbar>
            </Card>
          </Grid>

          {!props.create && (
            <Grid item xs={12} md={4}>
              <VendorDestinations vendor_fk_code={formProps.record.code} data={formProps.record.vendor_ports} />
              <Separate value={3} />
              <VendorCurrency />
            </Grid>
          )}
        </Grid>
      )}
    />
  )
}

export default VendorDetailForm
