import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Separate from 'src/components/Separate'

interface Props {
  open: boolean
  value: number
  onChange: (value: number) => void
  onClose: () => void
  onSave: () => void
}

const ReachTargetDialog = (props: Props) => {
  const { open, value, onClose, onChange, onSave } = props
  return (
    <Dialog open={open} maxWidth="xs" onClose={onClose} fullWidth>
      <DialogTitle>Individual sales target (USD)</DialogTitle>
      <DialogContent>
        <TextField
          variant="filled"
          label="Value"
          value={value || ''}
          onChange={(e) => onChange(Number(e.target.value))}
          fullWidth
        />
        <Separate value={3} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReachTargetDialog
