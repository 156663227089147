import { InvoiceProductSample_fields } from 'src/types/globalTypes'
import { toDecimalStr } from 'src/utils/toDecimal'

type Count = { [k: number]: number }

export function invoice_productSample2(data: InvoiceProductSample_fields[]) {
  let countRow = 0
  let count: Count = { 0: 0 }
  let useMerge = 0

  let sumNetWeight = 0
  let sumGrossWeight = 0

  if (data.length) {
    countRow = data.length
    sumNetWeight = data.map((item) => item.net_weight_kg).reduce((a, b) => a + b)
    sumGrossWeight = data.map((item) => item.gross_weight_kg).reduce((a, b) => a + b)
  }

  data.forEach((x) => (count[x.box] = (count[x.box] || 0) + 1))

  let rowSpan = countRow ? countRow : 1
  let margin = countRow >= 3 ? 'rowSpan4' : 'rowSpan'

  let formatted_items = data.map((item) => {
    useMerge = count[item.box]

    let result = [
      {
        style: 'tcCell',
        text: ``,
      },
      {
        style: ['tlCell', 'sample'],
        text: `${item.product || ''}`,
      },
      {
        rowSpan,
        style: ['tcCell', margin],
        text: `SAMPLE`,
      },
      {
        style: ['tcCell', margin],
        text: '1',
      },
      {
        rowSpan: useMerge,
        style: ['tcCell', margin],
        text: `1`,
      },
      {
        rowSpan,
        style: ['trCell', margin],
        text: `${toDecimalStr(sumNetWeight)}`,
      },

      {
        rowSpan,
        style: ['trCell', margin],
        text: `${toDecimalStr(sumGrossWeight)}`,
      },
    ]

    return result
  })
  return formatted_items
}
