import CountryList from './CountryList'
import CountryCreate from './CountryCreate'
import CountryEdit from './CountryEdit'

const countries = {
  list: CountryList,
  create: CountryCreate,
  edit: CountryEdit,
}

export default countries
