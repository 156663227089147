import React, { FC } from 'react'
import { ReferenceField, ReferenceFieldProps } from 'react-admin'
import CustomerPortCountryField from './CustomerPortCountryField'
import CustomerPortNameField from './CustomerPortNameField'

const CustomerPortReferenceField: FC<
  Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string
    target?: string
  }
> = (props) => {
  return (
    <ReferenceField source="customer_port.id" reference="customer_port" {...props}>
      {props.target === 'country' ? <CustomerPortCountryField /> : <CustomerPortNameField />}
    </ReferenceField>
  )
}

CustomerPortReferenceField.defaultProps = {
  source: 'customer.id',
  addLabel: true,
}

export default CustomerPortReferenceField
