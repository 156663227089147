import React from 'react'
import Grid from '@material-ui/core/Grid'
import { ReferenceInput, SelectInput } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import CustomerPortReferenceInput from 'src/pages/customerPorts/CustomerPortReferenceInput'
import VendorPortReferenceInput from 'src/pages/vendors/VendorPortReferenceInput'
import useCustomerOne from 'src/hooks/useCustomerOne'

interface Props {
  disabled?: boolean
  vendor_fk_code: string
  customer_fk_code: string
}

const OrderShippingDetails = (props: Props) => {
  const { disabled } = props
  const { data: customer } = useCustomerOne({ customer_fk_code: props.customer_fk_code || '' })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionTitle mb={0.1} label="resources.order.fieldGroups.shipping_details" />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          disabled={disabled}
          fullWidth
          source="shipping_details.terms_of_sales"
          label="Terms of sales"
          helperText={false}
          defaultValue={'cnf'}
          choices={[
            { id: 'cnf', name: 'CNF' },
            { id: 'cif', name: 'CIF' },
            { id: 'fob', name: 'FOB' },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <VendorPortReferenceInput
          disabled={disabled}
          fullWidth
          allowEmpty
          label="From Port"
          source="shipping_details.from_port"
          vendor_fk_code={props.vendor_fk_code}
          helperText={false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomerPortReferenceInput
          disabled={disabled}
          fullWidth
          allowEmpty
          label="Destination Port"
          source="shipping_details.destination_port"
          customer_fk_code={props.customer_fk_code}
          helperText={`Default value: ${customer.customer_ports[0]?.destination_port.name || ''}`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ReferenceInput
          disabled={disabled}
          fullWidth
          allowEmpty
          label="Shipment"
          source="shipment_type_fk_id"
          reference="shipment_type"
          sort={{ field: 'name', order: 'ASC' }}
          helperText={false}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Grid>
    </Grid>
  )
}

export default OrderShippingDetails
