import React, { FC } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { NumberInput } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'

interface Props {
  disabled?: boolean
}

const CustomerCreditLimit: FC<Props> = ({ disabled }) => {
  return (
    <Card>
      <CardContent>
        <SectionTitle label="resources.customer.fieldGroups.credit_limit" />
        <NumberInput fullWidth source="credit_limit" disabled={disabled} />
      </CardContent>
    </Card>
  )
}

export default CustomerCreditLimit
