import { InvoicePDFSettingsProps } from '../types'

export const invoicePDFSettings: InvoicePDFSettingsProps = {
  itemView: 1,
  letterHead: 0,
  showProduct: ['local'],
  showFormatRef: false,
  showCustomRefInv: true,
  showProductSample: true,
  showProduction: true,
  showExport: true,
  showBank: false,
  showRemark: false,
  showFooter: false,
  showFreightCharge: true,
  exportTitle: 'export',
}
