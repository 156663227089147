import React from 'react'
import Grid from '@material-ui/core/Grid'
import { ReferenceManyField, TextInput } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import Box from '@material-ui/core/Box'
import { DateInput } from 'react-admin'
import Separate from 'src/components/Separate'
import InvoiceTermsForm from 'src/pages/invoiceHeaders/InvoiceHeaderTermsForm'
import InvoiceOptions from './InvoiceOptions'
import InvoiceInformation from './InvoiceInformation'
import InvoiceHeaderReferenceField from '../invoiceHeaders/InvoiceHeaderReferenceField'
import { TimelineIterator } from '../timelineComments/TimelineIterator'
import BankChoiceReferenceInput from '../bankChoices/BankChoiceReferenceInput'

export default function InvoiceDetailForm() {
  return (
    <>
      <Box p={3}>
        <Box display={{ md: 'block', lg: 'flex' }}>
          <Box flex={2} mr={{ md: 0, lg: '3em' }}>
            <SectionTitle label="resources.global.fieldGroups.overview" />
            <Box display={{ xs: 'block', sm: 'flex' }}>
              <Box flex={2} mr={{ xs: 0, sm: '1em' }}>
                <TextInput
                  label="Ref Invoice (header)"
                  disabled
                  fullWidth
                  source="invoice_header.ref_invoice"
                  helperText={false}
                />
                <Box ml={1.5} mt={0.3}>
                  <InvoiceHeaderReferenceField source="invoice_header.id" />
                </Box>

                <Separate value={2} />
                <TextInput source="invoice_header.custom_ref_inv" fullWidth label="Custom (Ref INV.)" disabled />
              </Box>
              <Box flex={1}>
                <DateInput source="date" fullWidth />
              </Box>
            </Box>

            <Separate value={3} />
            <InvoiceInformation />

            <Separate value={3} />
            <SectionTitle label="resources.invoice_header.fieldGroups.export_details" />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInput source="author_name" fullWidth label="Export Section" helperText={false} />
              </Grid>
              <Grid item xs={12} md={6}>
                <BankChoiceReferenceInput fullWidth source="bank_fk_code" label="Bank account" />
              </Grid>
              <Grid item xs={12}>
                <TextInput source="description" fullWidth label="Remark" helperText={false} />
              </Grid>
            </Grid>

            <Separate value={3} />
            <ReferenceManyField
              target="invoice_id"
              reference="timeline_comment"
              sort={{ field: 'createdAt', order: 'DESC' }}
            >
              <TimelineIterator reference="invoice" />
            </ReferenceManyField>
          </Box>
          <Box flex={2}>
            <InvoiceTermsForm />
            <Separate value={1} />
            <InvoiceOptions />
          </Box>
        </Box>
      </Box>
    </>
  )
}
