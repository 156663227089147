export function pi_header2(unit_code: string | null, currency: string | null, locale_currency_fk: string | null) {
  const header = [
    {
      style: 'tcCell',
      text: 'NO',
    },
    {
      style: 'tcCell',
      text: 'ITEM',
    },
    {
      style: 'tcCell',
      text: 'WET./MC',
    },
    {
      style: 'tcCell',
      text: 'PACK',
    },
    {
      style: 'tcCell',
      text: 'TOTAL CTN.',
    },
    {
      style: 'tcCell',
      text: 'TOTAL NET WET.',
    },

    {
      style: 'tcCell',
      text: `TOTAL RW PRICE (${currency}/${unit_code})`,
    },
    {
      style: 'tcCell',
      text: `FINISHED GOODS (${currency}/${unit_code})`,
    },
    {
      style: 'tcCell',
      text: `ETC.COST (${locale_currency_fk}/${unit_code})`,
    },
    {
      style: 'tcCell',
      text: `PACK COST (${currency}/${unit_code})`,
    },

    {
      style: 'tcCell',
      text: `SHIPPING (${currency}/${unit_code})`,
    },
    {
      style: 'tcCell',
      text: `UNIT COST (USD/${unit_code})`,
    },
    {
      style: 'tcCell',
      text: 'TOTAL COST USD.',
    },
  ]

  return [header]
}
