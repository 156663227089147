import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import PullDataIcon from '@material-ui/icons/CloudDownload'
import EditIcon from '@material-ui/icons/Edit'
import CancelIcon from '@material-ui/icons/Cancel'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TextField from '@material-ui/core/TextField'
import SectionTitle from 'src/components/SectionTitle'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import { OrderLineItemsTableProps } from '../types'
import SaveButtonOrderRevise from './SaveButtonOrderRevise'
import { formatDate } from 'src/utils/formatDate'
import { OrderRevise_fields } from 'src/types/globalTypes'
import PullingExpenseDialog from './PullingExpenseDialog'
import FilledCurrencySelect from 'src/components/FilledCurrencySelect'
import { useLoading } from 'react-admin'

interface Props extends OrderLineItemsTableProps {
  vendor_fk_code: string
  customer_fk_code: string
  total_net_weight_kg: number
  total_ctn: number
  setRevise: (values: OrderRevise_fields) => void
}

export default function OrderExchangesExpenses(props: Props) {
  const [open, setOpen] = useState(false)
  const [editable, setEditable] = useState(false)
  const loading = useLoading()
  const {
    vendor_fk_code,
    customer_fk_code,
    unit_code,
    currency_code,
    revise,
    total_ctn,
    total_net_weight_kg,
    setRevise,
  } = props

  function handleReviseChange(name: string, value: any) {
    if (revise) {
      let exchange_rate_info = { ...revise.exchange_rate_info }
      let locale_currency_fk = revise.locale_currency_fk

      if (name === 'etc_cost') {
        exchange_rate_info = { ...exchange_rate_info, etc_cost: value }
      } else if (name === 'shipping_usd') {
        exchange_rate_info = { ...exchange_rate_info, shipping_usd: value }
      } else if (name === 'locale_currency_fk') {
        locale_currency_fk = value
      }

      setRevise({ ...revise, exchange_rate_info, locale_currency_fk })
    }
  }

  function handleExpenseChange(index: number, value: number) {
    if (revise) {
      let dataUpdata = [...revise.expense_rate_info]
      dataUpdata[index].value = value

      setRevise({ ...revise, expense_rate_info: dataUpdata })
    }
  }

  return (
    <Paper>
      <Box p={2} display={{ sm: 'block', md: 'flex' }}>
        <Box flex="1">
          <Box display="flex">
            <SectionTitle label="resources.order.fieldGroups.exchange_rates" />
            <Typography style={{ marginTop: 8, marginLeft: 8 }} variant="body2">
              Date: {formatDate(revise?.date)}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FilledCurrencySelect
                label="Expense currency"
                name="locale_currency_fk"
                value={revise?.locale_currency_fk || ''}
                onChange={(e) => handleReviseChange('locale_currency_fk', e.target.value as string)}
                disabled={false === editable}
                loading={loading}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                type="number"
                label={`ETC Cost (${revise?.locale_currency_fk}/KG)`}
                size="small"
                variant="filled"
                value={revise?.exchange_rate_info.etc_cost || ''}
                fullWidth
                onChange={(e) => handleReviseChange('etc_cost', Number(e.target.value))}
                disabled={false === editable}
                helperText={false}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                type="number"
                label={`Shipping Exchange Rate *(${revise?.locale_currency_fk}-${currency_code})`}
                size="small"
                variant="filled"
                value={revise?.exchange_rate_info.shipping_usd || ''}
                onChange={(e) => handleReviseChange('shipping_usd', Number(e.target.value))}
                helperText={`Total Expense(${revise?.locale_currency_fk}) / Shipping Exchange Rate(${revise?.locale_currency_fk}) = Shipping Expense(USD)`}
                disabled={false === editable}
              />
            </Grid>
          </Grid>
        </Box>
        <Box flex="1" ml={4}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <SectionTitle label="resources.order.fieldGroups.expenses" />
              <div style={{ marginLeft: 16 }}>
                {revise?.id && (
                  <Tooltip title="Pulling expense default values">
                    <IconButton size="small" color="primary" onClick={() => setOpen(true)}>
                      <PullDataIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </Box>
            {editable ? (
              <>
                <div>
                  <SaveButtonOrderRevise
                    id={revise?.id || ''}
                    changes={{
                      expense_rate_info: revise?.expense_rate_info || [],
                      exchange_rate_info: {
                        etc_cost: revise?.exchange_rate_info.etc_cost || 0,
                        shipping_usd: revise?.exchange_rate_info.shipping_usd || 0,
                      },
                      locale_currency_fk: revise?.locale_currency_fk,
                    }}
                    record={revise}
                    setEditable={setEditable}
                  />

                  <IconButton size="small" color="secondary" onClick={() => setEditable(false)}>
                    <CancelIcon />
                  </IconButton>
                </div>
              </>
            ) : (
              <>
                <div>
                  <Tooltip title="Edit expense">
                    <IconButton size="small" color="primary" onClick={() => setEditable(true)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {open && (
                    <PullingExpenseDialog
                      open={open}
                      record={revise}
                      onClose={() => setOpen(false)}
                      setOpen={setOpen}
                      setRevise={setRevise}
                      vendor_fk_code={vendor_fk_code}
                      customer_fk_code={customer_fk_code}
                    />
                  )}
                </div>
              </>
            )}
          </Box>

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: `200px` }}>DETAILS OF EXPENSES TOTAL</TableCell>
                <TableCell align="right">TOTAL</TableCell>
                <TableCell style={{ width: `150px ` }} align="right">
                  SHIPPING COST ({revise?.locale_currency_fk}/{unit_code.toUpperCase()})
                </TableCell>
                <TableCell style={{ width: `150px ` }} align="right">
                  SHIPPING COST (USD/{unit_code.toUpperCase()})
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {revise?.expense_rate_info.length
                ? revise?.expense_rate_info.map((item, index) =>
                    editable ? (
                      <TableRow key={index}>
                        <TableCell>{item.label}</TableCell>
                        <TableCell align="right">
                          <TextField
                            size="small"
                            inputProps={{ style: { textAlign: 'right' } }}
                            value={item.value || ''}
                            onChange={(e) => handleExpenseChange(index, Number(e.target.value))}
                          />
                        </TableCell>
                        <TableCell style={{ width: `80px` }} align="right">
                          {toDecimalStr(unit_code === 'kg' ? item.value / total_net_weight_kg : item.value / total_ctn)}
                        </TableCell>
                        <TableCell style={{ width: `80px` }} align="right">
                          {toDecimalStr(
                            unit_code === 'kg'
                              ? item.value / total_net_weight_kg / revise.exchange_rate_info.shipping_usd
                              : item.value / total_ctn / revise.exchange_rate_info.shipping_usd
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={index}>
                        <TableCell>{item.label}</TableCell>
                        <TableCell align="right">{toDecimalStr(item.value)}</TableCell>
                        <TableCell style={{ width: `80px` }} align="right">
                          {toDecimalStr(unit_code === 'kg' ? item.value / total_net_weight_kg : item.value / total_ctn)}
                        </TableCell>
                        <TableCell style={{ width: `80px` }} align="right">
                          {toDecimalStr(
                            unit_code === 'kg'
                              ? item.value / total_net_weight_kg / revise.exchange_rate_info.shipping_usd
                              : item.value / total_ctn / revise.exchange_rate_info.shipping_usd
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  )
                : null}

              <TableRow>
                <TableCell>
                  <strong>Shipping Expense ({revise?.locale_currency_fk})</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {revise?.expense_rate_info.length
                      ? toDecimalStr(
                          revise?.expense_rate_info.map((item) => (item.value ? item.value : 0)).reduce((a, b) => a + b)
                        )
                      : 0}
                  </strong>
                </TableCell>

                <TableCell align="right">
                  <strong>
                    {revise?.expense_rate_info.length
                      ? toDecimalStr(
                          revise?.expense_rate_info
                            .map((item) =>
                              item.value
                                ? unit_code === 'kg'
                                  ? toDecimal(item.value / total_net_weight_kg)
                                  : toDecimal(item.value / total_ctn)
                                : 0
                            )
                            .reduce((a, b) => a + b)
                        )
                      : 0}
                  </strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {revise?.expense_rate_info.length
                      ? toDecimalStr(
                          revise?.expense_rate_info
                            .map((item) =>
                              item.value
                                ? unit_code === 'kg'
                                  ? toDecimal(item.value / total_net_weight_kg / revise.exchange_rate_info.shipping_usd)
                                  : toDecimal(item.value / total_ctn / revise.exchange_rate_info.shipping_usd)
                                : 0
                            )
                            .reduce((a, b) => a + b)
                        )
                      : 0}
                  </strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Paper>
  )
}
