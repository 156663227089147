import React, { useCallback, useEffect, useRef } from 'react'
import _ from 'lodash'
import { Chart, registerables } from 'chart.js'
import { InsightSalesAndProfitState } from '../../types'
import { insightGraphData } from '../../data'
import InsightCard from '../Tools/InsightCard'
import { ordersByPeriod } from '../../utils/ordersByPeriod'
import getOrderSumValues from 'src/pages/orders/utils/getOrderSumValues'
import { toDecimal } from 'src/utils/toDecimal'
import { Order_expanded } from 'src/pages/orders/types'
import moment from 'moment'
import 'chartjs-adapter-moment'
import { chartOptions } from '../../utils/chartOptions'

Chart.register(...registerables)

interface Props {
  target: number
  graphStats: InsightSalesAndProfitState
  colors: any
}

export default function ProfitTarget(props: Props) {
  const { target, graphStats, colors } = props
  const chartInstance = useRef<any>(null)
  const downloadChart = useRef<any>(null)

  const loadChart = useCallback(() => {
    if (!graphStats.fetchOrders) return
    if (!downloadChart?.current) return
    if (!chartInstance.current) {
      const ctx = downloadChart?.current.getContext('2d')
      chartInstance.current = new Chart(ctx, { type: 'line', data: insightGraphData })
    }

    if (graphStats.fetchOrders.length > 0) {
      const chartData = { labels: [], datasets: [] } as any

      graphStats.fetchOrders.forEach((graphStat, i) => {
        const dataColor = colors[i].join(',')
        const groupedData = groupAmountByWeek({
          target,
          orders: graphStat.shippedout_orders || [],
          weekly: graphStats.weekly || [],
          period: 'week',
          split: graphStat.split,
        })

        if (i === 0) {
          const labels = graphStats.weekly?.map((periodData) => moment(periodData).toDate())
          chartData.labels = labels
        }

        Object.values(groupedData).forEach((group: any) => {
          let data = group.orders.map((item: any) => ({
            x: item.period,
            y: item.revenue,
          }))

          const dataset = {
            label: `${group.title}`,
            backgroundColor: `rgba(${dataColor},1)`,
            borderColor: `rgba(${dataColor},1)`,
            pointRadius: 4,
            pointHoverRadius: 4,
            pointBorderWidth: 1,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: `rgba(${dataColor},1)`,
            pointHoverBorderColor: '#ffffff',
            fill: false,
            data,
          }

          chartData.datasets.push(dataset)
        })
      })

      let firstDateForChartMoment = null

      if (graphStats.fetchOrders[0].shippedout_orders) {
        firstDateForChartMoment = moment(
          graphStats.fetchOrders[0].shippedout_orders[graphStats.fetchOrders[0].shippedout_orders.length - 1]?.pi_date
        )
      }

      const monthsToNow = moment().diff(firstDateForChartMoment, 'months')

      let xAxisDisplayUnit: any = 'week'

      if (monthsToNow >= 24) {
        xAxisDisplayUnit = 'year'
      } else if (monthsToNow >= 12) {
        xAxisDisplayUnit = 'quarter'
      } else if (monthsToNow >= 3) {
        xAxisDisplayUnit = 'month'
      }

      chartInstance.current.data = chartData
      chartInstance.current.options = chartOptions(xAxisDisplayUnit)
      chartInstance.current.update()
    }
  }, [graphStats, target, colors])

  useEffect(() => {
    loadChart()
  }, [loadChart])

  return (
    <InsightCard title="Profit target" subTitle="(%)">
      <canvas ref={downloadChart} id="profit-target-chart" />
    </InsightCard>
  )
}

interface GroupAmount {
  orders: Order_expanded[]
  weekly: string[]
  period: moment.unitOfTime.StartOf
  split?: boolean
  target: number
}

const groupAmountByWeek = (props: GroupAmount) => {
  const { target, orders, weekly, period, split } = props
  let groupOrdersByWeek = ordersByPeriod(orders, weekly, period, split)
  const result: any = []

  Object.values(groupOrdersByWeek).forEach((item) => {
    const amountByGroup: any = { title: '', orders: {} }
    Object.keys(item.orders).forEach((date) => {
      let amountOrders = item.orders[date].map((order) => {
        let { total_gross_profit } = getOrderSumValues({
          unit_code: order.unit_code?.code || 'kg',
          lineItems: order.order_line_items,
          revise: order.order_revises[0],
          exchange_rate: order.exchange_rate,
        })

        return toDecimal((total_gross_profit / target) * 100)
      })

      let revenue = amountOrders.length ? toDecimal(amountOrders.reduce((a, b) => a + b)) : 0

      amountByGroup.orders[date] = amountByGroup.orders[date] ? amountByGroup.orders[date] + revenue || 0 : revenue || 0
    })

    let orders = Object.entries(amountByGroup.orders).map(([key, value]) => ({
      period: moment(key).toDate(),
      revenue: value,
    }))

    result.push({ title: item.title, orders: _.sortBy(orders, 'period') })
  })

  return result
}
