import React, { useState } from 'react'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DeleteIcon from '@material-ui/icons/Delete'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Link, useDelete, useGetList, useNotify, useRefresh, useResourceContext } from 'react-admin'
import { OrderLineItems_expanded } from '../orders/types'
import { formatDate } from 'src/utils/formatDate'
import MTTable from 'src/components/MTTable'

const SizeValue = ({ value }: { value: any }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const resource = useResourceContext()

  const [open, setOpen] = useState(false)

  const { data, ids } = useGetList<OrderLineItems_expanded>(
    'order_line_item',
    { page: 1, perPage: 100 },
    { field: 'order.pi_date', order: 'DESC' },
    { size_value_fk: value && value.id }
  )

  const [handleDelete] = useDelete(resource, value.id, value, {
    onSuccess: () => {
      notify('resources.global.notifications.success.delete', {
        type: 'info',
        messageArgs: undefined,
        autoHideDuration: undefined,
        undoable: true,
      })
      refresh()
    },
  })

  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{value.code}</TableCell>
        <TableCell>{value.label}</TableCell>
        <TableCell>
          <IconButton color="secondary" size="small" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>

              <MTTable
                columns={[
                  {
                    title: 'Date',
                    field: 'order.pi_date',
                    render: (rowData: any) => <div>{formatDate(rowData.order?.pi_date)} </div>,
                  },
                  {
                    title: 'Ref PI',
                    field: 'order.ref_pi',
                    render: (rowData: any) => <Link to={`/order/${rowData.order?.id}`}>{rowData.order?.ref_pi}</Link>,
                  },
                ]}
                data={ids.length ? ids.map((id) => data[id]) : []}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default SizeValue
