import { InvoiceLineItemProfileItemValueInput_expanded, InvoiceProductSampleInput } from '../../types/input'

const initialValues: InvoiceProductSampleInput = {
  invoice_id: '',
  product: '',
  size: '',
  weight_mc: 0,
  gross_weight: 0,
  net_weight_kg: 0,
  gross_weight_kg: 0,
  pack: '',
  box: 1,
  amount: 0,
  unit_price: 0,
}

export default function formatInvoiceArrayProductVariantsToSamples(
  data: InvoiceLineItemProfileItemValueInput_expanded[],
  invoice_id?: string
): InvoiceProductSampleInput {
  let item: InvoiceProductSampleInput = initialValues

  if (data.length) {
    item = {
      ...initialValues,
      invoice_id: invoice_id || '',
      product: data.map((item) => item.product_variant.product.name).join(','),
    }
  }

  return item
}
