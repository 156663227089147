import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Separate from 'src/components/Separate'
import CustomerFinancials from './CustomerFinancials'
import { Order_expanded } from 'src/pages/orders/types'
import { Payment_fields } from 'src/types/globalTypes'
import CustomerFinancialTable from './CustomerFinancialTable'
import { Typography } from '@material-ui/core'

interface Props {
  loading?: boolean
  value: number
  index: number
  fetchOrders?: {
    shippedout_orders: Order_expanded[]
    pending_orders: Order_expanded[]
    split: boolean
  }
  fetchPayments?: {
    payments: Payment_fields[]
    split: boolean
  }
  monthly?: string[]
  total_invoice_values: number[]
  total_paids: number[]
  count_invoies: number
}

export default function CustomerFinancialTabPanel(props: Props) {
  const {
    value,
    index,
    loading,
    fetchOrders,
    fetchPayments,
    monthly,
    total_invoice_values,
    total_paids,
    count_invoies,
  } = props

  return (
    <>
      <TabPanel value={value} index={index}>
        <Separate value={3} />
        <Grid container spacing={3}>
          <Separate value={1} />
          {loading ? (
            <div>Loading...</div>
          ) : (
            <CustomerFinancials
              count_invoices={count_invoies}
              total_invoice_value={total_invoice_values[value] || 0}
              total_paid={total_paids[value] || 0}
            />
          )}
          <Grid item xs={12}>
            {loading ? (
              <div></div>
            ) : (
              <>
                <Separate value={3} />
                <Typography variant="h6">Stats</Typography>
                <CustomerFinancialTable
                  monthly={monthly || []}
                  shipeedout_orders={fetchOrders?.shippedout_orders || []}
                  payments={fetchPayments?.payments || []}
                  split={fetchOrders?.split || false}
                />
              </>
            )}
          </Grid>
        </Grid>
      </TabPanel>
    </>
  )
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}
