import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Invoice_expanded } from 'src/pages/invoices/types'
import InsightWidget from '../Tools/InsightWidget'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import { invoicesByPeriod } from '../../utils/invoicesByPeriod'
import getInvoiceSumValues from 'src/pages/invoices/utils/getInvoiceSumValues'

interface Props {
  invoices: Invoice_expanded[]
  monthly: string[]
  split: boolean
}

export default function Cashflow(props: Props) {
  const { invoices, monthly, split } = props

  let groupedData = groupAmountByWeek({ invoices, monthly, period: 'month', split })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Amount invoiced (USD)" value={toDecimalStr(groupedData.amount)} />
      </Grid>
      <Grid item xs={12} md={4}>
        <InsightWidget title="Count invoices" value={`${invoices.length}`} />
      </Grid>
      <Grid item xs={12} md={4}>
        {/* <InsightWidget title="Amount due (USD)" value={toDecimalStr(0)} /> */}
      </Grid>
    </Grid>
  )
}

interface GroupAmount {
  invoices: Invoice_expanded[]
  monthly: string[]
  period: moment.unitOfTime.StartOf
  split?: boolean
}

const groupAmountByWeek = (props: GroupAmount) => {
  const { invoices, monthly, period, split } = props
  let groupOrdersByWeek = invoicesByPeriod(invoices, monthly, period, split)

  const amountByGroup: any = { title: '', amount: 0 }

  Object.values(groupOrdersByWeek).forEach((item) => {
    Object.keys(item.invoices).forEach((date) => {
      let amountInvoices = item.invoices[date].map((invoice) => {
        let { total_amount } = getInvoiceSumValues({
          itemView: 1,
          unit_code: invoice.unit_code?.code || 'kg',
          lineItems: invoice.invoice_line_items,
        })

        return toDecimal(total_amount)
      })

      let amount = amountInvoices.length ? toDecimal(amountInvoices.map((value) => value).reduce((a, b) => a + b)) : 0

      amountByGroup.amount += amount || 0
    })

    amountByGroup.title = item.title
  })

  return amountByGroup
}
