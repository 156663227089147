import { useMemo } from 'react'
import _ from 'lodash'
import { useQueryWithStore } from 'react-admin'
import { OrderLineItems_expanded } from '../types'

interface Props {
  order_id?: string
  revise_key?: number
}

export default function useFetchOrderLineItemsByOrder(props: Props) {
  const { order_id, revise_key } = props

  const { loading, data, error } = useQueryWithStore({
    type: 'getList',
    resource: 'order_line_item',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: {
        order_id: order_id,
        revise_key: revise_key,
      },
    },
  })

  let lineItems = useMemo(() => formatItems(data), [data])

  return {
    data: lineItems,
    loading,
    error,
  }
}

function formatItems(data: OrderLineItems_expanded[]) {
  let result: OrderLineItems_expanded[] = []
  if (data) {
    result = _.orderBy(
      data,
      ['product.name', 'size_value.label', 'size.label', 'pack.name'],
      ['asc', 'asc', 'asc', 'asc']
    )
  }
  return result
}
