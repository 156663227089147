import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Link } from 'react-admin'
import useUser from 'src/hooks/useUser'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(3),
  },
  block: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    borderRadius: 8,
    boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
  },
  topic: {
    fontSize: '18px',
    marginBottom: theme.spacing(1),
  },
  children: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
  },
  item: {
    textDecoration: 'none',
    '&:link:hover': {
      textDecoration: 'underline',
    },
  },
}))

const configurations = [
  {
    title: 'Products',
    children: [
      {
        title: 'Categories',
        path: '/category',
      },
      {
        title: 'Product types',
        path: '/product_type',
      },
      {
        title: 'Sizes',
        path: '/size',
      },
      {
        title: 'Packs',
        path: '/pack',
      },
    ],
  },
  {
    title: 'Information',
    children: [
      {
        title: 'Vendors',
        path: '/vendor',
      },
      {
        title: 'Currencies',
        path: '/currency',
      },
      {
        title: 'Form port/Destination port',
        path: '/destination_port',
      },
      {
        title: 'Bank accounts',
        path: '/bank_choice',
      },
      // {
      //   title: 'Warehouse',
      //   path: '/store_type',
      // },
    ],
  },
  {
    title: 'Orders & Invoices',
    children: [
      {
        title: `Profile items`,
        path: '/profile_item',
      },
      {
        title: 'Expenses',
        path: '/expenses',
      },
      {
        title: 'Unit codes',
        path: '/unit_code',
      },
      {
        title: 'Invoice prefix',
        path: '/invoice_prefix',
      },
    ],
  },

  {
    title: 'Others',
    children: [
      {
        title: `Calendar`,
        path: '/calendar_event',
      },
      // {
      //   title: 'Manufacturer',
      //   path: '/manufacturer',
      // },
      {
        title: 'Shipment types',
        path: '/shipment_type',
      },
      {
        title: 'Country codes',
        path: '/country',
      },
    ],
  },
]

export default function Configuration() {
  const classes = useStyles()

  const { data, loaded } = useUser()
  const { permissions } = usePermissionByUser()

  let userPermissions =
    loaded && data.isSuperuser
      ? [
          {
            title: 'Users & Permissions',
            children: [
              {
                title: `Staff Members`,
                path: '/user',
              },
              {
                title: 'Permission groups',
                path: '/permission_group',
              },
            ],
          },
        ]
      : []

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Configuration`} />
      <div className={classes.header}>
        <Typography variant="h5" color="inherit">
          Configuration
        </Typography>
      </div>

      <Grid container spacing={3}>
        {configurations.length
          ? [...configurations, ...userPermissions].map((item, index) => (
              <Grid item xs={12} md={3} key={index}>
                <div className={classes.block}>
                  <Typography className={classes.topic}>{item.title}</Typography>
                  {item.children.length ? (
                    <Box display="flex" flexDirection="column" pl={{ xs: '8px' }} lineHeight={1.8}>
                      {item.children.map((link, linkIndex) => {
                        return permissions[link.path.slice(1)].read ? (
                          <Link key={linkIndex} className={classes.item} to={link.path}>
                            {link.title}
                          </Link>
                        ) : null
                      })}
                    </Box>
                  ) : null}
                </div>
              </Grid>
            ))
          : null}
      </Grid>
    </>
  )
}
