import React, { FC, useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import InvoicePreviewPDF from './InvoicePreviewPDF'
import { InvoiceLineItem_expanded, InvoicePDFSettingsProps, Invoice_expanded } from './types'
import InvoicePDFSettings from './InvoicePDFSettings'
import { invoicePDFSettings } from './constants'
import { InvoiceGroupItem_fields, InvoiceProductSample_fields } from 'src/types/globalTypes'

const pdf = pdfMake
pdf.vfs = pdfFonts.pdfMake.vfs

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiBackdrop-root': {
        display: `none`,
      },
    },
    content: {
      position: 'relative',
      minHeight: '90vh',
    },
    previewZone: {
      width: '100%',
    },
    settingsZone: {
      position: 'relative',
      width: '500px',
      overflow: 'hidden',
    },
    generateBtn: {
      position: 'absolute',
      bottom: 20,
      right: 20,
    },
  })
)

interface Props {
  typeNo: number
  open: boolean
  record: Invoice_expanded
  lineItems: InvoiceLineItem_expanded[]
  groupItems: InvoiceGroupItem_fields[]
  productSamples: InvoiceProductSample_fields[]
  customerPort?: string
  vendorPort?: string
  onClose?: () => void
  onSave?: () => void
  onCancel?: () => void
}

const InvoicePDFDialog: FC<Props> = ({
  open,
  record,
  lineItems,
  groupItems,
  productSamples,
  customerPort,
  vendorPort,
  typeNo,
  onClose,
  onSave,
  onCancel,
}) => {
  const classes = useStyle()
  const [template, setTemplate] = useState(1)
  const [settings, setSettings] = useState<InvoicePDFSettingsProps>(invoicePDFSettings)

  useEffect(() => {
    let newSettings: InvoicePDFSettingsProps = invoicePDFSettings

    if (template === 1) {
      newSettings = {
        ...newSettings,
        showFooter: true,
      }
    }

    if (template === 3) {
      newSettings = {
        ...newSettings,
        showProduction: false,
      }
    }

    setSettings(newSettings)
  }, [template])

  const handleTemplate = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTemplate(Number(event.target.value))
  }

  const handleSettingsChange = (name: keyof InvoicePDFSettingsProps, value: any) => {
    let newValue = { ...settings, [name]: value }
    if (value !== null) {
      setSettings(newValue)
    }
  }

  // function handleGenerate() {
  //     let documentDefinition = invoicePDF({ data, address, groupData, template, settings })
  //     pdfMake.createPdf(documentDefinition as any).download(`${data.invoice_header.ref_invoice}.pdf`)
  // }

  return (
    <Dialog classes={{ root: classes.root }} fullWidth={true} maxWidth="lg" open={open} onClose={onClose}>
      <div className={classes.content}>
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.previewZone}>
            <InvoicePreviewPDF
              data={record}
              lineItems={lineItems}
              groupItems={groupItems}
              productSamples={productSamples}
              customerPort={customerPort}
              vendorPort={vendorPort}
              template={template}
              settings={settings}
            />
          </Box>
          <Box p={2} className={classes.settingsZone}>
            <InvoicePDFSettings
              template={template}
              settings={settings}
              onTemplate={handleTemplate}
              onSettings={handleSettingsChange}
            />

            {/* <Box pt={3} textAlign="right">
              <Button variant="contained" color="primary" onClick={handleGenerate}>
                GENERATE
              </Button>
            </Box> */}
          </Box>
        </Box>
      </div>
    </Dialog>
  )
}

export default InvoicePDFDialog
