import React from 'react'
import { useParams } from 'react-router-dom'
import { Title, useCreate, useGetOne, useNotify, useRedirect } from 'react-admin'
import { Form } from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import Separate from 'src/components/Separate'
import ProductVariantExistList from './ProductVariantExistList'
import ProductVariantDetailForm from './ProductVariantDetailForm'
import { ProductVariant_form } from './types/ProductVariant'
import { Product_fields } from 'src/types/globalTypes'

const initialValues: ProductVariant_form = {
  full_size: null,
  glazing: null,
  sku: '',
  weight_loss: 0,
}

const ProductVariantCreate = (props: any) => {
  const { id }: any = useParams()
  const [createOne] = useCreate()
  const redirectTo = useRedirect()
  const notify = useNotify()

  const { data: product } = useGetOne<Product_fields>('product', id)

  const onSubmit = async (values: ProductVariant_form) => {
    createOne(
      'product_variant',
      {
        product_fk: id,
        size_fk: values.full_size?.size?.id || null,
        size_value_fk: values.full_size?.id || null,
        glazing: values.glazing,
        weight_loss: values.weight_loss,
        sku: values.sku,
      },
      {
        onSuccess: (values) => {
          redirectTo(`/product/${id}/product_variant/${values.data.id}`)
        },
        onFailure: () => {
          notify('resources.global.notifications.error.create', 'info')
        },
      }
    )
  }

  return (
    <>
      <Title title={product?.name} />
      <RouteBreadcrumbs {...props} />
      <Separate value={3} />
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ form, values, submitting, pristine, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <ProductVariantExistList productId={id} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <ProductVariantDetailForm submitting={submitting} pristine={pristine} />
                </Grid>
              </Grid>
            </form>
          )
        }}
      />
    </>
  )
}

export default ProductVariantCreate
