import React, { FC } from 'react'
import { Datagrid, Filter, FilterProps, List, ListProps, SearchInput, TextField } from 'react-admin'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import useVendorsByUser from 'src/hooks/useVendorsByUser'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'

const ProfileItemFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput source="vendor#code@_ilike" alwaysOn />
  </Filter>
)

const ProfileItemList: FC<ListProps> = (props) => {
  const { data: vendorsByUser, isSuperuser } = useVendorsByUser('id')
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Profile items`} />
      <RouteBreadcrumbs {...props} />
      <List
        {...props}
        perPage={25}
        sort={{ field: 'vendor.code', order: 'ASC' }}
        filters={<ProfileItemFilter />}
        filterDefaultValues={{ vendor_fk: isSuperuser ? undefined : vendorsByUser }}
        bulkActionButtons={false}
      >
        <Datagrid rowClick="edit">
          <TextField source="vendor.code" label="Vendor" />
          <TextField source="profile_item_values_aggregate.aggregate.count" label="Items" sortable={false} />
        </Datagrid>
      </List>
    </>
  )
}

export default ProfileItemList
