import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import SectionTitle from 'src/components/SectionTitle'
import Separate from 'src/components/Separate'
import OrderOptions from './OrderOptions'
import OrderDocumentDetails from './OrderDocumentDetails'
import OrderGeneralInformation from './OrderGeneralInformation'
import OrderShippingDetails from './OrderShippingDetail'
import { ReferenceManyField } from 'react-admin'
import { TimelineIterator } from 'src/pages/timelineComments/TimelineIterator'

const OrderInformationDetailTab = (props: any) => {
  const { record, disabled } = props

  return (
    <>
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center">
              <Box display="flex">
                <SectionTitle mb={0.1} label="resources.global.fieldGroups.overview" />
              </Box>
              <Typography style={{ marginLeft: '16px' }} variant="caption">
                #{record.ref_pi}
              </Typography>
            </Box>
            <OrderGeneralInformation hasEdited disabled={disabled} />
            <Separate value={5} />
            <OrderShippingDetails
              disabled={disabled}
              customer_fk_code={record.customer_fk_code}
              vendor_fk_code={record.vendor_fk_code}
            />
            <Separate value={5} />
            <ReferenceManyField
              target="order_id"
              reference="timeline_comment"
              sort={{ field: 'createdAt', order: 'DESC' }}
            >
              <TimelineIterator disabled={disabled} reference="order" />
            </ReferenceManyField>
          </Grid>

          <Grid item xs={12} md={6}>
            <OrderOptions disabled={disabled} />
            <OrderDocumentDetails disabled={disabled} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default OrderInformationDetailTab
