import { toDecimalStr } from 'src/utils/toDecimal'

export function pi_total3(total_ctn: number, total_net_weight: number, total_gross_weight: number) {
  const total = [
    [
      { colSpan: 4, style: 'textCenter', text: `TOTAL` },
      {},
      {},
      {},
      {
        style: 'textRight',
        text: `${toDecimalStr(total_ctn, true)}`,
      },
      {
        style: 'textRight',
        text: `${toDecimalStr(total_net_weight)}`,
      },
      {
        style: 'textRight',
        text: `${toDecimalStr(total_gross_weight)}`,
      },
      {},
    ],
  ]
  return total
}
