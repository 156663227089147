import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import Separate from 'src/components/Separate'
import { WEB_APP_TITLE } from 'src/constants'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { useDataProvider, useGetList, useLoading, useRedirect } from 'react-admin'
import InsightDateRange from '../insights/components/Tools/InsightDateRange'
import DownloadIcon from '@material-ui/icons/GetApp'
import { DateRange } from 'materialui-daterange-picker'
import moment from 'moment'
import { formatDate, formatDateToAPI } from 'src/utils/formatDate'
import { BankChoice_fields, Payment_fields } from 'src/types/globalTypes'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import { CSVLink } from 'react-csv'
import EnhancedTableHead from './components/EnchancedTableHead'
import { getComparator } from './utils/comparator'
import { stableSort } from './utils/stableSort'

type Order = 'asc' | 'desc'

interface Transaction {
  payment_id: string
  date: string | Date
  code: string | null
  type: string | null
  payment_date: string | Date
  exchange: number
  bank: string | null
  bank_charge: number
  deposit: number
  withdraw: number
  adjust: number
  balance: number
  remark: string | null
}

interface State {
  broughtForward: number
  transactions: Transaction[]
  balances: number[]
  total_charge: number
  total_deposit: number
  total_withdraw: number
  total_adjust: number
}

const dateInitial = {
  label: 'This year',
  startDate: moment(new Date()).startOf('year').toDate(),
  endDate: moment(new Date()).endOf('year').toDate(),
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Transaction
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  { id: 'payment_date', numeric: false, disablePadding: true, label: 'Payment date' },
  { id: 'code', numeric: false, disablePadding: true, label: 'Code' },
  { id: 'bank', numeric: false, disablePadding: true, label: 'Bank' },
  { id: 'exchange', numeric: true, disablePadding: true, label: 'Exchange rate' },
  { id: 'bank_charge', numeric: true, disablePadding: true, label: 'Bank charge' },
  { id: 'deposit', numeric: true, disablePadding: true, label: 'Deposit' },
  { id: 'withdraw', numeric: true, disablePadding: true, label: 'Withdraw' },
  { id: 'adjust', numeric: true, disablePadding: true, label: 'Adjust' },
  { id: 'balance', numeric: true, disablePadding: true, label: 'Balance/(USD)' },
  { id: 'remark', numeric: false, disablePadding: true, label: 'Remark' },
]

const Statement = () => {
  const [dateRange, setDateRange] = useState(dateInitial)
  const loading = useLoading()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Transaction>('payment_date')
  const [bank, setBank] = useState<string | null>(null)
  const [state, setState] = useState<State>({
    broughtForward: 0,
    transactions: [],
    balances: [],
    total_charge: 0,
    total_adjust: 0,
    total_deposit: 0,
    total_withdraw: 0,
  })

  const fetchPayments = useCallback(async () => {
    let start_date = dateRange ? { 'payment_date@_gte': formatDateToAPI(dateRange.startDate) } : null
    let end_date = dateRange ? { 'payment_date@_lte': formatDateToAPI(dateRange.endDate) } : null
    let bank_filter = bank ? { bank: bank } : undefined

    const { data: bPayments } = await dataProvider.getList<Payment_fields>('payment', {
      pagination: { page: 1, perPage: 50000 },
      sort: { field: 'payment_date', order: 'ASC' },
      filter: { 'payment_date@_lt': formatDateToAPI(dateRange.startDate), ...bank_filter },
    })

    let broughtForward = bPayments.length
      ? bPayments
          .map((item) => {
            let total = 0
            if (item.type === 'deposit' || item.type === 'adjust') {
              total += item.amount_deposit
            }

            if (item.type === 'withdraw') {
              total = total - item.amount_deposit
            }

            return total
          })
          .reduce((a, b) => a + b)
      : 0

    setState((prev) => ({
      ...prev,
      broughtForward,
    }))

    let aggregations: Transaction[] = []

    const { data: payments } = await dataProvider.getList<Payment_fields>('payment', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'payment_date', order: 'ASC' },
      filter: { ...start_date, ...end_date, ...bank_filter },
    })

    Object.values(payments).forEach((item) => {
      if (item.type === 'deposit') {
        aggregations.push({
          payment_id: item.id,
          date: item.date,
          payment_date: item.payment_date,
          bank: item.bank,
          code: item.customer_fk_code,
          bank_charge: item.bank_charge,
          exchange: item.exchange,
          deposit: item.amount_deposit,
          type: item.type,
          withdraw: 0,
          adjust: 0,
          remark: item.remark,
          balance: 0,
        })
      }
      if (item.type === 'withdraw') {
        aggregations.push({
          payment_id: item.id,
          date: item.date,
          payment_date: item.payment_date,
          code: item.customer_fk_code,
          bank: item.bank,
          bank_charge: item.bank_charge,
          exchange: item.exchange,
          type: item.type,
          deposit: 0,
          adjust: 0,
          withdraw: item.amount_deposit,
          remark: item.remark,
          balance: 0,
        })
      }
      if (item.type === 'adjust') {
        aggregations.push({
          payment_id: item.id,
          date: item.date,
          payment_date: item.payment_date,
          code: item.customer_fk_code,
          bank: item.bank,
          bank_charge: item.bank_charge,
          exchange: item.exchange,
          type: item.type,
          deposit: 0,
          adjust: item.amount_deposit,
          withdraw: 0,
          remark: item.remark,
          balance: 0,
        })
      }
    })

    aggregations.reduce((acc, curr, index) => {
      if (index === 0) {
        if (curr.type === 'deposit' || curr.type === 'adjust') {
          aggregations[0].balance = toDecimal(broughtForward + curr.deposit + curr.adjust)
        }
        if (curr.type === 'withdraw') {
          aggregations[0].balance = toDecimal(broughtForward - curr.withdraw)
        }
      }

      if (index > 0) {
        if (curr.type === 'deposit' || curr.type === 'adjust') {
          aggregations[index].balance = toDecimal(aggregations[index - 1].balance + curr.deposit + curr.adjust)
        }
        if (curr.type === 'withdraw') {
          aggregations[index].balance = toDecimal(aggregations[index - 1].balance - curr.withdraw)
        }
      }

      return acc
    }, {})

    let total_charge = aggregations.length
      ? toDecimal(aggregations.map((item) => item.bank_charge).reduce((a, b) => a + b))
      : 0

    let total_deposit = aggregations.length
      ? toDecimal(aggregations.map((item) => item.deposit).reduce((a, b) => a + b))
      : 0
    let total_withdraw = aggregations.length
      ? toDecimal(aggregations.map((item) => item.withdraw).reduce((a, b) => a + b))
      : 0

    let total_adjust = aggregations.length
      ? toDecimal(aggregations.map((item) => item.adjust).reduce((a, b) => a + b))
      : 0

    setState((prev) => ({
      ...prev,
      transactions: aggregations,
      total_charge,
      total_adjust,
      total_deposit,
      total_withdraw,
    }))
  }, [dataProvider, dateRange, bank])

  useEffect(() => {
    fetchPayments()
  }, [fetchPayments])

  const handleBankChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setBank(event.target.value as string)
  }

  const handleDateRange = (value: DateRange) => {
    setDateRange((prev) => ({ ...prev, ...value }))
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Transaction) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Bank statement`} />
      <RouteBreadcrumbs />
      <Separate value={3} />

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <InsightDateRange dateRange={dateRange} setDateRange={handleDateRange} />
              <BankSelect value={bank || ''} onChange={handleBankChange} />
            </Box>
            <CSVLink data={state.transactions} filename={`statement`} style={{ textDecoration: 'none' }}>
              <Button color="primary" startIcon={<DownloadIcon />} variant="text" size="small" disabled={loading}>
                Export
              </Button>
            </CSVLink>
          </Box>
        </Grid>
      </Grid>
      <Separate value={1} />
      <Paper>
        <Table stickyHeader size="small">
          <EnhancedTableHead headCells={headCells} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />

          <TableBody>
            <TableRow>
              <TableCell colSpan={9} align="right">
                <Typography style={{ fontWeight: 'bold' }} component="h6">
                  Balance brought forward {toDecimalStr(state.broughtForward)}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            {stableSort(state.transactions, getComparator(order, orderBy)).map((item, index) => (
              <TableRow key={index} onClick={() => redirect(`/payment/${item.payment_id}`)} hover>
                <TableCell>{formatDate(item.payment_date)}</TableCell>
                <TableCell>{item.code}</TableCell>
                <TableCell>{item.bank?.toUpperCase()}</TableCell>
                <TableCell align="right">{toDecimalStr(item.exchange)}</TableCell>
                <TableCell align="right">{toDecimalStr(item.bank_charge)}</TableCell>
                <TableCell align="right">{item.deposit ? toDecimalStr(item.deposit) : ''}</TableCell>
                <TableCell align="right">{item.withdraw ? toDecimalStr(item.withdraw) : ''}</TableCell>
                <TableCell align="right">{item.adjust ? toDecimalStr(item.adjust) : ''}</TableCell>
                <TableCell align="right">{toDecimalStr(item.balance)}</TableCell>
                <TableCell>{item.remark}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4} align="center" style={{ fontWeight: 'bold' }}>
                Total
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                {toDecimalStr(state.total_charge)}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                {toDecimalStr(state.total_deposit)}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                {toDecimalStr(state.total_withdraw)}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                {toDecimalStr(state.total_adjust)}
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default Statement

interface BankProps {
  value: string | null
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void
}

function BankSelect(props: BankProps) {
  const { value, onChange } = props
  const { data, ids } = useGetList<BankChoice_fields>(
    'bank_choice',
    { page: 1, perPage: 100 },
    { field: 'code', order: 'ASC' },
    {}
  )

  return (
    <>
      <FormControl variant="filled" size="small">
        <InputLabel id="bank-select-label">Bank</InputLabel>
        <Select
          id="bank-select"
          labelId="bank-select-label"
          style={{ width: '200px' }}
          value={value}
          onChange={onChange}
        >
          <MenuItem value={''}>No select</MenuItem>
          {ids.length ? (
            ids.map((id) => (
              <MenuItem key={id} value={data[id].code}>
                {data[id].label}
              </MenuItem>
            ))
          ) : (
            <MenuItem>Loading...</MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  )
}
