import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/CardContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import VendorReferenceInput from '../vendors/VendorReferenceInput'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuIcon from '@material-ui/icons/Menu'
import { toDecimalStr } from 'src/utils/toDecimal'
import { required, useInput, TextInput, useGetOne } from 'react-admin'
import { FieldArray } from 'react-final-form-arrays'
import { Expenses_fields, ExpenseValues_field } from 'src/types/globalTypes'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

// const useStyles = makeStyles()

interface Props {
  id: string
  open: boolean
  onSubmit: (values: any) => void
  onClose?: () => void
  onCancel?: () => void
}

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const EditExpenseDialog: FC<Props> = ({ id, onClose, onSubmit, ...rest }) => {
  const { data, loaded } = useGetOne<Expenses_fields>('expenses', id)

  if (!data || !loaded) return null

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} {...rest}>
      <DialogTitle>Edit expense </DialogTitle>
      <Form
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          title: data.title,
          vendor_fk_code: data.vendor_fk_code,
          expenses: data.values,
        }}
        onSubmit={onSubmit}
        render={({ form, pristine, values, handleSubmit }) => {
          const onDragEnd = (result: any) => {
            // dropped outside the list
            if (!result.destination) {
              return
            }

            const items = reorder(values.expenses, result.source.index, result.destination.index)

            form.change('expenses', items)
          }

          return (
            <form id="expense-form" onSubmit={handleSubmit}>
              <Box position="absolute" top={24} left={160}>
                <Typography variant="caption" style={{ color: '#6e6e6e' }}>
                  Shipping expense:{' '}
                  {toDecimalStr(
                    values.expenses.length
                      ? values.expenses
                          .map((item: ExpenseValues_field) => Number(item.value))
                          .reduce((a: number, b: number) => a + b)
                      : 0
                  )}
                </Typography>
              </Box>
              <DialogContent style={{ position: 'relative' }}>
                <TextInput fullWidth source="title" />
                <VendorReferenceInput fullWidth label="Vendor" source="vendor_fk_code" validate={required()} disabled />

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <FieldArray name="expenses">
                          {({ fields }) => (
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell width={50}></TableCell>
                                  <TableCell>DETAILS OF EXPENSES TOTAL</TableCell>
                                  <TableCell width={100} align="right">
                                    TOTAL
                                  </TableCell>
                                  <TableCell width={50}></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {fields.map((name, index) => (
                                  <Draggable key={`${name}.label`} draggableId={`${name}.label`} index={index}>
                                    {(provided, snapshot) => (
                                      <TableRow
                                        innerRef={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                      >
                                        <TableCell>
                                          <MenuIcon style={{ cursor: 'grab' }} />
                                        </TableCell>
                                        <TableCell>
                                          <BoundedTextField name={`${name}.label`} />
                                        </TableCell>
                                        <TableCell align="right">
                                          <BoundedTextField
                                            type="number"
                                            name={`${name}.value`}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                            size="small"
                                            color="secondary"
                                            onClick={() => fields.remove(index)}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                <TableRow>
                                  <TableCell colSpan={4}>
                                    <Button
                                      onClick={() => fields.push({ label: '', value: 0 })}
                                      variant="outlined"
                                      color="primary"
                                    >
                                      Add item
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          )}
                        </FieldArray>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </DialogContent>
              <DialogActions>
                <Button style={{ marginRight: '10px' }} onClick={onClose} variant="outlined">
                  Cancel
                </Button>
                <Button
                  style={{ marginRight: '20px' }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={pristine}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )
        }}
      />
    </Dialog>
  )
}

export default EditExpenseDialog

const BoundedTextField = (props: any) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props)

  return (
    <TextField
      fullWidth
      variant="outlined"
      inputProps={props.inputProps}
      size="small"
      name={name}
      label={props.label}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      required={isRequired}
      {...rest}
    />
  )
}
