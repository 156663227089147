import React from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { FormWithRedirect, Pagination, ReferenceManyField, required, TextInput, Toolbar } from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import Separate from 'src/components/Separate'
import SizeValueIterator from './SizeValueIterator'

export default function SizeDetailForm(props: any) {
  return (
    <FormWithRedirect
      {...props}
      redirect={props.redirect}
      render={(formProps: any) => (
        <form>
          <Box p="1" width="50em">
            <Card>
              <CardContent>
                <SectionTitle label="resources.global.fieldGroups.information" />
                <TextInput source="label" fullWidth validate={required()} />
                <TextInput source="description" fullWidth />
                <Separate value={3} />

                <SectionTitle label="resources.size.fieldGroups.attribute_values" />
                <ReferenceManyField
                  target="size_fk_id"
                  reference="size_value"
                  sort={{ field: 'label', order: 'ASC' }}
                  pagination={<Pagination />}
                >
                  <SizeValueIterator reference="size" />
                </ReferenceManyField>
              </CardContent>
              <Toolbar
                record={formProps.record}
                basePath={formProps.basePath}
                invalid={formProps.invalid}
                handleSubmit={formProps.handleSubmit}
                saving={formProps.saving}
                pristine={formProps.pristine}
                resource="size"
              />
            </Card>
          </Box>
        </form>
      )}
    />
  )
}
