import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { OrderRevise_fields } from 'src/types/globalTypes'
import { formatDate, formatTimestampToDate } from 'src/utils/formatDate'
import { OrderLineItems_expanded, Order_expanded } from '../../types'
import getOrderSumValues from '../getOrderSumValues'
import { pi_header3 } from './components/template3/header'
import { pi_lineItems3 } from './components/template3/lineItems'
import { pageHeading_3 } from './components/template3/pageHeading'
import { pi_total3 } from './components/template3/total'
import { pi_style } from './styles'

const pdf = pdfMake
pdf.vfs = pdfFonts.pdfMake.vfs

interface Props {
  data: Order_expanded
  lineItems: OrderLineItems_expanded[]
  revise?: OrderRevise_fields
}

export default function order_template3(props: Props) {
  const { data, lineItems, revise } = props
  let expDate = `${formatTimestampToDate(data.end_date)}`

  const { total_ctn, total_net_weight_kg, total_gross_weight_kg } = getOrderSumValues({
    unit_code: data.unit_code?.code || 'kg',
    lineItems,
    revise,
    exchange_rate: data.exchange_rate,
  })

  let pageHeading = pageHeading_3({ ...data })
  let header = pi_header3()
  let lineItems_row = pi_lineItems3({ data: lineItems })

  let total = pi_total3(total_ctn, total_net_weight_kg, total_gross_weight_kg)

  const documentDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    info: {
      title: `${data.ref_pi}-loading-revise-${data.current_revise_num}`,
      subject: `${data.ref_pi}-loading-revise-${data.current_revise_num}`,
    },
    pageMargins: [20, 20, 20, 30],
    content: [
      {
        style: 'header',
        text: `${data.vendor?.company_name}`,
      },
      ...pageHeading,

      {
        columns: [
          {
            style: ['table', 'mt3'],
            table: {
              headerRows: 1,
              widths: [25, 195, 195, 45, 50, 55, 55, 70],
              body: [...header, ...lineItems_row, ...total],
            },
            fontSize: 9,
          },
        ],
      },
      {
        style: 'endLine',
        text: `${
          data.current_revise_num !== 0 ? `Revise: ${data.current_revise_num}, Date: ${formatDate(revise?.date)}` : ``
        }`,
      },
    ],

    styles: pi_style,
  }

  return documentDefinition
}
