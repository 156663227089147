import React, { FC } from 'react'
import Typography from '@material-ui/core/Typography'
import { useGetList } from 'react-admin'
import Dialog from 'src/components/Dialog'
import MTTable from 'src/components/MTTable'
import { OrderLineItems_expanded } from '../types'
import { useTheme } from '@material-ui/core'
import { toDecimalStr } from 'src/utils/toDecimal'
import { formatDate } from 'src/utils/formatDate'

interface Props {
  open: boolean
  data: OrderLineItems_expanded | null
  onClose?: () => void
  onCancel?: () => void
}

const OrderPreviousPriceDialog: FC<Props> = ({ data, ...props }) => {
  const theme = useTheme()
  const {
    data: lineItems,
    ids,
    loading,
  } = useGetList<OrderLineItems_expanded>(
    'order_line_item',
    { page: 1, perPage: 100 },
    { field: 'order.pi_date', order: 'DESC' },
    {
      product_fk: data?.product_fk,
      size_value_fk: data?.size_value_fk || null,
      size_fk: data?.size_fk || null,
      pack_fk: data?.pack_fk || '',
    }
  )

  let newData = ids.map((id) => ({ ...lineItems[id] }))

  return (
    <Dialog size="lg" title="Previous price" {...props}>
      <MTTable
        isLoading={loading}
        columns={[
          {
            title: 'Date',
            field: 'order.pi_date',
            render: (rowData: any) => <div>{formatDate(rowData.order.pi_date)}</div>,
          },
          {
            title: 'Product',
            field: 'product.name',
            editable: 'never',
            render: (rowData: any) => (
              <div style={{ width: '350px' }}>
                <Typography variant="body2">
                  {rowData.product.name}
                  <Typography component="span" variant="caption">
                    {rowData.size_value ? ` - ${rowData.size_value.label} ${rowData.size?.label}` : ''}
                  </Typography>
                </Typography>
                <Typography style={{ color: theme.palette.grey[600] }} variant="caption">
                  {rowData.pack.name}
                </Typography>
              </div>
            ),
          },
          {
            title: 'Customer',
            field: 'order.customer_fk_code',
          },
          {
            title: 'Vendor',
            field: 'order.vendor_fk_code',
          },
          {
            title: 'WET/MC (KG) & GROSS NET/MC (KG)',
            field: 'weight_mc',
            align: 'center',
            editable: 'never',
            render: (rowData: any) => (
              <div style={{ width: '140px' }}>
                {rowData.weight_mc} / {rowData.gross_weight}
              </div>
            ),
          },
          { title: 'MC', field: 'box', type: 'numeric' },
          {
            title: 'Total NET/WET (KG)',
            field: 'net_weight_kgs',
            type: 'numeric',
            editable: 'never',
            render: (rowData: any) => toDecimalStr(rowData.weight_mc * rowData.box),
          },
          //   {
          //     title: `Unit Cost (USD/${unit_code})`,
          //     // field: '',
          //     type: 'numeric',
          //     editable: 'never',
          //     render: (rowData: any) => {
          //       let total_line_cost = getOrderTotalListCost({
          //         raw_cost: rowData.raw_cost,
          //         import_cost: rowData.import_cost,
          //         pack_cost_baht: rowData.pack_cost_baht,
          //         etc_cost: revise?.exchange_rate_info.etc_cost,
          //         usd_export: exchange_rate,
          //         shipping_cost,
          //       })

          //       return <div style={{ width: '100px' }}>{toDecimalStr(total_line_cost)}</div>
          //     },
          //   },
          //   {
          //     title: 'Target Profit(%)',
          //     field: 'profit',
          //     type: 'numeric',
          //     render: (rowData: any) => toDecimalStr(rowData.profit),
          //   },
          //   {
          //     title: 'Suggested Price  (USD)',
          //     // field: 'margins',
          //     type: 'numeric',
          //     render: (rowData: any) => {
          //       let result = 0
          //       let unit_cost = getOrderTotalListCost({
          //         raw_cost: rowData.raw_cost,
          //         import_cost: rowData.import_cost,
          //         pack_cost_baht: rowData.pack_cost_baht,
          //         etc_cost: revise?.exchange_rate_info.etc_cost,
          //         usd_export: exchange_rate,
          //         shipping_cost,
          //       })

          //       result = toDecimal(unit_cost * (1 + rowData.profit / 100))

          //       return <div>{toDecimalStr(result)}</div>
          //     },
          //   },
          //   {
          //     title: 'Previous price (USD)',
          //     align: 'center',
          //     render: (rowData: any) => (
          //       <div style={{ width: '120px' }}>
          //         <IconButton color="primary" onClick={() => handleOpen(rowData)}>
          //           <SearchIcon />
          //         </IconButton>
          //       </div>
          //     ),
          //   },
          {
            title: `Unit Price`,
            field: 'unit_price',
            type: 'numeric',
          },
          //     {
          //       title: 'Amount (USD)',
          //       field: 'amount',
          //       type: 'numeric',
          //       editable: 'never',
          //       render: (rowData: any) =>
          //         toDecimalStr(
          //           getOrderLineAmount({
          //             unit_code,
          //             weight_mc: rowData.weight_mc,
          //             box: rowData.box,
          //             unit_price: rowData.unit_price,
          //           })
          //         ),
          //     },
        ]}
        data={newData}
        options={{
          pageSize: 20,
        }}
      />
    </Dialog>
  )
}

export default OrderPreviousPriceDialog
