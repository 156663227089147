import { OrderReviseExpenseRateInfo_field } from 'src/types/globalTypes'
import { toDecimal } from 'src/utils/toDecimal'

interface Props {
  unit_code: string
  expense_rate_info: OrderReviseExpenseRateInfo_field[]
  shipping_usd: number
  total_net_weight_kg: number
  total_ctn: number
}

export default function getOrderShippingValues(props: Props) {
  const { unit_code, expense_rate_info, shipping_usd, total_net_weight_kg, total_ctn } = props

  let shipping_expense_locale_currency = 0
  let shipping_expense_usd = 0
  let shipping_cost = 0

  if (expense_rate_info) {
    shipping_expense_locale_currency = expense_rate_info.length
      ? expense_rate_info.map((item) => (item.value ? Number(item.value) : 0)).reduce((a, b) => a + b)
      : 0

    shipping_expense_usd = toDecimal(shipping_expense_locale_currency / shipping_usd)

    let divideWith = unit_code === 'kg' ? total_net_weight_kg : total_ctn

    shipping_cost = expense_rate_info.length
      ? expense_rate_info
          .map((item) => (item.value ? toDecimal(Number(item.value) / divideWith / shipping_usd) : 0))
          .reduce((a, b) => a + b)
      : 0
  }

  return {
    shipping_expense_locale_currency,
    shipping_expense_usd,
    shipping_cost,
  }
}
