import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { OrderProductSample_fields, OrderRevise_fields } from 'src/types/globalTypes'
import { OrderLineItems_expanded, Order_expanded } from '../../types'
import getOrderSumValues from '../getOrderSumValues'
import { pi_expense2 } from './components/template2/expense'
import { pi_header2 } from './components/template2/header'
import { pi_lineItems } from './components/template2/lineItems'
import { pi_productSamples } from './components/template2/productSamples'
import { pi_total2 } from './components/template2/total'
import { pi_style } from './styles'

const pdf = pdfMake
pdf.vfs = pdfFonts.pdfMake.vfs

interface Props {
  data: Order_expanded
  lineItems: OrderLineItems_expanded[]
  productSamples: OrderProductSample_fields[]
  customerPort?: string
  revise?: OrderRevise_fields
  hasAccess?: boolean
}

type Counter = { [k: number]: number }

export default function order_template2(props: Props) {
  const { data, lineItems, revise, customerPort, productSamples, hasAccess } = props

  let total_sample_net_weight_kg = 0
  let sample_counter: Counter = { 0: 0 }
  let total_sample_ctn = 0

  if (productSamples.length) {
    total_sample_net_weight_kg = productSamples.map((item) => item.net_weight_kg).reduce((a, b) => a + b)
    sample_counter = productSamples.reduce((r: Counter, o) => {
      r[o.box] = (r[o.box] || 0) + 1
      return r
    }, {})

    total_sample_ctn = Object.keys(sample_counter).length
  }

  const { shipping_cost, shipping_expense_locale_currency, total_cost_usd, total_ctn, total_net_weight_kg } =
    getOrderSumValues({
      unit_code: data.unit_code?.code || 'kg',
      lineItems,
      revise,
      exchange_rate: data.exchange_rate,
      total_sample_ctn,
      total_sample_net_weight_kg,
    })

  let getMiddleName = data.ref_pi.split('/')[1]
  let ref_pi_num = getMiddleName.slice(getMiddleName.length - data.customer_fk_code.length + 1, getMiddleName.length)

  let header = pi_header2(data.unit_code?.name || '', data.currency_fk || '', revise?.locale_currency_fk || '')
  let expenses = pi_expense2({
    revise,
    shipping_expense_locale_currency,
    shipping_cost,
    total_net_weight: total_net_weight_kg + total_sample_net_weight_kg,
    currency: data.currency_fk || '',
    unit_code: data.unit_code?.code,
  })
  let lineItems_row = pi_lineItems({
    unit_code: data.unit_code?.code,
    data: lineItems,
    shipping_baht: shipping_cost,
    exchange_rate: data.exchange_rate,
    etc_cost: revise?.exchange_rate_info.etc_cost || 0,
    shipping_cost,
  })

  let productSamples_row = pi_productSamples(lineItems.length, productSamples)
  let expenses_row = hasAccess ? { ...expenses } : undefined

  let total = pi_total2(
    data.shipping_details.terms_of_sales,
    customerPort || '',
    total_ctn + total_sample_ctn,
    total_net_weight_kg + total_sample_net_weight_kg,
    total_cost_usd
  )

  const documentDefinition = {
    info: {
      title: `${data.ref_pi}-expenses-revise-${data.current_revise_num}`,
      subject: `${data.ref_pi}-expenses-revise-${data.current_revise_num}`,
    },
    pageSize: 'LEGAL',
    pageOrientation: 'landscape',
    pageMargins: [20, 20, 20, 20],
    content: [
      {
        style: 'header',
        text: 'CK FROZEN FISH & FOOD CO.,LTD',
      },
      {
        columns: [
          {
            width: 140,
            style: 'subheader',
            text: `${data.customer_fk_code} ${ref_pi_num}`,
          },
          {
            width: 150,
            style: 'subHeader2',
            text: `Date: ${data.pi_date}
            CONTAINER NO: 
            SEAL NO: 
            `,
          },
        ],
      },
      {
        columns: [
          {
            style: 'table',
            fontSize: 9,
            table: {
              headerRows: 1,
              widths: [15, 120, 30, 70, 40, 40, 45, 40, 45, 45, 45, 45, 60],
              body: [...header, ...lineItems_row, ...productSamples_row, ...total],
            },
          },
          expenses_row,
        ],
      },
    ],
    styles: pi_style,
  }

  return documentDefinition
}
