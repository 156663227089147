import { OrderCustomKG_field } from 'src/types/globalTypes'
import { toDecimal } from 'src/utils/toDecimal'

interface Props {
  unit_code: string
  weight_mc: number
  box: number
  price: number
  custom_kg: OrderCustomKG_field
  showSample?: boolean
}

export default function getInvoiceLineAmount(props: Props) {
  const { unit_code, weight_mc, box, price, custom_kg, showSample } = props
  let net_weight_kg = showSample
    ? toDecimal(weight_mc * (box || 0) + custom_kg.net_weight_kg)
    : toDecimal(weight_mc * (box || 0))
  let result = 0
  if (unit_code) {
    result = unit_code === 'mc' ? toDecimal(price * box) : toDecimal(price * net_weight_kg)
  }

  return result
}
