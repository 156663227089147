import { useQueryWithStore } from 'react-admin'
import moment from 'moment'

const useInvoiceHeaderVendorCount = (vendor: string, pi_date: string | Date) => {
  let year = moment(pi_date).year()

  const { total } = useQueryWithStore({
    type: 'getList',
    resource: 'invoice_header',
    payload: {
      filter: {
        'order#vendor_fk_code@_eq': vendor,
        'order#pi_date@_gte': `${year}-01-01`,
        'order#pi_date@_lte': `${year}-12-31`,
      },
    },
  })

  return total || 0
}

export default useInvoiceHeaderVendorCount
