import { InvoiceLineItem_expanded, InvoicePDFSettingsProps } from 'src/pages/invoices/types'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'

interface Props {
  mark_nos: string | null
  data: InvoiceLineItem_expanded[]
  settings: InvoicePDFSettingsProps
}

export function invoice_lineItems2(props: Props) {
  let formatted_lineItmes = makeData(props)
  return formatted_lineItmes
}

function makeData(props: Props) {
  const { settings, data, mark_nos } = props
  let formatted_data = data.map((item, index) => {
    let products: string[] = []
    let productText = ''

    let productNames = {
      // local: item.product.name,
      // english: item.product.full_name,
      // sci1: item.product.other_names.scientific_name1,
      // sci2: item.product.other_names.scientific_name2,
      // sci3: item.product.other_names.scientific_name3,
    }

    productText = products.length ? products.join(', ') : ''

    let net_weight_kg = settings.showProductSample
      ? toDecimal(item.box * item.weight_mc + item.custom_kg.net_weight_kg)
      : toDecimal(item.box * item.weight_mc)

    let result =
      index === 0
        ? [
            {
              style: 'tcCell',
              text: `${mark_nos || ''}`,
            },
            {
              style: 'tlCell',
              text: `${item.product?.name || ''}`,
            },
            {
              style: ['tlCell', 'sizeText'],
              text: `${item.size_value ? `${item.size_value.label} ${item.size?.label}` : ''}`,
            },
            {
              style: ['tcCell'],
              text: `${item.weight_mc || 0}`,
            },

            {
              style: 'tcCell',
              text: `${toDecimalStr(item.box, true)}`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(net_weight_kg)}`,
            },

            {
              style: 'trCell',
              text: `${toDecimalStr(net_weight_kg + item.box)}`,
            },
          ]
        : [
            {
              style: 'tcCell',
              text: ``,
            },
            {
              style: 'tlCell',
              text: `${item.product?.name || ''}`,
            },
            {
              style: ['tlCell', 'sizeText'],
              text: `${item.size_value ? `${item.size_value.label} ${item.size?.label}` : ''}`,
            },
            {
              style: ['tcCell'],
              text: `${item.weight_mc || 0}`,
            },
            {
              style: 'tcCell',
              text: `${toDecimalStr(item.box, true)}`,
            },
            {
              style: 'trCell',
              text: `${toDecimalStr(net_weight_kg)}`,
            },

            {
              style: 'trCell',
              text: `${toDecimalStr(net_weight_kg + item.box)}`,
            },
          ]

    return result
  })

  return formatted_data
}
