import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core'
import { useCreate, useDelete, useListContext, useNotify, useRefresh, useUpdate } from 'react-admin'
import Separate from 'src/components/Separate'
import { ProfileItemValue_expanded } from './types'
import SectionTitle from 'src/components/SectionTitle'
import AddProductProfileItemValueDialog from './AddProductProfileItemValueDialog'
import { useForm, useFormState } from 'react-final-form'
import { addItemState } from './data'
import { ProfileItemValue_input } from './types/input'
import EditProfileItemValueDialog from './EditProfileItemValueDialog'
import RemoveDialog from 'src/components/RemoveDialog'
import useCreateUserLog from 'src/hooks/useCreateUserLog'
import PackReferenceInput from 'src/components/ReferenceInputs/PackReferenceInput'

const useStyles = makeStyles({
  root: {
    marginTop: '0.5em',
  },
  size: {
    color: `rgb(117, 117, 117)`,
  },
})

const ProfileItemValueIterator = () => {
  const classes = useStyles()
  const [selectedId, setSelectedId] = useState('')
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const [openEditProduct, setOpenEditProduct] = useState(false)
  const [items, setItems] = useState<ProfileItemValue_expanded[]>([])
  const { data, ids, loaded, filterValues, setFilters, displayedFilters } = useListContext<ProfileItemValue_expanded>()
  const { values } = useFormState()
  const { change } = useForm()
  const [addItem] = useCreate()
  const { createLog } = useCreateUserLog()
  const [updateItem] = useUpdate()
  const [deleteItem] = useDelete()
  const notify = useNotify()
  const refresh = useRefresh()

  useEffect(() => {
    if (!loaded) return undefined

    if (ids.length) {
      let newValues = _.sortBy(
        ids.map((id) => data[id]),
        ['product_variant.product.name', 'product_variant.size_value.label', 'product_variant.size.label', 'pack.name']
      )
      setItems(newValues)
    }
  }, [ids, data, loaded])

  useEffect(() => {
    if (selectedId) {
      let index = items.findIndex((item) => item.id === selectedId)
      let values = items[index]
      change('editItem', values)
    }
  }, [items, selectedId, change])

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters(
          {
            ...filterValues,
            'product_variant#product#name@_ilike': `%${value}%`,
          },
          displayedFilters
        )
    },
    [displayedFilters, filterValues, setFilters]
  )

  const handlePackChange = useCallback(
    (value: any) => {
      setFilters &&
        setFilters(
          {
            ...filterValues,
            pack_fk: value?.id || undefined,
          },
          displayedFilters
        )
    },
    [displayedFilters, filterValues, setFilters]
  )

  const handleAddItemSave = (addMore?: boolean) => {
    if (values.id) {
      let data: ProfileItemValue_input = {
        vendor_code: values.vendor.code,
        profile_item_fk: values.id,
        product_variant_fk: values.addItem.product_variant.id,
        pack_fk: values.addItem.pack.id,
        weight_mc: values.addItem.pack.weight_mc,
        gross_weight: values.addItem.use_default
          ? values.addItem.pack.weight_mc + values.default_gross_weight
          : values.addItem.pack.gross_weight,
        weight_glazing: 0,
        rw_price: 0,
        default_gross_weight: values?.default_gross_weight || 0,
        profit: 0,
        use_default: values.addItem.use_default,
        customer_fk_ids: values.addItem.customer_fk_ids,
      }

      addItem('profile_item_value', data, {
        onSuccess: () => {
          notify('resources.global.notifications.success.create', { type: 'info' })
          change('addItem', addItemState)
          createLog(
            'profile_item_value',
            'CREATE',
            `Add ${values.vendor.code}'s profile item ${values.addItem.product_variant.product?.name}${
              values.addItem.product_variant?.size
                ? ` ${values.addItem.product_variant?.size_value.label} - ${values.addItem.product_variant.size.label}, pack: ${values.addItem.pack?.name}, weight_mc: ${data.weight_mc}, gross_weight: ${data.gross_weight}`
                : ''
            }`
          )
          setOpenAddProduct(addMore ? true : false)
          if (addMore === false) {
            refresh()
          }
        },
      })
    }
  }

  const handleItemDelete = (id: string) => {
    if (id) {
      let index = items.findIndex((item) => item.id === id)
      let dataDelete = [...items]
      dataDelete.splice(index, 1)
      deleteItem('profile_item_value', id, data[id], {
        onSuccess: () => {
          notify('resources.global.notifications.success.delete', { type: 'info' })
          createLog(
            'profile_item_value',
            'DELETE',
            `DELETE ${data[id].vendor_code}'s ${data[id].product_variant.product.name}${
              data[id].product_variant.size
                ? ` ${data[id].product_variant.size_value?.label} - ${data[id].product_variant.size?.label}`
                : ''
            }, pack: ${data[id].pack.name}, weight_mc: ${data[id].weight_mc}, gross_wieght: ${data[id].gross_weight}`
          )
          setItems(dataDelete)
        },
      })
    }
  }

  const handleAddItemClose = () => {
    setOpenAddProduct(false)
    refresh()
  }

  const handleAddItemCancel = () => {
    setOpenAddProduct(false)
    change('addItem', addItemState)
    refresh()
  }

  const handleEditItemSave = () => {
    if (selectedId) {
      let id = items.findIndex((item) => item.id === selectedId)
      let data = [...items]
      data[id] = {
        ...data[id],
        customer_fk_ids: values.editItem.customer_fk_ids,
        weight_mc: values.editItem.weight_mc,
        use_default: values.editItem.use_default,
        gross_weight: values.editItem.use_default
          ? values.editItem.weight_mc + values.default_gross_weight
          : values.editItem.gross_weight,
      }

      updateItem(
        'profile_item_value',
        selectedId,
        data[id],
        {},
        {
          onSuccess: () => {
            notify('resources.global.notifications.success.create', { type: 'info' })
            createLog(
              'profile_item_value',
              'UPDATE',
              `UPDATE ${data[id].vendor_code}'s ${data[id].product_variant.product.name}${
                data[id].product_variant.size
                  ? ` ${data[id].product_variant.size_value?.label} - ${data[id].product_variant.size?.label}`
                  : ''
              }, pack: ${data[id].pack.name}, weight_mc: ${data[id].weight_mc}, gross_wieght: ${data[id].gross_weight}`
            )
            setItems(data)
            handleEditItemClose()
          },
        }
      )
    }
  }

  const handleEditItemClose = () => {
    setSelectedId('')
    setOpenEditProduct(false)
  }

  const handleEditItemOpen = (id: string) => {
    setSelectedId(id)
    setOpenEditProduct(true)
  }

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between">
        <SectionTitle label="resources.profile_item_value.fieldGroups.items" />
        <div>
          <Button color="primary" variant="contained" onClick={() => setOpenAddProduct(true)}>
            Add item
          </Button>
        </div>
      </Box>
      <Separate value={3} />
      <Box display="flex">
        <TextField
          style={{ width: 240, marginRight: 16 }}
          variant="filled"
          size="small"
          label="Search"
          onChange={(e) => handleChange(e, e.target.value)}
        />

        <PackReferenceInput
          style={{ width: '400px' }}
          source="pack_fk"
          defaultValue={''}
          onChange={(e) => handlePackChange(e)}
        />
      </Box>
      <Separate value={2} />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Pack</TableCell>
            <TableCell align="center">Weight MC</TableCell>
            <TableCell align="center">Gross Weight</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length
            ? items.map((item, index) => (
                <TableRow key={index} hover>
                  <TableCell>
                    <Typography variant="body2">
                      {item.product_variant?.product?.name}
                      <Typography component="span" variant="caption" className={classes.size}>
                        {item.product_variant?.size_value
                          ? ` - ${item.product_variant.size_value.label} ${item.product_variant.size?.label}`
                          : ''}
                      </Typography>
                    </Typography>
                  </TableCell>
                  <TableCell>{item.pack?.name}</TableCell>
                  <TableCell align="center">{item.weight_mc}</TableCell>
                  <TableCell align="center">{item.gross_weight}</TableCell>
                  <TableCell style={{ width: '300px' }}>
                    {item.customer_fk_ids.map((customer, id: number) => <Chip key={id} label={customer} />).slice(0, 3)}
                    {item.customer_fk_ids.length - 3 > 0 ? ` +${item.customer_fk_ids.length - 3}` : null}
                  </TableCell>
                  <TableCell style={{ width: '80px' }}>
                    <Box display="flex" justifyContent="space-between">
                      <IconButton size="small" color="primary" onClick={() => handleEditItemOpen(item.id)}>
                        <EditIcon />
                      </IconButton>
                      <RemoveDialog
                        title={item.product_variant?.product?.name}
                        onClick={() => handleItemDelete(item.id)}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>

      {openAddProduct && (
        <AddProductProfileItemValueDialog
          open={openAddProduct}
          onSave={() => handleAddItemSave(false)}
          onSaveAndAdd={() => handleAddItemSave(true)}
          onClose={handleAddItemClose}
          onCancel={handleAddItemCancel}
          disabled={values.addItem.product_variant === null || values.addItem.pack === null ? true : false}
        />
      )}

      {openEditProduct && (
        <EditProfileItemValueDialog
          id={selectedId}
          open={openEditProduct}
          onSave={handleEditItemSave}
          onClose={handleEditItemClose}
          onCancel={handleEditItemClose}
        />
      )}
    </div>
  )
}

export default ProfileItemValueIterator
