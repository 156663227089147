import { useMemo, useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import { Customer_expanded } from 'src/pages/customers/types'

const initialValues: Customer_expanded = {
  id: '',
  company_name: '',
  code: '',
  first_name: '',
  last_name: '',
  email: '',
  document: [],
  credit_limit: 0,
  remark: '',
  customer_ports: [],
  address_books: [],
}

interface Props {
  customer_fk_code?: string
}

export default function useCustomerOne(props: Props) {
  const [result, setResult] = useState<Customer_expanded>(initialValues)

  const { data, loading, error } = useQueryWithStore({
    type: 'getList',
    resource: 'customer',
    payload: {
      pagination: { page: 1, perPage: 1 },
      filter: { code: props.customer_fk_code },
    },
  })

  let customer = useMemo(() => {
    if (data) {
      setResult(data[0])
    }

    return result
  }, [data, result])

  return {
    data: customer,
    loading,
    error,
  }
}
