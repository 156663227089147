import React, { FC, useMemo } from 'react'
import { AutocompleteInput, AutocompleteInputProps, useQueryWithStore } from 'react-admin'
import { Country_fields } from 'src/types/globalTypes'

interface Data {
  id: string
  name: string
}

const BankChoiceReferenceInput: FC<AutocompleteInputProps> = (props) => {
  const { data, loading } = useQueryWithStore({
    type: 'getList',
    resource: 'bank_choice',
    payload: {
      sort: { field: 'code', order: 'asc' },
    },
  })

  let choices = useMemo(() => formatItems(data), [data])

  return <AutocompleteInput loading={loading} choices={choices} optionText="name" {...props} />
}

export default BankChoiceReferenceInput

function formatItems(data: Country_fields[]) {
  let items: Data[] = []
  if (data) {
    items = data.map((item) => ({
      id: item.code,
      name: item.label,
    }))
  }
  return items
}
