import { InvoiceGroupItem_fields, InvoiceProductSample_fields } from 'src/types/globalTypes'
import { InvoiceLineItem_expanded, InvoicePDFSettingsProps, Invoice_expanded } from '../../types'
import getInvoiceSumValues from '../getInvoiceSumValues'
import { invoice_authorization } from './footer/authorization'
import { invoice_production } from './footer/production'
import { invoice_pdf_style } from './styles/general'
import { invoice_groupItems3 } from './template3/groupItems'
import { invoice_header3 } from './template3/header'
import { invoice_lineItems3 } from './template3/lineItems'
import { invoice_lineItemsGroupItems3 } from './template3/LineItemsGroupItems'
import { invoice_totalAmount3 } from './template3/totalAmount'
import { getLetterHeader } from './utils/getLetterHeader'

const INVOICE_HEADER_COL_SPAN = 7
const LEFT_HEADER_COL_SPAN = 2
const RIGHT_HEADER_COL_SPAN = 5
const HEADER_ROWS = 6

interface Props {
  data: Invoice_expanded
  customerPort?: string
  vendorPort?: string
  lineItems: InvoiceLineItem_expanded[]
  groupItems: InvoiceGroupItem_fields[]
  productSamples: InvoiceProductSample_fields[]
  settings: InvoicePDFSettingsProps
}

export function invoice_template3(props: Props) {
  const { data, vendorPort, customerPort, lineItems, groupItems, productSamples, settings } = props
  const { bank_choice } = props.data
  // let showProductSample = settings?.showProductSample === 'show' ? true : false

  let displayLineItems: any[] = []
  let bank_details = ''
  let displayHeader = getLetterHeader(settings.letterHead)

  if (bank_choice) {
    for (let [key, value] of Object.entries(bank_choice.description)) {
      bank_details += `${key}: ${value}\n`
    }
  }

  let { total_ctn, total_net_weight_kg, total_gross_weight_kg, total_amount } = getInvoiceSumValues({
    unit_code: data.unit_code?.code || '',
    lineItems,
    groupItems,
    itemView: settings.itemView,
    showSample: settings.showProductSample,
    total_sample_ctn: productSamples.length,
  })

  let header = invoice_header3({
    data,
    customerPort,
    invoiceColSpan: INVOICE_HEADER_COL_SPAN,
    leftColSpan: LEFT_HEADER_COL_SPAN,
    rightColSpan: RIGHT_HEADER_COL_SPAN,
    settings,
  })

  let lineItemsTable = invoice_lineItems3({
    mark_nos: props.data.information.mark_nos,
    data: lineItems,
  })

  let lineItemsGroupItems = invoice_lineItemsGroupItems3({
    mark_nos: data.information.mark_nos,
    data: lineItems,
    groupData: groupItems,
    unit_code: data.unit_code?.code || 'kg',
    showSample: settings.showProductSample,
  })

  let groupItemsTable = invoice_groupItems3({
    data: groupItems,
    mark_nos: props.data.information.mark_nos,
  })

  let totalAmount = invoice_totalAmount3({
    total_package_cartons: total_ctn,
    total_net_weight: total_net_weight_kg,
    total_gross_weight: total_gross_weight_kg,
    total_amount,
    freight_charge: data.freight_charge,
  })

  // let friehgtCharge = invoice_freightCharge({
  //   type_no,
  //   total_amount: showProductSample ? ps_values.amount + total_amount_value : total_amount_value,
  //   terms_of_sale,
  //   freight_charge,
  //   port,
  // })

  // let uploadImage =
  //   props.data.invoice_attachment !== null
  //     ? [
  //         {
  //           margin: [0, 10, 0, 10],
  //           image: 'attachFile',
  //           width: 150,
  //           height: 150,
  //         },
  //       ]
  //     : []

  // let showAmountOnlyType = type_no !== 3 ? showAmount : []
  // let showFreightCharge = type_no !== 2 ? friehgtCharge : []

  let production = invoice_production({
    settings,
    start_date: data.invoice_header?.order.start_date,
    end_date: data.invoice_header?.order.end_date,
    total_net_weight: total_net_weight_kg,
    total_gross_weight: total_gross_weight_kg,
    total_package_cartons: total_ctn,
    production_of: data.information.production_country,
  })

  let authorization =
    settings.showExport === true ? invoice_authorization({ ...props.data, bank_details, settings }) : []

  switch (settings.itemView) {
    case 1:
      displayLineItems = lineItemsTable
      break
    case 2:
      displayLineItems = lineItemsGroupItems
      break
    case 3:
      displayLineItems = groupItemsTable
      break
    default:
      displayLineItems = lineItemsTable
      break
  }

  const documentDefinition = {
    pageSize: 'A4',
    pageMargins: [32, 100, 10, 30],
    ...displayHeader,
    footer: (currentPage: number, pageCount: number) => {
      return [
        {
          fontSize: 9,
          margin: [0, 0, 10, 0],
          text: `${currentPage}/${pageCount}`,
          alignment: 'right',
        },
      ]
    },
    info: {
      title: `${data.invoice_header?.ref_invoice}`,
    },

    content: [
      {
        margin: [0, 0, 0, 0],
        style: 'tableFontSizeDefault',
        table: {
          widths: [55, 140, 40, 60, 60, 55, 55],
          headerRows: HEADER_ROWS,
          body: [...header, ...displayLineItems, ...totalAmount],
        },
      },
      ...production,
      // ...uploadImage,
      ...authorization,
    ],
    // images: {
    //   attachFile: props.data.invoice_attachment?.path || '',
    // },
    styles: invoice_pdf_style,
  }
  return documentDefinition
}
