import React, { FC, useContext } from 'react'
import AppActionContext from './AppActionContext'
import AppLocationContext from './AppLocationContext'

const WrapperProvider: FC = ({ children }) => {
  const appLocation = React.useRef<HTMLDivElement | null>(null)
  const appAction = React.useRef<HTMLDivElement | null>(null)

  return (
    <AppLocationContext.Provider value={appLocation}>
      <AppActionContext.Provider value={appAction}>{children}</AppActionContext.Provider>
    </AppLocationContext.Provider>
  )
}

function useAppActionContext() {
  let context = useContext(AppActionContext)
  if (context === undefined) {
    throw new Error(`useAppActionContext must be used within a AppActionContext`)
  }
  return context
}
function useAppLocationContext() {
  let context = useContext(AppLocationContext)
  if (context === undefined) {
    throw new Error(`useAppLocationContext must be used within a AppLocationContext`)
  }
  return context
}

export { WrapperProvider, useAppActionContext, useAppLocationContext }
