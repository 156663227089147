import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

type Order = 'asc' | 'desc'

interface Transaction {
  payment_id: string
  date: string | Date
  code: string | null
  type: string | null
  payment_date: string | Date
  exchange: number
  bank: string | null
  bank_charge: number
  deposit: number
  withdraw: number
  adjust: number
  balance: number
  remark: string | null
}

interface EnhancedTableProps {
  headCells: any
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Transaction) => void
  order: Order
  orderBy: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
)

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const classes = useStyles()

  const { headCells, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof Transaction) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            style={{ backgroundColor: '#fff' }}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
