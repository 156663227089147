import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core'
import { useListContext } from 'react-admin'
import { Permission_fields } from 'src/types/globalTypes'
import Permission from './Permission'
import { useForm, useFormState } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
  header: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 12,
    cursor: 'pointer',
    color: '#504e4e',
  },
}))

function createData(name: string, permissions: Array<any>) {
  return {
    name,
    permissions,
  }
}

export default function PermissionIterator() {
  const classes = useStyles()
  const [items, setItems] = useState<Permission_fields[]>([])
  const { data, ids, loaded } = useListContext<Permission_fields>()
  const { change } = useForm()
  const { values } = useFormState()

  useEffect(() => {
    if (!loaded) return undefined

    if (ids.length) {
      let newValues = _.sortBy(
        ids.map((id) => data[id]),
        ['name']
      )
      setItems(newValues)
    }
  }, [data, ids, loaded])

  const rows = [
    createData('General pages', items?.length ? items.filter((item: any) => item.tab === 1) : []),
    createData('Configuration pages', items?.length ? items.filter((item: any) => item.tab === 2) : []),
    createData('Admin pages', items?.length ? items.filter((item: any) => item.tab === 3) : []),
    createData('Order item tabs', items?.length ? items.filter((item: any) => item.tab === 4) : []),
  ]

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell width="260">Page</TableCell>
          <TableCell className={classes.header}>
            <div>Create</div>
          </TableCell>
          <TableCell className={classes.header}>
            <div>Read</div>
          </TableCell>
          <TableCell className={classes.header}>
            <div>Update</div>
          </TableCell>
          <TableCell className={classes.header}>
            <div>Delete</div>
          </TableCell>
          {/* <TableCell className={classes.header}>
            <div>Action</div>
          </TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <Row key={index} row={row} />
        ))}
      </TableBody>
    </Table>
  )
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow style={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
        <TableCell colSpan={6}>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row.name}
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  {row.permissions.map((permission, index) => (
                    <Permission key={index} permission={permission} />
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
