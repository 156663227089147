import { useMemo, useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import { AppState, PermissionGroup_fields } from 'src/types/globalTypes'

export default function usePermissionGroups() {
  const [result, setResult] = useState<PermissionGroup_fields[]>([])

  const { data, total, loading, error } = useQueryWithStore<AppState>({
    type: 'getList',
    resource: 'permission_group',
    payload: {
      sort: { field: 'name', order: 'ASC' },
    },
  })

  let items = useMemo(() => {
    if (data) {
      setResult(data)
    }

    return result
  }, [data, result])

  return {
    data: items,
    total: total || 0,
    loading,
    error,
  }
}
