/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
import CustomerCreate from './CustomerCreate'
import CustomerEdit from './CustomerEdit'
import CustomerList from './CustomerList'

export default {
  list: CustomerList,
  create: CustomerCreate,
  edit: CustomerEdit,
}
