import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  BooleanInput,
  DeleteButton,
  FormWithRedirect,
  ReferenceManyField,
  required,
  SaveButton,
  TextInput,
  Toolbar,
} from 'react-admin'
import SectionTitle from 'src/components/SectionTitle'
import VendorReferenceArrayInput from '../vendors/VendorReferenceArrayInput'
import Separate from 'src/components/Separate'
import PermissionIterator from '../permissions/PermissionIterator'
import PermissionDataTable from '../permissions/PermissionDataTable'

export default function PermissionGroupForm(props: any) {
  return (
    <FormWithRedirect
      {...props}
      initialValues={props.create ? props.initialValues : props.record}
      redirect={props.redirect}
      render={(formProps: any) => (
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <SectionTitle label="resources.global.fieldGroups.information" />
                  <TextInput source="name" validate={required()} fullWidth />
                  <Separate value={3} />
                  <SectionTitle label="resources.permission_group.fieldGroups.permissions" />
                  {props.create && <PermissionDataTable />}
                  {props.edit && (
                    <ReferenceManyField
                      addLabel={false}
                      target="permission_group_id"
                      reference="permission"
                      perPage={50}
                      // sort={{ field: 'per', order: 'ASC' }}
                    >
                      <PermissionIterator />
                    </ReferenceManyField>
                  )}
                </CardContent>
                <Toolbar>
                  <Box display="flex" width="100%" justifyContent="space-between">
                    <SaveButton
                      disabled={formProps.pristine}
                      saving={formProps.saving}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    />
                    <DeleteButton
                      confirmTitle={`Delete permission #${formProps.record?.name}`}
                      record={formProps.record}
                      mutationMode="pessimistic"
                    />
                  </Box>
                </Toolbar>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <SectionTitle label="resources.permission_group.fieldGroups.configuration" />
                  <BooleanInput
                    source="enable_configuration"
                    helperText="Allow view configuration tab"
                    label="Enable configuration"
                  />
                  <Separate />
                  <BooleanInput
                    source="enable_report_items"
                    helperText="Allow view report items tab"
                    label="Enable report items"
                  />
                </CardContent>
              </Card>
              <Separate value={3} />
              <Card>
                <CardContent>
                  <SectionTitle label="resources.permission_group.fieldGroups.vendors" />
                  <Typography>Vendors are assigned to this:</Typography>
                  <VendorReferenceArrayInput source="vendors" validate={required()} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    />
  )
}
