import React, { FC } from 'react'
import ArrowDown from '@material-ui/icons/ArrowDownward'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { FieldProps, useGetOne, useShowController } from 'react-admin'
import InvoiceQuickCreateButton from '../invoices/InvoiceQuickCreateButton'
import { InvoiceHeader_expanded } from './types'
import { toDecimalStr } from 'src/utils/toDecimal'
import { VendorPort_expanded } from '../vendors/types'

interface Props extends FieldProps<InvoiceHeader_expanded> {
  onOpen: (id: string) => void
}

const InvoiceHeaderPanel: FC<Props> = ({ onOpen, ...rest }) => {
  const { record, loading } = useShowController<InvoiceHeader_expanded>({ ...rest })
  const classes = useStyles()

  const { data: vendorPort } = useGetOne<VendorPort_expanded>('vendor_port', record?.port || '')

  if (!record) return null

  return (
    <>
      <ArrowDown className={classes.arrowIcon} />
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography className={classes.headline} variant="h6">
                CKERP-NEXT
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column" alignItems="flex-end" position="relative">
                <Typography className={classes.headline} variant="h6" align="right">
                  {record.ref_invoice}
                </Typography>
                <span className={classes.quickEditButton} onClick={() => onOpen(record.id)}>
                  Quick edit
                </span>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body2" className={classes.mr1}>
                  Quick create:
                </Typography>
                <InvoiceQuickCreateButton
                  type={1}
                  comparator={record.use_header1}
                  loading={loading}
                  disabled={record.order ? false : true}
                />
                <InvoiceQuickCreateButton
                  type={2}
                  comparator={record.use_header2}
                  loading={loading}
                  disabled={record.order ? false : true}
                />
                <InvoiceQuickCreateButton
                  type={3}
                  comparator={record.use_header3}
                  loading={loading}
                  disabled={record.order ? false : true}
                />
              </Box>
              <Box>
                <Typography variant="caption">Ref PI: {record.order?.ref_pi}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.textBold}>Information</Typography>
                <Typography className={classes.textLight} variant="caption">
                  Container No: {record.information.cont_no}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Seal No: {record.information.seal_no}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  BL number: {record.information.bl_number}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Vessel: {record.information.vessel}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Feeder: {record.information.feeder}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Mark & Nos: {record.information.mark_nos}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Destinations:{' '}
                  {record.information.destination.length ? record.information.destination.map((text) => text) : ''}
                </Typography>

                <Typography className={classes.textBold}>Total</Typography>
                <Typography className={classes.textLight} variant="caption">
                  {/* Subtotal: {lineItems.length} items */}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  {toDecimalStr(record.order?.total_net_weight || 0)} KG
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" flexDirection="column">
                <Typography className={classes.textBold}>Terms</Typography>
                <Typography className={classes.textLight} variant="caption">
                  Terms of pamyent: {record.information.term_of_payment}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Within after vessel arrival: {record.information.within} days
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Before vessel arrival: {record.information.is_before_vessel ? 'Yes' : 'No'}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Production of: {record.information.production_country}
                </Typography>
                <Typography className={classes.textBold}>Options</Typography>
                <Typography className={classes.textLight} variant="caption">
                  Use format: {record.use_format ? 'Yes' : 'No'}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Prefix: {record.invoice_prefix_fk_code}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Terms of sale: {record.terms_of_sale}
                </Typography>
                <Typography className={classes.textLight} variant="caption">
                  Form port: {vendorPort?.destination_port.name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default InvoiceHeaderPanel

const useStyles = makeStyles((theme: Theme) => ({
  root: { width: 768, margin: 'auto' },
  mr1: {
    marginRight: theme.spacing(1),
  },
  arrowIcon: { width: `100%`, color: '#b3b3b3', textAlign: 'center', margin: `0 auto` },
  textLight: { color: theme.palette.grey[600] },
  headline: { fontWeight: 600 },
  textBold: { fontWeight: 'bold' },
  quickEditButton: {
    color: theme.palette.primary.light,
    cursor: 'pointer',
  },
}))
