import React, { useState } from 'react'
import { useCreate, useGetOne, useNotify, useRefresh } from 'react-admin'
import { useParams } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Customer_fields, Expenses_fields } from 'src/types/globalTypes'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import ExpenseCard from './ExpenseCard'
import Separate from 'src/components/Separate'
import NewExpenseDialog from './NewExpenseDialog'
import { expenseValuesData } from './data'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'

interface Customer extends Customer_fields {
  expenses: Expenses_fields[]
}

const ExpenseEdit = () => {
  const { id }: any = useParams()
  const [open, setOpen] = useState(false)
  const { data, loaded } = useGetOne<Customer>('customer', id)
  const [create] = useCreate()
  const notify = useNotify()
  const refresh = useRefresh()

  if (!data || !loaded) return null

  const handleSubmit = async (values: any) => {
    let shipping_expense = (await values.expenses.length)
      ? values.expenses.map((item: any) => Number(item.value)).reduce((a: number, b: number) => a + b)
      : 0

    let expenses = (await values.expenses.length)
      ? values.expenses.map((item: any) => {
          return {
            label: item.label,
            value: Number(item.value),
          }
        })
      : expenseValuesData

    const object: any = {
      title: values.title,
      customer_fk_code: data.code,
      vendor_fk_code: values.vendor_fk_code,
      shipping_expense,
      values: expenses,
    }

    create('expenses', object, {
      onSuccess: () => {
        notify('resources.global.notifications.success.create', { type: 'info' })
        handleClose()
        refresh()
      },
    })
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - ${data.code}'s expenses`} />
      <RouteBreadcrumbs />
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          New expense
        </Button>
        <NewExpenseDialog open={open} onSubmit={handleSubmit} onCancel={handleClose} onClose={handleClose} />
      </Box>
      <Separate value={3} />
      <Grid container spacing={3}>
        {data?.expenses && data.expenses.length
          ? data.expenses.map((expense, index) => (
              <Grid item xs={3} key={index}>
                <ExpenseCard data={expense} />
              </Grid>
            ))
          : null}
      </Grid>
    </>
  )
}

export default ExpenseEdit
