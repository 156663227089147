import { gql } from '@apollo/client'

export const GET_LIST_VENDORS = gql`
  {
    id
    company_name
    code
    email
    tel
    fax
    phone
    country
    currency_default
    currency {
      id
      label
      code
    }
  }
`

export const GET_VENDOR_ONE = gql`
  {
    id
    company_name
    code
    email
    tel
    fax
    phone
    currency_default
    country
    address_1
    address_2
    vendor_ports {
      id
      destination_port {
        id
        name
      }
      isDefault
    }
    currency {
      id
      label
      code
    }
  }
`
