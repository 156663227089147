import React from 'react'
import moment from 'moment'
import MTTable from 'src/components/MTTable'
import { Order_expanded } from 'src/pages/orders/types'
import { Payment_fields } from 'src/types/globalTypes'
import { Invoice_expanded } from 'src/pages/invoices/types'
import { ordersByPeriod } from '../../utils/ordersByPeriod'
import { toDecimal, toDecimalStr } from 'src/utils/toDecimal'
import getOrderSumValues from 'src/pages/orders/utils/getOrderSumValues'
// import { paymentsByPeriod } from '../../utils/paymentsByPeriod'

function createData(name: string, period: { [key: string]: number }) {
  return { name, period }
}

interface Props {
  monthly: string[]
  shipeedout_orders: Order_expanded[]
  pending_orders: Order_expanded[]
  payments: Payment_fields[]
  invoices: Invoice_expanded[]
  split: boolean
}

export default function RevenueAnalysisTable(props: Props) {
  const { monthly, shipeedout_orders, pending_orders, split } = props
  const rows: any = []
  // let currentMonth = moment(new Date()).startOf('month').format('YYYY-MM-DD')
  // let currentIndex = monthly.findIndex((date) => date === currentMonth)
  let newMonthly = monthly.slice(0).reverse()
  let moreColumns = newMonthly.map((date) => ({
    title: moment(date).format('MMM YYYY'),
    field: `period.${moment(date).format('MMM').toLowerCase()}`,
    type: 'numeric' as 'numeric',
    render: (rowData: any) => {
      let dateKey = moment(date).format('MMM').toLowerCase()
      let result = toDecimalStr(rowData.period[dateKey] || 0)

      return result
    },
  }))

  let groupedOrders = groupAmountByWeek({ orders: shipeedout_orders, weekly: newMonthly, period: 'month', split })
  let groupedOrdersUn = groupAmountByWeek({ orders: pending_orders, weekly: newMonthly, period: 'month', split })

  let total_revenue = Object.keys(groupedOrders.orders.revenues).reduce((a, k) => {
    a[k] = groupedOrders.orders.revenues[k] + groupedOrdersUn.orders.revenues[k]
    return a
  }, {} as any)

  let backlog = Object.keys(groupedOrdersUn.orders.revenues).reduce((a, k) => {
    a[k] = (groupedOrdersUn.orders.revenues[k] / total_revenue[k]) * 100
    return a
  }, {} as any)

  rows.push(createData('Realised revenue (USD)', groupedOrders.orders.revenues))
  rows.push(createData('Unrealised revenue (USD)', groupedOrdersUn.orders.revenues))
  rows.push(createData('Total revenue (USD)', total_revenue))
  rows.push(createData('Backlog ratio (USD)', backlog))

  return (
    <>
      <MTTable
        columns={[
          {
            title: 'Title',
            field: 'name',
            width: `200px`,
            cellStyle: {
              width: '200px',
            },
          },
          // {
          //   title: 'Today',
          //   field: 'period.today',
          //   type: 'numeric',
          // },
          ...moreColumns,
        ]}
        data={rows}
        options={{
          paging: false,
          search: false,
          // fixedColumns: {
          //   left: 1,
          // },
        }}
      />
    </>
  )
}

// interface GroupPaymentProps {
//   payments: Payment_fields[]
//   monthly: string[]
// }

// const groupPaymentByMonth = (props: GroupPaymentProps) => {
//   const { payments, monthly } = props
//   const groupPaymentsByPeriod = paymentsByPeriod(payments, monthly, 'month')
//   const result: { [key: string]: number } = {}
//   Object.values(groupPaymentsByPeriod).forEach((item) => {
//     Object.keys(item.payments).forEach((date) => {
//       let dateKey = moment(date).format('MMM').toLowerCase()
//       let total_amount = item.payments[date].length
//         ? item.payments[date].map((item) => item.amount_deposit).reduce((a, b) => a + b)
//         : 0

//       result[dateKey] = result[dateKey] ? result[dateKey] + total_amount : total_amount
//     })
//   })

//   return result
// }

interface GroupAmount {
  orders: Order_expanded[]
  weekly: string[]
  period: moment.unitOfTime.StartOf
  split?: boolean
}

const groupAmountByWeek = (props: GroupAmount) => {
  const { orders, weekly, period, split } = props
  let groupOrdersByWeek = ordersByPeriod(orders, weekly, period, split)

  const amountByGroup: any = {
    title: '',
    orders: { revenues: {}, costs_usd: {}, gross_profits: {}, gross_profit_margins: {} },
  }

  Object.values(groupOrdersByWeek).forEach((item) => {
    Object.keys(item.orders).forEach((date) => {
      let amountOrders = item.orders[date].map((order) => {
        let { total_amount, total_cost_usd, total_gross_profit } = getOrderSumValues({
          unit_code: order.unit_code?.code || 'kg',
          lineItems: order.order_line_items,
          revise: order.order_revises[0],
          exchange_rate: order.exchange_rate,
        })
        return {
          total_amount,
          total_cost_usd,
          total_gross_profit,
          total_gross_profit_margin: toDecimal((total_gross_profit / total_cost_usd) * 100),
        }
      })

      let revenue = amountOrders.length
        ? toDecimal(amountOrders.map((item) => toDecimal(item.total_amount)).reduce((a, b) => a + b))
        : 0

      let cost_usd = amountOrders.length
        ? toDecimal(amountOrders.map((item) => toDecimal(item.total_cost_usd)).reduce((a, b) => a + b))
        : 0

      let gross_profits = amountOrders.length
        ? toDecimal(amountOrders.map((item) => toDecimal(item.total_gross_profit)).reduce((a, b) => a + b))
        : 0

      let gross_profit_margins = amountOrders.length
        ? toDecimal(amountOrders.map((item) => toDecimal(item.total_gross_profit_margin)).reduce((a, b) => a + b))
        : 0

      let dateKey = moment(date).format('MMM').toLowerCase()
      amountByGroup.orders.revenues[dateKey] = amountByGroup.orders.revenues[dateKey]
        ? amountByGroup.orders.revenues[dateKey] + revenue || 0
        : revenue || 0
      amountByGroup.orders.costs_usd[dateKey] = amountByGroup.orders.costs_usd[dateKey]
        ? amountByGroup.orders.costs_usd[dateKey] + cost_usd || 0
        : cost_usd || 0
      amountByGroup.orders.gross_profits[dateKey] = amountByGroup.orders.gross_profits[dateKey]
        ? amountByGroup.orders.gross_profits[dateKey] + gross_profits || 0
        : gross_profits || 0
      amountByGroup.orders.gross_profit_margins[dateKey] = amountByGroup.orders.gross_profit_margins[dateKey]
        ? amountByGroup.orders.gross_profit_margins[dateKey] + gross_profit_margins || 0
        : gross_profit_margins || 0
    })
  })

  return amountByGroup
}
