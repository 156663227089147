import React, { SetStateAction, useCallback, useEffect, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import _ from 'lodash'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import { useDataProvider, useGetOne, useLoading } from 'react-admin'
import moment from 'moment'
import InsightDateRange from './components/Tools/InsightDateRange'
import { InsightFilterStates, InsightSalesAndProfitState, InsightWidgetStates } from './types'
import { Order_expanded } from '../orders/types'
import { colors } from 'src/utils/colors'
import Separate from 'src/components/Separate'
import { DateRange } from 'materialui-daterange-picker'
import { formatDateToAPI } from 'src/utils/formatDate'
import InsightFilters from './components/Tools/InsightFilters'
import { formatArrayNames } from './utils/formatArrayNames'
import RevenueRealisedGraph from './components/SalesAndProfits/RevenueRealisedGraph'
import RevenueUnRealisedGraph from './components/SalesAndProfits/RevenueUnRealisedGraph'
import GrossProfit from './components/SalesAndProfits/GrossProfit'
import GrossProfitMargin from './components/SalesAndProfits/GrossProfitMargin'
import ProfitTarget from './components/SalesAndProfits/ProfitTarget'
import RevenueTarget from './components/SalesAndProfits/RevenueTarget'
import StatsTable from './components/SalesAndProfits/StatsTable'

interface OrderStats {
  title: string
  vendor: string | null
  customers: string[]
  split: boolean
  shippedout_orders: Order_expanded[]
  pending_orders: Order_expanded[]
}

interface Props {
  state: InsightFilterStates
  setState: (values: SetStateAction<InsightFilterStates>) => void
}

export default function InsightSalesAndProfit(props: Props) {
  const widgetId = 'e0952e5e-e24a-4002-94bc-d31749fd430f'
  const { state, setState } = props
  let loading = useLoading()
  const dataProvider = useDataProvider()

  const [targetValue, setTargetValue] = useState(0)
  const [data, setData] = useState<InsightSalesAndProfitState>({})

  const { data: insight } = useGetOne<InsightWidgetStates>('widgets', widgetId)

  const fetchOrders = useCallback(async () => {
    let vendors: string[] = []
    let customers: string[] = []
    let weekly: string[] = []

    Object.values(state.filterTags || []).forEach((item) => {
      vendors.push(item.vendor || '')
      item.customers.map((customer) => customers.push(customer))
    })

    let vendor = vendors.length ? { vendor_fk_code: _.uniq(vendors) } : undefined
    let customer = customers.length ? { customer_fk_code: _.uniq(customers) } : undefined
    let start_date = state.dateRange ? { 'pi_date@_gte': formatDateToAPI(state.dateRange.startDate) } : null
    let end_date = state.dateRange ? { 'pi_date@_lte': formatDateToAPI(state.dateRange.endDate) } : null

    const { data: orders } = await dataProvider.getList<Order_expanded>('order', {
      filter: { ...vendor, ...customer, ...start_date, ...end_date },
      sort: { field: 'ref_pi', order: 'DESC' },
      pagination: { page: 1, perPage: vendors.length || customers.length ? 3000 : 0 },
    })

    let i = 1
    let days = moment.duration(moment(state.dateRange?.endDate).diff(moment(state.dateRange?.startDate))).asDays()

    while (i <= days) {
      weekly.push(moment(state.dateRange?.startDate).clone().add(i, 'day').startOf('week').format('YYYY-MM-DD'))
      i += 7
    }

    const aggregations = state.filterTags?.map((item) => {
      let dataset: OrderStats = {
        title: `${item.vendor || ''}:${item.customers.join(',')}`,
        vendor: item.vendor || '',
        customers: item.customers || [],
        shippedout_orders: [],
        pending_orders: [],
        split: item.split || false,
      }

      orders.reduce((stats, order) => {
        if (item.vendor === order.vendor_fk_code && item.customers.includes(order.customer_fk_code)) {
          if (order.status === 'shipped_out') {
            dataset.shippedout_orders.push(order)
          }
          if (order.status !== 'cancelled' && order.status !== 'annulled' && order.status !== 'shipped_out') {
            dataset.pending_orders.push(order)
          }
        }

        return stats
      }, {})

      return dataset
    })

    setData((prev) => ({ ...prev, fetchOrders: aggregations, weekly }))
  }, [dataProvider, state.filterTags, state.dateRange])

  useEffect(() => {
    fetchOrders()

    return () => {
      setData({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOrders])

  useEffect(() => {
    setTargetValue(insight?.values.revenue_target || 0)
  }, [targetValue, insight])

  const handleCustomerChange = (name: string, value: any) => {
    let newValue = { ...state, [name]: value }
    setState(newValue)
  }

  const handleDateRange = (value: DateRange) => {
    setState((prev) => ({ ...prev, dateRange: value }))
  }

  const handleAddFilter = () => {
    let newValues = []

    if (state.split) {
      newValues = Object.values(state.customers || []).map((customer) => ({
        vendor: state.vendor,
        vendors: [],
        customers: [customer],
        split: state.split,
      }))
    } else {
      newValues = [{ vendor: state.vendor, vendors: [], customers: state.customers || [], split: state.split }]
    }

    let newTags = [...(state.filterTags || []), ...newValues]
    setState((prev) => ({
      ...prev,
      vendor: null,
      customers: [],
      filterTags: newTags,
    }))
  }

  const handleRemoveFilter = (index: number) => {
    if (state.filterTags) {
      let newValue = [...state.filterTags]
      newValue.splice(index, 1)
      setState({ ...state, filterTags: newValue })
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <InsightFilters
            loading={loading}
            useVendor
            useCustomers
            useSplit
            {...state}
            onChange={handleCustomerChange}
            onAddFilter={handleAddFilter}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box textAlign="right">
            <InsightDateRange dateRange={state.dateRange} setDateRange={handleDateRange} />
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          {state.filterTags?.length
            ? state.filterTags.map((item, i) => {
                const dataColor = colors[i].join(',')
                return (
                  <Tooltip title={item.customers.join(', ')} key={i}>
                    <Chip
                      variant="outlined"
                      style={{ border: `2px solid rgb(${dataColor})`, marginRight: 8 }}
                      label={`${item.vendor || ''}: ${formatArrayNames(item.customers || [])}`}
                      onDelete={() => handleRemoveFilter(i)}
                    />
                  </Tooltip>
                )
              })
            : null}
        </Grid>
      </Grid>
      <Box mt={3} />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4}>
          <RevenueRealisedGraph graphStats={data} colors={colors} />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <RevenueUnRealisedGraph graphStats={data} colors={colors} />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <GrossProfit graphStats={data} colors={colors} />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <GrossProfitMargin graphStats={data} colors={colors} />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <ProfitTarget target={targetValue} graphStats={data} colors={colors} />
        </Grid>

        <Grid item xs={12}>
          <RevenueTarget stats={data} colors={colors} />
        </Grid>

        <Grid item xs={12}>
          <Separate value={3} />
          <StatsTable stats={data} colors={colors} />
        </Grid>
      </Grid>
    </Box>
  )
}
