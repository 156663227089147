import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import Separate from 'src/components/Separate'
import CalendarEventDialog from './CalendarEventDialog'
import { CalendarEvent_fields } from 'src/types/globalTypes'
import { useCreate, useDelete, useNotify, useQueryWithStore, useRefresh } from 'react-admin'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import CalendarEventDeleteDialog from './CalendarEventDeleteDialog'

const CalendarList = () => {
  const [open, setOpen] = useState(false)
  const [eventId, setEventId] = useState('')
  const [title, setTitle] = useState('')
  const [showDelete, setDelete] = useState(false)
  const [selectDate, setSelectDate] = useState<any>(null)
  const [value, setValue] = useState('')
  const [createEvent] = useCreate()
  const [deleteEvent] = useDelete()
  const notify = useNotify()
  const refresh = useRefresh()

  const { data: calendarData } = useQueryWithStore({
    type: 'getList',
    resource: 'calendar_event',
    payload: {},
  })

  const handleDateSelect = (arg: any) => {
    setOpen(true)
    setSelectDate(arg)
    setValue('')
  }

  function handleConfirm() {
    let data = {
      title: value,
      start: selectDate.startStr,
      end: selectDate.endStr,
    }
    createEvent('calendar_event', data, {
      onSuccess: () => {
        refresh()
      },
    })
    setOpen(false)
    setValue('')
  }

  function handleEventClick(clickInfo: any) {
    setDelete(true)
    setTitle(clickInfo.event._def.title)
    let id = clickInfo.event._def.publicId
    setEventId(id)
  }

  function handleDeleteSave() {
    deleteEvent('calendar_event', eventId, undefined, {
      onSuccess: () => {
        notify('resources.global.notifications.success.delete', { type: 'info' })
        handleDeleteClose()
        refresh()
      },
    })
  }

  function handleDeleteClose() {
    setDelete(false)
    setTitle('')
    setEventId('')
  }

  return (
    <div>
      <Helmet title={`${WEB_APP_TITLE} - Calendar`} />
      <RouteBreadcrumbs />
      <Separate value={3} />
      <Paper>
        <Box p={3} width={{ xs: `100%`, md: `800px` }}>
          <FullCalendar
            timeZone="Asia/Bangkok"
            plugins={[dayGridPlugin, interactionPlugin, momentPlugin]}
            initialView="dayGridMonth"
            selectable={true}
            editable={true}
            select={handleDateSelect}
            eventSources={[
              {
                events: calendarData?.length
                  ? calendarData.map((item: CalendarEvent_fields) => {
                      return {
                        id: item.id,
                        title: item.title,
                        start: item.start,
                        end: item.end,
                      }
                    })
                  : [],
              },
            ]}
            eventClick={handleEventClick}
          />
        </Box>
      </Paper>

      {showDelete && (
        <CalendarEventDeleteDialog
          title={`Delete event #${title}`}
          open={showDelete}
          onSave={handleDeleteSave}
          onClose={handleDeleteClose}
          onCancel={handleDeleteClose}
        />
      )}

      {open && (
        <CalendarEventDialog
          open={open}
          value={value}
          onChange={setValue}
          onClose={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          onSave={handleConfirm}
        />
      )}
    </div>
  )
}

export default CalendarList
