export const WEB_APP_TITLE = 'CKorder'

const HASURA_GRAPHQL_ENGINE_HOSTNAME = 'ckerp-next.herokuapp.com'
// const HASURA_GRAPHQL_ENGINE_HOSTNAME = 'ckorder-next-beta.herokuapp.com'

const scheme = (proto: string) => {
  return window.location.protocol === 'http:' ? `${proto}s` : proto
}

export const GRAPHQL_URL = `${scheme('http')}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`

export const REALTIME_GRAPHQL_URL = `${scheme('ws')}s://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`

// export const authClientId = 'fLaPX044OEealxQpRAUL8Dq8UIbnoX7J'
// export const authDomain = 'nmeesang.au.auth0.com'
// export const callbackUrl = `${scheme('http')}://ckffforder.com`

export const audience = `https://nmeesang.au8.webtask.io/auth0-authentication-api-debugger`

export const AWS_S3_URL = `https://n7nx9jmpv1.execute-api.ap-southeast-1.amazonaws.com/default/ckUpload`
