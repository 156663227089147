import React, { FC, useMemo } from 'react'
import { AutocompleteInput, AutocompleteInputProps, useQueryWithStore } from 'react-admin'
import { Customer_fields } from 'src/types/globalTypes'

interface Choice {
  id: string
  name: string
}

const CustomerReferenceInput: FC<AutocompleteInputProps> = (props) => {
  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'customer',
    payload: {
      sort: { field: 'code', order: 'ASC' },
    },
  })

  let options = useMemo(() => formatItems(data), [data])

  return <AutocompleteInput choices={options} optionText="name" {...props} />
}
export default CustomerReferenceInput

function formatItems(data: Customer_fields[]) {
  let result: Choice[] = []
  if (data) {
    result = data.map((item: Customer_fields) => ({
      id: item.code,
      name: item.code,
    }))
  }

  return result
}
