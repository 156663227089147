import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Link, Record, useGetList } from 'react-admin'
import { OrderLineItems_expanded } from '../orders/types'

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 16,
    width: 600,
  },
}))

interface Props {
  record?: Record
  basePath?: string
}

export default function PackAside(props: Props) {
  const classes = useStyles()
  const { record } = props

  const { data, ids } = useGetList<OrderLineItems_expanded>(
    'order_line_item',
    { page: 1, perPage: 100 },
    { field: 'order.pi_date', order: 'DESC' },
    { pack_fk: record && record.id }
  )

  return (
    <Card className={classes.root}>
      <CardHeader title="Order history" />
      <CardContent>
        <Table size="small">
          <TableBody>
            {ids.length
              ? ids.map((id) => (
                  <TableRow>
                    <TableCell>
                      <Link to={`/order/${data[id].order?.id}`}>{data[id].order?.ref_pi}</Link>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
