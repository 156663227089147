const NORMAL_FONT = 9

export const invoice_pdf_style = {
  headerText: {
    fontSize: 22,
    bold: true,
    italics: true,
    alignment: 'center',
  },
  production: {
    fontSize: NORMAL_FONT,
  },
  production_date: {
    margin: [0, 20, 0, 0],
    fontSize: NORMAL_FONT,
  },
  bank: {
    fontSize: NORMAL_FONT,
    margin: [0, 10, 30, 0],
  },
  authorization: {
    fontSize: NORMAL_FONT,
    lineHeight: 1.5,
    margin: [40, 10, 0, 0],
    alignment: 'center',
  },
  authorizationText: {
    fontSize: NORMAL_FONT,
    alignment: 'center',
  },
  tableFontSizeDefault: {
    fontSize: NORMAL_FONT,
  },
  tableHeader: {
    fontSize: NORMAL_FONT,
    margin: [0, 0, 0, 3],
  },
  tableHeader2: {
    alignment: 'center',
    fontSize: NORMAL_FONT,
    bold: true,
    verticalAlign: 'center',
  },
  tColumn: {
    fontSize: NORMAL_FONT,
    alignment: 'center',
    bold: true,
  },
  tlCell: {
    fontSize: NORMAL_FONT,
    alignment: 'left',
  },
  tcCell: {
    fontSize: NORMAL_FONT,
    alignment: 'center',
  },
  trCell: {
    fontSize: NORMAL_FONT,
    alignment: 'right',
  },
  rowSpan: {
    margin: [0, 2, 0, 0],
  },
  rowSpan2: {
    margin: [0, 8, 0, 0],
  },
  rowSpan3: {
    margin: [0, 16, 0, 0],
  },
  rowSpan4: {
    margin: [0, 28, 0, 0],
  },
  textCenter: {
    alignment: 'center',
  },
  textRight: {
    alignment: 'right',
  },
  textBold: {
    bold: true,
  },
  sample: {
    fontSize: 7,
  },
  mt1: {
    margin: [0, 10, 0, 0],
  },
  mt3: {
    margin: [0, 20, 0, 0],
  },
  size: {
    fontSize: 8,
  },
}
