import React, { FC } from 'react'
import { Create, CreateProps } from 'react-admin'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import VendorDetailForm from './VendorDetailForm'

const VendorCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - New vendor`} />
      <RouteBreadcrumbs {...props} />
      <Create {...props} component="div">
        <VendorDetailForm create />
      </Create>
    </>
  )
}

export default VendorCreate
