import React, { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import { Helmet } from 'react-helmet'
import RouteBreadcrumbs from 'src/components/RouteBreadcrumbs'
import { WEB_APP_TITLE } from 'src/constants'
import { sanitize } from 'src/utils/sanitize'
import SizeDetailForm from './SizeDetailForm'

const Title = ({ record }: any) => {
  return record.label
}

const SizeEdit: FC<EditProps> = (props) => {
  const transform = (data: any) => ({
    ...data,
    code: sanitize(data.label),
  })

  // console.log(props)

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - ${props.id}`} />
      <RouteBreadcrumbs {...props} />
      <Edit title={<Title />} component="div" transform={transform} {...props}>
        <SizeDetailForm redirect={false} />
      </Edit>
    </>
  )
}

export default SizeEdit
