import { useAuth0 } from '@auth0/auth0-react'

const useAuth0Provider = () => {
  const { user, isAuthenticated, logout, getAccessTokenSilently, loginWithRedirect } = useAuth0()

  const result = {
    // called when the user attempts to log in
    login: (url: any) => {
      return loginWithRedirect()
    },
    // called when the user clicks on the logout button
    logout: () => {
      if (isAuthenticated) {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
            federated: true,
          }
        })
        localStorage.removeItem('token')
      }
      return Promise.resolve()
    },
    // called when the API returns an error
    checkError: ({ status }: any) => {
      if (status === 401 || status === 403) {
        return Promise.reject()
      }
      return Promise.resolve()
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: async () => {
      return getAccessTokenSilently().then((token: any) => {
        if (isAuthenticated) {
          return Promise.resolve()
        }
        return token
      })
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: (props: any) => {
      return Promise.resolve('')
    },
    getIdentity: () =>
      Promise.resolve({
        id: `${user?.sub}`,
        fullName: `${user?.nickname}`,
        avatar: `${user?.picture}`,
      }),
  }
  return result
}

export default useAuth0Provider
