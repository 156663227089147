import { gql } from '@apollo/client'

export const GET_LIST_ORDERS = gql`
  {
    id
    ref_pi
    pi_date
    date_shipment
    status
    total_amount
    current_revise_num
    total_net_weight
    status
    customer_fk_code
    vendor_fk_code
    exchange_rate
    shipping_details
    isDone
    vendor {
      id
      code
      country
      currency_default
    }
    customer {
      id
      code
    }

    user_id
    user {
      id
      name
    }

    order_line_items {
      id
      order_id
      product {
        id
        name
      }
      size {
        id
        label
      }
      size_value {
        id
        label
      }
      pack {
        id
        name
      }
      amount
      cost
      glazing
      import_cost
      pack_cost_baht
      profit
      raw_cost
      total_cost_usd
      total_cost_usd_kg
      unit_price
      weight_glazing
      margins
      custom_kg
      custom_import_costs
      net_weight_kgs
      weight_mc
      gross_weight
      revise_key
      box
    }

    order_revises(order_by: { revise_key: desc }, limit: 1) {
      id
      exchange_rate_info
      expense_rate_info
      revise_key
    }

    unit_code {
      id
      name
      code
    }
  }
`

export const GET_ORDER = gql`
  {
    id
    ref_excel
    ref_pi
    currency_fk
    current_revise_num
    customer_fk_code
    date_exp
    date_shipment
    start_date
    end_date
    description
    isCancelled
    isDone
    isDraft
    pi_date
    shelf_life
    shipping_addresses
    shipping_details
    status
    total_amount
    total_net_weight
    unit_code_fk_id
    shipment_type_fk_id
    user_id
    vendor_fk_code
    exchange_rate
    remark
    sticker
    vendor {
      id
      code
      company_name
      currency_default
    }
    customer {
      id
      code
    }

    currency {
      id
      code
    }

    orderNotes {
      id
      order_id
      text
      user_id
      createdAt
      updatedAt
    }

    order_revises(order_by: { revise_key: desc }) {
      id
      order_id
      name
      exchange_rate_info
      expense_rate_info
      date
      expense_id
      revise_key
      locale_currency_fk

      currency {
        id
        code
      }
    }

    order_product_samples(order_by: { product: asc }) {
      id
      order_id
      product
      net_weight_kg
      gross_weight_kg
      box
    }

    order_line_items {
      id
      order_id
      product_fk
      size_fk
      size_value_fk
      pack_fk
      product {
        id
        name
      }
      size {
        id
        label
      }
      size_value {
        id
        label
      }
      pack {
        id
        name
      }
      amount
      cost
      glazing
      import_cost
      pack_cost_baht
      profit
      raw_cost
      total_cost_usd
      total_cost_usd_kg
      unit_price
      weight_glazing
      margins
      custom_kg
      custom_import_costs
      net_weight_kgs
      weight_mc
      gross_weight
      revise_key
      box
      remark
      custom_import_costs
      custom_kg
    }
    unit_code {
      id
      name
      code
    }
  }
`
