import React, { FC } from 'react'
import {
  Datagrid,
  List,
  ListProps,
  TextField,
  FilterProps,
  Filter,
  SearchInput,
  useRedirect,
  useNotify,
} from 'react-admin'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import usePermissionByUser from 'src/hooks/usePermissionByUser'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import CategoryReferenceField from '../categories/CategoryReferenceField'
import ProductTypeReferenceField from '../productTypes/ProductTypeReferenceField'

const ProductFilter: FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props}>
      <SearchInput source="name,full_name,short_name" alwaysOn />
    </Filter>
  )
}

const ProductList: FC<ListProps> = (props) => {
  const { permission, loaded } = usePermissionByUser('product')
  const redirect = useRedirect()
  const notify = useNotify()

  if (loaded && !permission.read) {
    redirect('/')
    notify('pos.access_denied', { type: 'info' })
  }

  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Products`} />
      <RouteBreadcrumbs {...props} />
      <List
        {...props}
        perPage={25}
        sort={{ field: 'name', order: 'ASC' }}
        filters={<ProductFilter />}
        bulkActionButtons={false}
        hasCreate={permission.create}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <TextField source="full_name" label="English name" />
          <ProductTypeReferenceField label="Product Type" />
          <CategoryReferenceField label="Category" />
        </Datagrid>
      </List>
    </>
  )
}

export default ProductList
