import React from 'react'
import Grid from '@material-ui/core/Grid'
import { TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import SectionTitle from 'src/components/SectionTitle'
import CustomerPortReferenceInput from 'src/pages/customerPorts/CustomerPortReferenceInput'

export default function InvoiceInformation() {
  const { values } = useFormState()

  return (
    <>
      <SectionTitle label="resources.global.fieldGroups.information" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="information.cont_no" label="Container No." helperText={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="information.seal_no" label="Seal No." helperText={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="information.bl_number" label="BL number." helperText={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="information.vessel" label="Vessel" helperText={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="information.feeder" label="Feeder." helperText={false} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput fullWidth source="information.mark_nos" label="Mark & Nos" helperText={false} />
        </Grid>
        <Grid item xs={12}>
          <CustomerPortReferenceInput
            fullWidth
            allowEmpty
            label="Destination Port"
            source="information.destination_port"
            customer_fk_code={values.invoice_header?.order?.customer_fk_code}
            helperText={false}
          />
        </Grid>
      </Grid>
    </>
  )
}
