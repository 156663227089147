import { useMemo } from 'react'
import _ from 'lodash'
import { useGetMany } from 'react-admin'
import { Vendor_fields } from 'src/types/globalTypes'
import useUser from './useUser'

export default function useVendorsByUser(target?: string) {
  const { data: user, loading } = useUser()
  const { data: permissionGroups, error } = useGetMany('permission_group', user.permission_group_ids)

  let result = useMemo(() => {
    let vendors_arr: string[] = []
    Object.values(permissionGroups).forEach((group) => {
      if (group) {
        if (target === 'id') {
          group.vendors.map((item: Vendor_fields) => vendors_arr.push(item.id))
        } else {
          group.vendors.map((item: Vendor_fields) => vendors_arr.push(item.code))
        }
      }
    })
    return _.uniq(vendors_arr)
  }, [target, permissionGroups])

  return {
    data: result,
    isSuperuser: user.isSuperuser,
    loading,
    error,
  }
}
