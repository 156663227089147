import React from 'react'
import { useInput } from 'react-admin'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import useCurrencies from 'src/hooks/useCurrencies'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 10,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  })
)

export default function CurrencySelect(props: any) {
  const classes = useStyles()
  const { data } = useCurrencies()

  const {
    input: { name, value, onChange, ...rest },
  } = useInput(props)

  return (
    <div style={{ width: `100%` }}>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={classes.input}
        disabled={props.disabled}
        {...rest}
        required={true}
      >
        <option value="">Select currency</option>
        {data.length ? (
          data.map((item, index) => (
            <option key={index} value={item.code}>
              {item.code}
            </option>
          ))
        ) : (
          <option>No data</option>
        )}
      </select>
    </div>
  )
}
