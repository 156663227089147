import { buildFields, FetchType, ResourceType } from 'ra-data-hasura'
import { GET_LIST, GET_ONE, GET_MANY_REFERENCE, GET_MANY, UPDATE, DELETE, CREATE } from 'react-admin'
import { GET_CUSTOMER, GET_LIST_CUSTOMERS } from './queries/customers'
import { GET_LIST_ORDERS, GET_ORDER } from './queries/orders'
import { GET_LIST_PAYMENTS } from './queries/payments'
import { GET_LIST_SIZES } from './queries/sizes'
import { GET_LIST_SIZE_VALUES } from './queries/sizeValues'
import { GET_LIST_DESTINATION_PORTS } from './queries/destinationPorts'
import { DELETE_INVOICE, GET_INVOICE_ONE, GET_LIST_INVOICES } from './queries/invoices'
import { GET_LIST_CUSTOMER_PORTS } from './queries/customer_ports'
import { GET_LIST_VENDORS, GET_VENDOR_ONE } from './queries/vendors'
import { GET_LIST_VENDOR_PORTS } from './queries/vendor_ports'
import { GET_LIST_PRODUCT_VARIANTS, GET_ONE_PRODUCT_VARIANT } from './queries/product_variant'
import { GET_ORDER_LINE_ITEM } from './queries/order_line_items'
import { GET_LIST_PROFILE_ITEM_VALUES } from './queries/profile_item_value'
import { GET_INVOICE_HEADER, GET_LIST_INVOICE_HEADERS } from './queries/invoice_header'
import { GET_LIST_PRODUCTS } from './queries/products'
import { GET_LIST_PROFILE_ITEMS, GET_PROFILE_ITEM_ONE } from './queries/profile_items'
import { GET_PERMISSION_GROUP_ONE } from './queries/permission_group'
import { GET_LIST_COMMENTS } from './queries/timeline_comments'
import { GET_INVENTORY_LINE_ITEMS } from './queries/inventory_line_items'
import { GET_INVENTORY } from './queries/inventory'

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst: any) => queryAst.definitions[0].selectionSet.selections

// An object of all the custom queries we have defined.
const CUSTOM_QUERIES: any = {
  customer: {
    [GET_LIST]: GET_LIST_CUSTOMERS,
    [GET_ONE]: GET_CUSTOMER,
  },
  customer_port: {
    [GET_LIST]: GET_LIST_CUSTOMER_PORTS,
    [GET_ONE]: GET_LIST_CUSTOMER_PORTS,
    [GET_MANY]: GET_LIST_CUSTOMER_PORTS,
  },
  destination_port: {
    [GET_LIST]: GET_LIST_DESTINATION_PORTS,
    [GET_ONE]: GET_LIST_DESTINATION_PORTS,
  },
  inventory_line_item: {
    [GET_LIST]: GET_INVENTORY_LINE_ITEMS,
    [GET_MANY]: GET_INVENTORY_LINE_ITEMS,
    [GET_MANY_REFERENCE]: GET_INVENTORY_LINE_ITEMS,
  },
  invoice: {
    [GET_LIST]: GET_LIST_INVOICES,
    [GET_ONE]: GET_INVOICE_ONE,
    [GET_MANY]: GET_LIST_INVOICES,
    [GET_MANY_REFERENCE]: GET_LIST_INVOICES,
    [CREATE]: GET_INVOICE_ONE,
    [UPDATE]: GET_INVOICE_ONE,
    [DELETE]: DELETE_INVOICE,
  },
  order: {
    [GET_LIST]: GET_LIST_ORDERS,
    [GET_ONE]: GET_ORDER,
    [GET_MANY]: GET_LIST_ORDERS,
  },
  order_line_item: {
    [GET_LIST]: GET_ORDER_LINE_ITEM,
    [GET_ONE]: GET_ORDER_LINE_ITEM,
  },

  inventory: {
    [GET_ONE]: GET_INVENTORY,
  },
  invoice_header: {
    [GET_LIST]: GET_LIST_INVOICE_HEADERS,
    [GET_ONE]: GET_INVOICE_HEADER,
    [GET_MANY]: GET_LIST_INVOICE_HEADERS,
  },

  payment: {
    [GET_LIST]: GET_LIST_PAYMENTS,
  },
  permission_group: {
    [GET_LIST]: GET_PERMISSION_GROUP_ONE,
    [GET_ONE]: GET_PERMISSION_GROUP_ONE,
    [GET_MANY]: GET_PERMISSION_GROUP_ONE,
  },
  product: {
    [GET_LIST]: GET_LIST_PRODUCTS,
  },
  product_variant: {
    [GET_LIST]: GET_LIST_PRODUCT_VARIANTS,
    [GET_ONE]: GET_ONE_PRODUCT_VARIANT,
    [GET_MANY]: GET_ONE_PRODUCT_VARIANT,
  },
  profile_item: {
    [GET_LIST]: GET_LIST_PROFILE_ITEMS,
    [GET_ONE]: GET_PROFILE_ITEM_ONE,
  },
  profile_item_value: {
    [GET_LIST]: GET_LIST_PROFILE_ITEM_VALUES,
    [GET_ONE]: GET_LIST_PROFILE_ITEM_VALUES,
    [GET_MANY]: GET_LIST_PROFILE_ITEM_VALUES,
    [GET_MANY_REFERENCE]: GET_LIST_PROFILE_ITEM_VALUES,
  },
  size: {
    [GET_LIST]: GET_LIST_SIZES,
  },
  size_value: {
    [GET_LIST]: GET_LIST_SIZE_VALUES,
  },
  timeline_comment: {
    [GET_LIST]: GET_LIST_COMMENTS,
    [GET_ONE]: GET_LIST_COMMENTS,
    [GET_MANY]: GET_LIST_COMMENTS,
    [GET_MANY_REFERENCE]: GET_LIST_COMMENTS,
  },
  vendor: {
    [GET_LIST]: GET_LIST_VENDORS,
    [GET_ONE]: GET_VENDOR_ONE,
  },
  vendor_port: {
    [GET_LIST]: GET_LIST_VENDOR_PORTS,
    [GET_ONE]: GET_LIST_VENDOR_PORTS,
  },
}

// Function which defines query overrides for specific resources/fetchTypes.
const customBuildFields = (type: ResourceType, fetchType: FetchType) => {
  const resourceName: string = type.name

  // First check if the resource has any custom queries defined.
  const resourceCustomQueries = CUSTOM_QUERIES[resourceName]

  // If this specific query i.e. resource and fetchType has a custom query, extract the fields from it.
  if (resourceCustomQueries && resourceCustomQueries[fetchType]) {
    return extractFieldsFromQuery(resourceCustomQueries[fetchType])
  }

  // No custom query defined, so use the default query fields (all, but none related/nested).
  return buildFields(type, fetchType)
}

export default customBuildFields
