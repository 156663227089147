import React, { FC, useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { FieldProps, useNotify, useRefresh, useUpdate } from 'react-admin'
import { CustomerDocument_field, Customer_fields } from 'src/types/globalTypes'
import Button from '@material-ui/core/Button'
import SectionTitle from 'src/components/SectionTitle'
import Dot from 'src/icons/Dot'
import EditCustomerDocumentDialog from './EditCustomerDocumentDialog'

interface Props extends FieldProps<Customer_fields> {
  disabled?: boolean
}

const CustomerDocument: FC<Props> = ({ record, disabled }) => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState<CustomerDocument_field[]>([])

  const [updateItem] = useUpdate()
  const refresh = useRefresh()
  const notify = useNotify()

  useEffect(() => {
    if (!record) return undefined

    if (record.document.length) {
      setData(record.document)
    }
  }, [record])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let index = data.findIndex((item) => item.label === event.target.name)
    let updateData = [...data]
    updateData[index].isUsed = event.target.checked
    setData(updateData)
  }

  const handleSave = () => {
    if (record?.id) {
      updateItem(
        'customer',
        record.id,
        { document: data },
        {},
        {
          onSuccess: () => {
            notify('resources.global.notifications.success.update', { type: 'info' })
            refresh()
          },
        }
      )
    }
  }

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <SectionTitle label="resources.customer.fieldGroups.document" />
          <Button size="small" color="primary" variant="outlined" onClick={() => setOpen(true)} disabled={disabled}>
            Manage
          </Button>
        </Box>
        {data.length
          ? data
              .filter((item) => item.isUsed === true)
              .map((item, index) => (
                <Box key={index} ml={{ xs: 3 }} display="flex" alignItems="center">
                  <Dot size="small" />
                  <Typography style={{ marginLeft: `8px` }} component="p" key={index} variant="caption">
                    {item.label}
                  </Typography>
                </Box>
              ))
          : null}
      </CardContent>

      {open && (
        <EditCustomerDocumentDialog
          open={open}
          data={data}
          onChange={handleChange}
          onClose={() => setOpen(false)}
          onSubmit={handleSave}
        />
      )}
    </Card>
  )
}

CustomerDocument.defaultProps = {
  source: 'document',
  label: 'Document',
}

export default CustomerDocument
