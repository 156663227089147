import React, { FC } from 'react'
import { ChipField, ReferenceArrayField, ReferenceArrayFieldProps, SingleFieldList } from 'react-admin'

const PermissionGroupReferenceField: FC<
  Omit<ReferenceArrayFieldProps, 'reference' | 'children' | 'source'> & {
    source?: string
  }
> = (props) => {
  return (
    <ReferenceArrayField source="permission_group_ids" reference="permission_group" {...props}>
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  )
}

PermissionGroupReferenceField.defaultProps = {
  source: 'pack.id',
  addLabel: true,
}

export default PermissionGroupReferenceField
