import React, { FC } from 'react'
import { Edit, EditProps } from 'react-admin'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'
import PackAside from './PackAside'
import PackDetailForm from './PackDetailForm'

const Title = ({ record }: any) => {
  return record.name
}

const PackEdit: FC<EditProps> = (props) => {
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Pack #${props.id}`} />
      <RouteBreadcrumbs {...props} />
      <Edit {...props} title={<Title />} aside={<PackAside />}>
        <PackDetailForm redirect={false} />
      </Edit>
    </>
  )
}

export default PackEdit
