import React, { FC } from 'react'
import { Datagrid, Filter, FilterProps, List, SearchInput, TextField } from 'react-admin'
import { Helmet } from 'react-helmet'
import { WEB_APP_TITLE } from 'src/constants'
import RouteBreadcrumbs from '../../components/RouteBreadcrumbs'

const ExpenseFilter: FC<Omit<FilterProps, 'children'>> = (props) => (
  <Filter {...props}>
    <SearchInput source="code" alwaysOn />
  </Filter>
)

const ExpenseList = () => {
  return (
    <>
      <Helmet title={`${WEB_APP_TITLE} - Expenses`} />
      <RouteBreadcrumbs />
      <List
        resource="customer"
        basePath={`/expenses`}
        perPage={25}
        sort={{ field: 'code', order: 'ASC' }}
        filters={<ExpenseFilter />}
        bulkActionButtons={false}
        hasCreate={false}
      >
        <Datagrid rowClick="edit">
          <TextField source="code" />
          <TextField source="expenses_aggregate.aggregate.count" label="Items" />
        </Datagrid>
      </List>
    </>
  )
}

export default ExpenseList
