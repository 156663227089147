import { InvoicePDFSettingsProps, Invoice_expanded } from 'src/pages/invoices/types'
import { empty_brackets } from 'src/utils/emptyBrackets'
import { formatTimestampToDate } from 'src/utils/formatDate'

interface Props {
  data: Invoice_expanded
  customerPort?: string
  invoiceColSpan: number
  leftColSpan: number
  rightColSpan: number
  settings: InvoicePDFSettingsProps
}

export function invoice_header2(props: Props) {
  const { customerPort, invoiceColSpan, leftColSpan, rightColSpan, settings } = props
  const { date, invoice_header, information } = props.data
  const { company_name, address_books } = invoice_header?.order.customer as any
  const { vessel, seal_no, bl_number, cont_no, term_of_payment, feeder, within, is_before_vessel } = information

  let refInv = settings.showCustomRefInv ? invoice_header?.custom_ref_inv || '' : `CK${invoice_header?.ref_invoice}`
  let short_pi = ''

  if (invoice_header?.order) {
    let names_arr = invoice_header.order.ref_pi.split('/')
    let findNumeric = names_arr[0].match(/[0-9]{3}/)
    let vendorNo = findNumeric?.shift()
    let customerNo = names_arr[1]
    short_pi = `${vendorNo}_${customerNo}`
  }

  // let ref_pi = invoice_header?.order.ref_pi.split(' ')[1].split('/').slice(0, 2).join('_')

  return [
    [
      {
        colSpan: invoiceColSpan,
        text: 'PACKING LIST',
        border: [false, false, false, false],
        style: 'headerText',
      },
      ...empty_brackets(6),
    ],
    [
      {
        colSpan: leftColSpan,
        rowSpan: 2,
        lineHeight: 1.2,
        style: 'tableHeader',
        text: `Sold to/Buyer/Consignee
                    ${company_name}.
                    ${address_books[0].address_1 || ''} ${
          address_books[0].address_2 ? `\n${address_books[0].address_2}` : ''
        } ${address_books[0].tel ? `\nTEL: ${address_books[0].tel}` : ''}${
          address_books[0].phone ? `\nMOB: ${address_books[0].phone}` : ''
        }`,
      },
      {},

      {
        colSpan: rightColSpan,
        style: 'tableHeader',
        text: `Invoice No: ${refInv}
                    Date: ${formatTimestampToDate(date, '/')}
                    Ref.P/I:  ${short_pi}`,
      },
      ...empty_brackets(4),
    ],

    [
      ...empty_brackets(2),
      {
        colSpan: rightColSpan,
        style: 'tableHeader',
        text: `Cont No: ${cont_no}
                  Seal No: ${seal_no}
                  BL No: ${bl_number || ''}`,
      },
      ...empty_brackets(4),
    ],

    [
      {
        colSpan: leftColSpan,
        style: 'tableHeader',
        text: `Vessel: ${vessel}
                  Feeder: ${feeder}
                  Destination: ${customerPort}`,
      },
      {},
      {
        colSpan: rightColSpan,
        text: `Terms of Payment: ${term_of_payment?.toUpperCase()}
                  ${is_before_vessel ? 'BEFORE VESSEL ARRIVAL' : `WITHIN ${within} DAYS AFTER VESSEL ARRIVAL`}`,
      },
      ...empty_brackets(4),
    ],

    [
      { style: 'tcCell', text: 'MARK & NOS' },
      { style: 'tlCell', text: 'DESCRIPTION OF GOODS' },
      { style: 'tcCell', text: 'SIZE' },
      { style: 'tcCell', text: 'WET/MC (KG.)' },
      { style: 'tcCell', text: 'TOTAL (MC)' },
      { style: 'tcCell', text: 'NET WEIGHT (KG.)' },
      { style: 'tcCell', text: 'GROSS WEIGHT (KG.)' },
    ],
  ]
}
