import React, { useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { useForm, useFormState } from 'react-final-form'
import OrderTotal from './OrderTotal'
import Separate from 'src/components/Separate'
import { reviseInitialValues } from '../constants/order'
import getOrderSumValues from '../utils/getOrderSumValues'
import OrderAddItemsDialog from './OrderAddItemsDialog'
import { OrderAddLineItemInput, OrderReviseCreateInput } from '../types/input'
import formatProductVariantToLineItem from '../utils/formats/formatProductVariantToLineItem'
import OrderTableItemsCreate from './OrderTableItemsCreate'

const OrderLineItemsTabCreate = () => {
  const { change } = useForm()
  const { values } = useFormState()
  const [open, setOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState<OrderAddLineItemInput[]>([])
  const [revise, setRevise] = useState<OrderReviseCreateInput>(reviseInitialValues)

  const tableRef = useRef<any>()

  let unit_code = values.unit_code_fk_id === '0959d6e8-6fe4-4ab8-81da-a40c61726226' ? 'kg' : 'mc'

  const {
    shipping_cost,
    shipping_expense_usd,
    total_cost_usd,
    total_ctn,
    total_amount,
    total_net_weight_kg,
    total_gross_weight_kg,
  } = getOrderSumValues({
    unit_code,
    lineItems: values.order_line_items,
    revise,
    exchange_rate: values.exchange_rate,
  })

  useEffect(() => {
    let getRevise: OrderReviseCreateInput = reviseInitialValues
    if (values) {
      if (values?.order_revises.length) {
        getRevise = {
          name: values.order_revises[0].name,
          date: values.order_revises[0].date,
          exchange_rate_info: values.order_revises[0].exchange_rate_info,
          expense_id: values.order_revises[0].expense_id,
          expense_rate_info: values.order_revises[0].expense_rate_info,
          revise_key: 0,
          shipping_baht: values.order_revises[0].shipping_baht,
          gross_percentage: values.order_revises[0].gross_percentage,
          locale_currency_fk: 'USD',
        }
      }

      setRevise(getRevise)
    }
  }, [values])

  function handleAddItemSave() {
    let newValue = selectedItems.map((item) => {
      let format = formatProductVariantToLineItem(item, 0, 2)
      return format
    })

    change('order_line_items', newValue)
    handleAddItemCancel()
  }

  function handleAddItemCancel() {
    // Should check that the element is available to prevent errors
    if (tableRef.current) {
      tableRef.current.onAllSelected(false)
    }
    setSelectedItems([])
    setOpen(false)
  }

  function handleSelectionChange(row: OrderAddLineItemInput[]) {
    setSelectedItems(row)
  }

  return (
    <>
      <Box>
        <OrderTotal
          unit_code={unit_code}
          countItems={values.order_line_items.length || 0}
          shipping_usd={revise.exchange_rate_info.shipping_usd || 0}
          etc_cost={revise.exchange_rate_info.etc_cost || 0}
          exchange_rate={values.exchange_rate}
          shipping_cost={shipping_cost}
          shipping_expense_usd={shipping_expense_usd}
          total_ctn={total_ctn}
          total_amount={total_amount}
          total_cost_usd={total_cost_usd}
          total_gross_weight_kg={total_gross_weight_kg}
          total_net_weight_kg={total_net_weight_kg}
          total_product_samples_net_wet_kg={0}
        />
        <Separate value={3} />
        <Divider />
        <Separate value={3} />
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
            Add Product
          </Button>
        </Box>
        <Separate value={3} />
        <OrderTableItemsCreate vendor_fk_code={values.vendor_fk_code} lineItems={values.order_line_items} />
      </Box>
      {open && (
        <OrderAddItemsDialog
          open={open}
          vendor_fk_code={values.vendor_fk_code}
          customer_fk_code={values.customer_fk_code}
          tableRef={tableRef}
          disabled={selectedItems.length ? false : true}
          onSelectionChange={handleSelectionChange}
          onClose={() => setOpen(false)}
          onCancel={handleAddItemCancel}
          onSave={handleAddItemSave}
        />
      )}
    </>
  )
}

export default OrderLineItemsTabCreate
