import { Payment_fields } from 'src/types/globalTypes'

export const paymentInitialValues: Omit<Payment_fields, 'id' | 'column_id'> = {
  title: '',
  customer_fk_code: null,
  date: new Date(),
  payment_date: new Date(),
  remark: '',
  rate: 0,
  amount_deposit: 0,
  bank_charge: 0,
  exchange: 0,
  paid_type: 'over_sea',
  type: 'deposit',
  bank: '',
  check_number: '',
  currency_fk: 'USD',
  isNegative: false,
  negatiev_adjust: 0,
}
