import React, { FC, useCallback, useEffect, useState } from 'react'
// import Welcome from './Welcome'
import Container from '@material-ui/core/Container'
import RecentOrders from './RecentOrders'
import RecentInvoices from './RecentInvoices'
import ExchangeRatesDaily from './ExchangeRatesDaily'
import Separate from 'src/components/Separate'
import EditExchangeRateDialog from './EditExchangeRateDialog'
import { useDataProvider, useRefresh, useUpdate } from 'react-admin'
import { Order_fields, WidgetValues_field } from 'src/types/globalTypes'
import { formatDateToAPI } from 'src/utils/formatDate'
import CalendarEvents from './CalendarEvents'
import useVendorsByUser from 'src/hooks/useVendorsByUser'
import useUser from 'src/hooks/useUser'
import usePermissionByUser from 'src/hooks/usePermissionByUser'

interface State {
  nb_items?: number
  recentOrders: Order_fields[]
}

const Dashboard: FC = () => {
  const { isSuperuser } = useUser()
  const [open, setOpen] = useState(false)
  const [selectedId, setSelected] = useState('')
  const [update] = useUpdate()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()
  const { data: vendorsByUser } = useVendorsByUser()
  const { permissions } = usePermissionByUser()

  const fetchOrders = useCallback(async () => {
    let year = new Date().getFullYear()
    const { data: recentOrders } = await dataProvider.getList<Order_fields>('order', {
      filter: {
        vendor_fk_code: vendorsByUser,
        'pi_date@_gte': `${year}-01-01`,
        status: ['order_received', 'order_approved', 'processing_order', 'rev_order_inprocess'],
      },
      sort: { field: 'pi_date', order: 'DESC' },
      pagination: { page: 1, perPage: 25 },
    })
  }, [vendorsByUser, dataProvider])

  const [exchanges, setExchanges] = useState<WidgetValues_field>({
    buy_rate: 0,
    sale_rate: 0,
    date_buy: new Date(),
    date_sale: new Date(),
  })

  useEffect(() => {
    fetchOrders()
  }, [fetchOrders])

  function handleOpen(id: string) {
    setSelected(id)
    setOpen(true)
  }

  function handleClose() {
    setSelected('')
    setOpen(false)
  }

  function handleSave() {
    let newValue = { ...exchanges, date_buy: formatDateToAPI(new Date()), date_sale: formatDateToAPI(new Date()) }
    update(
      'widgets',
      selectedId,
      { values: newValue },
      {},
      {
        onSuccess: () => {
          refresh()
          handleClose()
        },
      }
    )
  }

  return (
    <Container maxWidth="lg">
      {/* <Welcome /> */}
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          {permissions['order'].read && <RecentOrders />}
          <Separate value={2} />
          {(permissions['invoice-1'].read || permissions['invoice-2'].read || permissions['invoice-3'].read) && (
            <RecentInvoices />
          )}
        </div>

        <div style={styles.rightCol}>
          <CalendarEvents />
          <Separate value={2} />
          <ExchangeRatesDaily isSuperuser={isSuperuser} onClick={handleOpen} />
          {open && selectedId && (
            <EditExchangeRateDialog
              id={selectedId}
              open={open}
              state={exchanges}
              setState={setExchanges}
              onClose={handleClose}
              onSave={handleSave}
            />
          )}
        </div>
      </div>
    </Container>
  )
}

export default Dashboard

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 2, marginRight: '0.5em' },
  rightCol: { marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
}
